import { ReactNode, useCallback } from "react";
import classNames from "classnames";
import Card from "@components/embla/card";
import MarkingIcon from "@pages/pdfviewer/component/shared/markingIcon";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import { MarkingModel } from "../../../models/markingModel";
import styles from "./markingPresentationPreview.module.scss";

interface SimpleMarkingPreviewProps {
  marking: MarkingModel;
  children?: ReactNode;
  toggleMarkingClicked?: (id: string, value: boolean) => void;
  toggleMarkingState: boolean;
  disableCheckbox?: boolean;
}

const MarkingPresentationPreview = ({
  marking,
  children,
  toggleMarkingClicked,
  toggleMarkingState,
  disableCheckbox
}: SimpleMarkingPreviewProps) => {
  if (!marking.id) {
    throw new Error("marking id must have a value");
  }

  const markingSwitchChanged = useCallback(
    (value: boolean) => {
      if (!marking.id) {
        throw new Error("marking id must have a value");
      }
      if (toggleMarkingClicked) {
        return toggleMarkingClicked(marking.id, value);
      }
    },
    [marking.id, toggleMarkingClicked]
  );

  return (
    <>
      <Card.Body className="small">
        <div className="row">
          <div className="col-6 d-flex align-items-start">
            <div className="d-flex align-items-center">
              <div className={classNames("flex-shrink-0", styles.markingIcon)}>
                <MarkingIcon markingType={marking.type} />
              </div>
              <div className="margin-left-s d-flex">
                <div
                  className={classNames(
                    "small",
                    "margin-bottom-xs",
                    "text-nowrap",
                    "text-truncate",
                    styles.nameContainer,
                    marking.isPrivate && styles.isPrivate,
                    styles.tagNameOverflow
                  )}
                >
                  {marking.owner.name}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 d-flex align-items-start justify-content-end">
            <div className="d-flex align-items-center">
              <div className={classNames("marking-presentation-switch margin-left-m")}>
                <CheckboxSwitch
                  debounceLength={10}
                  id={marking.id}
                  onchangeCallback={markingSwitchChanged}
                  externalChecked={toggleMarkingState}
                  disabled={disableCheckbox}
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
      {children && <Card.Body>{children}</Card.Body>}
    </>
  );
};

export default MarkingPresentationPreview;
