import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export enum IntervalExtractionType {
  AllDocument = 0,
  DefinedPages = 1,
  PagesWithVerticalLines = 2,
  NoDocuments = 3
}

export type AddDocumentsToDraftModel = {
  sectionId: string;
  documents: {
    caseDocumentId: string;
    pageIntervals: string;
    intervalExtractionType: IntervalExtractionType;
    sortOrder?: number;
  }[];
};

export type DraftDocumentCreateModel = {
  caseDocumentId: string;
  title: string;
  includePagesWithVerticalLines: boolean;
};

export type DraftDocumentCreateFormModel = {
  isSelected: boolean;
  document: DraftDocumentCreateModel;
};

export type AddDocumentsToDraftFormModel = {
  documents: DraftDocumentCreateFormModel[];
};
// "1"; "1, 2, 3"; "1-3, 5, 7-10"; " 1 - 3 , 5 , 7 - 10 "; ""
export const pageIntervalRegexString = "^(,?\\s*)[0-9]+(?:(?:\\s*,\\s*|\\s*-)[0-9]+)*(,?\\s*)$|^$";

const draftDocumentCreateModelValidation = (localizer: Localizer) =>
  createValidationSchema<DraftDocumentCreateModel>({
    caseDocumentId: yup.string().required(),
    title: yup.string(),
    includePagesWithVerticalLines: yup.boolean(),
    pageIntervals: yup.string().matches(RegExp(pageIntervalRegexString), 'e.g. "1-3,20,24-50"') //TODO: localize and make better message
  });

const draftDocumentCreateFormModelValidation = (localizer: Localizer) =>
  createValidationSchema<DraftDocumentCreateFormModel>({
    document: draftDocumentCreateModelValidation(localizer),
    isSelected: yup.boolean()
  });

export const addDocumentsToDraftFormModelValidation = (localizer: Localizer) =>
  createValidationSchema<AddDocumentsToDraftFormModel>({
    documents: yup.array().of(draftDocumentCreateFormModelValidation(localizer))
  });
