import { useCallback } from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import { useModalMessage } from "@components/modal/modalMessageContext";
import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";

const useSafeMarkingClose = () => {
  const localizer = useLocalization();
  const { showModalMessage } = useModalMessage();
  const { activeMarking, setActiveMarking, setHoveredMarkingId } = useMarkingsPageContext();

  const closeCard = useCallback(
    (discard = false) => {
      if (activeMarking?.unsaved && !discard) {
        showModalMessage({
          title: localizer.unsavedChangesWarningTitle(),
          description: localizer.unsavedChangesWarningText(),
          cancelButtonText: localizer.back(),
          acceptAction: () => closeCard(true)
        });
      } else {
        setActiveMarking(undefined);
        setHoveredMarkingId(undefined);
      }
    },
    [activeMarking?.unsaved, localizer, setActiveMarking, setHoveredMarkingId, showModalMessage]
  );

  return { closeCard };
};

export default useSafeMarkingClose;
