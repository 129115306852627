import Crud from "@components/embla/crud";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { ChangePasswordModel } from "@services/api/user/models/changePasswordModel";
import { changePasswordValidation } from "./changePasswordValidation";

interface ProfileProps {
  userId: string;
  onSubmit: (model: ChangePasswordModel, resetForm: () => void) => void;
}

const ChangePasswordForm = ({ userId, onSubmit }: ProfileProps) => {
  const localizer = useLocalization();

  const defaultValues: ChangePasswordModel = {
    id: userId,
    password: "",
    repeatPassword: ""
  };

  const methods = useValidatedForm({
    defaultValues,
    validationSchema: changePasswordValidation(localizer)
  });

  return (
    <form onSubmit={methods.handleSubmit((model) => onSubmit(model, methods.reset))}>
      <Crud
        title={localizer.changePassword()}
        body={
          <>
            <FormInput
              methods={methods}
              id="profile-new-password"
              label={localizer.newPassword()}
              placeholder={localizer.newPasswordPlaceholder()}
              name="password"
              type="password"
              autoComplete="new-password"
            />

            <FormInput
              methods={methods}
              id="profile-repeat-password"
              label={localizer.repeatNewPassword()}
              placeholder={localizer.repeatNewPasswordPlaceholder()}
              name="repeatPassword"
              type="password"
              autoComplete="repeat-new-password"
            />
          </>
        }
        footer={
          <FormSubmitButton state={methods.formState}>
            {localizer.changePassword()}
          </FormSubmitButton>
        }
      />
    </form>
  );
};

export default ChangePasswordForm;
