import classNames from "classnames";
import { useEffect, useState } from "react";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Button from "@components/embla/button";
import { SubmitState } from "@components/forms/FormSubmitButton";
import WizardPanelContextProvider from "@components/embla/wizard/wizardPanelContextProvider";
import { WizardActionButton } from "@components/embla/wizard/wizardActionButton";
import styles from "./wizardPanel.module.scss";

export type WizardStep = {
  stepNumber: number;
  active: boolean;
  title: string;
  content: JSX.Element;
  onPrevious?: () => void;
  onNext: () => void;
};

type WizardPanelProps = {
  steps: WizardStep[];
  submitButtonText: string;
  submitState?: SubmitState;
};

const getMaxNumberInArray = (numberArray: number[]) =>
  numberArray.reduce((prev, current) => (current > prev ? current : prev));

export const WizardPanel = ({ steps, submitButtonText, submitState }: WizardPanelProps) => {
  const localizer = useLocalization();
  const [currentStep, setCurrentStep] = useState<WizardStep>(
    steps.find((step) => step.active) ?? steps[0]
  );

  useEffect(() => {
    setCurrentStep(steps.find((x) => x.active) ?? steps[0]);
  }, [steps]);

  const isFinalStep =
    currentStep.stepNumber === getMaxNumberInArray(steps.map((step) => step.stepNumber));

  return (
    <WizardPanelContextProvider>
      <div className={classNames("wizard-panel", styles.wizardPanel)}>
        <div className="wizard-steps">
          {steps.map((step) => (
            <div
              key={step.stepNumber}
              className={classNames(
                "wizard-step ",
                currentStep.stepNumber === step.stepNumber && "active-step",
                currentStep.stepNumber > step.stepNumber && "done-step"
              )}
            >
              <div className={classNames(styles.wizardStepContent, "wizard-step-content")}>
                {currentStep.stepNumber > step.stepNumber ? (
                  <EmblaIcon iconName="success" />
                ) : (
                  step.stepNumber
                )}
              </div>
              <div className="wizard-step-text">{step.title}</div>
            </div>
          ))}
        </div>
        <div className={classNames(styles.wizardContent, "wizard-content")}>
          <div className={classNames(styles.headerAndContent)}>
            <h3 className="wizard-content-header">{currentStep.title}</h3>
            <div className="mb-4">{currentStep.content}</div>
          </div>

          <div className={classNames(styles.actions, "wizard-actions")}>
            {currentStep.onPrevious && (
              <div className="wizard-actions-left">
                <Button theme="default" onClick={currentStep.onPrevious}>
                  {localizer.previous()}
                </Button>
              </div>
            )}
            <div className="wizard-actions-right">
              <WizardActionButton
                submitButtonText={submitButtonText}
                isFinalStep={isFinalStep}
                currentStep={currentStep}
                submitState={submitState}
              />
            </div>
          </div>
        </div>
      </div>
    </WizardPanelContextProvider>
  );
};
