import { Popover } from "@headlessui/react";
import classNames from "classnames";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { EmblaIcon, IconSize } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Card from "@components/embla/card";
import Button from "@components/embla/button";
import { useGetCaseDocumentsPaginatedQuery } from "@services/api/document/caseDocumentApi";
import { CaseDocumentPaginatedListInput } from "@services/api/document/models/CaseDocumentPaginatedListInput";
import KeyNavigation from "@pages/pdfviewer/component/searchPopover/keyNavigation";
import { CaseDocumentPaginatedListModel } from "@services/api/document/models/caseDocumentPaginatedListModel";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import { PaginatedListSortOrderInput } from "@models/paginatedList/paginatedListSortOrderInput";
import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";
import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import styles from "./locatePagePopover.module.scss";
import {
  PRESENTATION_BROADCAST_CHANNEL,
  PresentationAction,
  PresentationActionType
} from "./presentationBroadcastActions";
import { setTempPresentationPageState } from "./presentationSlice";

interface LocatePagePopoverProps {
  caseId: string;
  open: boolean;
  onClosePopoverClicked: () => void;
}

type PageNumberModel = {
  pageNumber?: number;
};

const intialCaseDocumentsPaginatedListInput: CaseDocumentPaginatedListInput = {
  draw: 0,
  start: 0,
  length: 10,
  search: {
    value: "",
    regex: false
  },
  excludedTypes: [CaseElementTypeEnum.ExtractDraft],
  types: [],
  order: [{ columnName: "name", dir: "asc" } as PaginatedListSortOrderInput]
};

const LocatePagePopover = ({ caseId, open, onClosePopoverClicked }: LocatePagePopoverProps) => {
  const localizer = useLocalization();
  const dispatch = useDispatch();
  const [paginatedListInput, setPaginatedListInput] = useState(
    intialCaseDocumentsPaginatedListInput
  );
  const [chosenDocument, setChosenDocument] = useState<CaseDocumentPaginatedListModel | undefined>(
    undefined
  );
  const { data: caseDocuments } = useGetCaseDocumentsPaginatedQuery({
    caseId,
    input: paginatedListInput
  });

  const { postMessage } = useBroadcastChannel<PresentationAction>({
    name: PRESENTATION_BROADCAST_CHANNEL
  });

  const onInputChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatedListInput((x) => ({
      ...x,
      search: {
        value: event.target.value,
        regex: false
      }
    }));
  }, 200);

  const methods = useValidatedForm({
    validationSchema: createValidationSchema<PageNumberModel>({
      pageNumber: yup
        .number()
        .min(1)
        .max(chosenDocument?.pageCount ?? 0)
        .required()
    }),
    mode: "onSubmit",
    defaultValues: {
      pageNumber: undefined
    } as PageNumberModel
  });

  const closePopover = useCallback(() => {
    onClosePopoverClicked();
    setChosenDocument(undefined);
    methods.reset();
    setPaginatedListInput(intialCaseDocumentsPaginatedListInput);
  }, [methods, onClosePopoverClicked]);

  const onSubmit = (model: PageNumberModel) => {
    if (chosenDocument && model.pageNumber) {
      postMessage({
        type: PresentationActionType.ShowTempPage,
        action: { caseDocumentId: chosenDocument.id, pageIndex: model.pageNumber - 1 }
      });
      dispatch(
        setTempPresentationPageState({
          documentId: chosenDocument.id,
          pageIndex: model.pageNumber - 1
        })
      );
    }
    closePopover();
  };

  return (
    <Popover>
      {open && (
        <Popover.Panel className={styles.popoverPanel} static as={KeyNavigation}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {localizer.locatePage()}
                <Button iconBtn borderless onClick={closePopover}>
                  <EmblaIcon iconName="close" size={IconSize.Large} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {chosenDocument ? (
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      {localizer.show()}
                      <FormInput
                        methods={methods}
                        id={"pageNumber"}
                        type="number"
                        style={{ width: "100px" }}
                        autoFocus
                        hideErrorText
                        name="pageNumber"
                        formGroupClassName="margin-left-s margin-right-s"
                        required
                      />
                      {`${localizer.of()} ${chosenDocument.pageCount}`}
                    </div>
                    <Button theme={"primary"} type="submit">
                      {localizer.show()}{" "}
                      <span className="text-lowercase">{localizer.pdfPage()}</span>{" "}
                      <EmblaIcon iconName={"forward"} />
                    </Button>
                  </div>
                </form>
              ) : (
                <>
                  <KeyNavigation.Item key={0} index={0}>
                    <input
                      id={styles.searchInput}
                      autoFocus
                      className={classNames("form-control")}
                      placeholder={localizer.searchAtLeast3Characters()}
                      onChange={onInputChange}
                    />
                  </KeyNavigation.Item>
                  <div className="mt-2">
                    {caseDocuments &&
                      caseDocuments.data.map((caseDocument, i) => (
                        <KeyNavigation.Item key={i + 1} index={i + 1}>
                          <div
                            className={classNames("d-flex py-2", styles.navigationItem)}
                            tabIndex={0}
                            role="button"
                            onKeyPress={() => setChosenDocument(caseDocument)}
                            onClick={() => setChosenDocument(caseDocument)}
                          >
                            <span>
                              <EmblaIcon iconName={"document"} />
                            </span>
                            <span className="text-truncate">{caseDocument.title}</span>
                          </div>
                        </KeyNavigation.Item>
                      ))}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default LocatePagePopover;
