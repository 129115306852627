import classNames from "classnames";
import { FieldValues } from "react-hook-form";
import { LineAppearance } from "@pages/pdfviewer/component/models/markingModel";
import Button from "@components/embla/button";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import radioStyles from "../../shared/radioGroup.module.scss";
import VerticalLinesSelect from "../VerticalLinesSelect/VerticalLinesSelect";
import styles from "./VerticalLinesSelectModal.module.scss";

type VerticalLinesSelectProps = {
  name: string;
  id: string;
  isOpen: boolean;
  onOpen: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VerticalLinesSelectModal = <T extends FieldValues = any>({
  methods,
  name,
  id,
  isOpen,
  onOpen
}: FormControlProps<T, VerticalLinesSelectProps>) => {
  const selectedStyle = methods.getValues(name);

  return (
    <>
      <Button borderless onClick={onOpen} className="p-1">
        {selectedStyle === LineAppearance.Brackets ? (
          <div className={classNames(radioStyles.radio, styles.bracketsContainer, "rounded")}>
            <div className={styles.Brackets} />
          </div>
        ) : (
          <div
            className={classNames(
              radioStyles.radio,
              styles.lineStyle,
              "rounded",
              styles[LineAppearance[selectedStyle]]
            )}
          />
        )}
      </Button>
      {isOpen && (
        <VerticalLinesSelect
          top={45}
          externalOnChange={onOpen}
          id={id}
          methods={methods}
          name={name}
        />
      )}
    </>
  );
};

export default VerticalLinesSelectModal;
