import classNames from "classnames";
import { Spinner } from "@components/spinner/spinner";
import { EmblaIcon } from "./emblaIcon";
import styles from "./statusIcon.module.scss";

export type StatusType = "loading" | "error" | "success";

interface StatusIconProps {
  status: StatusType;
  inverted?: boolean;
}

const StatusIcon = ({ status, inverted }: StatusIconProps) => {
  switch (status) {
    case "loading":
      return <Spinner size="extra-tiny-small" color={inverted ? "white" : "primary"} />;
    case "success":
      return (
        <EmblaIcon
          iconName="success"
          additionalClasses={classNames(styles.success, inverted && styles.inverted)}
        />
      );
    case "error":
      return (
        <EmblaIcon
          iconName="danger"
          additionalClasses={classNames(styles.error, inverted && styles.inverted)}
        />
      );
  }
};

export default StatusIcon;
