import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clearTitle } from "@components/layout/titleBar/titlebarSlice";
import { Spinner } from "@components/spinner/spinner";
import PdfViewer from "@pages/pdfviewer/component/pdfViewer";
import { useGetDocumentDownloadUrlQuery } from "@services/api/document/caseDocumentApi";

export const PdfViewerPage = () => {
  const dispatch = useDispatch();
  const { caseId, documentId } = useParams();
  if (!caseId || !documentId) {
    throw new Error('Params "caseId" and "documentId" are required');
  }

  const { data: downloadUrlModel, isLoading } = useGetDocumentDownloadUrlQuery({
    caseId,
    documentId
  });

  useEffect(() => {
    dispatch(clearTitle());
  }, [dispatch]);

  if (isLoading || !downloadUrlModel) {
    return <Spinner size="small" />;
  }

  return (
    <PdfViewer
      key={documentId}
      caseId={caseId}
      documentId={documentId}
      title={downloadUrlModel.fileName}
      subTitle={downloadUrlModel.caseTitle}
      fileUrl={downloadUrlModel.downloadUrl}
    />
  );
};
