export class StorageKeys {
  public static readonly token = "token";
  public static readonly localizations = "localizations";
  public static readonly localizationVersion = "localizationVersion";
  public static readonly currentLanguage = "currentLanguage";
  public static readonly user = "user";
  public static readonly offlineFallbackUserInfo = "offlineFallbackUserInfo";

  private static readonly caseSettings = "caseSettings";
  private static readonly caseDocument = "caseDocumentSettings";
  private static readonly offlineLastPresentationSave = "offlineLastPresentationSave";

  public static offlineLastPresentationSaveStorage = (presentationId: string) => {
    return StorageKeys.offlineLastPresentationSave + ":" + presentationId;
  };

  public static caseSettingsStorage = (caseid: string) => {
    return StorageKeys.caseSettings + ":" + caseid;
  };

  public static caseDocumentSettingsStorage = (caseDocumentid: string) => {
    return StorageKeys.caseDocument + ":" + caseDocumentid;
  };
}
