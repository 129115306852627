import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { xor } from "lodash";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { ActiveMarkingInfo, MarkingActiveStateType } from "./models/activeMarkingInfo";
import {
  BulkAddToPresentations,
  BulkAddToPresentationsOrderModel
} from "./models/bulkAddToPresentations";
import { EventHistory } from "./models/markingEvent";
import { MarkingModel } from "./models/markingModel";
import { ActivePdfTool, PdfToolType } from "./models/pdfTool";
import { PresentationInfo } from "./models/presentationInfo";
import { removeMarkingFromEventHistory } from "./utils/updateEventHistory";
import type { RootState } from "@app";

export enum PdfType {
  CaseDocument = "document",
  Presentation = "presentation"
}

export interface PdfViewerState {
  caseId?: string;
  pdfTypeId?: string;
  pdfType?: PdfType;
  title?: string;
  mostVisiblePageIndex: number;
  totalPages: number;
  searchHits: { hitText: string; pageIndex: number }[] | null;
  scale: number;
  toolbarCollapsed: boolean;
  goToPageIndex?: number;
  markingOwnerFilters?: string[];
  markingTypeFilters?: MarkingType[];
  sessionEventHistory: EventHistory;
  activeTool: ActivePdfTool;
  activeMarking?: ActiveMarkingInfo;
  activePresentation?: PresentationInfo;
  bulkAdd?: BulkAddToPresentations;
  hoveredMarkingId?: string;
  dragging?: boolean;
}

const initialState: PdfViewerState = {
  mostVisiblePageIndex: 0,
  goToPageIndex: 0,
  totalPages: 0,
  searchHits: null,
  scale: 1,
  toolbarCollapsed: false,
  sessionEventHistory: { currentEventIndex: -1, eventList: [] },
  activeTool: { type: PdfToolType.SelectMarking }
};

export const pdfViewerSlice = createSlice({
  name: "pdfviewer",
  initialState,
  reducers: {
    initPdfViewer: (state, { payload }: PayloadAction<Partial<PdfViewerState>>) => ({
      ...initialState,
      ...payload,
      activePresentation: state.activePresentation,
      goToPageIndex: state.goToPageIndex //Don't overwrite goToPageIndex(it is controlled by pdfViewer.tsx)
    }),
    setPdfInfo: (
      state,
      {
        payload
      }: PayloadAction<{ title?: string; caseId: string; pdfTypeId: string; pdfType: PdfType }>
    ) => {
      const isNewCase = !!state.caseId && state.caseId !== payload.caseId;
      const newCaseIntialState: Partial<PdfViewerState> = isNewCase
        ? {
            activePresentation: undefined
          }
        : {};

      return {
        ...state,
        ...newCaseIntialState,
        ...payload
      };
    },
    removeEventsFromLogWithMarkingId: (state, action: PayloadAction<string>) => {
      removeMarkingFromEventHistory(state, action.payload);
    },
    updateEventLog: (state, action: PayloadAction<EventHistory>) => {
      state.sessionEventHistory = action.payload;
    },
    setMostVisiblePageIndex: (state, action: PayloadAction<number>) => {
      state.mostVisiblePageIndex = action.payload;
    },
    setGoToIndex: (state, action: PayloadAction<number | undefined>) => {
      state.goToPageIndex = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setSearchHits: (state, action: PayloadAction<{ hitText: string; pageIndex: number }[]>) => {
      state.searchHits = action.payload;
    },
    setScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload;
    },
    setPdfTypeId: (state, action: PayloadAction<string>) => {
      state.pdfTypeId = action.payload;
    },
    setPdfType: (state, action: PayloadAction<PdfType>) => {
      state.pdfType = action.payload;
    },
    setToolbarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.toolbarCollapsed = action.payload;
    },
    setMarkingOwnerFilters: (state, action: PayloadAction<string[]>) => {
      state.markingOwnerFilters = action.payload;
    },
    setMarkingTypeFilters: (state, action: PayloadAction<MarkingType[]>) => {
      state.markingTypeFilters = action.payload;
    },
    setActiveMarking: (
      state,
      {
        payload
      }: PayloadAction<ActiveMarkingInfo | Omit<ActiveMarkingInfo, "activeState" | "unsaved">>
    ) => {
      state.activeMarking = {
        activeState: MarkingActiveStateType.Edit,
        ...state.activeMarking,
        ...payload
      };
    },
    setUnsavedMarking: (state, { payload }: PayloadAction<Partial<MarkingModel<false>>>) => {
      if (state.activeMarking) {
        state.activeMarking.unsaved = payload;
      }
    },
    removeActiveMarking: (state) => {
      delete state.activeMarking;
    },
    setActivePresentation: (state, { payload }: PayloadAction<PresentationInfo | undefined>) => {
      state.activePresentation = payload;
    },
    removeActivePresentation: (state) => {
      delete state.activePresentation;
    },
    bulkAddStart: (state, { payload }: PayloadAction<{ isPresentationsSelectable: boolean }>) => {
      state.bulkAdd = {
        isPresentationsSelectable: payload.isPresentationsSelectable,
        documentId: state.pdfTypeId!,
        presentations: state.activePresentation ? [{ id: state.activePresentation.id }] : [],
        pageIndexes: [state.mostVisiblePageIndex]
      };
    },
    bulkAddStop: (state) => {
      delete state.bulkAdd;
    },
    bulkAddTogglePresentation: (
      state,
      { payload }: PayloadAction<BulkAddToPresentationsOrderModel>
    ) => {
      if (state.bulkAdd) {
        const index = state.bulkAdd.presentations.findIndex(
          (presentation) => presentation.id === payload.id
        );
        if (index === -1) {
          state.bulkAdd.presentations.push(payload);
        } else {
          state.bulkAdd.presentations.splice(index, 1);
        }
      }
    },
    bulkAddTogglePage: (state, { payload }: PayloadAction<number>) => {
      if (state.bulkAdd) {
        state.bulkAdd.pageIndexes = xor(state.bulkAdd.pageIndexes, [payload]);
      }
    },
    bulkAddPageRange: (state, { payload }: PayloadAction<number>) => {
      if (state.bulkAdd) {
        // take last page added to presentation as start of selection
        const startIndex = state.bulkAdd.pageIndexes[state.bulkAdd.pageIndexes.length - 1];
        const endIndex = payload;

        const addToTheEnd = (array: number[], item: number) => {
          // remove if already exists
          const index = array.indexOf(item);
          if (index !== -1) {
            array.splice(index, 1);
          }
          // add to the end
          array.push(item);
        };

        if (startIndex !== undefined) {
          const direction = startIndex < endIndex ? 1 : -1;
          for (let i = startIndex; i !== endIndex; i = i + direction) {
            addToTheEnd(state.bulkAdd.pageIndexes, i);
          }
        }

        addToTheEnd(state.bulkAdd.pageIndexes, endIndex);
      }
    },
    setActiveTool: (state, { payload }: PayloadAction<ActivePdfTool>) => {
      state.activeTool = payload;
    },
    setActiveToolColor: (state, { payload }: PayloadAction<string>) => {
      if ("template" in state.activeTool && "color" in state.activeTool.template.data) {
        state.activeTool.template.data.color = payload;
      }
    },
    resetActiveTool: (state) => {
      state.activeTool = { type: PdfToolType.SelectMarking };
    },
    updateTemplate: (state, { payload }: PayloadAction<MarkingModel<true>>) => {
      if (state.activeTool.type === payload.type) {
        state.activeTool.template = payload;
      }
    },
    setHoveredMarkingId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.hoveredMarkingId = payload;
    },
    setDragState: (state, { payload }: PayloadAction<boolean>) => {
      state.dragging = payload;
    }
  }
});

export const {
  initPdfViewer,
  setPdfInfo,
  removeEventsFromLogWithMarkingId,
  updateEventLog,
  setMostVisiblePageIndex,
  setGoToIndex,
  setTotalPages,
  setSearchHits,
  setScale,
  setPdfType,
  setPdfTypeId,
  setActivePresentation,
  removeActivePresentation,
  bulkAddStart,
  bulkAddStop,
  bulkAddTogglePresentation,
  bulkAddTogglePage,
  bulkAddPageRange,
  setToolbarCollapsed,
  setMarkingOwnerFilters,
  setMarkingTypeFilters,
  setActiveMarking,
  setUnsavedMarking,
  removeActiveMarking,
  setActiveTool,
  setActiveToolColor,
  resetActiveTool,
  updateTemplate,
  setHoveredMarkingId,
  setDragState
} = pdfViewerSlice.actions;
export const pdfViewerStateSelector = (state: RootState) => state.pdfViewer;
export const eventLogStateSelector = (state: RootState) =>
  pdfViewerStateSelector(state).sessionEventHistory;
export const totalPagesSelector = (state: RootState) => pdfViewerStateSelector(state).totalPages;
export const mostVisiblePageIndexSelector = (state: RootState) =>
  pdfViewerStateSelector(state).mostVisiblePageIndex;
export const pdfTitleSelector = (state: RootState) => pdfViewerStateSelector(state).title;
export const pdfTypeIdSelector = (state: RootState) => pdfViewerStateSelector(state).pdfTypeId;
export const pdfTypeSelector = (state: RootState) => pdfViewerStateSelector(state).pdfType;
export const activeMarkingSelector = (state: RootState) =>
  pdfViewerStateSelector(state).activeMarking;
export const activeToolSelector = (state: RootState) => pdfViewerStateSelector(state).activeTool;
export const pdfViewerScaleSelector = (state: RootState) => pdfViewerStateSelector(state).scale;
export const markingOwnerFiltersSelector = (state: RootState) =>
  pdfViewerStateSelector(state).markingOwnerFilters;
export const markingTypeFiltersSelector = (state: RootState) =>
  pdfViewerStateSelector(state).markingTypeFilters;
export const hoveredMarkingIdSelector = (state: RootState) =>
  pdfViewerStateSelector(state).hoveredMarkingId;
export const activePresentationSelector = (state: RootState) =>
  pdfViewerStateSelector(state).activePresentation;
export const bulkAddSelector = (state: RootState) => pdfViewerStateSelector(state).bulkAdd;

export default pdfViewerSlice.reducer;
