import classNames from "classnames";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetCaseMarkingTagsQuery } from "@services/api/case/caseApi";
import { LINE_COLORS, VerticalLinesMarkingModel } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import ColorSelection from "../shared/ColorSelection/ColorSelection";
import MarkingTagsDropdown from "../shared/MarkingTagsDropdown/MarkingTagsDropdown";
import ColorSelectionModal from "../shared/ColorSelectionModal/colorSelectionModal";
import MarkingTagsDropdownModal from "../shared/MarkingTagsDropdownModal/MarkingTagsDropdownModal";
import styles from "./verticalLinesEdit.module.scss";
import VerticalLinesSelect from "./VerticalLinesSelect/VerticalLinesSelect";
import VerticalLinesSelectModal from "./VerticalLinesSelectModal/VerticalLinesSelectModal";

const lineColors = Object.values(LINE_COLORS);

const VerticalLinesEdit = ({
  marking,
  onMarkingChange,
  onSave,
  onDelete,
  onClose,
  isTemplate
}: EditCardProps<VerticalLinesMarkingModel>) => {
  const [openModal, setOpenModal] = useState<"color" | "lines" | "tag" | null>(null);
  const localizer = useLocalization();
  const { caseId } = useParams();

  const markingCantBeDeleted = marking.presentationList && marking.presentationList.length > 0;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: marking.tags,
      data: {
        color: marking.data.color,
        lines: marking.data.lines
      }
    }
  });

  if (!caseId) {
    throw new Error('Param "caseId" is required');
  }

  const { data: markingTags } = useGetCaseMarkingTagsQuery({ caseId: caseId });

  const handleColorSelectionOpen = () => {
    setOpenModal(openModal === "color" ? null : "color");
  };

  const handleVerticalLinesSelectOpen = () => {
    setOpenModal(openModal === "lines" ? null : "lines");
  };

  const handleTagSelectionOpen = () => {
    setOpenModal(openModal === "tag" ? null : "tag");
  };

  return (
    <Card className={classNames(styles.container, isTemplate && styles.templateStyles)}>
      {!isTemplate && <Card.Header>{localizer.verticalLinesTitle()}</Card.Header>}
      <form
        onSubmit={submitHandler}
        className={classNames("d-flex align-items-center gap-8", isTemplate && "shadow-2 p-1")}
      >
        {!isTemplate ? (
          <Card.Body className="d-flex flex-column gap-8 w-100">
            <Card>
              <ColorSelection
                id="vertical-lines-color"
                methods={methods}
                name="data.color"
                colors={lineColors}
                noContainerStyle
              />
              <VerticalLinesSelect
                id={`vertical-lines-styles-${marking.id}`}
                name="data.lines"
                methods={methods}
                noContainerStyle
              />
            </Card>
            <span>{localizer.tags()}</span>
            <MarkingTagsDropdown
              isOpen={openModal === "tag"}
              onOpen={handleTagSelectionOpen}
              methods={methods}
              id={`vertical-lines-tags-${marking.id}`}
              name="tags"
              markingTags={markingTags}
            />
          </Card.Body>
        ) : (
          <>
            <h4 className="px-2">{localizer.verticalLinesTitle()}</h4>
            <ColorSelectionModal
              colors={lineColors}
              id="vertical-lines-color"
              isOpen={openModal === "color"}
              onOpen={handleColorSelectionOpen}
              methods={methods}
              name="data.color"
            />
            <VerticalLinesSelectModal
              id="vertical-lines-styles"
              isOpen={openModal === "lines"}
              onOpen={handleVerticalLinesSelectOpen}
              methods={methods}
              name="data.lines"
            />
            <MarkingTagsDropdownModal
              id={`vertical-lines-tags-${marking.id}`}
              isOpen={openModal === "tag"}
              onOpen={handleTagSelectionOpen}
              methods={methods}
              name="tags"
              markingTags={markingTags}
            />
          </>
        )}
      </form>
      {!isTemplate && (
        <>
          <hr className="m-0" />
          <Card.Footer>
            <MarkingDeleteBtn cantBeDeleted={markingCantBeDeleted ?? false} onDelete={onDelete} />
          </Card.Footer>
        </>
      )}
    </Card>
  );
};

export default VerticalLinesEdit;
