import { MultiPolygon } from "polygon-clipping";
import { SVGProps } from "react";

interface SvgPolygonsProps extends SVGProps<SVGSVGElement> {
  polygons?: MultiPolygon;
}

/**
 * Draws svg with provided polygons.
 * polygons must comply with GeiJSON, mainly:
 * * For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings.
 * * Exterior rings are counterclockwise, and interior rings (holes) are clockwise.
 */
const SvgPolygons = ({ polygons, ...svgProps }: SvgPolygonsProps) => {
  return (
    <svg {...svgProps}>
      {polygons?.map((polygon, i) => (
        <path
          key={i}
          d={polygon
            .map((ring) =>
              ring.map(([x, y], index) => `${index === 0 ? "M " : "L "} ${x} ${y}`).join(" ")
            )
            .join(" ")}
        />
      ))}
    </svg>
  );
};

export default SvgPolygons;
