import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "@components/auth/requireAuth";
import { NotFoundPage } from "@components/error/notFoundPage";
import { Layout } from "@components/layout/layout";
import { AuthRedirectPage } from "@pages/auth/authRedirectPage";
import { ImpersonateRedirectPage } from "@pages/auth/impersonateRedirectPage";
import { DashboardPage } from "@pages/dashboardPage/dashboardPage";
import PresentRouting from "@pages/present/presentRouting";
import ProfilePage from "@pages/profile/profilePage";
import CaseRouting from "./caseRouting";
import EmailRouting from "./emailRouting";
import EmployeeRouting from "./employeeRouting";
import { RoutePaths } from "./routes";

const MainRouting: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.signinRedirect.path} element={<AuthRedirectPage />} />
      <Route path={RoutePaths.impersonateRedirect.path} element={<ImpersonateRedirectPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<DashboardPage />} />

        <Route path={RoutePaths.profile.path} element={<ProfilePage />} />
        <Route path={RoutePaths.case.path} element={<CaseRouting />} />
        <Route
          path={RoutePaths.employees.path}
          element={
            <RequireAuth requiredAccessRight={RoutePaths.employees.accessRight}>
              <EmployeeRouting />
            </RequireAuth>
          }
        />
        <Route path={RoutePaths.emailTemplates.path} element={<EmailRouting />} />
      </Route>
      <Route
        path={RoutePaths.present.path}
        element={
          <RequireAuth>
            <PresentRouting />
          </RequireAuth>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
export default MainRouting;
