import { useMemo, useState } from "react";
import { MultiValue, StylesConfig } from "react-select";
import { Dropdown } from "@components/dropdown/dropdown";
import { useLocalization } from "@components/localization/localizationProvider";
import { Localizer } from "@components/localization/localizer";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { FilterMarkingTypeEnum, MarkingTypeFilterOption } from "./markingTypeFilter";

const defaultFilterOptions = (localizer: Localizer) =>
  [
    {
      label: localizer.all(),
      value: `${FilterMarkingTypeEnum.All}`,
      type: FilterMarkingTypeEnum.All
    },
    {
      label: localizer.none(),
      value: `${FilterMarkingTypeEnum.None}`,
      type: FilterMarkingTypeEnum.None
    },
    {
      label: localizer.comment(),
      value: `${FilterMarkingTypeEnum.Comment}`,
      type: FilterMarkingTypeEnum.Comment
    },
    {
      label: localizer.focusBoxTitle(),
      value: `${FilterMarkingTypeEnum.FocusBox}`,
      type: FilterMarkingTypeEnum.FocusBox
    },
    {
      label: localizer.verticalLinesTitle(),
      value: `${FilterMarkingTypeEnum.VerticalLines}`,
      type: FilterMarkingTypeEnum.VerticalLines
    },
    {
      label: localizer.highlightTitle(),
      value: `${FilterMarkingTypeEnum.Highlight}`,
      type: FilterMarkingTypeEnum.Highlight
    }
  ] as MarkingTypeFilterOption[];

const convertToGlobalMarkingType = (filterMarkingType: FilterMarkingTypeEnum) => {
  switch (filterMarkingType) {
    case FilterMarkingTypeEnum.Comment:
      return MarkingType.Comment;
    case FilterMarkingTypeEnum.FocusBox:
      return MarkingType.FocusBox;
    case FilterMarkingTypeEnum.Highlight:
      return MarkingType.Highlight;
    case FilterMarkingTypeEnum.VerticalLines:
      return MarkingType.VerticalLines;
    default:
      throw new Error(`FilterMarkingType: '${filterMarkingType}' not implemented`);
  }
};

const filterAllAndNone = (filtrationMarkingOptions: MarkingTypeFilterOption[]) => {
  return filtrationMarkingOptions.filter(
    (x) => x.type !== FilterMarkingTypeEnum.All && x.type !== FilterMarkingTypeEnum.None
  );
};

interface MarkingDropdownProps {
  onChangeCallback: (selectedMarkingsTypes: MarkingType[]) => void;
  className?: string;
  stylesOverride?: StylesConfig;
}

const MarkingTypeDropdown = ({ onChangeCallback, ...dropdownProps }: MarkingDropdownProps) => {
  const localizer = useLocalization();
  const filtrationMarkingOptions = useMemo(() => defaultFilterOptions(localizer), [localizer]);

  const [selectedMarkingFilters, setSelectedMarkingFilters] = useState<MarkingTypeFilterOption[]>(
    filterAllAndNone(filtrationMarkingOptions)
  );

  const markingFiltrationChanged = (newVal: MultiValue<MarkingTypeFilterOption>) => {
    let selectedValues: MarkingTypeFilterOption[];

    if (newVal.findIndex((x) => x.type === FilterMarkingTypeEnum.All) >= 0) {
      selectedValues = defaultFilterOptions(localizer);
    } else if (newVal.findIndex((x) => x.type === FilterMarkingTypeEnum.None) >= 0) {
      selectedValues = [];
    } else {
      selectedValues = newVal.map((x) => x);
    }

    selectedValues = filterAllAndNone(selectedValues);
    setSelectedMarkingFilters(selectedValues);

    onChangeCallback(selectedValues.map((x) => convertToGlobalMarkingType(x.type)));
  };

  return (
    <Dropdown
      options={filtrationMarkingOptions}
      onChange={markingFiltrationChanged}
      value={selectedMarkingFilters}
      renderMultiValuesAsText={true}
      isClearable={false}
      hideSelectedOptions={false}
      placeholder={localizer.none()}
      minWidth={130}
      isMulti
      closeMenuOnOptions={[`${FilterMarkingTypeEnum.All}`, `${FilterMarkingTypeEnum.None}`]}
      {...dropdownProps}
    />
  );
};

export default MarkingTypeDropdown;
