import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { NotificationModule } from "ditmer-embla";
import ReactDOMServer from "react-dom/server";
import { Localizer } from "@components/localization/localizer";
import TopMenu from "@components/layout/topMenu/topMenu";
import { RootState } from "@app";
import { DateFormatter } from "src/hooks/useDateFormatter";
import { Config } from "@config/configManager";
import { isProduction } from "src/utility/env";
import { setError } from "./errorSlice";
import GenericErrorPage from "./genericErrorPage";

const mapStateToProps = (state: RootState) => ({
  hasError: state.error.hasError,
  error: state.error.error
});

const mapDispatchToProps = {
  setError: (hasError: boolean, error: unknown) =>
    setError({
      hasError,
      error
    })
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ErrorBoundaryProps = ConnectedProps<typeof connector> & {
  localizer: Localizer;
  dateFormatter: DateFormatter;
  config: Config;
};

const getErrorButtonActionHtml = (
  localizer: Localizer,
  dateFormatter: DateFormatter,
  errorDate: Date,
  mailAddress: string
) => {
  const mailToHref = `mailto:${mailAddress}?subject=${localizer.errorOccuredAt(dateFormatter.time(errorDate), dateFormatter.date(errorDate))}`;
  const mailLinkHtml = ReactDOMServer.renderToStaticMarkup(
    <a href={mailToHref}>{localizer.sendEmail()}</a>
  );
  return $("<button>").addClass("btn btn-danger").html(mailLinkHtml);
};

const showErrorToast = (
  localizer: Localizer,
  dateFormatter: DateFormatter,
  contactSupportEmailAddress: string
) => {
  const now = new Date();
  const toastTitle = localizer.errorOccuredTitle();
  const toastMessage = `${localizer.errorOccuredAt(dateFormatter.time(now), dateFormatter.date(now))} ${localizer.errorOccuredContent()}`;
  const actionsHtml = getErrorButtonActionHtml(
    localizer,
    dateFormatter,
    now,
    contactSupportEmailAddress
  );

  if ($(".toast-error").length === 0 && !isProduction) {
    // needed to use jquery, as NotificationModule does not enable options. We are gonna replace our embla toast module anyway
    NotificationModule.errorTimeout = 0;
    NotificationModule.extendedTimeout = 0;
    NotificationModule.showError(toastTitle, toastMessage, actionsHtml, true);
  }
};

class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>> {
  static getDerivedStateFromError(error: unknown) {
    // eslint-disable-next-line no-console
    console.log("getDerivedStateFromError", error);
    return { hasError: true, error };
  }

  private handleGenericError() {
    const contactSupportEmail = this.props.config.contactSupportEmail;
    showErrorToast(this.props.localizer, this.props.dateFormatter, contactSupportEmail);
  }

  componentDidMount() {
    if (this.props.hasError) {
      // eslint-disable-next-line no-console
      console.log("componentDidMount and error", this.props.error);
      this.handleGenericError();
    }
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const currentError = this.props.error;
    if (this.props.hasError && prevProps.error !== currentError) {
      // eslint-disable-next-line no-console
      console.log("componentDidUpdate and error", currentError);
      this.handleGenericError();
    }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // eslint-disable-next-line no-console
    console.log("componentDidCatch", error, errorInfo);
  }

  renderError() {
    return (
      <div className="body-container">
        <TopMenu />
        <GenericErrorPage />
      </div>
    );
  }

  render() {
    if (this.props.hasError) {
      return this.renderError();
    }

    return this.props.children;
  }
}

const ErrorBoundaryConnected = connector(ErrorBoundary);

export { ErrorBoundaryConnected as ErrorBoundary };
