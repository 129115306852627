import React from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";
import Modal from "@components/modal/modal";
import { EditUploadedDocument } from "@pages/case/documents/addDocuments/uploadDocuments/editUploadedDocument";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";

type DocumentEditProps = {
  caseId: string;
  documentfileName: string;
  documentType: CaseElementTypeEnum;
  dateType: DateTypeEnum;
  documentDate?: string;
  caseDocumentId: string;
  caseDocumentNumber?: string;
};

export const EditDocument: React.FC<DocumentEditProps> = (props) => {
  const localizer = useLocalization();

  return (
    <>
      <Modal.Header>{localizer.editDocument()}</Modal.Header>
      <Modal.Body>
        <EditUploadedDocument
          caseId={props.caseId}
          documentfileName={props.documentfileName}
          documentType={props.documentType}
          dateType={props.dateType}
          documentDate={props.documentDate}
          caseDocumentId={props.caseDocumentId}
          caseDocumentNumber={props.caseDocumentNumber}
          index={1}
          multiline
        />
      </Modal.Body>
    </>
  );
};
