import Cropper, { Area } from "react-easy-crop";
import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Crud from "@components/embla/crud";
import FormFile from "@components/forms/FormFile";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Button from "@components/embla/button";
import { Event } from "@infrastructure/Event";
import styles from "./changeProfilePicture.module.scss";
import { ChangeProfilePictureModel } from "./changeProfilePictureModel";
import { changeProfilePictureValidation } from "./changeProfilePictureValidation";

interface ChangeProfilePictureProps {
  existingProfilePictureUrl?: string;
  onSubmit: (model: File) => void;
  onDelete: (resetForm: () => void) => void;
}

const ChangeProfilePicture = ({
  existingProfilePictureUrl,
  onSubmit,
  onDelete
}: ChangeProfilePictureProps) => {
  const localizer = useLocalization();

  const defaultValues: ChangeProfilePictureModel = { file: undefined };
  const methods = useValidatedForm({
    defaultValues: defaultValues,
    validationSchema: changeProfilePictureValidation(localizer)
  });

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [croppedImgFile, setCroppedImgFile] = useState<File>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(Event.Load, () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeletingFile(false);

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = (await readFile(file)) as string;

      setImageSrc(imageDataUrl);
    }
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener(Event.Load, () => resolve(image));
      image.addEventListener(Event.Error, (error) => reject(error));
      image.src = url;
    });

  const onCropComplete = useCallback((_, croppedAreaPix) => {
    setCroppedAreaPixels(croppedAreaPix);
  }, []);

  const showCroppedImage = useCallback(async () => {
    const getCroppedImg = async (imgUrl: string, pixelCrop: Area): Promise<File> => {
      const image = await createImage(imgUrl);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("canvas context is null");
      }

      // set canvas size to match the bounding box
      canvas.width = image.width;
      canvas.height = image.height;

      // draw rotated image
      ctx.drawImage(image, 0, 0);

      // croppedAreaPixels values are bounding box relative
      // extract the cropped image using these values
      const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      // paste generated rotate image at the top left corner
      ctx.putImageData(data, 0, 0);

      // As a blob
      const objectUrl = await new Promise((resolve, _) => {
        canvas.toBlob((file) => {
          if (file === null) return;

          resolve(file);
        }, "image/jpeg");
      });

      return objectUrl as File;
    };

    if (croppedAreaPixels && imageSrc) {
      const imgCropBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

      setCroppedImgFile(imgCropBlob);
    }
  }, [croppedAreaPixels, imageSrc]);

  useEffect(() => {
    showCroppedImage();
  }, [croppedAreaPixels, showCroppedImage]);

  useEffect(() => {
    setImageSrc("");
    setCroppedImgFile(undefined);
  }, [existingProfilePictureUrl]);

  const submitProfilePicture = async () => {
    if (croppedImgFile) {
      await onSubmit(croppedImgFile);
    }
  };

  const OnDeleteProfilePicture = () => {
    setIsDeletingFile(true);

    onDelete(() => {
      methods.reset();
      if (fileRef.current) {
        fileRef.current.value = "";
      }
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitProfilePicture)}>
      <Crud
        title={localizer.changeProfilePicture()}
        body={
          <>
            <FormFile
              methods={methods}
              id="employee-form-profile-picture"
              label={localizer.profileImage()}
              placeholder={localizer.profileImagePlaceholder()}
              name="file"
              ref={fileRef}
              onChange={onFileChange}
            />
            {(imageSrc || existingProfilePictureUrl) && (
              <div className={classNames(styles.profilePictureContainer)}>
                {imageSrc ? (
                  <div className={classNames(styles.cropContainer)}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      zoomSpeed={0.5}
                      maxZoom={100}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                ) : (
                  existingProfilePictureUrl && (
                    <img
                      className={classNames(
                        styles.currentProfilePicture,
                        "rounded-circle",
                        "margin-top-m"
                      )}
                      alt={"profilepicture"}
                      src={existingProfilePictureUrl}
                    />
                  )
                )}
              </div>
            )}
          </>
        }
        footer={
          <>
            {existingProfilePictureUrl && (
              <Button
                theme="danger"
                status={isDeletingFile ? "loading" : undefined}
                onClick={OnDeleteProfilePicture}
              >
                {localizer.deleteProfilePicture()}
              </Button>
            )}
            <FormSubmitButton
              className="margin-left-xs"
              disabled={croppedImgFile === undefined}
              state={methods.formState}
            >
              {localizer.changeProfilePicture()}
            </FormSubmitButton>
          </>
        }
      />
    </form>
  );
};

export default ChangeProfilePicture;
