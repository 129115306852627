import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import useRetainedState from "src/hooks/useRetainedState";
import { MarkingHubEventType } from "@services/signalRClient/MarkingHubConnectionManager";
import { useHubConnectionManager } from "@components/hubConnectionManager/hubConnectionProvider";
import {
  useChangePageNumberCoordinatesMutation,
  useGetDocumentPagesQuery
} from "@services/api/document/caseDocumentApi";
import { usePostMarkingEventMutation } from "../markingsApi";
import { DocumentMarkingEventResultModel } from "../models/markingEvent";
import styles from "./documentStatus.module.scss";

const DocumentStatus = ({ documentId }: { documentId: string }) => {
  const localizer = useLocalization();
  const { caseId } = useParams<{ caseId: string }>();
  const [, { isLoading, isError, requestId }] = usePostMarkingEventMutation(documentId);
  const [, { isLoading: isLoadingChangeCoordinates, isError: isErrorChangeCoordinates }] =
    useChangePageNumberCoordinatesMutation(documentId);
  const { isFetching } = useGetDocumentPagesQuery({
    caseId: caseId ?? "",
    documentId: documentId ?? ""
  });
  const [isBackendLoading, setIsBackendLoading] = useState(false);
  const retainedIsLoading = useRetainedState(isBackendLoading, 2000);
  const [isBackendProcessingError, setIsBackendProcessingError] = useState(false);

  const hubConnectionManager = useHubConnectionManager();

  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setLoadingCount((prev) => prev + 1);
    }
  }, [isLoading, requestId]);

  useEffect(() => {
    setIsBackendLoading(loadingCount > 0);
  }, [loadingCount]);

  useEffect(() => {
    if (isError) {
      setLoadingCount((prev) => prev - 1);
    }
  }, [isError]);

  useEffect(() => {
    hubConnectionManager.startConnection(documentId).then(() => {
      hubConnectionManager.on(
        documentId,
        MarkingHubEventType.MarkingEventResult,
        (e: DocumentMarkingEventResultModel) => {
          setIsBackendProcessingError(!e.success);
          setLoadingCount((prev) => prev - 1);
        }
      );
    });

    return () => {
      hubConnectionManager.stopConnection(documentId);
    };
  }, [documentId, hubConnectionManager]);

  const showError = useMemo(() => {
    return isError || isBackendProcessingError || isErrorChangeCoordinates;
  }, [isError, isBackendProcessingError, isErrorChangeCoordinates]);

  return (
    <div
      className={classNames(
        "d-flex align-items-center",
        styles.container,
        "joyrideDocumentViewerDocumentStatus"
      )}
    >
      {(retainedIsLoading || isLoadingChangeCoordinates || isFetching) && !showError ? (
        <>
          <Spinner size="extra-tiny-small" color="match-font" className="mr-2" />
          {localizer.saving()}...
        </>
      ) : showError ? (
        <>
          <EmblaIcon iconName="danger" additionalClasses={classNames(styles.statusIcon, "mr-1")} />
          {localizer.couldNotSave()}
        </>
      ) : (
        <>
          <EmblaIcon iconName="success" additionalClasses={styles.statusIcon} />
          {localizer.saved()}
        </>
      )}
    </div>
  );
};

export default DocumentStatus;
