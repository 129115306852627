export interface HttpRequestResultSuccess<TResult> {
  readonly status: "success";
  readonly result: TResult;
}

export interface HttpRequestResultValidationError {
  readonly status: "validation-error";
  readonly errorCode: number;
  readonly validationError: ValidationErrorResponse;
}

export interface HttpRequestResultError {
  readonly status: "error";
  readonly errorCode: number;
  readonly error: unknown;
}

export type ValidationErrorResponse = {
  errorMessage: string;
  errorData: Record<string, string>;
  warningData?: Record<string, string>;
};

export class HttpRequestError extends Error {
  public readonly error: unknown;
  constructor(message: string, error: unknown) {
    super(message);
    this.error = error;
  }
}

export type HttpRequestResult<TResult> =
  | HttpRequestResultSuccess<TResult>
  | HttpRequestResultValidationError;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPayloadValidationError = (payload: any): payload is PayloadValidationError => {
  return isValidationError(payload?.error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidationError = (error: any): error is ValidationError => {
  return error?.data?.errorMessage === "ValidationError";
};

export type PayloadValidationError = {
  error: ValidationError;
};

export type ValidationError = {
  data: ValidationErrorResponse;
  status: 400;
};
