import { useCallback, useMemo } from "react";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  useAddPresentationPageNoteMutation,
  useDeletePresentationPageNoteMutation,
  useUpdatePresentationPageNoteMutation
} from "@services/api/casePresentation/casePresentationApi";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import Button from "@components/embla/button";
import Card from "@components/embla/card";
import { CasePresentationPageNoteModel } from "@services/api/case/models/casePresentationPageModel";
import {
  AddOrUpdateNoteModel,
  AddOrUpdateNoteModelValidation
} from "@services/api/casePresentation/models/AddOrUpdateNoteModel";
import { StatusType } from "@components/embla/statusIcon";
import FormRichTextEditor from "@components/forms/FormRichTextEditor";

interface Props {
  note?: CasePresentationPageNoteModel;
  pageId: string;
  presentationId: string;
  caseId: string;
  onActionFinishedCallback?: () => void;
  onDiscardCallback?: () => void;
  index?: number;
}

export const PresentationSpeakerNoteCreateEditCard = ({
  note,
  pageId,
  presentationId,
  caseId,
  onActionFinishedCallback,
  onDiscardCallback,
  index
}: Props) => {
  const localizer = useLocalization();
  const [addNote, addNoteRequest] = useAddPresentationPageNoteMutation();
  const [updateNote, updateNoteRequest] = useUpdatePresentationPageNoteMutation();
  const [deleteNote, deleteNoteRequest] = useDeletePresentationPageNoteMutation();

  const markdownLinebreakConstant = "&nbsp; ";
  const emblaMarkdownLinebreakConstant = "<br>";

  const noteDefaultValues: AddOrUpdateNoteModel = {
    note: note?.note.replaceAll(markdownLinebreakConstant, emblaMarkdownLinebreakConstant) ?? ""
  };

  const methods = useValidatedForm<AddOrUpdateNoteModel>({
    validationSchema: AddOrUpdateNoteModelValidation(),
    defaultValues: noteDefaultValues,
    mode: "onSubmit"
  });

  const callFinishedCallback = () => {
    onActionFinishedCallback?.();
    methods.reset();
  };
  const callDiscardCallback = () => {
    onDiscardCallback?.();
    methods.reset();
  };

  const onSubmit = (model: AddOrUpdateNoteModel) => {
    model.note = model.note.replaceAll(emblaMarkdownLinebreakConstant, markdownLinebreakConstant);

    if (note?.id) {
      updateNote({
        model: model,
        caseId: caseId,
        presentationPageId: pageId,
        presentationId: presentationId,
        presentationPageNoteId: note.id
      })
        .unwrap()
        .then((_) => callFinishedCallback());
    } else {
      addNote({
        model: model,
        caseId: caseId,
        presentationPageId: pageId,
        presentationId: presentationId
      })
        .unwrap()
        .then((_) => callFinishedCallback());
    }
  };

  const onClickDelete = useCallback(() => {
    if (note?.id) {
      deleteNote({
        caseId: caseId,
        presentationPageId: pageId,
        presentationId: presentationId,
        presentationPageNoteId: note.id
      })
        .unwrap()
        .then((_) => onActionFinishedCallback?.());
    }
  }, [caseId, deleteNote, note?.id, onActionFinishedCallback, pageId, presentationId]);

  const deleteStatus: StatusType | undefined = useMemo(() => {
    if (deleteNoteRequest.isLoading) return "loading";
    else if (deleteNoteRequest.isSuccess) return "success";
    else if (deleteNoteRequest.error) return "error";

    return undefined;
  }, [deleteNoteRequest.error, deleteNoteRequest.isLoading, deleteNoteRequest.isSuccess]);

  return (
    <Card>
      <Card.Body>
        <form className={"d-flex flex-column"} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormRichTextEditor
            methods={methods}
            id={"addOrEditNoteField" + index}
            name="note"
            type="multi-line"
            toolbarItems={["heading", "bold", "italic", "divider", "ul", "ol", "indent", "outdent"]}
            mergefields={[]}
            mergefieldAllowLinks={false}
            required
            disableMergePlugin
          />

          <div className={"d-flex justify-content-between"}>
            <Button
              theme={"default"}
              className={"text-danger mr-2"}
              disabled={!note?.id}
              onClick={onClickDelete}
              status={deleteStatus}
            >
              <EmblaIcon iconName={"delete"} color={IconColor.Danger} /> {localizer.delete()}
            </Button>

            <div>
              <Button theme={"default"} className={"mr-2"} onClick={callDiscardCallback}>
                {localizer.discardChanges()}
              </Button>
              <FormSubmitButton
                isPrimary
                state={{
                  isSubmitting: addNoteRequest.isLoading || updateNoteRequest.isLoading,
                  isSubmitSuccessful: addNoteRequest.isSuccess || updateNoteRequest.isSuccess
                }}
              >
                {localizer.save()}
              </FormSubmitButton>
            </div>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};
