import { CellProps } from "react-table";
import styles from "./tableCell.module.scss";

export const TableCell = <T extends object>({ value }: CellProps<T>) => {
  return (
    <div className={styles["table-cell"]} title={typeof value === "string" ? value : undefined}>
      {value}
    </div>
  );
};
