import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback } from "react";
import { useGetCaseRightsQuery } from "@services/api/case/caseApi";
import { CaseRight } from "../../../infrastructure/CaseRight";

const useCaseRights = (caseId?: string) => {
  const { data, isLoading } = useGetCaseRightsQuery(caseId ?? skipToken);

  const hasCaseRight = useCallback(
    (right: CaseRight) => data?.caseAccessRights.includes(right) ?? false,
    [data]
  );

  const isParticipant = useCallback(
    (participantId: string) => data?.participantId === participantId ?? false,
    [data]
  );

  return { hasCaseRight, isParticipant, isLoading };
};

export default useCaseRights;
