import classNames from "classnames";
import { PillBadge } from "@components/badge/pillBadge";
import { BadgeSize, Color } from "src/utility/constants";
import styles from "./participantBadge.module.scss";

interface ParticipantBadgeProps {
  name: string;
  initials?: string;
  profilePictureUrl?: string;
  badgeContainerClassNames?: string;
  nameFieldClassNames?: string;
  hideNameField?: boolean;
  size?: BadgeSize;
  pill?: boolean;
}

export const ParticipantBadge: React.FC<ParticipantBadgeProps> = ({
  name,
  profilePictureUrl,
  badgeContainerClassNames,
  nameFieldClassNames,
  hideNameField,
  size = BadgeSize.Small,
  initials
}: ParticipantBadgeProps) => {
  const formattedInitials = initials
    ? initials
    : name
        .split(" ")
        .map((word) => word[0] + (word[1] ?? ""))
        .slice(0, 3)
        .join("")
        .toUpperCase();

  return (
    <>
      <div className={classNames(styles.participantBadge, badgeContainerClassNames)}>
        {profilePictureUrl ? (
          <img
            className={classNames(styles.participantBadge, styles.profilePicture)}
            alt={"profilepicture"}
            src={profilePictureUrl}
          />
        ) : (
          <PillBadge size={size} title={name} color={Color.Secondary}>
            {formattedInitials}
          </PillBadge>
        )}
      </div>
      {!hideNameField && (
        <div
          className={classNames(styles.nameField, "margin-left-s", nameFieldClassNames)}
          title={name}
        >
          <div>{name}</div>
        </div>
      )}
    </>
  );
};
