import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";

type PresentationPageContextProviderType = {
  presentationId?: string;
  setPresentationId: React.Dispatch<React.SetStateAction<string | undefined>>;
  totalPresentationPages: number;
  setTotalPresentationPages: React.Dispatch<React.SetStateAction<number>>;
  mostVisiblePageIndex: number;
  setMostVisiblePageIndex: React.Dispatch<React.SetStateAction<number>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
};

type PresentationPageContextProviderProps = {
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PresentationPageContext = createContext<PresentationPageContextProviderType | undefined>(
  undefined
);

export const usePresentationPageContext = () => {
  const context = useContext(PresentationPageContext);
  if (!context) {
    throw new Error("usePresentationPageContext must be used in PresentationPageContextProvider");
  }
  return context;
};

const PresentationPageContextProvider = ({ children }: PresentationPageContextProviderProps) => {
  const { seqId } = useParams();

  const [presentationId, setPresentationId] = useState<string | undefined>(seqId);
  const [totalPresentationPages, setTotalPresentationPages] = useState<number>(0);
  const [mostVisiblePageIndex, setMostVisiblePageIndex] = useState(0);
  const [scale, setScale] = useState(1);

  return (
    <PresentationPageContext.Provider
      value={{
        setPresentationId,
        presentationId,
        setTotalPresentationPages,
        totalPresentationPages,
        mostVisiblePageIndex,
        setMostVisiblePageIndex,
        scale,
        setScale
      }}
    >
      {children}
    </PresentationPageContext.Provider>
  );
};

export default PresentationPageContextProvider;
