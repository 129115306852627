import classNames from "classnames";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styles from "./dragNDrop.module.scss";

interface DnDProps {
  children: React.ReactNode;
  index: number;
  onPreviewDropRow: (draggedItemIndex: number, hoverIndex: number) => void;
  onDropRow: (droppedIndex: number) => void;
  dndType: string;
  disableDrag?: boolean;
  additionalData?: { [key: string]: unknown };
}

interface DragItem {
  index: number;
}

export const DragNDrop = (props: DnDProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const { additionalData = {} } = props;

  const [{ isDragging }, drag] = useDrag({
    type: props.dndType,
    item: { index: props.index, ...additionalData } as DragItem | unknown,
    canDrag: !props.disableDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => {
      props.onDropRow(props.index);
    }
  });

  const [{ isOver }, drop] = useDrop({
    accept: props.dndType,
    hover: (draggedItem: DragItem) => {
      if (!elementRef.current) {
        return;
      }

      const draggedItemIndex = draggedItem.index;
      const hoverIndex = props.index;

      if (draggedItemIndex === hoverIndex) {
        return;
      }

      draggedItem.index = hoverIndex;
      props.onPreviewDropRow(draggedItemIndex, hoverIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  drag(elementRef);
  drop(elementRef);

  return (
    <div
      className={classNames(!props.disableDrag && styles.draggable, {
        [styles.isDragging]: isDragging,
        [styles.isOver]: isOver
      })}
      ref={elementRef}
    >
      {props.children}
    </div>
  );
};
