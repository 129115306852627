import Card from "./card";

type Props = {
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  body: React.ReactNode | React.ReactNode[];
  children?: never;
} & React.HTMLAttributes<HTMLDivElement>;

const Crud: React.FC<Props> = ({ title, footer, body, children, ...htmlDivProps }) => {
  const bodyArray: React.ReactNode[] = [];

  if (Array.isArray(body)) bodyArray.push(...body);
  else bodyArray.push(body);

  return (
    <Card {...htmlDivProps}>
      {title && typeof title === "string" && (
        <Card.Header>
          <div className="row">
            <div className="col-6 offset-3">{title}</div>
          </div>
        </Card.Header>
      )}
      {title && typeof title !== "string" && { title }}

      {bodyArray.map((b, index) => (
        <Card.Body key={index}>
          <div className="row">
            <div className="col-6 offset-3">{b}</div>
          </div>
        </Card.Body>
      ))}

      {footer && (
        <Card.Footer>
          <div className="row">
            <div className="col-6 offset-3">{footer}</div>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};

export default Crud;
