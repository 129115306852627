export const originalGetContext = HTMLCanvasElement.prototype.getContext;

function getContext(
  contextId: "2d",
  options?: CanvasRenderingContext2DSettings
): CanvasRenderingContext2D | null;
function getContext(
  contextId: "bitmaprenderer",
  options?: ImageBitmapRenderingContextSettings
): ImageBitmapRenderingContext | null;
function getContext(
  contextId: "webgl",
  options?: WebGLContextAttributes
): WebGLRenderingContext | null;
function getContext(
  contextId: "webgl2",
  options?: WebGLContextAttributes
): WebGL2RenderingContext | null;
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getContext(this: any, contextId: string, options?: any): RenderingContext | null {
  let extendedOpt = options;
  if (contextId === "2d") {
    extendedOpt = { willReadFrequently: true, ...options };
  }
  return originalGetContext.apply(this, [contextId, extendedOpt]);
}

HTMLCanvasElement.prototype.getContext = getContext;
