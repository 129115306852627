import { useEffect, useState } from "react";
import { CasePresentationPageModel } from "@services/api/case/models/casePresentationPageModel";

interface HookProps {
  continueOnPage?: string;
  pagesData: CasePresentationPageModel[];
  currentPageIndex: number;
  goToPage: (page: number) => void;
}

/**
 * On first render (when presentation is resumed) finds page by id and navigates to it
 */
const useContinueOnPage = ({
  continueOnPage,
  pagesData,
  currentPageIndex,
  goToPage
}: HookProps) => {
  const [handled, setHandled] = useState(false);
  useEffect(() => {
    if (!handled && pagesData) {
      setHandled(true);
      let newPageIndex = pagesData.findIndex(({ id }) => id === continueOnPage);
      if (newPageIndex === -1) {
        newPageIndex =
          currentPageIndex < pagesData.length ? currentPageIndex : pagesData.length - 1;
      }
      goToPage(newPageIndex);
    }
  }, [currentPageIndex, goToPage, handled, continueOnPage, pagesData]);
};

export default useContinueOnPage;
