import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { setError } from "@components/error/errorSlice";
import { isValidationError } from "@services/httpClient/httpRequestResult";

export const errorHandlingMiddleware: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (!isValidationError(action?.payload)) {
      // this check is (a poor way) to prevent fileStorageApi errors from creating generic toasts
      if (!action.type.includes("api/file/")) {
        // eslint-disable-next-line no-console
        console.error("We got a rejected action!", action);

        api.dispatch(
          setError({
            hasError: true,
            error: action?.payload ?? action.error
          })
        );
      }
    }
  }

  return next(action);
};
