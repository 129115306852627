import { useDrop } from "react-dnd";
import { TableOfContentItemModel } from "@services/api/extractDraft/models/tableOfContentSectionModel";
import { ExtractDraftSplitterBundleModel } from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleSplitter";

interface BundleItemDnDProps {
  bundleItem: TableOfContentItemModel;
  itemIndex: number;
  dndHoverCallback: (
    bundle: ExtractDraftSplitterBundleModel,
    bundleHoverInfo: { hoverIndex: number; hoverDocumentId: string }
  ) => void;
}

const ExtractDraftBundleItemDnD = ({
  bundleItem,
  itemIndex,
  dndHoverCallback
}: BundleItemDnDProps) => {
  const [, drop] = useDrop(
    {
      accept: "bundle",
      drop: (dropped: {
        extractStartIndex: number;
        extractEndIndex: number;
        id: string;
        firstExtractDraftDocumentId: string;
        lastExtractDraftDocumentId: string;
      }) => {},
      canDrop: () => {
        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver()
      }),
      hover: (item, monitor) => {
        dndHoverCallback(item, {
          hoverIndex: bundleItem.pageNumber - 1,
          hoverDocumentId: bundleItem.extractDraftDocumentId
        });
      }
    },
    [dndHoverCallback, bundleItem.pageNumber]
  );

  return (
    <li
      key={bundleItem.extractDraftDocumentId}
      className="d-flex justify-content-between my-2 ml-4"
      ref={drop}
    >
      <span className="d-flex mr-3">
        <span>• </span>
        <span className="ml-2">{bundleItem.title}</span>
      </span>
      <span className="d-flex flex-shrink-0">
        {bundleItem.pageNumber}-{bundleItem.pageNumber + bundleItem.pageCount - 1}
      </span>
    </li>
  );
};

export default ExtractDraftBundleItemDnD;
