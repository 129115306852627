import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import { EmblaIcon } from "@components/embla/emblaIcon";
import formatBytes from "src/utility/formatBytes";
import { ProgressLine, ProgressLineColor } from "@components/progressLine/progressLine";
import { ReactComponent as RetryIcon } from "src/images/retry.svg";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { ReactComponent as CancelCross } from "@content/icons/cross-black.svg";
import { Localizer } from "@components/localization/localizer";
import { isValidationError } from "@services/httpClient/httpRequestResult";
import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";
import {
  useCreateCaseDocumentMutation,
  useLazyGetCaseDocumentQuery
} from "@services/api/document/caseDocumentApi";
import { CreateCaseDocumentModel } from "@services/api/document/models/createCaseDocumentModel";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";
import useFileUpload from "../../../../../hooks/useFileUpload";
import { EditUploadedDocument } from "./editUploadedDocument";
import styles from "./documentUploadRow.module.scss";

const DisplayFileUploadErrorToast = (localizer: Localizer, fileName: string, error: unknown) => {
  const validationErrorKeyValuePair = isValidationError(error)
    ? Object.entries(error.data.errorData)[0]
    : undefined;
  const extraErrorMessage =
    validationErrorKeyValuePair !== undefined ? `: ${validationErrorKeyValuePair[1]}` : "";
  NotificationModule.showErrorSmall(localizer.fileUploadError(fileName) + extraErrorMessage);
};

export interface DocumentUploadRowProps {
  caseId: string;
  file: File;
  fileId: string;
  index: number;
  beginUpload: boolean;
  uploadFinishedCallback: (fileId: string) => void;
  uploadFailedCallback: (fileId: string) => void;
  uploadResetCallback: (fileId: string) => void;
  uploadCancelCallback: (fileId: string) => void;
  onCurrentFilesInProgressCallback: () => void;
}

const chunkSize = 1048576 * 3;

export const DocumentUploadRow: React.FC<DocumentUploadRowProps> = ({
  caseId,
  file,
  fileId,
  index,
  beginUpload,
  uploadFinishedCallback,
  uploadFailedCallback,
  uploadResetCallback,
  uploadCancelCallback,
  onCurrentFilesInProgressCallback
}: DocumentUploadRowProps) => {
  const localizer = useLocalization();

  const [allowCancel, setAllowCancel] = useState(true);
  const [isFinalizingProxy, setIsFinalizingProxy] = useState(true);

  const [createDocument] = useCreateCaseDocumentMutation();

  const [uploadedDocument, setUploadedDocument] = useState<CaseDocumentModel | undefined>();

  const [uploadedDocumentQuery] = useLazyGetCaseDocumentQuery();

  const fileUploadedCallback = useCallback(
    (blobId: string, uploadedFile: File) => {
      const createDocumentModel: CreateCaseDocumentModel = {
        blobFileId: blobId,
        fileName: uploadedFile.name,
        contentType: uploadedFile.type,
        size: uploadedFile.size,
        elementType: CaseElementTypeEnum.Other
      };

      createDocument({ caseId: caseId, model: createDocumentModel })
        .unwrap()
        .then((caseDocumentId: string) => {
          uploadedDocumentQuery({ caseId: caseId, documentId: caseDocumentId })
            .unwrap()
            .then((caseDocument: CaseDocumentModel) => {
              setUploadedDocument(caseDocument);
              uploadFinishedCallback(fileId);
            });
        });
    },
    [caseId, createDocument, fileId, uploadFinishedCallback]
  );

  const {
    resetUpload,
    cancelUpload,
    uploadFailed,
    uploadFinished,
    uploadProgress,
    isFinalizing,
    beginUploadFile
  } = useFileUpload(caseId, fileUploadedCallback, chunkSize, fileId);

  useEffect(() => {
    if (uploadFinished) {
      setAllowCancel(false);
      onCurrentFilesInProgressCallback();
    }
  }, [uploadFinished]);

  useEffect(() => {
    if (beginUpload) {
      beginUploadFile(file);
    }
  }, [file, beginUpload, fileId]);

  useEffect(() => {
    if (uploadFailed) {
      setAllowCancel(true);
      DisplayFileUploadErrorToast(localizer, file.name, uploadFailed);
      uploadFailedCallback(fileId);
      onCurrentFilesInProgressCallback();
    }
  }, [file.name, fileId, localizer, uploadFailed]);

  const resetUploadProxy = async () => {
    await resetUpload();
    uploadResetCallback(fileId);
  };

  const cancelUploadProxy = async () => {
    onCurrentFilesInProgressCallback();
    await cancelUpload();
    uploadCancelCallback(fileId);
  };

  useEffect(() => {
    if (isFinalizing) {
      // to allow the progress bar animation to complete
      new Promise((res) => setTimeout(res, 1000)).then(() => setIsFinalizingProxy(isFinalizing));
    } else {
      setIsFinalizingProxy(isFinalizing);
    }
  }, [isFinalizing]);

  return (
    <div className={"d-flex align-items-baseline margin-top-m margin-bottom-m gap-s mw-100"}>
      <div
        className={
          uploadFailed ? classNames(styles.documentIconError) : classNames(styles.documentIcon)
        }
      >
        <EmblaIcon iconName="document" />
      </div>
      <div className={classNames("d-flex flex-grow-1 mw-100", styles.minWidth0Important)}>
        {!uploadedDocument && (
          <div className="d-flex flex-column flex-grow-1 mw-100">
            <div className="d-flex justify-content-between align-items-center mw-100">
              <div className="d-flex mw-100 overflow-hidden minW0">
                <div className="text-nowrap text-truncate flex-grow-0 minW0">{file.name}</div>
                {allowCancel && (
                  <div className={classNames(styles.cancelIcon)}>
                    <CancelCross onClick={cancelUploadProxy} />
                  </div>
                )}
                {isFinalizingProxy && (
                  <div className="d-flex margin-left-l flex-shrink-0 minW0">
                    <Spinner size="extra-tiny-small" />
                    <div className="margin-left-m">{localizer.processingFile()}</div>
                  </div>
                )}
              </div>
              <div className="small subtle d-flex align-items-center flex-shrink-0 margin-left-s minW0">
                {uploadFailed ? (
                  <button
                    onClick={resetUploadProxy}
                    type="button"
                    className="btn btn-default btn-sm margin-bottom-xs"
                  >
                    <span className="margin-right-xs">{localizer.tryAgain()}</span>
                    <RetryIcon />
                  </button>
                ) : (
                  <div>{formatBytes(file.size, 1)}</div>
                )}
              </div>
            </div>
            {!isFinalizingProxy && (
              <ProgressLine
                color={uploadFailed ? ProgressLineColor.Danger : ProgressLineColor.PrimaryLight}
                percentage={uploadProgress}
              ></ProgressLine>
            )}
          </div>
        )}
        {uploadedDocument && (
          <EditUploadedDocument
            caseId={caseId}
            caseDocumentNumber={uploadedDocument.caseDocumentNumber}
            documentDate={uploadedDocument.date}
            documentfileName={uploadedDocument.documentName}
            documentType={uploadedDocument.type}
            dateType={DateTypeEnum.YearMonthDay}
            caseDocumentId={uploadedDocument.id}
            index={index}
          />
        )}
      </div>
      {uploadedDocument && (
        <div className={classNames("small subtle", styles.fileSize)}>
          {formatBytes(file.size, 1)}
        </div>
      )}
    </div>
  );
};
