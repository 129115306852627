import classNames from "classnames";
import { HTMLAttributes } from "react";
import { Spinner } from "@components/spinner/spinner";
import styles from "./pageLoader.module.scss";

export const PageLoader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(styles.loader, className)} {...props}>
      <Spinner size="small" />
    </div>
  );
};
