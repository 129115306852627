import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";

interface ExtractCompositionMenuContextType {
  currentActiveTreeNodeType: TreeNodeType;
  currentActiveTreeNode: string | undefined;
}

export enum TreeNodeType {
  Frontpage = 1,
  TableOfContents = 2,
  TreeNode = 3
}

interface ExtractCompositionSectionContextType {
  createSectionShow: boolean;
  createSectionParentId: string | undefined;
}

interface ExtractCompositionStatusContextType {
  isLoading: boolean;
  isError: boolean;
}

interface SelectedDocument extends CaseDocumentModel {
  index: number;
}

interface SelectedDocumentsContextType {
  selectedDocuments: SelectedDocument[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ExtractCompositionContext = createContext<
  | {
      selectedDocumentsReducer: {
        state: SelectedDocumentsContextType;
        dispatch: React.Dispatch<SelectedDocumentsContextType>;
      };
      menuReducer: {
        state: ExtractCompositionMenuContextType;
        dispatch: React.Dispatch<ExtractCompositionMenuContextType>;
      };
      sectionReducer: {
        state: ExtractCompositionSectionContextType;
        dispatch: React.Dispatch<ExtractCompositionSectionContextType>;
      };
      statusReducer: {
        state: ExtractCompositionStatusContextType;
        dispatch: React.Dispatch<ExtractCompositionStatusContextType>;
      };
    }
  | undefined
>(undefined);

const selectedDocumentsReducer = (
  state: SelectedDocumentsContextType,
  action: { selectedDocuments: SelectedDocument[] }
): SelectedDocumentsContextType => {
  return { ...state, selectedDocuments: action.selectedDocuments };
};

const menuContextReducer = (
  state: ExtractCompositionMenuContextType,
  action: { currentActiveTreeNode: string | undefined; currentActiveTreeNodeType: TreeNodeType }
): ExtractCompositionMenuContextType => {
  return {
    ...state,
    currentActiveTreeNode: action.currentActiveTreeNode,
    currentActiveTreeNodeType: action.currentActiveTreeNodeType
  };
};

const sectionContextReducer = (
  state: ExtractCompositionSectionContextType,
  action: { createSectionShow: boolean; createSectionParentId: string | undefined }
): ExtractCompositionSectionContextType => {
  return {
    ...state,
    createSectionShow: action.createSectionShow,
    createSectionParentId: action.createSectionParentId
  };
};

const statusContextReducer = (
  state: ExtractCompositionStatusContextType,
  action: { isLoading: boolean; isError: boolean }
): ExtractCompositionStatusContextType => {
  return { ...state, isError: action.isError, isLoading: action.isLoading };
};

export const useExtractCompositionContext = () => {
  const context = useContext(ExtractCompositionContext);
  if (!context) {
    throw new Error(
      "useExtractCompositionContext must be used in ExtractCompositionContextProvider"
    );
  }
  return context;
};

export type ExtractCompositionContextProviderProps = {
  children: ReactNode | undefined;
};

const ExtractCompositionContextProvider = ({
  children
}: ExtractCompositionContextProviderProps) => {
  const [selectedDocumentsState, selectedDocumentsDispatch] = useReducer(selectedDocumentsReducer, {
    selectedDocuments: []
  });
  const [menuState, menuDispatch] = useReducer(menuContextReducer, {
    currentActiveTreeNode: undefined,
    currentActiveTreeNodeType: TreeNodeType.Frontpage
  });
  const [sectionState, sectionDispatch] = useReducer(sectionContextReducer, {
    createSectionParentId: undefined,
    createSectionShow: false
  });
  const [statusState, statusDispatch] = useReducer(statusContextReducer, {
    isLoading: false,
    isError: false
  });

  return (
    <ExtractCompositionContext.Provider
      value={{
        menuReducer: { state: menuState, dispatch: menuDispatch },
        sectionReducer: { state: sectionState, dispatch: sectionDispatch },
        statusReducer: { state: statusState, dispatch: statusDispatch },
        selectedDocumentsReducer: {
          state: selectedDocumentsState,
          dispatch: selectedDocumentsDispatch
        }
      }}
    >
      {children}
    </ExtractCompositionContext.Provider>
  );
};
export default ExtractCompositionContextProvider;
