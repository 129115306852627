import classNames from "classnames";
import { forwardRef, HTMLAttributes, Ref } from "react";
import {
  FocusBoxMarkingModel,
  MARKING_FADED_COLOR
} from "@pages/pdfviewer/component/models/markingModel";
import ResizableMarking from "../ResizableMarking/ResizableMarking";
import styles from "./FocusBoxMarking.module.scss";

interface FocusBoxMarkingProps extends HTMLAttributes<HTMLDivElement> {
  marking: FocusBoxMarkingModel<false>;
  scale: number;
  active: boolean;
  faded: boolean;
}

const FocusBoxMarking = forwardRef(
  (
    { marking, scale, active, faded, className, style, ...childProps }: FocusBoxMarkingProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const styleWithCssVariable = {
      ...style,
      "--focusbox-background-color": faded ? MARKING_FADED_COLOR : undefined
    };

    return (
      <ResizableMarking
        ref={ref}
        marking={marking}
        scale={scale}
        style={styleWithCssVariable}
        className={classNames(styles.background, className)}
        active={active}
        resizableHeight
        resizableWidth
        {...childProps}
      />
    );
  }
);

export default FocusBoxMarking;
