import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationModule } from "ditmer-embla";
import {
  FieldValues,
  Path,
  useForm,
  UseFormHandleSubmit,
  UseFormProps,
  get
} from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { useCallback } from "react";
import { isValidationError } from "@services/httpClient/httpRequestResult";

interface UseValidatedFormProps<T extends FieldValues = FieldValues> extends UseFormProps<T> {
  validationSchema?: AnyObjectSchema;
}

const GENERIC_FORM_ERROR_KEY = "__GENERIC_FORM_ERROR__";

/** Wraps useForm hook to catch and set validation errors from back-end */
const useValidatedForm = <T extends FieldValues>({
  validationSchema,
  ...props
}: UseValidatedFormProps<T> = {}) => {
  const methods = useForm({
    mode: "onTouched",
    resolver: validationSchema && yupResolver(validationSchema),
    ...props
  });
  const { handleSubmit, setError, control } = methods;

  const handleSubmitWithServerErrors: UseFormHandleSubmit<T> = useCallback(
    (onValid, onError) => {
      return handleSubmit(async (data, event) => {
        try {
          const res = await onValid(data, event);
          return res;
        } catch (error) {
          const setGenericFormError = (message?: string) => {
            // set error for no concrete field to change "isSubmitSuccessful" to "false"
            setError(
              GENERIC_FORM_ERROR_KEY as Path<T>,
              { type: "custom", message },
              { shouldFocus: true }
            );
          };
          if (!isValidationError(error)) {
            setGenericFormError();
            return;
          }
          for (const [field, message] of Object.entries(error.data.errorData)) {
            // normalize error keys to match js path ("employee.UserProfile.FirstName" to "employee.userProfile.firstName")
            const normalizedField = field && field.replace(/\.\w/g, (match) => match.toLowerCase());
            // set error if field name is provided on such field exist - else show notification
            if (normalizedField && get(control._fields, normalizedField)) {
              setError(
                normalizedField as Path<T>,
                { type: "custom", message },
                { shouldFocus: true }
              );
            } else {
              NotificationModule.showErrorSmall(message);
              setGenericFormError(message);
            }
          }
        }
      }, onError);
    },
    [control, handleSubmit, setError]
  );

  return {
    ...methods,
    handleSubmit: handleSubmitWithServerErrors
  };
};

export default useValidatedForm;
