import { DropdownOption } from "@components/dropdown/dropdown";
import { Localizer } from "@components/localization/localizer";

export enum MarkingType {
  Comment = 1,
  Highlight = 2,
  VerticalLines = 3,
  FocusBox = 4
}

export const allMarkings = Object.values(MarkingType).filter(
  (v) => !isNaN(Number(v))
) as MarkingType[];

export const getMarkingTypeDropdownOptions = (localizer: Localizer) => {
  return [
    {
      label: localizer.comment(),
      value: MarkingType.Comment
    },
    {
      label: localizer.highlightTitle(),
      value: MarkingType.Highlight
    },
    {
      label: localizer.verticalLinesTitle(),
      value: MarkingType.VerticalLines
    },
    {
      label: localizer.focusBoxTitle(),
      value: MarkingType.FocusBox
    }
  ] as DropdownOption<MarkingType>[];
};

export const convertMarkingTypeToString = (type: MarkingType, localizer: Localizer) => {
  switch (type) {
    case MarkingType.Comment:
      return localizer.comment();
    case MarkingType.Highlight:
      return localizer.highlightTitle();
    case MarkingType.VerticalLines:
      return localizer.verticalLinesTitle();
    case MarkingType.FocusBox:
      return localizer.focusBoxTitle();
  }
};
