import "ditmer-embla";
import { useEffect, useState } from "react";
import { BrowserRouter, HashRouter, useNavigate } from "react-router-dom";
import { AuthProvider } from "@components/auth/authProvider";
import StoreProvider from "@components/auth/storeProvider";
import { ConfigProvider, useConfig } from "@components/config/configProvider";
import useConfigLoader from "@components/config/useConfigLoader";
import { ErrorBoundary } from "@components/error/errorBoundary";
import GenericErrorPage from "@components/error/genericErrorPage";
import {
  LocalizationProvider,
  useLocalization
} from "@components/localization/localizationProvider";
import MainRouting from "@components/routing/mainRouting";
import { Config } from "@config/configManager";
import { UserService } from "@services/userService";
import { ModalMessageProvider } from "@components/modal/modalMessageContext";
import { HubConnectionManagerProvider } from "@components/hubConnectionManager/hubConnectionProvider";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { SessionStorageKeys } from "@infrastructure/sessionStorageKeys";
import useElectronApi from "./hooks/useElectronApi";
import useDateFormatter from "./hooks/useDateFormatter";

const App = () => {
  const { config, isLoading, isError } = useConfigLoader();

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <GenericErrorPage message="Error While loading config" />;
  }

  return (
    <Providers config={config}>
      <Main />
    </Providers>
  );
};

const Main = () => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();
  const config = useConfig();
  const navigate = useNavigate();
  const electronApi = useElectronApi();

  useEffect(() => {
    const unsubscribe = electronApi?.onNavigate(navigate);
    return unsubscribe;
  }, [electronApi, navigate]);

  return (
    <ErrorBoundary localizer={localizer} dateFormatter={dateFormatter} config={config}>
      <MainRouting />
    </ErrorBoundary>
  );
};

type ProvidersProps = {
  config: Config;
};

const Providers: React.FC<ProvidersProps> = ({ config, children }) => {
  const [userService, setUserService] = useState<UserService>();
  const electronApi = useElectronApi();
  const isPresentationWindow = sessionStorage.getItem(SessionStorageKeys.isPresentationWindow);

  useEffect(() => {
    if (!userService) {
      const isElectronApp = Boolean(electronApi);
      setUserService(new UserService(config, isElectronApp, Boolean(isPresentationWindow)));
    }
  }, [config, electronApi, isPresentationWindow, userService]);

  if (!userService) {
    return null;
  }

  if (electronApi) {
    console.log("App runs in electron environment");
  }

  const Router = electronApi ? HashRouter : BrowserRouter;

  return (
    <HubConnectionManagerProvider
      hubConnectionType={HubConnectionType.Markings}
      userService={userService}
      config={config}
    >
      <StoreProvider config={config} userService={userService}>
        <Router>
          <ConfigProvider>
            <LocalizationProvider>
              <AuthProvider userService={userService}>
                <ModalMessageProvider>{children}</ModalMessageProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ConfigProvider>
        </Router>
      </StoreProvider>
    </HubConnectionManagerProvider>
  );
};

export default App;
