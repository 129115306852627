import {
  cloneElement,
  createContext,
  FocusEvent,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  ReactElement,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import mergeRefs from "src/utility/mergeRefs";

interface ContextValue {
  activeIndex?: number;
  onItemFocus: (index: number) => void;
  onItemBlur: (index: number) => void;
  registerItem: () => () => void; // returns unregister function
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const KeyNavigationContext = createContext<ContextValue | null>(null);

export const useKeyNavigationContext = () => {
  const ctx = useContext(KeyNavigationContext);
  if (!ctx) {
    throw new Error("must be used inside context");
  }
  return ctx;
};

const KeyNavigation = forwardRef(
  (props: HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const [activeIndex, setActiveIndex] = useState<number>();
    const [total, setTotal] = useState<number>(0);

    const registerItem = useCallback(() => {
      setTotal((t) => t + 1);
      return () => setTotal((t) => t - 1);
    }, []);

    const onItemFocus = (index: number) => setActiveIndex(index);
    const onItemBlur = (index: number) => {
      if (activeIndex === index) {
        setActiveIndex(undefined);
      }
    };

    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "ArrowUp" || (e.shiftKey && e.key === "Tab")) {
        if (activeIndex && activeIndex > 0) {
          setActiveIndex(activeIndex - 1);
          e.preventDefault();
        }
      } else if (e.key === "ArrowDown" || e.key === "Tab") {
        if (activeIndex === undefined || activeIndex < total - 1) {
          setActiveIndex((activeIndex ?? -1) + 1);
          e.preventDefault();
        }
      }
      props.onKeyDown?.(e);
    };

    return (
      <KeyNavigationContext.Provider value={{ activeIndex, onItemFocus, onItemBlur, registerItem }}>
        <div ref={ref} {...props} onKeyDown={onKeyDown} />
      </KeyNavigationContext.Provider>
    );
  }
);

interface ItemProps {
  children: ReactElement;
  index: number;
}

const Item = ({ children, index }: ItemProps) => {
  const { activeIndex, onItemFocus, onItemBlur, registerItem } = useKeyNavigationContext();
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unregister = registerItem();
    return unregister;
  }, [registerItem]);

  useEffect(() => {
    if (activeIndex === index) {
      elementRef.current?.focus();
    }
  }, [activeIndex, index]);

  return cloneElement(children, {
    ref: mergeRefs(children, elementRef),
    onFocus: (e: FocusEvent) => {
      onItemFocus(index);
      children.props?.onFocus?.(e);
    },
    onBlur: () => onItemBlur(index)
  });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(KeyNavigation, { Item });
