export const handleDragStartRemoveGhost = (e: React.DragEvent) => {
  const draggableElement = e.target as HTMLDivElement;
  // // Make the default drag preview invisible
  draggableElement.style.opacity = "0";
};

export const handleDragEnd = (e: React.DragEvent) => {
  const draggableElement = e.target as HTMLDivElement;
  draggableElement.style.opacity = "1";
};
