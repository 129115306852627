import { EmblaIcon } from "@components/embla/emblaIcon";
import { ReactComponent as FocusBoxIcon } from "@content/icons/focus-box-icon.svg";
import { ReactComponent as MarkIcon } from "@content/icons/mark-icon.svg";
import { ReactComponent as VerticalLinesIcon } from "@content/icons/vertical-lines-icon.svg";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";

const MarkingIcon = ({
  markingType,
  className
}: {
  markingType: MarkingType;
  className?: string;
}) => {
  switch (markingType) {
    case MarkingType.Comment:
      return <EmblaIcon iconName="dialog" additionalClasses={className} />;
    case MarkingType.Highlight:
      return <MarkIcon className={className} />;
    case MarkingType.VerticalLines:
      return <VerticalLinesIcon className={className} />;
    case MarkingType.FocusBox:
      return <FocusBoxIcon className={className} />;
  }
};

export default MarkingIcon;
