import classNames from "classnames";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { ModalSizeEnum } from "./modal";
import styles from "./modalMessage.module.scss";

export interface ModalMessageProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  modalAcceptType?: "primary" | "danger";
  showCancelButton?: boolean;
  acceptButtonText?: string;
  cancelButtonText?: string;
  acceptAction?: () => void;
  cancelAction?: () => void;
}

const ModalMessage = ({
  open,
  onClose,
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  modalAcceptType = "primary",
  showCancelButton = true,
  acceptAction,
  cancelAction
}: ModalMessageProps) => {
  const localizer = useLocalization();

  const handleActionClick = (handler?: () => void) => {
    handler?.();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size={ModalSizeEnum.Small}
      type="message"
      className={classNames(styles.modal)}
    >
      <Modal.Body>
        <Modal.Title as="h3">{title}</Modal.Title>
        <Modal.Description>{description}</Modal.Description>
      </Modal.Body>
      <Modal.Footer>
        {showCancelButton && (
          <Button onClick={() => handleActionClick(cancelAction)}>
            {cancelButtonText ?? localizer.cancel()}
          </Button>
        )}
        <Button theme={modalAcceptType} onClick={() => handleActionClick(acceptAction)}>
          {acceptButtonText ?? localizer.confirm()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMessage;
