import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import Button from "@components/embla/button";
import { useGetCaseParticipantsQuery } from "@services/api/participant/caseParticipantApi";
import { useExportCaseDocumentMutation } from "@services/api/document/caseDocumentApi";
import { ExportCaseDocumentModel } from "@services/api/case/models/exportCaseDocumentModel";
import { ExportCaseDocumentForm } from "@components/Export/exportCaseDocumentForm";

type ExportDocumentProps = {
  caseId: string;
  caseDocumentId: string;
  caseDocumentTitle: string;
  onExportResult: (caseDocumentExportId: string) => void;
  onBackClicked: () => void;
};

export const ExportDocument = ({
  caseId,
  caseDocumentId,
  caseDocumentTitle,
  onExportResult,
  onBackClicked
}: ExportDocumentProps) => {
  const localizer = useLocalization();
  const [exportCaseDocument, exportCaseDocumentRequest] = useExportCaseDocumentMutation();
  const { data: participants, isLoading: participantsIsLoading } = useGetCaseParticipantsQuery({
    caseId
  });
  const formFieldIdPrepend = "export-case-document-";

  const initialValues: ExportCaseDocumentModel = {
    markingOwners: [],
    //TODO: Disable comment export until better designs are implemented
    markingTypes: [
      /*MarkingType.Comment,*/ MarkingType.Highlight,
      MarkingType.VerticalLines,
      MarkingType.FocusBox
    ],
    convertLinesToBlack: false
  };

  const methods = useValidatedForm({
    defaultValues: initialValues
  });

  const onSubmitExportCaseDocument = async (model: ExportCaseDocumentModel) => {
    const result = await exportCaseDocument({ caseId, caseDocumentId, model }).unwrap();

    if (result) {
      onExportResult(result);
    }
  };

  return (
    <>
      <Modal.Header>{`${localizer.exportCaseDocument()}: ${caseDocumentTitle}`}</Modal.Header>
      <Modal.Body>
        <div className="subtle">{localizer.exportCaseDocumentHint1()}</div>
        <div className="subtle">{localizer.exportCaseDocumentHint2()}</div>

        <ExportCaseDocumentForm
          participants={participants}
          methods={methods}
          fieldIdPrepend={formFieldIdPrepend}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onBackClicked}>{localizer.cancel()}</Button>
        <FormSubmitButton
          state={{
            isSubmitting: exportCaseDocumentRequest.isLoading,
            isSubmitSuccessful: exportCaseDocumentRequest.isSuccess
          }}
          isPrimary={true}
          onClick={methods.handleSubmit(onSubmitExportCaseDocument)}
          disabled={participantsIsLoading}
        >
          {localizer.exportVerb()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};
