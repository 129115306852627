import classNames from "classnames";
import { forwardRef, Ref } from "react";

type DownloadButtonProps = {
  theme?: "default" | "primary" | "secondary" | "danger";
  downloadUrl?: string;
} & React.ButtonHTMLAttributes<HTMLAnchorElement>;

export const DownloadButton = forwardRef(
  (
    { theme, downloadUrl, className, children, ...htmlAnchorProps }: DownloadButtonProps,
    ref: Ref<HTMLAnchorElement>
  ) => {
    return (
      <a
        {...htmlAnchorProps}
        className={classNames(
          "btn",
          theme && `btn-${theme}`,
          !downloadUrl && "disabled",
          className
        )}
        href={downloadUrl}
        download
        ref={ref}
      >
        {children}
      </a>
    );
  }
);
