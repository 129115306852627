import { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { debounce } from "lodash";
import FormDropdown from "@components/forms/FormDropdown";
import { useAuth } from "@components/auth/authProvider";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useGetEmployeeParticipantOptionsQuery } from "@services/api/employee/employeeApi";
import { EmployeeParticipantOptionsPaginatedListInput } from "@services/api/employee/models/employeeParticipantOptionsPaginatedListInput";
import { EmployeeParticipantOptionModel } from "@services/api/employee/models/EmployeeParticipantOptionModel";
import { allCaseRights } from "@infrastructure/CaseRight";
import { Spinner } from "@components/spinner/spinner";
import { useWizardPanelContext } from "@components/embla/wizard/wizardPanelContextProvider";
import { ParticipantCard } from "./participantCard/participantCard";
import { SelectParticipantsModel } from "./selectParticipantsModel";
import styles from "./selectParticipantsForm.module.css";
import { ParticipantAccessRightsForm } from "./accessRights/participantAccessRightsForm";
import { ParticipantAccessRightsModel } from "./accessRights/participantAccessRightsModel";

type SelectParticipantsFormProps = {
  initialValues: EmployeeParticipantOptionModel[];
  onChangeCallback?: (model: SelectParticipantsModel) => void;
  onSubmitCallback?: (model: SelectParticipantsModel) => void;
  title?: string;
  caseId?: string;
  renderFormActions?: ReactNode;
};

const intialEmployeeParticipantOptionsPaginatedListInput: EmployeeParticipantOptionsPaginatedListInput =
  {
    search: {
      value: "",
      regex: false
    },
    caseIdToExclude: undefined
  };

export interface SelectParticipantsFormRef {
  submit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

export const SelectParticipantsForm = forwardRef(
  (
    {
      initialValues,
      onChangeCallback,
      onSubmitCallback,
      title,
      caseId,
      renderFormActions
    }: SelectParticipantsFormProps,
    ref: Ref<SelectParticipantsFormRef>
  ) => {
    const { user } = useAuth();
    const localizer = useLocalization();
    const [employeeSearchOptions, setEmployeeSearchOptions] = useState<
      { label: string; value: EmployeeParticipantOptionModel }[]
    >([]);
    const [optionsPaginatedListInput, setOptionsPaginatedListInput] =
      useState<EmployeeParticipantOptionsPaginatedListInput>({
        ...intialEmployeeParticipantOptionsPaginatedListInput,
        caseIdToExclude: caseId
      });
    const { data: employees, isLoading: isGetEmployeesLoading } =
      useGetEmployeeParticipantOptionsQuery(optionsPaginatedListInput);

    const { dispatch } = useWizardPanelContext();

    // If caseId is not set, we are selecting particpants for a new case.
    // Therefore it should always have one participant
    const shouldHaveAtleastOneParticipant = !caseId;

    const methods = useValidatedForm({
      defaultValues: {
        selectedParticipants: initialValues
      } as SelectParticipantsModel
    });

    const submitHandler = methods.handleSubmit((model) =>
      onSubmitCallback ? onSubmitCallback(model) : {}
    );

    useImperativeHandle(ref, () => ({
      submit: submitHandler
    }));

    useEffect(() => {
      dispatch({ actionButtonDisabledState: isGetEmployeesLoading });
    }, [isGetEmployeesLoading, dispatch]);

    // Set dropdown search-options
    useEffect(() => {
      if (!employees) {
        return;
      }
      const values =
        employees.map((t) => {
          return {
            label: t.name,
            value: t
          };
        }) ?? [];

      setEmployeeSearchOptions(values);
    }, [employees]);

    // Watch after changes: call onChange-callback
    useEffect(() => {
      const subscription = methods.watch((value, field) => {
        if (onChangeCallback) {
          onChangeCallback(value as SelectParticipantsModel);
        }
      });
      return () => subscription.unsubscribe();
    }, [methods, onChangeCallback]);

    // Set default participant if needed
    useEffect(() => {
      const existingParticipants = methods.getValues("selectedParticipants");

      if (
        shouldHaveAtleastOneParticipant &&
        employeeSearchOptions &&
        existingParticipants.length === 0
      ) {
        const defaultParticipants = employeeSearchOptions
          .filter((t) => t.value.userId === user.id)
          .map((v) => ({
            ...v.value,
            caseAccessRights: allCaseRights
          }));

        methods.setValue("selectedParticipants", defaultParticipants, { shouldTouch: true });
      }
    }, [caseId, employeeSearchOptions, methods, shouldHaveAtleastOneParticipant, user.id]);

    const watchParticipants = methods.watch("selectedParticipants");

    const removeParticipant = (removeId: string) => {
      methods.setValue(
        "selectedParticipants",
        watchParticipants.filter((participant) => participant.employeeId !== removeId)
      );
    };

    // Handle async search on dropdown
    const onDropdownInputChangedDebounce = debounce((newValue: string) => {
      if (!newValue && newValue !== "") {
        return;
      }
      setOptionsPaginatedListInput((prev) => ({
        ...prev,
        search: { value: newValue, regex: false }
      }));
    }, 500);

    useEffect(() => {
      return onDropdownInputChangedDebounce.cancel();
    }, [onDropdownInputChangedDebounce]);

    // Renders collapsable component for participantCard
    const renderSelectAccessRightsSubComponent = (participant: EmployeeParticipantOptionModel) => {
      const participantIndex = watchParticipants.findIndex(
        (p) => p.employeeId === participant.employeeId
      );

      const onChangeAccessRights = (model: ParticipantAccessRightsModel) => {
        const participantsToUpdate = methods.getValues("selectedParticipants");
        participantsToUpdate.splice(participantIndex, 1, {
          ...participant,
          caseAccessRights: model.caseAccessRights
        });
        methods.setValue("selectedParticipants", participantsToUpdate); //Have to set whole array, else it causes problems
      };

      return (
        <ParticipantAccessRightsForm
          entityId={participant.employeeId}
          initialAccessRights={participant.caseAccessRights}
          onChangeCallback={onChangeAccessRights}
        />
      );
    };

    return (
      <form className={styles.selectParticipantsForm} onSubmit={submitHandler}>
        <div className={styles.formContent}>
          {title && <h3 className={classNames(styles.formHeader)}>{title}</h3>}

          <div className={styles.formDescription}>
            <p className="small subtle">{localizer.addParticipantsHint1()}</p>
          </div>
          {isGetEmployeesLoading && <Spinner />}
          {!isGetEmployeesLoading && (
            <div>
              <div className={classNames("row", styles.searchBar)}>
                <div className="col-lg-10">
                  <FormDropdown
                    methods={methods}
                    id="case-form-participants"
                    name="selectedParticipants"
                    options={employeeSearchOptions}
                    hideDropdownIndicator
                    controlShouldRenderValue={false}
                    isLoading={isGetEmployeesLoading}
                    getOptionValue={(option) => option.value.employeeId}
                    valueToOptionTransform={(value: EmployeeParticipantOptionModel) =>
                      value ? { label: value.name, value: value } : null
                    }
                    filterOption={(o, searchValue) => true} // Disable synchronous search
                    onInputChange={(newValue) => onDropdownInputChangedDebounce(newValue)}
                    isClearable={false}
                    noOptionsMessage={() => localizer.noResultsFound()}
                    placeholder={localizer.addParticipantsSearchPlaceholder()}
                    isMulti
                  />
                </div>
              </div>
              <div className="participants-cards-container">
                <div className="row">
                  <div className="col-lg-10">
                    {watchParticipants &&
                      watchParticipants.map((participant, i) => {
                        const participantsCount = watchParticipants.length;
                        return (
                          <ParticipantCard
                            key={participant.employeeId}
                            employeeId={participant.employeeId}
                            profilePictureUrl={participant.profilePictureImageUrl}
                            participantName={participant.name}
                            initials={participant.initials}
                            index={i}
                            deleteCallback={() => removeParticipant(participant.employeeId)}
                            canBeDeleted={!shouldHaveAtleastOneParticipant || participantsCount > 1}
                          >
                            {renderSelectAccessRightsSubComponent(participant)}
                          </ParticipantCard>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {renderFormActions}
      </form>
    );
  }
);
