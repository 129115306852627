import { FieldValues, useController } from "react-hook-form";
import classNames from "classnames";
import { MergefieldEditor, MergefieldEditorProps } from "@components/embla/mergefieldEditor";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

export type FormRichTextEditorProps = {
  disableMergePlugin?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormRichTextEditor = <TFieldValues extends FieldValues = any>({
  methods: { control },
  label,
  name,
  required,
  formGroupClassName,
  disableMergePlugin,
  ...editorProps
}: FormControlProps<TFieldValues, MergefieldEditorProps> & FormRichTextEditorProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control });

  return (
    <div className={classNames("form-group", formGroupClassName)}>
      {label && <FormInputLabel htmlFor={editorProps.id} labelText={label} isRequired={required} />}

      <MergefieldEditor
        {...editorProps}
        className="form-control"
        onChange={field.onChange}
        defaultValue={field.value}
        disableMergePlugin={disableMergePlugin}
      />

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormRichTextEditor;
