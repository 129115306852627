import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { FieldValues, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";
import useCheckedState from "./useCheckedState";
import styles from "./formSwitch.module.scss";

type FormSwitchProps<T extends FieldValues> = {
  overwriteMarginTopBottom?: "xs" | "s" | "m" | "l";
  defaultLabelMarginLeft?: boolean;
  labelClassName?: string;
} & FormControlProps<T, InputHTMLAttributes<HTMLInputElement>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormSwitch = <T extends FieldValues = any>({
  methods: { control },
  label,
  name,
  value: checkboxValue,
  formGroupClassName,
  overwriteMarginTopBottom,
  defaultLabelMarginLeft,
  labelClassName,
  ...inputProps
}: FormSwitchProps<T>) => {
  // use controller instead of uncontrolled inputs to work with number type arrays
  const {
    field,
    fieldState: { error }
  } = useController({ name, control });
  const { isChecked, onChange } = useCheckedState({ field, checkboxValue });

  return (
    <div
      className={classNames(
        "form-group",
        formGroupClassName,
        overwriteMarginTopBottom && `margin-bottom-${overwriteMarginTopBottom}`
      )}
    >
      <label
        className={classNames(
          "switch-container",
          overwriteMarginTopBottom && `margin-top-${overwriteMarginTopBottom}`
        )}
      >
        <input
          type="checkbox"
          className={classNames("form-field", "switch")}
          {...inputProps}
          {...field}
          checked={isChecked}
          onChange={onChange}
          value={checkboxValue}
        />
        <div className="slider"></div>
      </label>
      {label && (
        <FormInputLabel
          htmlFor={inputProps.id}
          labelText={label}
          className={classNames(
            "checkbox-label",
            defaultLabelMarginLeft && styles.marginLeftDefault,
            labelClassName
          )}
        />
      )}
      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormSwitch;
