import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import {
  DataFunc,
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem
} from "react-mentions";
import { useParams } from "react-router-dom";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import { FormInputLabel } from "@components/forms/formInputLabel";
import { mentionConstants } from "@components/mention/mentionConstants";
import MentionListContainer from "@components/mention/mentionListContainer";
import {
  AutoSizeTextAreaOptions,
  calculateRowsHeight,
  getPaddingHeight,
  getRowHeight
} from "@components/textArea/useAutosizeTextArea";
import { useLazyGetEmployeesWithAccessToCaseQuery } from "@services/api/employee/employeeApi";
import useForwardedRef from "src/hooks/useForwardedRef";
import { EmployeeParticipantOptionModel } from "@services/api/employee/models/EmployeeParticipantOptionModel";
import styles from "./mentionRichTextArea.module.scss";

export interface MentionRichTextAreaProps extends Omit<MentionsInputProps, "children"> {
  addMentionCallback: (userId: string) => void;
  noFormGroup?: boolean;
  useAutoSizeOptions?: AutoSizeTextAreaOptions;
}

const MentionRichTextArea = <T extends FieldValues = any>({
  methods: { control },
  useAutoSizeOptions,
  name,
  addMentionCallback,
  label,
  required,
  className,
  formGroupClassName,
  noFormGroup,
  ...inputProps
}: FormControlProps<T, MentionRichTextAreaProps>) => {
  const {
    field: { ref: fieldRef, ...field },
    fieldState: { error }
  } = useController({ name, control });
  const textAreaRef = useForwardedRef(fieldRef);

  const { caseId } = useParams();
  if (!caseId) {
    throw new Error('Param "caseId" is required');
  }

  const [getEmployeesWithAccessToCase, { isLoading }] = useLazyGetEmployeesWithAccessToCaseQuery();
  const [caseEmployeeParticipants, setCaseEmployeeParticipants] = useState<
    EmployeeParticipantOptionModel[] | undefined
  >();

  const calculateHeigthFromLines = (lines: number) => {
    if (textAreaRef.current && lines) {
      const rowHeight = getRowHeight(textAreaRef.current);
      const paddingHeight = getPaddingHeight(textAreaRef.current);
      return calculateRowsHeight(rowHeight, paddingHeight, lines);
    }
  };

  const getStyleVariables = () => {
    const minLines = useAutoSizeOptions?.minRows ?? 1;
    const maxLines = useAutoSizeOptions?.maxRows ?? 5;
    const minHeight = calculateHeigthFromLines(minLines);
    const maxHeight = calculateHeigthFromLines(maxLines);

    return {
      "--min-heigth": `${minHeight}px`,
      "--max-heigth": `${maxHeight}px`
    } as React.CSSProperties;
  };

  const queryUsers: DataFunc = async (queryParameter, callback) => {
    if (!caseEmployeeParticipants) {
      getEmployeesWithAccessToCase(caseId)
        .unwrap()
        .then((result) => {
          setCaseEmployeeParticipants(result);
          callback(mapToSuggestedItems(result, queryParameter));
        });
    } else {
      callback(mapToSuggestedItems(caseEmployeeParticipants, queryParameter));
    }
  };

  const mapToSuggestedItems = (employees: EmployeeParticipantOptionModel[], filter: string) => {
    return employees
      .map((e) => ({ id: e.userId, display: e.name }))
      .filter((x) => x.display.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  };

  const onAddMentionEvent = (employeeId: string | number) => {
    addMentionCallback(employeeId as string);
  };

  return (
    <div className={classNames(!noFormGroup && "form-group", formGroupClassName)}>
      {label && <FormInputLabel htmlFor={inputProps.id} labelText={label} isRequired={required} />}

      <MentionsInput
        customSuggestionsContainer={(children: ReactNode) => (
          <MentionListContainer>{children}</MentionListContainer>
        )}
        allowSpaceInQuery={true}
        suggestionsPortalHost={document.body}
        className={classNames("mentions", error && "error", className)}
        classNames={styles}
        style={getStyleVariables()}
        inputRef={textAreaRef}
        {...inputProps}
        {...field}
      >
        <Mention
          markup={`${mentionConstants.mentionBeginTag}__display__${mentionConstants.mentionEndTag}`}
          trigger="@"
          data={queryUsers}
          isLoading={isLoading}
          onAdd={onAddMentionEvent}
          renderSuggestion={renderSuggestion}
          className={styles.mentions__mention}
        />
      </MentionsInput>
      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

const renderSuggestion = (
  entry: SuggestionDataItem,
  search: string,
  highlightedDisplay: ReactNode,
  index: number,
  focused: boolean
) => {
  return (
    <div
      className={classNames(
        "d-flex",
        focused && "alert-primary",
        "border",
        "padding-s",
        "align-items-center"
      )}
    >
      <ParticipantBadge name={entry.display ?? "-"} hideNameField={true} />
      <div className="margin-left-s">{highlightedDisplay}</div>
    </div>
  );
};

export default MentionRichTextArea;
