import { EmblaIllustration } from "@components/embla/emblaIllustration/emblaIllustration";

export type NoResultsProps = {
  noResultsMessage: string;
  /// Default: "failure-mark"
  noResultsIllustrationName?: string;
};

export const NoResultsMessage: React.FC<NoResultsProps> = ({
  noResultsMessage,
  noResultsIllustrationName
}) => {
  return (
    <div className="no-results-message">
      <EmblaIllustration
        illustrationName={noResultsIllustrationName ?? "failure-mark"}
        additionalClasses="subtle"
      />
      <p className="h4 subtle margin-top-s">{noResultsMessage}</p>
    </div>
  );
};
