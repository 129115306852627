import Joyride, { Step, STATUS } from "react-joyride";
import { useLocalization } from "@components/localization/localizationProvider";
import { Localizer } from "@components/localization/localizer";
import { joyrideConstants } from "./joyrideConstants";

export enum JoyridePage {
  DocumentViewer
}

const documentViewerJoyRide = (localizer: Localizer): Step[] => {
  return [
    {
      target: `.${joyrideConstants.documentViewerSidebarHeader}`,
      content: localizer.joyRideConstantsDocumentViewerSidebar(),
      disableBeacon: true,
      placement: "right-start"
    },
    {
      target: `.${joyrideConstants.documentViewerToolboxMarking}`,
      content: localizer.joyRideConstantsDocumentViewerToolboxMarking(),
      disableBeacon: true,
      placement: "left-start"
    },
    {
      target: `.${joyrideConstants.documentViewerToolboxSelector}`,
      content: localizer.joyRideConstantsDocumentViewerToolboxSelector(),
      disableBeacon: true,
      placement: "left-start"
    },
    {
      target: `.${joyrideConstants.documentViewerBottombar}`,
      content: localizer.joyRideConstantsDocumentViewerToolboxBottombar(),
      disableBeacon: true
    },
    {
      target: `.${joyrideConstants.documentViewerDocumentStatus}`,
      content: localizer.joyRideConstantsDocumentDocumentStatus(),
      disableBeacon: true,
      placement: "left-start"
    }
  ];
};
const DetermineJoyride = (joyridePage: JoyridePage, localizer: Localizer): Step[] => {
  switch (joyridePage) {
    case JoyridePage.DocumentViewer:
      return documentViewerJoyRide(localizer);
    default:
      throw new Error(`${joyridePage} has not been implemented`);
  }
};

export type JoyrideOverlayProps = {
  joyridePage: JoyridePage;
  run: boolean;
  joyrideFinishedCallback: () => void;
};
const JoyrideOverlay = ({ joyridePage, run, joyrideFinishedCallback }: JoyrideOverlayProps) => {
  const localizer = useLocalization();

  const handleJoyrideCallback = (data: any) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      joyrideFinishedCallback();
    }
  };

  return (
    <Joyride
      steps={DetermineJoyride(joyridePage, localizer)}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      run={run}
      hideCloseButton={true}
      locale={{
        back: localizer.previous(),
        next: localizer.next(),
        last: localizer.close(),
        skip: localizer.close()
      }}
      floaterProps={{
        disableAnimation: true
      }}
      styles={{
        options: {
          primaryColor: "#116c9b",
          zIndex: 99999
        }
      }}
    />
  );
};

export default JoyrideOverlay;
