import { useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetCaseMarkingTagsQuery } from "@services/api/case/caseApi";
import { HighlightMarkingModel, HIGHLIGHT_COLOR_VALUES } from "../../../models/markingModel";
import { EditCardProps } from "../shared/cardProps.model";
import useMarkingCardForm from "../shared/useMarkingCardForm";
import MarkingDeleteBtn from "../shared/markingDeleteBtn";
import MarkingTagsDropdown from "../shared/MarkingTagsDropdown/MarkingTagsDropdown";
import ColorSelectionModal from "../shared/ColorSelectionModal/colorSelectionModal";
import ColorSelection from "../shared/ColorSelection/ColorSelection";
import MarkingTagsDropdownModal from "../shared/MarkingTagsDropdownModal/MarkingTagsDropdownModal";
import MarkingCloseBtn from "../shared/markingCloseBtn";
import styles from "./highlightEdit.module.scss";

const HighlightEdit = ({
  marking,
  onMarkingChange,
  onSave,
  onDelete,
  onClose,
  isTemplate
}: EditCardProps<HighlightMarkingModel>) => {
  const [modalOpen, setModalOpen] = useState<"color" | "tag" | null>(null);

  const { caseId } = useParams();

  if (!caseId) {
    throw new Error('Param "caseId" is required');
  }

  const { data: markingTags } = useGetCaseMarkingTagsQuery({ caseId: caseId });

  const localizer = useLocalization();
  const markingCantBeDeleted = marking.presentationList && marking.presentationList.length > 0;

  const { methods, submitHandler } = useMarkingCardForm({
    marking,
    onMarkingChange,
    onSave,
    defaultValues: {
      tags: marking.tags,
      data: {
        color: marking.data.color
      }
    }
  });

  const handleColorSelectionOpen = () => {
    setModalOpen(modalOpen === "color" ? null : "color");
  };

  const handleTagSelectionOpen = () => {
    setModalOpen(modalOpen === "tag" ? null : "tag");
  };

  return (
    <Card className={classNames(styles.container, isTemplate && styles.templateStyles)}>
      {!isTemplate && (
        <Card.Header>
          {localizer.highlightTitle()}
          <MarkingCloseBtn close={onClose} />
        </Card.Header>
      )}
      <form
        onSubmit={submitHandler}
        className={classNames("d-flex align-items-center py-2 gap-8", isTemplate && "shadow-2 p-1")}
      >
        {!isTemplate ? (
          <Card.Body>
            <ColorSelection
              colors={HIGHLIGHT_COLOR_VALUES}
              methods={methods}
              id="highlight-color"
              name="data.color"
            />
            <div className="mt-4">{localizer.tags()}</div>
            <MarkingTagsDropdown
              isOpen={modalOpen === "tag"}
              onOpen={handleTagSelectionOpen}
              methods={methods}
              id={`highlight-tags-${marking.id}`}
              name="tags"
              markingTags={markingTags}
            />
          </Card.Body>
        ) : (
          <>
            <h4 className="px-2">{localizer.highlightTitle()}</h4>
            <ColorSelectionModal
              isOpen={modalOpen === "color"}
              onOpen={handleColorSelectionOpen}
              id="highlight-color"
              methods={methods}
              name="data.color"
              colors={HIGHLIGHT_COLOR_VALUES}
            />
            <MarkingTagsDropdownModal
              markingTags={markingTags}
              id={`highlight-tags-${marking.id}`}
              isOpen={modalOpen === "tag"}
              methods={methods}
              name="tags"
              onOpen={handleTagSelectionOpen}
            />
          </>
        )}
      </form>
      {!isTemplate && (
        <>
          <hr className="m-0" />
          <Card.Footer>
            <MarkingDeleteBtn cantBeDeleted={markingCantBeDeleted ?? false} onDelete={onDelete} />
          </Card.Footer>
        </>
      )}
    </Card>
  );
};

export default HighlightEdit;
