import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./addButton.module.scss";

type HtmlButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, "type">;
type Props = {
  className?: string;
  fixedPosition?: boolean;
  defaultSize?: boolean;
} & HtmlButtonProps;

export const AddButton: React.FC<Props> = ({
  className,
  fixedPosition = true,
  defaultSize,
  ...htmlButtonProps
}) => {
  return (
    <button
      className={classNames(
        "btn btn-primary btn-icon add-button",
        styles.button,
        className,
        fixedPosition && styles.fixedPosition,
        !defaultSize && styles.bigSize
      )}
      {...htmlButtonProps}
    >
      <EmblaIcon additionalClasses={styles.icon} iconName="plus" />
    </button>
  );
};
