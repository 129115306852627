import { PaginatedListOutput } from "@models/paginatedList/paginatedListOutput";
import { EmployeePaginatedListModel } from "@services/api/employee/models/employeePaginatedListModel";
import { getFormData } from "@services/helpers/formData";
import { UserProfilePictureModel } from "@models/profile/userProfilePictureModel";
import baseApi, { apiTags } from "../baseApi";
import { buildQueryString } from "../queryStringBuilder";
import { CreateEditEmployeeModel } from "./models/createEditEmployeeModel";
import { DeleteEmployeeResponseModel } from "./models/deleteEmployeeResponseModel";
import { EmployeePaginatedListInput } from "./models/employeePaginatedListInput";
import { EmployeeParticipantOptionsPaginatedListInput } from "./models/employeeParticipantOptionsPaginatedListInput";
import { EmployeeParticipantOptionModel } from "./models/EmployeeParticipantOptionModel";

const employeeUrl = "/api/employee";
const getEmployeeUrl = (id: string) => `${employeeUrl}/${id}`;
const getEmployeeWithAccessToCaseUrl = (caseId: string) => `${employeeUrl}/accessToCase/${caseId}`;
const deleteEmployeeUrl = (id: string) => `${employeeUrl}/${id}`;
const employeesPaginatedUrl = (employeePaginatedListInput: EmployeePaginatedListInput) =>
  `${employeeUrl}/paginatedList?${buildQueryString(employeePaginatedListInput)}`;
const profilePictureUrl = (id: string) => `/api/employee/${id}/profilepicture`;
const employeeOptionsUrl = (
  employeeParticipantOptionsPaginatedListInput: EmployeeParticipantOptionsPaginatedListInput
) =>
  `${employeeUrl}/options/list?${buildQueryString(employeeParticipantOptionsPaginatedListInput)}`;
const resendActivationLinkUrl = (id: string) => `${employeeUrl}/resendActivationLink/${id}`;

const employeeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployee: builder.query<CreateEditEmployeeModel, string>({
      query: (id) => getEmployeeUrl(id),
      providesTags: (result) =>
        result
          ? [
              {
                type: apiTags.employee,
                id: result.employee.id
              }
            ]
          : []
    }),
    getEmployees: builder.query<
      PaginatedListOutput<EmployeePaginatedListModel>,
      EmployeePaginatedListInput
    >({
      query: (input) => ({
        url: employeesPaginatedUrl(input),
        method: "GET"
      }),
      providesTags: (result) => [
        { type: apiTags.employee },
        ...(result?.data.map((c) => ({ type: apiTags.employee, id: c.id })) ?? [])
      ]
    }),
    getEmployeesWithAccessToCase: builder.query<EmployeeParticipantOptionModel[], string>({
      query: (caseId) => getEmployeeWithAccessToCaseUrl(caseId)
    }),
    resendActivationLink: builder.mutation<string, string>({
      query: (input) => ({
        url: resendActivationLinkUrl(input),
        method: "POST"
      })
    }),
    getEmployeeParticipantOptions: builder.query<
      EmployeeParticipantOptionModel[],
      EmployeeParticipantOptionsPaginatedListInput
    >({
      query: (input) => ({
        url: employeeOptionsUrl(input),
        method: "GET"
      }),
      providesTags: (result) => [
        { type: apiTags.employee },
        ...(result?.map((c) => ({ type: apiTags.employee, id: c.employeeId })) ?? [])
      ]
    }),
    createEmployee: builder.mutation<CreateEditEmployeeModel, CreateEditEmployeeModel>({
      query: (model) => ({
        url: employeeUrl,
        method: "POST",
        body: getFormData(model)
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: apiTags.employee },
              { type: apiTags.employee, id: result.employee.id },
              { type: apiTags.user, id: result.employee.userProfile.id },
              { type: apiTags.profile, id: result.employee.userProfile.id }
            ]
          : []
    }),
    editEmployee: builder.mutation<CreateEditEmployeeModel, CreateEditEmployeeModel>({
      query: (model) => ({
        url: employeeUrl,
        method: "PUT",
        body: getFormData(model)
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: apiTags.employee },
              { type: apiTags.employee, id: result.employee.id },
              { type: apiTags.user, id: result.employee.userProfile.id },
              { type: apiTags.profile, id: result.employee.userProfile.id }
            ]
          : []
    }),
    updateEmployeeProfilePicture: builder.mutation<boolean, UserProfilePictureModel>({
      query: (model) => ({
        url: profilePictureUrl(model.employeeId),
        method: "POST",
        body: getFormData(model)
      }),
      invalidatesTags: (result, _, model) =>
        result
          ? [
              apiTags.profilePicture,
              apiTags.caseParticipant,
              apiTags.case,
              { type: apiTags.employee, id: model.employeeId }
            ]
          : []
    }),
    deleteEmployeeProfilePicture: builder.mutation<boolean, string>({
      query: (employeeId) => ({
        url: profilePictureUrl(employeeId),
        method: "DELETE"
      }),
      invalidatesTags: (result, _, employeeId) => [
        apiTags.profilePicture,
        apiTags.caseParticipant,
        apiTags.case,
        { type: apiTags.employee, id: employeeId }
      ]
    }),
    deleteEmployee: builder.mutation<DeleteEmployeeResponseModel, string>({
      query: (id) => ({
        url: deleteEmployeeUrl(id),
        method: "DELETE"
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: apiTags.employee },
              { type: apiTags.employee, id: result.employeeId },
              { type: apiTags.user, id: result.userId },
              { type: apiTags.profile, id: result.userId },
              { type: apiTags.caseParticipant }
            ]
          : []
    })
  })
});

export default employeeApi;
export const {
  useGetEmployeeQuery,
  useGetEmployeeParticipantOptionsQuery,
  useGetEmployeesWithAccessToCaseQuery,
  useLazyGetEmployeesWithAccessToCaseQuery,
  useGetEmployeesQuery,
  useLazyGetEmployeesQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useUpdateEmployeeProfilePictureMutation,
  useDeleteEmployeeProfilePictureMutation,
  useDeleteEmployeeMutation,
  useResendActivationLinkMutation
} = employeeApi;
export const { resetApiState } = employeeApi.util;
