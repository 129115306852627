import { ListChildComponentProps } from "react-window";
import { CSSProperties, useMemo } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { MarkingsPageProvider } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import { PageDimensionsArray } from "../../pageDimensions";
import PageRenderer from "../PageRenderer/PageRenderer";
import { PageCanvas } from "../canvas/pageCanvas";
import { usePdfSize } from "../../hooks/pdfSizeProvider";

export type PageListChildData = {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
  documentId: string;
  draggable?: boolean;
  disableCanvas?: boolean;
  caseId?: string;
  documentHasMarkings?: boolean;
  setMostVisiblePageIndex: (index: number) => void;
  pageTextDataRequired?: boolean;
};

export const PageList = (props: ListChildComponentProps<PageListChildData>) => {
  const pageDimensions = props.data.pdfDimensions[props.index];
  const { pageTextDataRequired = true } = props.data;

  const {
    draggable = false,
    documentId,
    documentHasMarkings,
    setMostVisiblePageIndex
  } = props.data;

  const { width: canvasWidth } = usePdfSize();

  const [, drag, preview] = useDrag(
    {
      type: "page",
      item: { documentId: props.data.documentId, index: props.index },
      canDrag: draggable,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    },
    [draggable]
  );

  preview(getEmptyImage(), { captureDraggingState: true });

  const { disableCanvas = true } = props.data;

  const otherGaps = 85;

  const leftMargin = useMemo(
    () =>
      Math.max(window.innerWidth / 2 - canvasWidth / 2 - pageDimensions.width / 2 - otherGaps, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasWidth, pageDimensions.width, window.innerWidth]
  );

  const listStyle: CSSProperties = {
    ...props.style,
    position: "absolute",
    width: pageDimensions.width,
    height: pageDimensions.height,
    marginTop: props.data.pageMargin,
    left: "0",
    right: "0",
    marginLeft: disableCanvas || !documentHasMarkings ? "auto" : leftMargin, // dont change to e.g (transform: translate ...). That will cause blurryness
    marginRight: "auto" // dont change to e.g (transform: translate ...). That will cause blurryness
  };

  return (
    <MarkingsPageProvider>
      <div ref={draggable ? drag : undefined} style={listStyle}>
        <PageRenderer
          data={props.data}
          pageIndex={props.index}
          isScrolling={props.isScrolling}
          pageDimensions={pageDimensions}
          style={{ position: "absolute" }}
          onPageLoaded={props.data.onDocumentLoaded}
          setMostVisiblePageIndex={setMostVisiblePageIndex}
          orientation={props.data.pageOrientations?.[props.index]}
          textItemsRequired={pageTextDataRequired}
        />
        {!disableCanvas && props.data.caseId && (
          <PageCanvas
            disableCards={!documentHasMarkings}
            caseId={props.data.caseId}
            documentId={documentId}
            pageDimensions={pageDimensions}
            pageIndex={props.index}
            scale={props.data.scale}
          />
        )}
      </div>
    </MarkingsPageProvider>
  );
};

export default PageList;
