import React from "react";
import { debounce } from "lodash";
import { EmblaIcon } from "@components/embla/emblaIcon";
import "./searchInput.scss";

type SearchInputProps = {
  value: string;
  onChangeCallback: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  useDebounce?: boolean;
};

export const SearchInput: React.FC<SearchInputProps> = ({
  onChangeCallback,
  placeholder,
  useDebounce = true
}) => {
  return (
    <div className="form-group input-icon-container">
      <input
        className="form-control"
        onChange={useDebounce ? debounce(onChangeCallback, 500) : onChangeCallback}
        placeholder={placeholder}
      />
      <div className="input-icon">
        <EmblaIcon iconName="search" />
      </div>
    </div>
  );
};
