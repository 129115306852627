import { Store } from "@reduxjs/toolkit";
import { ReactNode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { setError } from "@components/error/errorSlice";
import { UserService } from "@services/userService";
import { ThunkExtras, createStore } from "@store";
import { Config } from "@config/configManager";
import HubConnectionFactory, {
  HubConnectionType
} from "@services/signalRClient/hubConnectionFactory";
import { Event } from "@infrastructure/Event";
import { useLocalization } from "@components/localization/localizationProvider";
import { useHubConnectionManager } from "@components/hubConnectionManager/hubConnectionProvider";

type StoreProvidersProps = {
  userService: UserService;
  config: Config;
  children: ReactNode;
};

const StoreProvider = ({ userService, config, children }: StoreProvidersProps) => {
  const [store, setStore] = useState<Store>();

  const localizer = useLocalization();

  const markingsHubConnectionManager = useHubConnectionManager();

  useEffect(() => {
    if (store) {
      return;
    }

    //const markingsHubConnectionManager = HubConnectionFactory.createHub(HubConnectionType.Markings, userService, config.apiUrl);
    const presentationHubConnectionManager = HubConnectionFactory.createHub(
      HubConnectionType.Presentation,
      userService,
      config.apiUrl
    );

    const newStoreValues: ThunkExtras = {
      userService,
      markingsHubConnectionManager,
      presentationHubConnectionManager,
      localizer
    };
    const newStore = createStore(newStoreValues);
    setStore(newStore);

    window.addEventListener(Event.Unhandledrejection, (error) => {
      // eslint-disable-next-line no-console
      console.log(Event.Unhandledrejection, error);

      newStore.dispatch(
        setError({
          hasError: true,
          error: error.reason?.message
            ? {
                message: error.reason?.message,
                stack: error.reason?.stack,
                response: error.reason?.response
              }
            : error
        })
      );
    });
  }, [config.apiUrl, localizer, markingsHubConnectionManager, store, userService]);

  if (!store) {
    return null;
  }

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
