import { useAuth } from "@components/auth/authProvider";
import { Spinner } from "@components/spinner/spinner";

export const ImpersonateRedirectPage = () => {
  const { userService } = useAuth();

  localStorage.clear();

  userService.login();

  return <Spinner />;
};
