import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "@components/routing/routes";
import { Event } from "@infrastructure/Event";
import { PresentationAction, PresentationActionType } from "./presentationBroadcastActions";

const usePresentNavigation = ({
  currentPageIndex,
  totalPages,
  isBigScreen,
  broadcastPageChange,
  pageSearchPopoverOpen
}: {
  currentPageIndex: number;
  totalPages: number;
  broadcastPageChange: (e: PresentationAction) => void;
  pageSearchPopoverOpen?: boolean;
  isBigScreen?: boolean;
}) => {
  const { presentationId } = useParams();
  if (!presentationId) {
    throw Error("presentationId");
  }

  const navigate = useNavigate();

  const goToPage = useCallback(
    (newPageIndex: number, broadcast = true) => {
      if (newPageIndex >= 0 && (newPageIndex < totalPages || !totalPages)) {
        const newUrl = isBigScreen
          ? RoutePaths.presentBigScreen.url(presentationId, newPageIndex)
          : RoutePaths.present.url(presentationId, newPageIndex);
        navigate(newUrl, { replace: true });
        if (broadcast) {
          broadcastPageChange({
            type: PresentationActionType.PageChange,
            pageIndex: newPageIndex
          });
        }
      }
    },
    [broadcastPageChange, isBigScreen, navigate, presentationId, totalPages]
  );

  const goBack = useCallback(() => goToPage(currentPageIndex - 1), [currentPageIndex, goToPage]);

  const goForward = useCallback(() => goToPage(currentPageIndex + 1), [currentPageIndex, goToPage]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (pageSearchPopoverOpen) return;

      if (e.key === "ArrowLeft") {
        goBack();
      } else if (e.key === "ArrowRight") {
        goForward();
      }
    };
    document.addEventListener(Event.Keydown, listener);
    return () => document.removeEventListener(Event.Keydown, listener);
  }, [goBack, goForward, pageSearchPopoverOpen]);

  return {
    goToPage,
    canGoBack: currentPageIndex > 0,
    goBack,
    canGoForward: currentPageIndex + 1 < totalPages,
    goForward
  };
};

export default usePresentNavigation;
