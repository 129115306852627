import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { InputStatusModule } from "ditmer-embla";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldPath, FieldValues, UseFormWatch } from "react-hook-form";

interface UseAutosaveProps<TValues extends FieldValues> {
  fieldIdPrefix: string;
  queryStatus: QueryStatus;
  watch: UseFormWatch<TValues>;
  onChange?: (field: string, value: unknown) => boolean | void; // if returns "false" - autosave is skipped
  onSubmit: () => void;
  displayStatus?: boolean;
}

const useAutosave = <TValues extends FieldValues>({
  fieldIdPrefix,
  queryStatus,
  watch,
  onChange,
  onSubmit,
  displayStatus = true
}: UseAutosaveProps<TValues>) => {
  const modulesRef = useRef<{ [fieldName: string]: InputStatusModule }>({});
  const [currentRequestFinished, setCurrentRequestFinished] = useState(true);
  const [lastChangedFieldName, setLastFieldNameChanged] = useState<
    FieldPath<TValues> | undefined
  >();

  const getFieldId = useCallback(
    (name: FieldPath<TValues>) => `${fieldIdPrefix}-${name}`,
    [fieldIdPrefix]
  );

  useEffect(() => {
    if (!lastChangedFieldName || !displayStatus) {
      return;
    }

    const clearInputStatuses = () => {
      for (const module of Object.values(modulesRef.current)) {
        module.hide();
      }
    };

    if (!modulesRef.current[lastChangedFieldName]) {
      const element = document.getElementById(getFieldId(lastChangedFieldName));
      if (element) {
        modulesRef.current[lastChangedFieldName] = new InputStatusModule($(element));
      }
    }
    const inputStatusModule = modulesRef.current[lastChangedFieldName];

    if (!inputStatusModule) {
      return;
    }

    switch (queryStatus) {
      case QueryStatus.pending:
        clearInputStatuses();
        inputStatusModule?.showLoading();
        setCurrentRequestFinished(false);
        break;
      case QueryStatus.fulfilled:
        if (!currentRequestFinished) {
          clearInputStatuses();
          inputStatusModule?.showSuccess();
          setCurrentRequestFinished(true);
          setLastFieldNameChanged(undefined);
        }
        break;
      case QueryStatus.rejected:
        if (!currentRequestFinished) {
          clearInputStatuses();
          inputStatusModule?.showError();
          setCurrentRequestFinished(true);
          setLastFieldNameChanged(undefined);
        }
        break;
    }
  }, [
    currentRequestFinished,
    lastChangedFieldName,
    modulesRef,
    fieldIdPrefix,
    queryStatus,
    getFieldId
  ]);

  // use reference, to not recreate memo and run effect each time function ref changes
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const debouncedSubmit = useMemo(
    () =>
      debounce((fieldName: FieldPath<TValues>) => {
        setLastFieldNameChanged(fieldName);
        onSubmitRef.current();
      }, 500),
    []
  );

  useEffect(() => {
    const subscription = watch((_, field) => {
      if (field.name && onChangeRef.current?.(field.name, field.value) !== false) {
        debouncedSubmit(field.name);
      }
    });
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, watch]);

  return { debouncedSubmit, getFieldId };
};

export default useAutosave;
