import Crud from "@components/embla/crud";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { profileValidation } from "@components/validation/sharedValidation/profileValidation";
import { UserProfileModel } from "@models/profile/userProfileModel";

interface ProfileProps {
  profile: UserProfileModel;
  onSubmit: (model: UserProfileModel) => void;
}

const ProfileForm = ({ profile, onSubmit }: ProfileProps) => {
  const localizer = useLocalization();

  const methods = useValidatedForm({
    defaultValues: profile,
    validationSchema: profileValidation(localizer)
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Crud
        body={
          <>
            <FormInput
              methods={methods}
              id="profile-form-first-name"
              label={localizer.firstName()}
              placeholder={localizer.firstNamePlaceholder()}
              name="firstName"
            />

            <FormInput
              methods={methods}
              id="profile-form-last-name"
              label={localizer.lastName()}
              placeholder={localizer.lastNamePlaceholder()}
              name="lastName"
            />
            <FormInput
              methods={methods}
              id="profile-form-email"
              label={localizer.email()}
              placeholder={localizer.emailPlaceholder()}
              name="email"
            />
          </>
        }
        footer={<FormSubmitButton state={methods.formState}>{localizer.save()}</FormSubmitButton>}
      />
    </form>
  );
};

export default ProfileForm;
