import { Popover } from "@headlessui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import classNames from "classnames";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import Badge from "@components/embla/badge";
import Button from "@components/embla/button";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { Tooltip } from "@components/tooltip/tooltip";
import { useAppDispatch, useAppSelector } from "@hooks";
import { useGetCaseMarkingTagsQuery } from "@services/api/case/caseApi";
import { useSearchDocumentsQuery } from "@services/api/document/caseDocumentApi";
import {
  DocumentSearchSortOrder,
  MarkingHit
} from "@services/api/document/models/documentSearchResultModel";
import useShortcut from "src/hooks/useShortcut";
import useSearch from "../hooks/useSearch";
import { MarkingType } from "../models/markingType";
import { markingOwnerFiltersSelector, pdfTypeIdSelector, setSearchHits } from "../pdfViewerSlice";
import MarkingIcon from "../shared/markingIcon";
import styles from "./searchPopover.module.scss";
import KeyNavigation from "./keyNavigation";

interface SearchModalProps {
  pdf?: DocumentCallback;
  disabled?: boolean;
}

const SearchPopover = (props: SearchModalProps) => {
  const { caseId } = useParams();
  if (!caseId) {
    throw new Error("caseId must be set");
  }

  const localizer = useLocalization();
  const searchHook = useSearch(props.pdf);
  const documentId = useAppSelector(pdfTypeIdSelector);
  const dispatch = useAppDispatch();
  const markingOwnerFilters = useAppSelector(markingOwnerFiltersSelector);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchState, setSearchState] = useState<"clear" | "loading" | "showingResults">("clear");
  const [orderStrategy, setOrderStrategy] = useState<DocumentSearchSortOrder>(
    DocumentSearchSortOrder.Page
  );
  const [tagFilters, setTagFilters] = useState<{ name: string; selected: boolean }[]>([]);

  const toggleBtnRef = useRef<HTMLButtonElement>(null);
  // track panel to know internal open state
  const [popoverPanel, setPopoverPanel] = useState<HTMLDivElement | null>(null);

  const { data: searchResultData, isFetching: isLoadingSearchResultData } = useSearchDocumentsQuery(
    (searchTerm.length >= 3 || tagFilters.some((s) => s.selected)) && documentId
      ? {
          caseId,
          documentId: documentId ?? "",
          searchTerm,
          sortOrder: orderStrategy,
          markingsByUsersEmployeeId: markingOwnerFilters ?? [],
          tags: tagFilters.filter((t) => t.selected).map((tag) => tag.name)
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const { data: allTags, isLoading: isLoadingAllTags } = useGetCaseMarkingTagsQuery({
    caseId: caseId
  });

  useShortcut({
    key: "f",
    modifier: true,
    callback: () => !popoverPanel && toggleBtnRef.current?.click()
  });

  useEffect(() => {
    setSearchTerm("");
    dispatch(setSearchHits([]));
  }, [dispatch, popoverPanel]);

  useEffect(() => {
    const hasInput = searchTerm.length >= 3 || tagFilters.some((t) => t.selected);

    if (hasInput && !isLoadingSearchResultData) {
      setSearchState("showingResults");
    } else if (hasInput && isLoadingSearchResultData) {
      setSearchState("loading");
    } else {
      setSearchState("clear");
    }
  }, [isLoadingSearchResultData, searchTerm.length, tagFilters]);

  useEffect(() => {
    if (allTags) {
      const tagOptions = allTags.map((tag) => {
        return { name: tag.tagName, selected: false };
      });

      setTagFilters(tagOptions);
    }
  }, [allTags, isLoadingAllTags]);

  useEffect(() => {
    if (searchResultData && !isLoadingSearchResultData) {
      const hitsStripped: { hitText: string; pageIndex: number }[] = [];
      for (const hit of searchResultData.documentHits) {
        const keywordsWithTags = hit.highlight.match(/<em>(.*?)<\/em>/g);
        if (keywordsWithTags) {
          const hitWithoutHighlightTags = keywordsWithTags.map((kw) => {
            return kw.replace("<em>", "").replace("</em>", "");
          });

          hitWithoutHighlightTags.forEach((hitWithout) =>
            hitsStripped.push({ hitText: hitWithout.toLowerCase(), pageIndex: hit.pageIndex })
          );
        }
      }

      const hitsUnique = [
        ...new Map(hitsStripped.map((item) => [item.pageIndex + item.hitText, item])).values()
      ];

      dispatch(setSearchHits(hitsUnique));
    }
  }, [searchResultData, isLoadingSearchResultData, dispatch]);

  const onSearchTermChanged = (searchTermValue: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = searchTermValue.target.value;
    setSearchTerm(newSearchTerm);
  };

  const onClickFilter = (filterName: string) => {
    const newState = tagFilters.map((obj) => {
      if (obj.name === filterName) {
        return { ...obj, selected: !obj.selected };
      }
      return obj;
    });

    setTagFilters(newState);
  };

  const onHitClicked = (pageIndex: number) => {
    searchHook.jumpToMatch(pageIndex);
  };

  const getMarkingText = (markingHit: MarkingHit) => {
    if (markingHit.markingType === MarkingType.Comment) {
      return transformHitHighlight(markingHit.commentHighlight);
    } else if (markingHit.markingType === MarkingType.FocusBox) {
      return localizer.focusBoxTitle();
    } else if (markingHit.markingType === MarkingType.Highlight) {
      return localizer.highlightTitle();
    } else if (markingHit.markingType === MarkingType.VerticalLines) {
      return localizer.verticalLinesTitle();
    } else {
      throw new Error("unknown markingtype");
    }
  };

  const transformHitHighlight = (highlight: string) => {
    const highlightSplit = highlight.split("<em>");
    const transformedHighlight = highlightSplit.map((element, i) => {
      if (element.match("</em>")) {
        const endSplit = element.split("</em>");
        return [
          <b key={i} className={classNames(styles.highlightHit)}>
            {endSplit[0]}
          </b>,
          endSplit[1]
        ];
      } else {
        return element;
      }
    });

    return transformedHighlight.flat();
  };

  const searchInputNavIndex = 0;
  const markingHitsNavIndex = (i: number) => 1 + i;
  const documentHitsNavIndex = (i: number) => 1 + (searchResultData?.markingHits.length ?? 0) + i;

  const renderSearchResults = () => {
    return (
      <>
        <div className={classNames("card-body", styles.markingHitsBody)}>
          <div>
            <span className="font-weight-bold">{searchResultData?.markingHits.length}</span>{" "}
            {localizer.markings()}
          </div>
          <div>
            {searchResultData?.markingHits.map((ch, i) => {
              return (
                <KeyNavigation.Item key={i} index={markingHitsNavIndex(i)}>
                  <div
                    tabIndex={0}
                    role="button"
                    className={classNames(
                      "d-flex",
                      "align-items-center",
                      "margin-top-m",
                      styles.contentHitRow
                    )}
                    onClick={() => onHitClicked(ch.pageIndex)}
                  >
                    <div className={classNames(styles.documentIcon)}>
                      <MarkingIcon
                        key={`${ch.markingType}-${i}-icon`}
                        markingType={ch.markingType}
                        className="embla-icon"
                      />
                    </div>
                    <div
                      className={classNames(
                        "margin-left-m",
                        "margin-right-m",
                        "d-flex",
                        "flex-grow-1",
                        styles.minWidth0
                      )}
                    >
                      <div className={classNames(styles.minWidth0)}>
                        <div className={classNames(styles.ellipsis)}>{getMarkingText(ch)}</div>
                        <div className="small subtle">{ch.owner}</div>
                      </div>
                    </div>
                    <div className={classNames(styles.pageNumber)}>
                      {`${localizer.pdfPage()} ${ch.pageIndex + 1}`}
                    </div>
                  </div>
                </KeyNavigation.Item>
              );
            })}
          </div>
        </div>
        {searchTerm.length >= 3 && (
          <div className={classNames("card-body", styles.documentHitsBody)} tabIndex={-1}>
            <div>
              <span className="font-weight-bold">{searchResultData?.documentHits.length}</span>{" "}
              {localizer.placesInContent()}
            </div>
            <div>
              {searchResultData?.documentHits.map((dh, i) => (
                <KeyNavigation.Item key={i} index={documentHitsNavIndex(i)}>
                  <div
                    tabIndex={0}
                    role="button"
                    className={classNames(
                      "d-flex",
                      "align-items-center",
                      "margin-top-m",
                      styles.contentHitRow
                    )}
                    onClick={() => onHitClicked(dh.pageIndex)}
                    onFocus={() => onHitClicked(dh.pageIndex)}
                  >
                    <div className={classNames(styles.documentIcon)}>
                      <EmblaIcon iconName="document" />
                    </div>
                    <div
                      className={classNames(
                        "margin-left-m",
                        "margin-right-m",
                        "d-flex",
                        "flex-grow-1",
                        styles.minWidth0
                      )}
                    >
                      <div className={classNames(styles.ellipsis)}>
                        {transformHitHighlight(dh.highlight)}
                      </div>
                    </div>
                    <div className={classNames(styles.pageNumber)}>
                      {`${localizer.pdfPage()} ${dh.pageIndex + 1}`}
                    </div>
                  </div>
                </KeyNavigation.Item>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Popover className="relative">
      <Tooltip message={!popoverPanel && `${localizer.search()} (Ctrl + F)`} placement="bottom">
        <div>
          <Popover.Button
            className={classNames("btn btn-icon btn-default px-3 py-1")}
            ref={toggleBtnRef}
            disabled={props.disabled}
          >
            <EmblaIcon iconName="search" color={IconColor.DarkGray} />
          </Popover.Button>
        </div>
      </Tooltip>

      {!props.disabled && (
        <Popover.Panel
          as={KeyNavigation}
          className={classNames(styles.popOverPanel)}
          ref={setPopoverPanel}
        >
          <div
            className={classNames("card", "shadow-1", "margin-bottom-0", styles.popOverPanelCard)}
          >
            <div className="card-body">
              <div className="form-group input-icon-container">
                <KeyNavigation.Item index={searchInputNavIndex}>
                  <input
                    id={styles.searchInput}
                    autoFocus
                    className={classNames("form-control")}
                    onChange={debounce(onSearchTermChanged, 500)}
                    placeholder={localizer.searchAtLeast3Characters()}
                  />
                </KeyNavigation.Item>
                <div className={classNames(styles.searchInputBtnGroup)}>
                  {searchState === "showingResults" && (
                    <div
                      className={classNames("margin-right-s")}
                      onClick={() =>
                        setOrderStrategy(
                          orderStrategy === DocumentSearchSortOrder.Page
                            ? DocumentSearchSortOrder.Score
                            : DocumentSearchSortOrder.Page
                        )
                      }
                    >
                      {orderStrategy === DocumentSearchSortOrder.Page
                        ? localizer.placement()
                        : localizer.relevance()}
                      <EmblaIcon iconName="arrow-down" />
                    </div>
                  )}

                  <Tooltip message={`${localizer.close()} (Esc)`}>
                    <Popover.Button as={Button} borderless iconBtn className={styles.closeBtn}>
                      <EmblaIcon iconName="close" />
                    </Popover.Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            {tagFilters.length > 0 && (
              <div className="card-body">
                {tagFilters.map((tag, _) => {
                  return (
                    <Badge
                      pill
                      appearance={tag.selected ? "primary" : "success-light"}
                      key={tag.name}
                      className={classNames("padding-s", "margin-xxs", styles.filterBadge)}
                      onClick={() => onClickFilter(tag.name)}
                    >
                      {tag.name}
                    </Badge>
                  );
                })}
              </div>
            )}
            {searchState === "showingResults" && renderSearchResults()}
            {searchState === "loading" && (
              <div className="card-body">
                {" "}
                <Spinner size="small" />{" "}
              </div>
            )}
          </div>
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default SearchPopover;
