import React from "react";
import Markdown from "react-markdown";
import classNames from "classnames";
import { SpeakerNoteSizeEnum } from "@services/api/case/models/casePresentationPageModel";
import styles from "./markdownRenderer.module.scss";

export type MarkdownRendererProps = {
  children: string;
  size?: SpeakerNoteSizeEnum;
};

const MarkdownRenderer = ({ children, size = 0 }: MarkdownRendererProps) => {
  return (
    <Markdown
      className={classNames(
        "break-spaces",
        styles.markdownRender,
        size === SpeakerNoteSizeEnum.Smallest && styles.smallest,
        size === SpeakerNoteSizeEnum.Smaller && styles.smaller,
        size === SpeakerNoteSizeEnum.Small && styles.small,
        size === SpeakerNoteSizeEnum.Medium && styles.medium,
        size === SpeakerNoteSizeEnum.Large && styles.large
      )}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownRenderer;
