import {
  ConfigProvider,
  DatePicker as AntDatePicker,
  DatePickerProps as AntDatePickerProps
} from "antd";
import { DatePickRef } from "antd/es/date-picker/generatePicker/interface";
import localeDaDK from "antd/es/date-picker/locale/da_DK";
import localeEnGB from "antd/es/date-picker/locale/en_GB";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { forwardRef } from "react";
import { Language } from "@infrastructure/language";
import { useAppSelector } from "@hooks";
import { localizationStateSelector } from "@components/localization/localizationSlice";
import { EmblaIcon, IconSize } from "@components/embla/emblaIcon";
import theme from "./antTheme.json";
import styles from "./datePicker.module.scss";

// support multiple styles of input
const dateFormats = ["DD/MM/YYYY", "D/M/YYYY", "DD/MM/YY", "D/M/YY", "YYYY-MM-DD"];
const dateTimeFormats = [...dateFormats.map((d) => `${d} HH:mm`), ...dateFormats];

export type DatePickerProps = AntDatePickerProps & {
  value?: string;
  onChange?: (value: string | null, dateString: string) => void;
};

const DatePicker = forwardRef(
  ({ className, value, onChange, ...props }: DatePickerProps, ref: DatePickRef<Dayjs>) => {
    const { language } = useAppSelector(localizationStateSelector);
    const locale = language === Language.Danish ? localeDaDK : localeEnGB;

    let showTime = "showTime" in props && props.showTime;
    if (showTime) {
      showTime = {
        showSecond: false, // don't show seconds by default
        format: dateTimeFormats[0], // fix picker dayjs adapter crash with array of date formats.
        ...(showTime === true ? {} : showTime)
      };
    }
    const timeProps = { showTime };

    const handleChange = (date: Dayjs | null, dateString: string) => {
      if (date === null || dayjs.isDayjs(value)) {
        onChange?.(date, dateString);
      } else {
        if (showTime) {
          onChange?.(date.toISOString(), dateString);
        } else {
          // We are only interested in the date and don't want to include any offset specific details since this makes the date change. The specific time is not meaningful.
          onChange?.(date.format("YYYY-MM-DDT00:00:00.000") + "Z", dateString);
        }
      }
    };

    return (
      <ConfigProvider theme={theme}>
        <AntDatePicker
          ref={ref}
          suffixIcon={<EmblaIcon iconName="calendar" size={IconSize.Small} />}
          clearIcon={<EmblaIcon iconName="remove" additionalClasses={styles.clear} />}
          className={classNames(styles.input, "form-control", className)}
          locale={locale}
          format={showTime ? dateTimeFormats : dateFormats}
          value={value && dayjs(value)}
          popupClassName={styles.popup}
          onChange={handleChange}
          {...props}
          {...timeProps}
        />
      </ConfigProvider>
    );
  }
);

export default DatePicker;
