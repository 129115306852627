import { NotificationModule } from "ditmer-embla";
import { useFieldArray } from "react-hook-form";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { useModalContext } from "@components/modal/modal";
import { useAddPresentationsMutation } from "@services/api/casePresentation/casePresentationApi";
import { CreatePresentationsModel } from "@services/api/casePresentation/models/createPresentationsModel";
import { AddPresentationsForm } from "@components/case/presentation/addPresentation/AddPresentationsForm";
import { addPresentationsValidation } from "./addPresentationsValidation";

type AddPresentationsProps = {
  caseId: string;
  startSortOrder: number;
};

const initialValues: CreatePresentationsModel = {
  presentations: []
};

export const AddPresentations: React.FC<AddPresentationsProps> = (props) => {
  const localizer = useLocalization();
  const [addPresentations, request] = useAddPresentationsMutation();
  const { close } = useModalContext();

  const methods = useValidatedForm({
    validationSchema: addPresentationsValidation(localizer),
    defaultValues: initialValues
  });

  const presentationsArray = useFieldArray({
    control: methods.control,
    name: "presentations"
  });

  const onSubmitForm = async (model: CreatePresentationsModel) => {
    addPresentations({ caseId: props.caseId, model: model }).then(() => {
      NotificationModule.showSuccessSmall(localizer.thePresentationsWasAdded());
      close();
    });
  };

  return (
    <>
      <Modal.Header>{localizer.addPresentation()}</Modal.Header>
      <Modal.Body>
        <AddPresentationsForm
          methods={methods}
          presentations={presentationsArray.fields}
          onAddPresentation={() => presentationsArray.append({ title: "" })}
          onDeletePresentation={(index: number) => presentationsArray.remove(index)}
          onSubmitCallback={methods.handleSubmit(onSubmitForm)}
          hideTitle={true}
          presentationStartSortOrder={props.startSortOrder}
        />
      </Modal.Body>
      <Modal.Footer>
        <FormSubmitButton
          state={{
            isSubmitting: request.isLoading,
            isSubmitSuccessful: request.isSuccess
          }}
          onClick={methods.handleSubmit(onSubmitForm)}
        >
          {localizer.save()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};
