import { CellProps } from "react-table";
import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./tableCell.module.scss";

export const TableIconCell = <T extends object>({ value, iconName, color }: CellProps<T>) => {
  return (
    <div
      className={classNames(styles["table-cell"])}
      title={typeof value === "string" ? value : undefined}
    >
      <EmblaIcon iconName={iconName} color={color} /> {value}
    </div>
  );
};
