import * as yup from "yup";

import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { EmailTemplateModel } from "@services/api/emailTemplate/models/emailTemplateModel";

export const emailTemplateUpdateValidation = (localizer: Localizer) =>
  createValidationSchema<EmailTemplateModel>({
    subject: yup.string().required(localizer.subjectRequired()).max(200, localizer.maxLength200()),
    bodyMarkdown: yup.string().required(localizer.bodyMarkdownRequired()),
    culture: yup.string(),
    readableCulture: yup.string(),
    readableType: yup.string(),
    type: yup.number()
  });
