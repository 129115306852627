import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Config } from "@config/configManager";
import type { RootState } from "@app";

export type ConfigState = Config;

const initialState: ConfigState | null = null as Config | null;

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (_, action: PayloadAction<Config>) => {
      return action.payload;
    }
  }
});

export const { setConfig } = configSlice.actions;
export const configStateSelector = (state: RootState) => state.config;
export default configSlice.reducer;
