import classNames from "classnames";
import { forwardRef, Ref, TextareaHTMLAttributes } from "react";
import useAutosizeTextArea, {
  AutoSizeTextAreaOptions
} from "@components/textArea/useAutosizeTextArea";
import useForwardedRef from "src/hooks/useForwardedRef";
import styles from "./textArea.module.scss";

type TextAreaProps = {
  useAutoSize?: boolean;
  autoSizeFieldValueToWatch?: string;
  autoSizeOptions?: AutoSizeTextAreaOptions;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef(
  (
    { useAutoSize, autoSizeFieldValueToWatch, autoSizeOptions, ...textAreaProps }: TextAreaProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const textAreaRef = useForwardedRef(ref);

    useAutosizeTextArea(!!useAutoSize, textAreaRef, autoSizeFieldValueToWatch, autoSizeOptions);

    return (
      <textarea
        ref={textAreaRef}
        {...textAreaProps}
        className={classNames(useAutoSize && styles.autoSize, textAreaProps.className)}
      />
    );
  }
);

export default TextArea;
