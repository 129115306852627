import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
  retry
} from "@reduxjs/toolkit/query/react";
import { RootState } from "@app";
import { getFormData } from "@services/helpers/formData";
import createBaseQuery from "../createBaseQuery";
import { UploadFileChunkModel } from "./models/uploadFileChunkModel";
import { UploadFileModel } from "./models/uploadFileModel";
import { FinalizeFileChunksModel } from "./models/FinalizeFileChunksModel";

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  baseQueryApi,
  extraOptions
) => {
  const baseUrl = (baseQueryApi.getState() as RootState).config?.fileApiUrl;
  const rawBaseQuery = createBaseQuery(baseUrl);
  return rawBaseQuery(args, baseQueryApi, extraOptions);
};

const fileStorageApi = createApi({
  reducerPath: "api/file",
  baseQuery: retry(dynamicBaseQuery, { maxRetries: 5 }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<string, { uploadFileUri: string; model: UploadFileModel }>({
      query: ({ uploadFileUri, model }) => ({
        url: uploadFileUri,
        method: "POST",
        body: getFormData(model)
      }),
      extraOptions: { maxRetries: 0 }
    }),

    uploadFileChunk: builder.mutation<
      string,
      { uploadFileUri: string; model: UploadFileChunkModel }
    >({
      query: ({ uploadFileUri, model }) => ({
        url: uploadFileUri,
        method: "POST",
        body: getFormData(model)
      })
    }),

    finalizeFileChunks: builder.mutation<
      string,
      { uploadFileUri: string; model: FinalizeFileChunksModel }
    >({
      query: ({ uploadFileUri, model }) => ({
        url: uploadFileUri,
        method: "POST",
        body: getFormData(model)
      })
    })
  })
});

export default fileStorageApi;
export const { useUploadFileMutation, useUploadFileChunkMutation, useFinalizeFileChunksMutation } =
  fileStorageApi;
export const { resetApiState } = fileStorageApi.util;
