import classNames from "classnames";
import { Bookmark } from "@pages/pdfviewer/component/pdfSidebar/bookmarks/bookmark";
import { useGetDocumentBookmarksQuery } from "@services/api/document/caseDocumentApi";

export const BookmarkTree = (props: {
  caseId: string;
  documentId: string;
  skipFetch?: boolean;
  className?: string;
}) => {
  const { data: bookmarks } = useGetDocumentBookmarksQuery(
    { caseId: props.caseId, documentId: props.documentId },
    { skip: props.skipFetch }
  );

  return (
    <div className={classNames("padding-m", props.className)}>
      {bookmarks && bookmarks.map((bookmark) => <Bookmark key={bookmark.id} bookmark={bookmark} />)}
    </div>
  );
};
