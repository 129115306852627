import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { NotificationModule } from "ditmer-embla";
import BackButton from "@components/button/BackButton";
import { RoutePaths } from "@components/routing/routes";
import { documentTabId } from "@pages/case/casePage";
import { useLocalization } from "@components/localization/localizationProvider";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useDeleteExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import ModalMessage from "@components/modal/modalMessage";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { UpdateExtractDraft } from "@pages/case/documents/addDocuments/createExtractDraft/updateExtractDraft";
import Button from "@components/embla/button";
import { useExtractDraftStatus } from "@pages/pdfviewer/component/hooks/useExtractDraftStatus";
import { PillBadge } from "@components/badge/pillBadge";
import CompleteOrExportExtractDraft from "@pages/extractCompositionPage/components/export/completeOrExportExtractDraft";
import { ExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import { ExtractCompositionAddSectionModal } from "../contentSidebar/extractCompositionAddSectionModal";
import styles from "./extractCompositionToolbar.module.scss";

interface ToolBarProps {
  caseId: string;
  extractDraftId: string;
  extractDraftTitle: string;
  subTitle: string;
  tableOfContentsSettings: ExtractDraftTableOfContentsModel;
}

const ExtractCompositionToolbar = ({
  caseId,
  extractDraftTitle,
  subTitle,
  extractDraftId,
  tableOfContentsSettings
}: ToolBarProps) => {
  const localizer = useLocalization();
  const navigate = useNavigate();
  const [deleteExtractDraft, deleteExtractDraftRequest] = useDeleteExtractDraftMutation();
  const [showDeleteExtractDraftModal, setShowDeleteExtractDraftModal] = useState(false);
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showExportExtractDraftModel, setShowExportExtractDraftModel] = useState<
    "export" | "complete" | undefined
  >(undefined);
  const [showEditExtractDraftModel, setShowEditExtractDraftMode] = useState<boolean>(false);
  const [exportExtractDraftSubmitPortal, setExportExtractDraftSubmitPortal] =
    useState<Element | null>();
  const [exportExtractDraftHeaderPortal, setExportExtractDraftHeaderPortal] =
    useState<Element | null>();

  const [isLoadingExtractDraftRequest, setIsLoadingExtractDraftRequest] = useState<boolean>(false);
  const [isErrorExtractDraftRequest, setIsErrorExtractDraftRequest] = useState<boolean>(false);

  useExtractDraftStatus(isLoadingExtractDraftRequest, isErrorExtractDraftRequest);

  const navigateBackUrl = RoutePaths.caseDetails.url(caseId, documentTabId);

  const deleteDraft = () => {
    deleteExtractDraft({ caseId: caseId, extractDraftId: extractDraftId });
  };

  const requestChanged = (isLoading: boolean, isError: boolean) => {
    setIsLoadingExtractDraftRequest(isLoading);
    setIsErrorExtractDraftRequest(isError);
  };

  useEffect(() => {
    if (deleteExtractDraftRequest.isSuccess) {
      NotificationModule.showSuccessSmall(localizer.draftWasDeleted());
      navigate(navigateBackUrl);
    }
  }, [deleteExtractDraftRequest.isSuccess, localizer, navigate, navigateBackUrl]);

  return (
    <>
      <div
        className={classNames(
          styles.toolbar,
          "align-items-end",
          "bg-white",
          "shadow-1",
          "px-3",
          "py-3"
        )}
      >
        <div>
          <BackButton onClickCallback={() => navigateBackUrl} />
        </div>

        <div className="row justify-content-center mb-n1">
          <div className={classNames("col mb-1 overflow-hidden", styles.pdfInfo)}>
            <div className="d-flex">
              <div className="min-w-0">
                <p className={classNames("m-0 text-truncate", styles.hiddenWhenWraps)}>
                  {subTitle}
                </p>
                <h2 className={classNames("text-truncate", styles.title)}>{extractDraftTitle}</h2>
              </div>
              <div className="px-3 d-flex mt-auto">
                <PillBadge smallText>
                  <EmblaIcon iconName="edit" color={IconColor.White} />
                  {localizer.draft()}
                </PillBadge>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-s">
          <Button
            theme="default"
            className="text-nowrap pl-2"
            onClick={() => setShowAddSectionModal(true)}
          >
            <EmblaIcon iconName="copy" />
            {localizer.addSection()}
          </Button>
          <Button
            theme="primary"
            className="text-nowrap d-flex pl-2"
            onClick={() => setShowExportExtractDraftModel("complete")}
          >
            <EmblaIcon iconName="document-check" />
            {localizer.approveExtractDraft()}
          </Button>
          <Button
            iconBtn={true}
            linkBtn={true}
            className={classNames(styles.EditButtonBorder)}
            onClick={() => setShowEditExtractDraftMode(true)}
          >
            <EmblaIcon iconName="overflow" />
          </Button>
        </div>
      </div>
      <ModalMessage
        open={showDeleteExtractDraftModal}
        onClose={() => setShowDeleteExtractDraftModal(false)}
        title={localizer.deleteExtractDraft()}
        description={localizer.areYouSureExtractDraftDelete()}
        acceptButtonText={localizer.deleteExtractDraft()}
        cancelButtonText={localizer.cancel()}
        cancelAction={() => setShowDeleteExtractDraftModal(false)}
        acceptAction={() => deleteDraft()}
        modalAcceptType={"danger"}
      />
      <Modal
        onClose={() => setShowEditExtractDraftMode(false)}
        open={showEditExtractDraftModel}
        size={ModalSizeEnum.Undefined}
      >
        <Modal.Header>{localizer.renameExtractDraft()}</Modal.Header>
        <Modal.Body>
          <UpdateExtractDraft
            caseId={caseId}
            extractDraftId={extractDraftId}
            initialValues={{ title: extractDraftTitle }}
            updateExtractDraftRequestStatusChanged={requestChanged}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            theme="danger"
            className="text-nowrap d-flex pl-2"
            onClick={() => setShowDeleteExtractDraftModal(true)}
          >
            <EmblaIcon iconName="delete" />
            {localizer.deleteExtractDraft()}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        onClose={() => setShowExportExtractDraftModel(undefined)}
        open={showExportExtractDraftModel !== undefined}
      >
        <Modal.Header>
          <div ref={setExportExtractDraftHeaderPortal}></div>
        </Modal.Header>
        <Modal.Body>
          {exportExtractDraftSubmitPortal && exportExtractDraftHeaderPortal && (
            <CompleteOrExportExtractDraft
              tableOfContentsSettings={tableOfContentsSettings}
              caseId={caseId}
              extractId={extractDraftId}
              submitPortal={exportExtractDraftSubmitPortal}
              headerPortal={exportExtractDraftHeaderPortal}
              initialTab={showExportExtractDraftModel}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div ref={setExportExtractDraftSubmitPortal}></div>
        </Modal.Footer>
      </Modal>
      <ExtractCompositionAddSectionModal
        caseId={caseId}
        extractId={extractDraftId}
        showModal={showAddSectionModal}
        onCloseCallback={() => setShowAddSectionModal(false)}
        onSuccessCallback={() => setShowAddSectionModal(false)}
      />
    </>
  );
};

export default ExtractCompositionToolbar;
