import classNames from "classnames";
import { forwardRef, HTMLAttributes, Ref } from "react";
import "./badge.module.scss";

interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  pill?: boolean;
  size?: "default" | "large" | "wide";
  appearance?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "success-light";
  isLoading?: boolean;
}

const Badge = forwardRef(
  (
    { pill, size, appearance = "default", className, ...htmlBadgeProps }: BadgeProps,
    ref: Ref<HTMLSpanElement>
  ) => {
    return (
      <span
        ref={ref}
        className={classNames(
          "badge",
          "badge-" + appearance,
          {
            "badge-pill": pill,
            "badge-lg": size === "large",
            "badge-wide": size === "wide",
            "color-white": appearance !== "default"
          },
          className
        )}
        {...htmlBadgeProps}
      />
    );
  }
);

export default Badge;
