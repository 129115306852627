import * as yup from "yup";

import { createValidationSchema } from "@components/validation/createValidationSchema";
import { Localizer } from "@components/localization/localizer";
import { ChangePasswordModel } from "@services/api/user/models/changePasswordModel";

export const changePasswordValidation = (localizer: Localizer) =>
  createValidationSchema<ChangePasswordModel>({
    id: yup.string(),
    password: yup.string().required(localizer.newPasswordRequired()),
    repeatPassword: yup.string().required(localizer.repeatNewPasswordRequired())
  });
