import classNames from "classnames";
import React from "react";
import styles from "./spinner.module.scss";

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  size?: "small" | "extra-small" | "extra-tiny-small";
  color?: "primary" | "white" | "match-font";
}

export const Spinner = ({
  text,
  size,
  color = "primary",
  className,
  ...divProps
}: SpinnerProps) => (
  <div className={classNames(styles.spinnerWithText, className)} {...divProps}>
    <div
      className={classNames(styles.spinner, {
        [styles.small]: size === "small",
        [styles.extraSmall]: size === "extra-small",
        [styles.extraTinySmall]: size === "extra-tiny-small",
        [styles.colorPrimary]: color === "primary",
        [styles.colorWhite]: color === "white"
      })}
    >
      <div className={styles.innerSpinner} />
    </div>
    {text && <h4 className="margin-left-s">{text}</h4>}
  </div>
);
