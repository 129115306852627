import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@components/auth/authProvider";
import { useLocalization } from "@components/localization/localizationProvider";
import { Dimensions } from "@components/resizable/resizable";
import { Spinner } from "@components/spinner/spinner";
import { CasePresentationEventModel } from "@pages/pdfviewer/component/models/casePresentationEventModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { createHighlightMarking } from "@pages/pdfviewer/component/page/canvas/utils/createMarking";
import { resetActiveTool, setActiveTool } from "@pages/pdfviewer/component/pdfViewerSlice";
import usePdfViewer from "@pages/present/usePdfViewer";
import usePresentPresentationDocument from "@pages/present/usePresentPresentationDocument";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { PresentationHubEventType } from "@services/signalRClient/presentationHubConnectionManager";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import useHubConnection from "src/hooks/useHubConnection";
import ModalMessage from "@components/modal/modalMessage";
import { apiTags } from "@services/api/baseApi";
import presentationApi from "@services/api/presentation/presentationApi";
import { RoutePaths } from "@components/routing/routes";
import PresentTopBar from "@pages/present/presentTopBar";
import SinglePagePdfView, {
  Position
} from "@pages/pdfviewer/component/SinglePagePdfView/SinglePagePdfView";
import NewMarkingsPageOverlay from "@pages/pdfviewer/component/page/canvas/MarkingsPageOverlay/MarkingsPageOverlay";
import { MarkingsPageProvider } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import MarkingsSidebar from "@pages/case/presentations/editPresentationPages/MarkingsSidebar/MarkingsSidebar";
import LiveZoom from "./liveZoom";
import styles from "./present.module.scss";
import {
  PRESENTATION_BROADCAST_CHANNEL,
  PresentationAction,
  PresentationActionType
} from "./presentationBroadcastActions";
import {
  clearPausedPresentationState,
  clearTempPresentationPageState,
  pausedPresentationStateSelector,
  tempPresentationPageStateSelector
} from "./presentationSlice";
import PresenterToolbox, { PresenterTool } from "./presenterToolbox";
import useDynamicPresentationPages from "./useDynamicPresentationPages";
import TempPresentationPageOverlay from "./tempPresentationPageOverlay";
import usePresentCaseDocument from "./usePresentCaseDocument";
import LocatePagePopover from "./LocatePagePopover";
import PresentSpeakerNotesSidebar from "./PresentSpeakerNotesSidebar/PresentSpeakerNotesSidebar";

const Present = () => {
  const { presentationId, ...params } = useParams();
  let pageIndex = parseInt(params.pageIndex ?? "");
  if (!presentationId || Number.isNaN(pageIndex)) {
    throw Error("presentationId and pageIndex params are required");
  }

  const [caseHubConnectionManager] = useState(useHubConnection(HubConnectionType.Presentation));
  const tempPresentationState = useSelector(tempPresentationPageStateSelector);

  const [stateToRestore] = useState(useSelector(pausedPresentationStateSelector)); // wrap in useState to take only on first render
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localization = useLocalization();
  const [showMarkings, setShowMarkings] = useState(false);
  const [showSearchPopover, setShowSearchPopover] = useState(false);
  const [showSpeakerNotes, setShowSpeakerNotes] = useState(true);
  const [scale, setScale] = useState(1);
  const { title, isLoading, pdfUrl } = usePresentPresentationDocument(presentationId);
  const { pagesData, isPagesLoading, markingsOverride } = useDynamicPresentationPages(
    presentationId,
    stateToRestore?.markingsOverride
  );
  if (pagesData && pageIndex >= pagesData.length) {
    pageIndex = pagesData.length - 1;
  }

  const { isLoading: isLoadingTempUrl, pdfUrl: pdfUrlTemp } = usePresentCaseDocument(
    tempPresentationState?.documentId,
    tempPresentationState?.pageIndex
  );
  const { handleLoadSuccess } = usePdfViewer(presentationId);
  const [tool, setTool] = useState<PresenterTool>(PresenterTool.Cursor);
  const { user } = useAuth();
  const localizer = useLocalization();

  const [closePresentationModalMessageOpen, setClosePresentationModalMessageOpen] = useState(false);

  const closePresentation = async () => {
    postMessage({ type: PresentationActionType.Close });
    dispatch(clearTempPresentationPageState());
    if (presentationId && caseHubConnectionManager) {
      await caseHubConnectionManager.stopConnection(presentationId);
    }

    if (pagesData && pagesData[0]) {
      navigate(
        RoutePaths.casePresentationEdit.url(pagesData[0].caseId, presentationId, pagesData[0].id)
      );
    } else {
      navigate(-1);
    }
  };

  const { postMessage } = useBroadcastChannel<PresentationAction>({
    name: PRESENTATION_BROADCAST_CHANNEL
  });

  useEffect(() => {
    return () => {
      if (presentationId && caseHubConnectionManager) {
        caseHubConnectionManager.stopConnection(presentationId);
      }
    };
  }, [caseHubConnectionManager, dispatch, presentationId]);

  useEffect(() => {
    caseHubConnectionManager.startConnection(presentationId);
    return () => {
      caseHubConnectionManager.stopConnection(presentationId);
    };
  }, [caseHubConnectionManager, presentationId]);

  const HandlePresentationUpdate = useCallback(
    (e: CasePresentationEventModel) => {
      dispatch(
        presentationApi.util.invalidateTags([
          { type: apiTags.casePresentation, id: e.presentationId },
          apiTags.casePresentationPdfFilesUrl
        ])
      );

      postMessage({ type: PresentationActionType.Refresh });

      NotificationModule.showInfo("", localization.presentationUpdated());
    },
    [dispatch, localization, postMessage]
  );

  useEffect(() => {
    caseHubConnectionManager.on(
      presentationId,
      PresentationHubEventType.PresentationEvent,
      HandlePresentationUpdate
    );

    return () => {
      caseHubConnectionManager.off(
        presentationId,
        PresentationHubEventType.PresentationEvent,
        HandlePresentationUpdate
      );
    };
  }, [HandlePresentationUpdate, caseHubConnectionManager, localization, presentationId]);

  useEffect(() => {
    if (stateToRestore) {
      dispatch(clearPausedPresentationState());
      postMessage({ type: PresentationActionType.Refresh });
    }
  }, [dispatch, postMessage, stateToRestore]);

  const changeZoom = useCallback(
    (area?: Dimensions) => {
      postMessage({ type: PresentationActionType.ZoomChange, area });
    },
    [postMessage]
  );

  const showPointer = useCallback(
    (position?: Position) => {
      postMessage({ type: PresentationActionType.PointerPositionChange, position });
    },
    [postMessage]
  );

  const handlePointerPositionChange = (position?: Position) => {
    if (tool === PresenterTool.Pointer) {
      showPointer(position);
    }
  };

  useEffect(() => {
    switch (tool) {
      case PresenterTool.Highlight:
        dispatch(
          setActiveTool({
            type: MarkingType.Highlight,
            template: createHighlightMarking({ user, isTemporary: true })
          })
        );
        return () => dispatch(resetActiveTool());
      case PresenterTool.Zoom:
        return () => changeZoom(undefined);
      case PresenterTool.Pointer:
        return () => showPointer(undefined);
    }
  }, [dispatch, changeZoom, showPointer, tool, user]);

  if (isLoading || isPagesLoading || isLoadingTempUrl) {
    return <Spinner className="h-100vh" />;
  }

  const pageColumnWidth = 6 + (showMarkings ? 0 : 3) + (showSpeakerNotes ? 0 : 3);

  return (
    <>
      <div className="h-100vh d-flex w-100 m-0 overflow-hidden">
        <MarkingsPageProvider>
          {pdfUrl && pagesData && (
            <>
              {showMarkings && (
                <MarkingsSidebar
                  documentId={presentationId}
                  caseId={pagesData[pageIndex].caseId}
                  additionalClasses="col-3 border-right"
                  pageId={pagesData[pageIndex].id}
                  pageIndex={pageIndex}
                />
              )}
              <div className={classNames(`h-100 d-flex flex-column p-0 col-${pageColumnWidth}`)}>
                {pagesData && !tempPresentationState && (
                  <div className={classNames("m-0 border-bottom padding-s")}>
                    <PresentTopBar
                      markingsOverride={markingsOverride}
                      pageIndex={pageIndex}
                      presentationId={presentationId}
                      title={title}
                      currentPageIndex={pageIndex}
                      pagesData={pagesData}
                      continueOnPage={stateToRestore?.pageId}
                      searchPagePopoverOpen={showSearchPopover}
                      showSpeakerNotesCallback={(x) => setShowSpeakerNotes(x)}
                      showMarkingsCallback={(x) => setShowMarkings(x)}
                      showPageSearchPopoverCallback={() => setShowSearchPopover(true)}
                      setClosePresentationModalMessageOpen={setClosePresentationModalMessageOpen}
                    />
                  </div>
                )}
                <div className="h-100 d-flex flex-column">
                  <div className="flex-1 container-fluid overflow-hidden p-0">
                    <div className="h-100 overflow-hidden d-sm-block flex-shrink-0">
                      {tempPresentationState && pdfUrlTemp ? (
                        <NewMarkingsPageOverlay
                          disableEditing={true}
                          pageIndex={tempPresentationState.pageIndex}
                          documentId={tempPresentationState.documentId}
                          isBigScreen
                          scale={scale}
                        >
                          <SinglePagePdfView
                            setScale={setScale}
                            pdfUrl={pdfUrlTemp}
                            pdfPageIndex={0}
                            pageMargins={10}
                            onPointerPositionChange={handlePointerPositionChange}
                            pageOverlay={(sc) =>
                              tool === PresenterTool.Zoom && (
                                <LiveZoom scale={sc} onZoomSelect={changeZoom} />
                              )
                            }
                          />
                        </NewMarkingsPageOverlay>
                      ) : (
                        <NewMarkingsPageOverlay
                          disableEditing={true}
                          pageIndex={pageIndex}
                          documentId={presentationId}
                          scale={scale}
                        >
                          <SinglePagePdfView
                            pdfUrl={pdfUrl}
                            pdfPageIndex={pageIndex}
                            onDocumentLoadSuccess={handleLoadSuccess}
                            pageMargins={10}
                            orientation={pagesData[pageIndex]?.orientation}
                            onPointerPositionChange={handlePointerPositionChange}
                            setScale={setScale}
                            pageOverlay={(sc) =>
                              tool === PresenterTool.Zoom && (
                                <LiveZoom scale={sc} onZoomSelect={changeZoom} />
                              )
                            }
                          />
                        </NewMarkingsPageOverlay>
                      )}
                      <div className="d-flex align-items-start w-100 gap-s">
                        <ModalMessage
                          open={closePresentationModalMessageOpen}
                          onClose={() => setClosePresentationModalMessageOpen(false)}
                          title={localizer.areYouSure()}
                          description={localizer.areYouSurePresentationClose()}
                          modalAcceptType="danger"
                          acceptButtonText={localizer.close()}
                          acceptAction={() =>
                            closePresentationModalMessageOpen && closePresentation()
                          }
                        />
                      </div>

                      <PresenterToolbox
                        className={styles.toolbox}
                        isShowingTempPage={Boolean(tempPresentationState)}
                        activeTool={tool}
                        onToolChange={setTool}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </MarkingsPageProvider>
        {pagesData && showSpeakerNotes && (
          <div className={classNames(styles.sidebar, "col-3 p-0 h-100 border-left")}>
            <PresentSpeakerNotesSidebar
              presentationId={presentationId}
              pagesData={pagesData}
              currentPageIndex={pageIndex}
            />
          </div>
        )}
      </div>
      {pagesData && (
        <LocatePagePopover
          open={showSearchPopover}
          caseId={pagesData[0].caseId}
          onClosePopoverClicked={() => setShowSearchPopover(false)}
        />
      )}
      <TempPresentationPageOverlay newSearchClicked={() => setShowSearchPopover(true)} />
    </>
  );
};

export default Present;
