import { FieldValues } from "react-hook-form";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import FormDropdown from "@components/forms/FormDropdown";
import { useLocalization } from "@components/localization/localizationProvider";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { MarkingTag } from "@services/api/sharedModels/markingTagModel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MarkingTagsDropdownProps<TFieldValues extends FieldValues> = FormControlProps<
  TFieldValues,
  any
> & {
  id: string;
  name: string;
  markingTags?: MarkingTag[];
  top?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MarkingTagsDropdown = <TMarkingModel extends MarkingModel = any>({
  id,
  methods,
  markingTags,
  name,
  formGroupClassName,
  top
}: MarkingTagsDropdownProps<TMarkingModel>) => {
  const localizer = useLocalization();

  return (
    <FormDropdown
      formGroupClassName={formGroupClassName}
      methods={methods}
      id={id}
      name={name}
      placeholder={localizer.createTags()}
      isMulti
      isClearable={false}
      allowCreate
      colorAsSubjects
      options={markingTags?.map((tag) => ({ label: tag.tagName, value: tag }))}
      getOptionValue={(option) => option.value.tagName}
      valueToOptionTransform={(value: MarkingTag) =>
        value ? { label: value.tagName, value: value } : null
      }
      getNewOptionData={(val: string) => ({ label: val, value: { tagName: val } })}
    />
  );
};

export default MarkingTagsDropdown;
