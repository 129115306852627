import classNames from "classnames";
import { PropsWithChildren } from "react";
import { BadgeSize, Color } from "src/utility/constants";
import styles from "./pillBadge.module.scss";

type PillBadgeProps = {
  smallText?: boolean;
  colorOverride?: "secondary" | "secondary-light";
  size?: BadgeSize;
  title?: string;
  color?: Color;
};

export const PillBadge: React.FC<PropsWithChildren<PillBadgeProps>> = ({
  size = BadgeSize.Small,
  smallText,
  colorOverride,
  title,
  children,
  color = Color.Primary
}) => {
  return (
    <span
      title={title}
      className={classNames(
        "rounded-pill",
        styles["pill-badge"],
        {
          small: smallText
        },
        colorOverride,
        {
          [styles["pill-badge--m"]]: size === BadgeSize.Medium,
          [styles["pill-badge--s"]]: size === BadgeSize.Small,
          [styles["pill-badge--xs"]]: size === BadgeSize.ExtraSmall,
          [styles["pill-badge--xxs"]]: size === BadgeSize.ExtraExtraSmall
        },
        {
          [styles["pill-badge--primary"]]: color === Color.Primary,
          [styles["pill-badge--secondary"]]: color === Color.Secondary,
          [styles["pill-badge--secondary-light"]]: color === Color.SecondaryLight
        }
      )}
    >
      {children}
    </span>
  );
};
