import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export type UpdateCaseExtractDraftModel = {
  title: string;
};

export const updateExtractDraftValidation = (localizer: Localizer) =>
  createValidationSchema<UpdateCaseExtractDraftModel>({
    title: yup.string().required(localizer.titleRequired())
  });
