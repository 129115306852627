import { useDispatch } from "react-redux";
import classNames from "classnames";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { setTotalPages } from "@pages/pdfviewer/component/pdfViewerSlice";
import { IconButton } from "@components/button/IconButton/IconButton";
import SinglePagePdfView from "@pages/pdfviewer/component/SinglePagePdfView/SinglePagePdfView";
import { MarkingsPageProvider } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import styles from "./DocumentModal.module.scss";

type MultipleDocumentsModalProps = {
  documentQuery: any;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  documentQueryParams: { [key: string]: string };
  isFrontPage?: boolean;
};

const DocumentModal = ({
  documentQuery,
  documentQueryParams,
  isOpen,
  onClose,
  title,
  isFrontPage
}: MultipleDocumentsModalProps) => {
  const dispatch = useDispatch();
  const { data } = documentQuery(documentQueryParams);

  return (
    <Modal size={ModalSizeEnum.Large} open={isOpen} onClose={onClose}>
      <div className="d-flex justify-content-between p-4 border-bottom">
        <h3>{title}</h3>
        <IconButton iconName="close" onClick={onClose} />
      </div>
      <div className={classNames(styles.fullSizeScrollable, "card-body background-gray")}>
        <MarkingsPageProvider>
          {data && (
            <SinglePagePdfView
              showMaxSize
              onDocumentLoadSuccess={(doc) => dispatch(setTotalPages(doc.numPages))}
              pdfUrl={isFrontPage ? data : data.downloadUrl}
              pageMargins={10}
            />
          )}
        </MarkingsPageProvider>
      </div>
    </Modal>
  );
};

export default DocumentModal;
