import { Dimensions } from "@components/resizable/resizable";
import { ShowTempPageAction } from "@pages/pdfviewer/component/models/showTempPageAction";
import { TempMarkingAction } from "@pages/pdfviewer/component/models/tempMarkingAction";
import { Position } from "@pages/pdfviewer/component/SinglePagePdfView/SinglePagePdfView";

export const PRESENTATION_BROADCAST_CHANNEL = "presentation_action";

export enum PresentationActionType {
  Close,
  PageChange,
  MarkingsChange,
  Refresh,
  ZoomChange,
  PointerPositionChange,
  TempMarkingChange,
  ShowTempPage,
  StopShowTempPage
}

interface PresentationActionBase {
  type: PresentationActionType;
}

export interface PresentationCloseAction extends PresentationActionBase {
  type: PresentationActionType.Close;
}

export interface PresentationPageChangeAction extends PresentationActionBase {
  type: PresentationActionType.PageChange;
  pageIndex: number;
}
export interface PresentationMarkingsChangeAction extends PresentationActionBase {
  type: PresentationActionType.MarkingsChange;
  pageIndex: number;
  markingIds: string[];
}

export interface PresentationRefreshAction extends PresentationActionBase {
  type: PresentationActionType.Refresh;
}

export interface PresentationZoomChangeAction extends PresentationActionBase {
  type: PresentationActionType.ZoomChange;
  area?: Dimensions;
}
export interface PointerPositionChangeAction extends PresentationActionBase {
  type: PresentationActionType.PointerPositionChange;
  position?: Position;
}

export interface TempMarkingChangeAction extends PresentationActionBase {
  type: PresentationActionType.TempMarkingChange;
  action: TempMarkingAction;
}

export interface ShowTempPageChangeAction extends PresentationActionBase {
  type: PresentationActionType.ShowTempPage;
  action: ShowTempPageAction;
}

export interface StopTempPageChangeAction extends PresentationActionBase {
  type: PresentationActionType.StopShowTempPage;
}

export type PresentationAction =
  | PresentationCloseAction
  | PresentationPageChangeAction
  | PresentationMarkingsChangeAction
  | PresentationRefreshAction
  | PresentationZoomChangeAction
  | PointerPositionChangeAction
  | TempMarkingChangeAction
  | ShowTempPageChangeAction
  | StopTempPageChangeAction;
