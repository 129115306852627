import { Route, Routes } from "react-router-dom";
import { RoutePaths } from "@components/routing/routes";
import Present from "./present";
import PresentBigScreen from "./presentBigScreen";

const PresentRouting = () => (
  <Routes>
    <Route index element={<Present />} />
    <Route path={RoutePaths.presentBigScreen.path} element={<PresentBigScreen />} />
  </Routes>
);

export default PresentRouting;
