import classNames from "classnames";
import { Menu } from "@headlessui/react";
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useState
} from "react";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import ReactDOM from "react-dom";
import { Link, LinkProps } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { EmblaIcon } from "@components/embla/emblaIcon";
import ExpandingDropdownMenuContextProvider, {
  useExpandingDropdownMenuContext
} from "@components/expandingDropdownMenu/expandingDropdownMenuContextProvider";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./expandingDropdownMenu.module.scss";

interface ChildrenProps {
  children: ReactNode;
}

interface ExpandingDropdownMenuProps extends ChildrenProps {
  buttonNode: ReactNode;
  menuDropdownPlacement?: Placement;
  triggeredBy?: "leftclick" | "rightclick";
}

const ExpandingDropdownMenu = ({
  children,
  buttonNode,
  menuDropdownPlacement = "bottom-end",
  triggeredBy = "leftclick"
}: ExpandingDropdownMenuProps) => {
  const [referenceEl, setReferenceEl] = useState<HTMLDivElement | null>();
  const [menuItemsContainer, setMenuItemsContainer] = useState<HTMLDivElement | null>();

  const { styles: popperStyles, attributes } = usePopper(referenceEl, menuItemsContainer, {
    placement: menuDropdownPlacement,
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, 8] }
      }
    ]
  });

  const onclickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const clickIsProgramiticallyActivated = e.pageX === 0 && e.pageY === 0;

      if (triggeredBy === "rightclick") {
        if (e.type === "contextmenu") {
          referenceEl?.click();
          e.preventDefault();
        } else if (!clickIsProgramiticallyActivated) {
          e.preventDefault();
        }
      }
    },
    [triggeredBy, referenceEl]
  );

  return (
    <ExpandingDropdownMenuContextProvider>
      <Menu>
        <Menu.Button
          ref={setReferenceEl}
          as="div"
          role="button"
          onClick={onclickHandler}
          onContextMenu={onclickHandler}
        >
          {buttonNode}
        </Menu.Button>
        {ReactDOM.createPortal(
          <Menu.Items
            ref={setMenuItemsContainer}
            className={classNames(styles.dropdownMenu, "show", "show", "dropdown-menu")}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {children}
          </Menu.Items>,
          document.body
        )}
      </Menu>
    </ExpandingDropdownMenuContextProvider>
  );
};

export interface ButtonItemProps {
  disabledText?: string;
}

const ButtonItem = forwardRef(
  (
    {
      className,
      disabled,
      disabledText,
      onClick,
      ...buttonProps
    }: ButtonHTMLAttributes<HTMLButtonElement> & ButtonItemProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <Tooltip message={disabled && disabledText}>
      <Menu.Item>
        {({ active }) => (
          <button
            ref={ref}
            type="button"
            onClick={(e) => {
              if (disabled) {
                e.stopPropagation();
                e.preventDefault();
                return;
              }

              onClick?.(e);
            }}
            className={classNames(
              "dropdown-item",
              styles.item,
              active && styles.active,
              className,
              disabled && styles.disabled,
              disabled && "disabled"
            )}
            {...buttonProps}
          />
        )}
      </Menu.Item>
    </Tooltip>
  )
);

export interface ExpandingItemProps extends ChildrenProps {
  buttonNode?: ReactNode;
  disabled?: boolean;
  disabledText?: string;
}

const ExpandingItem = ({ buttonNode, children, disabled, disabledText }: ExpandingItemProps) => {
  const [showItems, setShowItems] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState("");
  const [referenceEl, setReferenceEl] = useState<HTMLButtonElement | null>();
  const [menuItemsContainer, setMenuItemsContainer] = useState<HTMLDivElement | null>();

  const { styles: popperStyles, attributes } = usePopper(referenceEl, menuItemsContainer, {
    placement: "right-start"
  });

  const { state, dispatch } = useExpandingDropdownMenuContext();

  const buttonOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!disabled) {
        const newMenuId = uuid();
        setCurrentMenuId(newMenuId);
        dispatch({ currentOpenExpandingMenu: newMenuId });
      }
    },
    [disabled, dispatch]
  );

  useEffect(() => {
    if (state?.currentOpenExpandingMenu !== currentMenuId) {
      setShowItems(false);
    } else {
      setShowItems(true);
    }
  }, [currentMenuId, state]);

  return (
    <div className={classNames(disabled && "disabled")}>
      <ButtonItem
        ref={setReferenceEl}
        className="d-flex"
        onClick={buttonOnClick}
        disabled={disabled}
        disabledText={disabledText}
      >
        {buttonNode}
        <EmblaIcon iconName={"arrow-right"} additionalClasses="ml-auto" />
      </ButtonItem>

      {showItems && (
        <div
          ref={setMenuItemsContainer}
          className={classNames(styles.dropdownMenu, "show", "show", "dropdown-menu")}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const LinkItem = ({
  className,
  ...linkProps
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) => (
  <Menu.Item>
    {({ active }) => (
      <Link
        className={classNames("dropdown-item", styles.item, active && styles.active, className)}
        {...linkProps}
      />
    )}
  </Menu.Item>
);

const ItemDivider = () => <div className="dropdown-divider" />;

const EmptyItem = ({
  children,
  ...props
}: ChildrenProps & React.HTMLAttributes<HTMLDivElement>) => <div {...props}>{children}</div>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(ExpandingDropdownMenu, {
  ItemDivider,
  ButtonItem,
  ExpandingItem,
  LinkItem,
  EmptyItem
});
