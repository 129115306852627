import { NotificationModule } from "ditmer-embla";
import { useEffect } from "react";
import { useAuth } from "@components/auth/authProvider";
import { newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAppDispatch } from "@hooks";
import { UserProfileModel } from "@models/profile/userProfileModel";
import { ChangePasswordModel } from "@services/api/user/models/changePasswordModel";
import userApi, {
  useDeleteProfilePictureMutation,
  useGetProfilePictureUrlQuery,
  useGetProfileQuery,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
  useUpdateProfilePictureMutation
} from "@services/api/user/userApi";
import { apiTags } from "@services/api/baseApi";
import ChangePasswordForm from "./ChangePasswordForm";
import ProfileForm from "./ProfileForm";
import ChangeProfilePicture from "./changeProfilePicture";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    user: { id: userId, employeeId },
    userService
  } = useAuth();
  const { data: profile, isLoading } = useGetProfileQuery();
  const { data: profilePictureUrl, isLoading: profilePictureUrlIsLoading } =
    useGetProfilePictureUrlQuery(userId);

  const [updateProfile] = useUpdateProfileMutation();
  const [updatePassword] = useUpdatePasswordMutation();
  const [updateProfilePicture] = useUpdateProfilePictureMutation();
  const [deleteProfilePicture] = useDeleteProfilePictureMutation();
  const localizer = useLocalization();

  useEffect(() => {
    if (profile) {
      dispatch(newBreadcrumb({ text: localizer.profile(), url: "" }));
      dispatch(setTitle(`${profile.firstName} ${profile.lastName}`));
    }
  }, [dispatch, localizer, profile]);

  const onUpdateProfileSubmit = async (model: UserProfileModel) => {
    const result = await updateProfile(model).unwrap();

    if (result) {
      await userService.silentlyLogin();
      dispatch(userApi.util.invalidateTags([apiTags.user]));

      NotificationModule.showSuccessSmall(localizer.yourProfileWasUpdated());
    }
  };

  const onUpdatePasswordSubmit = async (model: ChangePasswordModel, resetForm: () => void) => {
    const result = await updatePassword(model).unwrap();

    if (result) {
      NotificationModule.showSuccessSmall(localizer.passwordChangedSuccess());
      resetForm();
    }
  };

  const onUpdateProfilePicture = async (croppedFile: File) => {
    const result = await updateProfilePicture({
      id: userId,
      employeeId,
      profilePictureFile: croppedFile
    }).unwrap();

    if (result) {
      NotificationModule.showSuccessSmall(localizer.yourProfilePictureChangedSuccessfully());
    }
  };

  const onDeleteProfilePicture = async (resetForm: () => void) => {
    await deleteProfilePicture({ userId, employeeId }).unwrap();
    NotificationModule.showSuccessSmall(localizer.yourProfilePictureDeletedSuccessfully());
    resetForm();
  };

  if (isLoading || !profile || profilePictureUrlIsLoading) return null;

  return (
    <>
      <ProfileForm profile={profile} onSubmit={onUpdateProfileSubmit} />
      <ChangePasswordForm userId={profile.id} onSubmit={onUpdatePasswordSubmit} />
      <ChangeProfilePicture
        existingProfilePictureUrl={profilePictureUrl}
        onSubmit={onUpdateProfilePicture}
        onDelete={onDeleteProfilePicture}
      />
    </>
  );
};

export default Profile;
