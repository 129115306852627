import { useEffect, useMemo } from "react";
import { CaseRight } from "@infrastructure/CaseRight";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import FormSwitch from "@components/forms/formSwitch";
import { ParticipantAccessRightsModel } from "./participantAccessRightsModel";

type ParticipantAccessRightsFormProps = {
  entityId: string;
  initialAccessRights: CaseRight[];
  onChangeCallback: (model: ParticipantAccessRightsModel) => void;
  addUsersFormFieldIsDisabled?: boolean;
  isReadonly?: boolean;
};

export const ParticipantAccessRightsForm: React.FC<ParticipantAccessRightsFormProps> = ({
  entityId,
  initialAccessRights,
  onChangeCallback,
  addUsersFormFieldIsDisabled = false,
  isReadonly = false
}) => {
  const localizer = useLocalization();

  const formFieldIdPrepend = useMemo(() => {
    return `participant-${entityId}-edit`;
  }, [entityId]);

  const initialValues: ParticipantAccessRightsModel = useMemo(() => {
    return {
      caseAccessRights: initialAccessRights
    };
  }, [initialAccessRights]);

  const methods = useValidatedForm({
    defaultValues: initialValues,
    mode: "onTouched"
  });

  useEffect(() => {
    /// Handler for handling field changes
    const subscription = methods.watch((value, field) => {
      if (!field.name || isReadonly) {
        return;
      }
      // Validate and call onChange
      methods.handleSubmit((model) => {
        onChangeCallback(model);
      })();
    });
    return () => subscription.unsubscribe();
  }, [isReadonly, methods, onChangeCallback]);

  return (
    // Note: Dont use form-tag, because it can be nested
    <div className="participant-access-rights-form">
      <FormSwitch
        methods={methods}
        id={`${formFieldIdPrepend}-right-add-users`}
        label={localizer.caseAccessRightAddUsers()}
        name="caseAccessRights"
        value={CaseRight.AddUsers}
        disabled={isReadonly || addUsersFormFieldIsDisabled}
        overwriteMarginTopBottom="xs"
      />
      <FormSwitch
        methods={methods}
        id={`${formFieldIdPrepend}-right-delete-documents`}
        label={localizer.caseAccessRightDeleteDocuments()}
        name="caseAccessRights"
        value={CaseRight.DeleteDocuments}
        disabled={isReadonly}
        overwriteMarginTopBottom="xs"
      />
      <FormSwitch
        methods={methods}
        id={`${formFieldIdPrepend}-right-edit-delete-all-markings`}
        label={localizer.caseAccessRightEditDeleteAllMarkings()}
        name="caseAccessRights"
        value={CaseRight.EditDeleteAllMarkings}
        disabled={isReadonly}
        overwriteMarginTopBottom="xs"
      />
      <FormSwitch
        methods={methods}
        id={`${formFieldIdPrepend}-right-edit-delete-presentations`}
        label={localizer.caseAccessRightEditDeletePresentations()}
        name="caseAccessRights"
        value={CaseRight.EditDeletePresentations}
        disabled={isReadonly}
        overwriteMarginTopBottom="xs"
      />
    </div>
  );
};
