import { useDrop } from "react-dnd";
import { useState } from "react";
import { dndItemTypes } from "../components/ExtractSections/DndItemTypes";

type Params = {
  type: keyof typeof dndItemTypes;
};

export const useIsDndObjectOver = ({ type }: Params) => {
  const [isOver, setIsOver] = useState(false);

  const [, drop] = useDrop(() => ({
    accept: type,
    hover: () => {
      setIsOver(true);
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setIsOver(false);
      }
      return {
        isOver: monitor.isOver()
      };
    }
  }));

  return { isOver, drop };
};
