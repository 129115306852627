import * as yup from "yup";

import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { ChangeProfilePictureModel } from "./changeProfilePictureModel";

export const changeProfilePictureValidation = (localizer: Localizer) =>
  createValidationSchema<ChangeProfilePictureModel>({
    file: yup.mixed()
  });
