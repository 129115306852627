import { useMemo, useState } from "react";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";

export interface DynamicMarkingsOverride {
  [pageId: string]: string[] | undefined;
}

const useDynamicPresentationPages = (
  presentationId: string,
  initialMarkingsOverride: DynamicMarkingsOverride = {}
) => {
  const { data, isLoading } = useGetPresentationPagesQuery(presentationId);
  const [markingsOverride, setMarkingsOverride] =
    useState<DynamicMarkingsOverride>(initialMarkingsOverride);

  const setPageMarkingsOverride = (page: number, markingIds: string[]) => {
    if (data) {
      setMarkingsOverride({
        ...markingsOverride,
        [data[page].id]: markingIds
      });
    }
  };

  const dataWithOverrides = useMemo(() => {
    if (!data || !markingsOverride) {
      return data;
    }
    return data.map((page) => {
      const markingIds = markingsOverride[page.id];
      return markingIds ? { ...page, markingIds } : page;
    });
  }, [data, markingsOverride]);

  return {
    pagesData: dataWithOverrides,
    isPagesLoading: isLoading,
    markingsOverride,
    setPageMarkingsOverride
  };
};

export default useDynamicPresentationPages;
