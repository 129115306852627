import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { HighlightMarkingModel } from "../../../models/markingModel";
import CardProps from "../shared/cardProps.model";
import MarkingPreview from "../shared/markingPreview";
import MarkingPresentationPreview from "../shared/markingPresentationPreview";
import HighlightEdit from "./highlightEdit";

const HighlightCardContent = ({ activeState, ...props }: CardProps<HighlightMarkingModel>) => {
  return activeState === MarkingActiveStateType.Edit ? (
    <HighlightEdit {...props} />
  ) : props.isPresentationEdit ? (
    <MarkingPresentationPreview
      marking={props.marking}
      toggleMarkingClicked={props.toggleMarkingClicked}
      toggleMarkingState={props.toggleMarkingState ?? false}
      disableCheckbox={props.disableCheckbox}
    />
  ) : (
    <MarkingPreview
      marking={props.marking}
      editMarkingClicked={props.editMarkingClicked}
      onReplyCreateEdit={props.onReplyCreateEdit}
      repliesOpen={activeState === MarkingActiveStateType.Replies}
    />
  );
};

export default HighlightCardContent;
