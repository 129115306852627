import { IMergeFieldModel } from "ditmer-embla";
import Crud from "@components/embla/crud";
import FormInput from "@components/forms/FormInput";
import FormRichTextEditor from "@components/forms/FormRichTextEditor";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { EmailTemplateModel } from "@services/api/emailTemplate/models/emailTemplateModel";
import { emailTemplateUpdateValidation } from "./emailTemplateValidation";
import { EmailTemplatePreview } from "./emailTemplatePreview";

interface Props {
  emailTemplate: EmailTemplateModel;
  onSubmit: (model: EmailTemplateModel) => void;
  subjectMergefields?: IMergeFieldModel[];
  bodyMergefields?: IMergeFieldModel[];
}

export const EmailTemplateForm = ({
  emailTemplate,
  onSubmit,
  subjectMergefields = [],
  bodyMergefields = []
}: Props) => {
  const localizer = useLocalization();
  const methods = useValidatedForm({
    validationSchema: emailTemplateUpdateValidation(localizer),
    defaultValues: emailTemplate
  });

  const watchBody = methods.watch("bodyMarkdown");

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Crud
        body={
          <>
            <FormInput
              methods={methods}
              id="email-template-type"
              label={localizer.type()}
              readOnly={true}
              name="readableType"
            />

            <FormInput
              methods={methods}
              id="email-template-language"
              label={localizer.language()}
              readOnly={true}
              name="readableCulture"
            />

            <FormRichTextEditor
              methods={methods}
              id="email-template-subject"
              name="subject"
              type="single-line"
              label={localizer.subject()}
              mergefields={subjectMergefields}
              mergefieldAllowLinks={false}
              required
            />

            <FormRichTextEditor
              methods={methods}
              id="email-template-body"
              name="bodyMarkdown"
              type="multi-line"
              toolbarItems={[
                "heading",
                "bold",
                "italic",
                "strike",
                "divider",
                "ul",
                "ol",
                "indent",
                "outdent",
                "divider"
              ]}
              label={localizer.bodyMarkdown()}
              mergefields={bodyMergefields}
              mergefieldAllowLinks={true}
              defaultValue="def valus"
              required
            />
          </>
        }
        footer={<FormSubmitButton state={methods.formState}>{localizer.save()}</FormSubmitButton>}
      />
      <EmailTemplatePreview
        titleLabel={localizer.preview()}
        templateType={`${emailTemplate.type}`}
        templateCulture={emailTemplate.culture}
        bodyMarkdown={watchBody}
      />
    </form>
  );
};
