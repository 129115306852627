export const buildQueryString = (obj: object, parentKey: string | null = null): string => {
  const queryString = Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => {
      const encodedKey = parentKey
        ? `${parentKey}.${encodeURIComponent(key)}`
        : encodeURIComponent(key);

      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        return buildQueryString(value, encodedKey);
      } else if (Array.isArray(value)) {
        return value
          .map((item, index) => {
            const arrayKey = `${encodedKey}[${index}]`;
            if (typeof item === "object" && item !== null) {
              return buildQueryString(item, arrayKey);
            } else {
              return `${arrayKey}=${encodeURIComponent(item)}`;
            }
          })
          .join("&");
      } else {
        return `${encodedKey}=${encodeURIComponent(value)}`;
      }
    })
    .join("&");

  return queryString;
};
