import { UserService } from "@services/userService";
import { HubConnectionManager } from "./hubConnectionManager";

export enum MarkingHubEventType {
  MarkingEvent = "MarkingEvent",
  MarkingEventResult = "MarkingEventResult"
}

export class MarkingHubConnectionManager extends HubConnectionManager {
  endpointName: string;
  userService: UserService;
  apiUrl: string;

  constructor(endpointName: string, userService: UserService, apiUrl: string) {
    super();

    this.endpointName = endpointName;
    this.userService = userService;
    this.apiUrl = apiUrl;
  }
}
