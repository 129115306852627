import { useState } from "react";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import { useLocalization } from "@components/localization/localizationProvider";
import { TableFilterRow } from "@components/table/components/TableFilterRow";
import { PaginatedListInput } from "@models/paginatedList/paginatedListInput";
import { CasePaginatedListInput } from "@services/api/case/models/casePaginatedListInput";
import "./caseList.scss";
import { CaseTable } from "./caseTable";

const initialCasePaginatedListInput: CasePaginatedListInput = {
  draw: 0,
  start: 0,
  length: 20,
  search: {
    value: "",
    regex: false
  },
  order: [],
  isActive: true
};

export const CaseList: React.FC = () => {
  const [activeCasesFilterInput, setActiveCasesFilterInput] = useState<CasePaginatedListInput>({
    ...initialCasePaginatedListInput,
    isActive: true
  });
  const [closedCasesFilterInput, setClosedCasesFilterInput] = useState<CasePaginatedListInput>({
    ...initialCasePaginatedListInput,
    isActive: false
  });
  const [showClosedCases, setShowClosedCases] = useState<boolean>(false);
  const localizer = useLocalization();

  const setFilterInputs = (action: React.SetStateAction<CasePaginatedListInput>) => {
    setActiveCasesFilterInput(action);
    setClosedCasesFilterInput(action);
  };

  // Handlers

  const handleSearchChanged = (search: string) => {
    setFilterInputs((prev) => ({ ...prev, search: { value: search, regex: false }, start: 0 }));
  };

  const handleToggleShowClosed = () => {
    setShowClosedCases((prev) => !prev);
  };

  return (
    <>
      <div className="case-list-content-item filters">
        <TableFilterRow
          searchFilterOptions={{
            onSearchChanged: handleSearchChanged,
            searchLabel: localizer.search(),
            searchQuery: activeCasesFilterInput.search.value
          }}
        >
          <CheckboxSwitch
            id="show-closed-cases-switch"
            label={localizer.showCompletedCases()}
            externalChecked={showClosedCases}
            onchangeCallback={handleToggleShowClosed}
          />
        </TableFilterRow>
      </div>

      <div className="case-list-content-item lists">
        <CaseTable
          title={localizer.ongoingCases()}
          casePaginatedListInput={activeCasesFilterInput}
          setPaginatedListInput={
            setActiveCasesFilterInput as unknown as React.Dispatch<
              React.SetStateAction<PaginatedListInput>
            >
          }
        />
        {showClosedCases && (
          <CaseTable
            title={localizer.completedCases()}
            casePaginatedListInput={closedCasesFilterInput}
            setPaginatedListInput={
              setClosedCasesFilterInput as unknown as React.Dispatch<
                React.SetStateAction<PaginatedListInput>
              >
            }
            includeStatusColumn={true}
          />
        )}
      </div>
    </>
  );
};
