import classNames from "classnames";
import { TextareaHTMLAttributes } from "react";
import { FieldValues, get } from "react-hook-form";
import TextArea from "@components/textArea/textArea";
import { AutoSizeTextAreaOptions } from "@components/textArea/useAutosizeTextArea";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

type FormTextAreaProps = {
  useAutoSizeOptions?: AutoSizeTextAreaOptions;
  noFormGroup?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormTextarea = <T extends FieldValues = any>({
  methods: { register, formState, watch },
  label,
  name,
  disabled,
  required,
  className,
  formGroupClassName,
  useAutoSizeOptions,
  noFormGroup,
  ...inputProps
}: FormControlProps<T, TextareaHTMLAttributes<HTMLTextAreaElement> & FormTextAreaProps>) => {
  const error = get(formState.errors, name);
  const fieldValue = watch(name);

  return (
    <div className={classNames(!noFormGroup && "form-group", formGroupClassName)}>
      {label && <FormInputLabel htmlFor={inputProps.id} labelText={label} isRequired={required} />}

      <TextArea
        className={classNames("form-control", className, error && "input-validation-error")}
        {...inputProps}
        {...register(name, { disabled })}
        useAutoSize={!!useAutoSizeOptions}
        autoSizeFieldValueToWatch={fieldValue}
        autoSizeOptions={useAutoSizeOptions}
      />

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormTextarea;
