import classNames from "classnames";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./menuDotBUtton.module.scss";

export type MenuDotButtonProps = {
  defaultBorder?: boolean;
  className?: string;
};

export const MenuDotButton = ({ defaultBorder, className }: MenuDotButtonProps) => {
  return (
    <Button
      iconBtn={true}
      linkBtn={true}
      className={classNames(defaultBorder && styles.border, className)}
    >
      <EmblaIcon iconName="overflow" />
    </Button>
  );
};
