import { MutableRefObject, Ref, RefAttributes } from "react";

/**
 * Helper to get reference to cloned element and at the same time maintain it's original ref.
 * Usage:
 * ```
 *   const localRef = useRef<HtmlElement>();
 *   ...
 *   React.cloneElement(children, {
 *     ...other props...
 *     ref: mergeRefs(children, localRef),
 *   })
 * ```
 */
const mergeRefs =
  <T extends HTMLElement, C extends RefAttributes<T>>(child: C, ...refs: Ref<T>[]) =>
  (instance: T) =>
    [child.ref, ...refs].forEach((ref) => {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = instance;
      }
    });

export default mergeRefs;
