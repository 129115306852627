/* eslint-disable react/jsx-no-useless-fragment */
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "@components/auth/requireAuth";
import { RoutePaths } from "@routes";
import { EmployeesPage } from "@pages/employees/employeesPage";
import { EmployeeCreatePage } from "@pages/employees/createEdit/employeeCreatePage";
import { EmployeeEditPage } from "@pages/employees/createEdit/employeeEditPage";

const EmployeeRouting: React.FC = () => {
  return (
    <Routes>
      <Route index element={<EmployeesPage />} />
      <Route
        path={RoutePaths.employeeCreate.path}
        element={
          <RequireAuth requiredAccessRight={RoutePaths.employeeCreate.accessRight}>
            <EmployeeCreatePage />
          </RequireAuth>
        }
      />
      <Route
        path={RoutePaths.employeeEdit.path}
        element={
          <RequireAuth requiredAccessRight={RoutePaths.employeeEdit.accessRight}>
            <EmployeeEditPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default EmployeeRouting;
