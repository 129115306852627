import { UserService } from "@services/userService";
import ExtractDraftExportHubConnectionManager from "@services/signalRClient/extractDraftExportHubConnectionManager";
import { MarkingHubConnectionManager } from "./MarkingHubConnectionManager";
import PresentationHubConnectionManager from "./presentationHubConnectionManager";
import { IHubConnectionManager } from "./IHubConnectionManager";

export enum HubConnectionType {
  Presentation,
  Markings,
  ExtractDraftExport
}

const presentationHubEndpointName = "presentationHub";
const markingHubEndpointName = "markingsHub";
const extractDraftExportHubEndpointName = "extractDraftExportHub";

export default class HubConnectionFactory {
  static createHub(
    type: HubConnectionType,
    userService: UserService,
    apiUrl: string
  ): IHubConnectionManager {
    switch (type) {
      case HubConnectionType.Presentation:
        return new PresentationHubConnectionManager(
          presentationHubEndpointName,
          userService,
          apiUrl
        );
      case HubConnectionType.Markings:
        return new MarkingHubConnectionManager(markingHubEndpointName, userService, apiUrl);
      case HubConnectionType.ExtractDraftExport:
        return new ExtractDraftExportHubConnectionManager(
          extractDraftExportHubEndpointName,
          userService,
          apiUrl
        );
      default:
        throw new Error("Type has not been implemented");
    }
  }
}
