import classNames from "classnames";
import { Spinner } from "@components/spinner/spinner";
import { TableBodyCenterContent } from "./tableBodyCenterContent";
import styles from "./TableSpinnerRow.module.css";

interface Props extends React.HTMLAttributes<HTMLTableSectionElement> {
  showSpinner?: boolean;
  colSpan: number;
}

const TableBodyWithSpinner = ({
  showSpinner,
  colSpan,
  className,
  children,
  ...tbodyProps
}: Props) => {
  return (
    <tbody className={classNames("relative", className)} {...tbodyProps}>
      {children}
      {showSpinner && (
        <TableBodyCenterContent className={styles.spinnerRow} colSpan={colSpan}>
          <Spinner size="small" className="h-full" />
        </TableBodyCenterContent>
      )}
    </tbody>
  );
};

export default TableBodyWithSpinner;
