import { OptionsOrGroups } from "react-select";

export enum MarkingOwnerFilterOptionTypeEnum {
  All,
  None,
  Owner
}

export type MarkingOwnerFilter = {
  ownerEmployeeId: string;
};

export type MarkingOwnerFilterOption = {
  label: string;
  value: string;
  type: MarkingOwnerFilterOptionTypeEnum;
  filter?: MarkingOwnerFilter;
};

export type MarkingOwnerFilterOptionGroup = {
  label?: string;
  options: MarkingOwnerFilterOption[];
};

const isOptionsGroup = (item: unknown): item is MarkingOwnerFilterOptionGroup => {
  return Array.isArray((item as { options: unknown }).options);
};

export const mapEmployeeToOwnerFilterOption = (employeeId: string, label: string) => ({
  label: label,
  value: `${MarkingOwnerFilterOptionTypeEnum.Owner}-${employeeId}`,
  type: MarkingOwnerFilterOptionTypeEnum.Owner,
  filter: { ownerEmployeeId: employeeId }
});

export const flattenOptionGroupsToOptions = (
  markingOptions: OptionsOrGroups<MarkingOwnerFilterOption, MarkingOwnerFilterOptionGroup>
) => {
  return markingOptions.flatMap((x) => (isOptionsGroup(x) ? x.options : x));
};
