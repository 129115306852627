import classNames from "classnames";
import { MouseEvent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocalization } from "@components/localization/localizationProvider";
import FormattedInput, { ParseError } from "@pages/pdfviewer/component/toolbar/FormattedInput";
import { setGoToIndex } from "@pages/pdfviewer/component/pdfViewerSlice";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./PagesControll.module.scss";

type PagesControlProps = {
  currentPage: number;
  totalPages: number;
};

const PagesControl = ({ currentPage, totalPages }: PagesControlProps) => {
  const localizer = useLocalization();
  const dispatch = useDispatch();

  const onInputClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  const parseInput = (inputValue: string) => {
    const parsed = Number.parseInt(inputValue);
    if (Number.isNaN(parsed)) {
      throw new ParseError();
    }
    return parsed;
  };

  const changePage = (newPage: number) => {
    dispatch(setGoToIndex(newPage - 1));
  };

  const formatDisplayValue = (value: number) =>
    `${localizer.pdfPage()} ${value} ${localizer.of()} ${totalPages}`;

  return (
    <div className={classNames(styles.container, "d-flex flex-nowrap")}>
      <div className="input-group-prepend">
        <Tooltip message={`${localizer.previousPage()}`} placement="bottom">
          <button
            disabled={currentPage === 1}
            type="button"
            className={classNames(styles.transparent, "btn btn-default btn-icon")}
            aria-label="Up"
            onClick={() => changePage(currentPage - 1)}
          >
            <EmblaIcon
              iconName="minus"
              color={currentPage === 1 ? IconColor.Gray : IconColor.Gray70}
            />
          </button>
        </Tooltip>
      </div>
      <FormattedInput
        className={classNames("form-control", styles.input, styles.transparent)}
        disabled={false}
        type="text"
        value={currentPage}
        onChange={changePage}
        onClick={onInputClick}
        formatDisplayValue={formatDisplayValue}
        parseValue={parseInput}
      />
      <div className="input-group-append">
        <Tooltip message={`${localizer.nextPage()}`} placement="bottom">
          <button
            disabled={currentPage === totalPages}
            type="button"
            className={classNames("btn btn-default btn-icon", styles.transparent)}
            aria-label="Down"
            onClick={() => changePage(currentPage + 1)}
          >
            <EmblaIcon
              iconName="plus"
              color={currentPage === totalPages ? IconColor.Gray : IconColor.Gray70}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PagesControl;
