import { createContext, ReactNode, useContext, useMemo } from "react";
import HubConnectionFactory, {
  HubConnectionType
} from "@services/signalRClient/hubConnectionFactory";
import { IHubConnectionManager } from "@services/signalRClient/IHubConnectionManager";
import { UserService } from "@services/userService";
import { Config } from "@config/configManager";

// eslint-disable-next-line @typescript-eslint/naming-convention
const HubConnectionManagerContext = createContext<IHubConnectionManager | null>(null);

export const useHubConnectionManager = () => {
  const context = useContext(HubConnectionManagerContext);
  if (!context) {
    throw new Error("useHubConnectionManager must be used in HubConnectionManagerProvider");
  }
  return context;
};

export const HubConnectionManagerProvider = (props: {
  children: ReactNode;
  hubConnectionType: HubConnectionType;
  userService: UserService;
  config: Config;
}) => {
  const connectionManager = useMemo(
    () =>
      HubConnectionFactory.createHub(
        props.hubConnectionType,
        props.userService,
        props.config.apiUrl
      ),
    [props.config.apiUrl, props.hubConnectionType, props.userService]
  );

  return (
    <HubConnectionManagerContext.Provider value={connectionManager}>
      {props.children}
    </HubConnectionManagerContext.Provider>
  );
};
