import { useState } from "react";
import ReactDOM from "react-dom";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import {
  useCompleteExtractDraftMutation,
  useGetTableOfContentsInfoExtractDraftQuery
} from "@services/api/extractDraft/extractDraftApi";
import { ExportExtractDraftForm } from "@components/Export/exportExtractDraftForm";
import {
  ExportExtractDraftModel,
  ExtractDraftBundleModel
} from "@services/api/case/models/exportExtractDraftModel";
import { useGetCaseParticipantsQuery } from "@services/api/participant/caseParticipantApi";
import { NavigateExtractDraftDocument } from "@pages/extractCompositionPage/components/export/navigateExtractDraftDocument";
import ExtractDraftBundleSplitter from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleSplitter";
import ExtractDraftSplitToBundlesFields from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftSplitToBundlesFields";
import { ExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";

interface ApproveExtractDraftProps {
  caseId: string;
  extractId: string;
  submitPortal: Element;
  headerPortal: Element;
  tableOfContentsSettings: ExtractDraftTableOfContentsModel;
}

const CompleteExtractDraft = ({
  caseId,
  extractId,
  submitPortal,
  headerPortal,
  tableOfContentsSettings
}: ApproveExtractDraftProps) => {
  const localizer = useLocalization();

  const [completeExtractDraftId, setCompleteExtractDraftId] = useState<string | undefined>(
    undefined
  );

  const { data: participants, isLoading: participantsIsLoading } = useGetCaseParticipantsQuery({
    caseId
  });
  const [completeExtractDraft, completeExtractDraftRequest] = useCompleteExtractDraftMutation();

  const [splitToBundles, setSplitToBundles] = useState(true);
  const [splitToBundlesPages, setSplitToBundlesPages] = useState<number | undefined>(undefined);
  const [bundles, setBundles] = useState<ExtractDraftBundleModel[]>([]);

  const [showBundleSplitter, setShowBundleSplitter] = useState<boolean>(false);
  const { data: tocData } = useGetTableOfContentsInfoExtractDraftQuery({
    caseId,
    extractDraftId: extractId,
    bundles: []
  });

  const formFieldIdPrepend = "approve-extractDraft";
  const initialValues: ExportExtractDraftModel = {
    markingOwners: [],
    markingTypes: [
      MarkingType.Comment,
      MarkingType.Highlight,
      MarkingType.VerticalLines,
      MarkingType.FocusBox
    ],
    convertLinesToBlack: true,
    extractBundles: undefined
  };

  const methods = useValidatedForm({
    defaultValues: initialValues
  });

  const onSubmitExportExtractDraft = async (model: ExportExtractDraftModel) => {
    if (bundles.length > 0) {
      model.extractBundles = bundles;
    }

    const result = await completeExtractDraft({
      caseId,
      extractDraftId: extractId,
      model
    }).unwrap();
    if (result) {
      setCompleteExtractDraftId(result);
    }
  };

  return (
    <div className="padding-top-l">
      {completeExtractDraftId ? (
        <NavigateExtractDraftDocument
          extractId={extractId}
          extractExportId={completeExtractDraftId}
          caseId={caseId}
        />
      ) : (
        <>
          {showBundleSplitter && tocData && splitToBundlesPages ? (
            <ExtractDraftBundleSplitter
              initialBundlePageCount={splitToBundlesPages}
              bundleChangeCallback={setBundles}
              caseId={caseId}
              extractId={extractId}
            />
          ) : (
            <>
              <div className="subtle">
                <p>{localizer.approveExtractDraftHint1()}</p>
                <p>{localizer.approveExtractDraftHint2()}</p>
                <p>{localizer.approveExtractDraftHint3()}</p>
              </div>

              <ExportExtractDraftForm
                methods={methods}
                fieldIdPrepend={formFieldIdPrepend}
                participants={participants}
              />
              {tocData && (
                <ExtractDraftSplitToBundlesFields
                  tableOfContentsSettings={tableOfContentsSettings}
                  tocData={tocData}
                  splitToBundlesPageCountCallback={(x) => setSplitToBundlesPages(x)}
                  splitToBundlesCallback={(x) => setSplitToBundles(x)}
                />
              )}
            </>
          )}
        </>
      )}
      {!completeExtractDraftId &&
        ReactDOM.createPortal(
          splitToBundles && !showBundleSplitter ? (
            <FormSubmitButton
              state={{
                isSubmitting: completeExtractDraftRequest.isLoading,
                isSubmitSuccessful: completeExtractDraftRequest.isSuccess
              }}
              isPrimary={true}
              onClick={() => setShowBundleSplitter(true)}
              disabled={participantsIsLoading || !splitToBundlesPages}
            >
              {localizer.next()}
            </FormSubmitButton>
          ) : (
            <FormSubmitButton
              state={{
                isSubmitting: completeExtractDraftRequest.isLoading,
                isSubmitSuccessful: completeExtractDraftRequest.isSuccess
              }}
              isPrimary={true}
              onClick={methods.handleSubmit(onSubmitExportExtractDraft)}
              disabled={participantsIsLoading}
            >
              {localizer.approveExtractDraft()}
            </FormSubmitButton>
          ),
          submitPortal
        )}

      {ReactDOM.createPortal(localizer.approveExtractDraft(), headerPortal)}
    </div>
  );
};

export default CompleteExtractDraft;
