import classNames from "classnames";
import illustrationSpriteSvg from "@content/illustrations/sprite.symbol.svg";
import styles from "./emblaIllustration.module.scss";

export enum IllustrationColor {
  Blue
}

type EmblaIllustrationProps = {
  illustrationName: string;
  color?: IllustrationColor;
  additionalClasses?: string;
};

export const EmblaIllustration = ({
  illustrationName,
  color,
  additionalClasses
}: EmblaIllustrationProps) => {
  const renderEmblaIllustration = () => {
    if (illustrationName) {
      return (
        <span>
          <svg
            className={classNames(
              "embla-illustration",
              {
                [styles.colorBlue]: color === IllustrationColor.Blue
              },
              additionalClasses
            )}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href={illustrationSpriteSvg + "#" + illustrationName} />
          </svg>
        </span>
      );
    }
    return;
  };

  return <>{renderEmblaIllustration()}</>;
};
