import { NotificationModule } from "ditmer-embla";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { addBreadcrumb, newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@components/routing/routes";
import { useAppDispatch } from "@hooks";
import {
  useGetEmailTemplateQuery,
  useGetMergefieldsQuery,
  useUpdateEmailTemplateMutation
} from "@services/api/emailTemplate/emailTemplateApi";
import { EmailTemplateModel } from "@services/api/emailTemplate/models/emailTemplateModel";
import { EmailTemplateForm } from "./emailTemplateForm";

export const EmailTemplateEditPage: React.FC = () => {
  const { type, culture } = useParams();
  if (!type || !culture) {
    throw new Error("Type and Culture must be provided!");
  }
  const { data: emailTemplate, isLoading } = useGetEmailTemplateQuery({ type, culture });
  const { data: rawMergefields } = useGetMergefieldsQuery({ type, culture });
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
  const dispatch = useAppDispatch();
  const localizer = useLocalization();

  const mergeFields = useMemo(
    () => rawMergefields?.map((x) => ({ text: x.description, value: x.field })),
    [rawMergefields]
  );

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.list(), url: RoutePaths.emailTemplates.url }));
    dispatch(addBreadcrumb({ text: localizer.edit(), url: "" }));
    dispatch(setTitle({ title: localizer.emailTemplate(), withBackButton: true }));
  }, [dispatch, localizer]);

  const onSubmit = async (model: EmailTemplateModel) => {
    const result = await updateEmailTemplate(model).unwrap();

    if (result) {
      NotificationModule.showSuccessSmall(localizer.theEmailTemplateWasUpdated());
    }
  };

  if (isLoading || !emailTemplate) return null;

  return (
    <EmailTemplateForm
      onSubmit={onSubmit}
      emailTemplate={emailTemplate}
      subjectMergefields={mergeFields}
      bodyMergefields={mergeFields}
    />
  );
};
