import { ReactNode } from "react";
import { FieldArrayWithId } from "react-hook-form/dist/types/fieldArray";
import { UseFormReturn } from "react-hook-form/dist/types/form";
import { EmblaIcon } from "@components/embla/emblaIcon";
import FormInput from "@components/forms/FormInput";
import { useLocalization } from "@components/localization/localizationProvider";
import { PresentationCard } from "../presentationCard/presentationCard";
import { CasePresentationModel } from "../casePresentationModel";
import styles from "./AddPresentationsForm.module.css";

type AddPresentationsFormProps<TFormModel extends { presentations: CasePresentationModel[] }> = {
  methods: UseFormReturn<TFormModel & any, any>;
  presentations: FieldArrayWithId<TFormModel>[];
  renderFormActions?: ReactNode;
  onAddPresentation: () => void;
  onDeletePresentation: (index: number) => void;
  onSubmitCallback?: () => void;
  hideTitle?: boolean;
  presentationStartSortOrder?: number;
};

export const AddPresentationsForm = <
  TFormModel extends { presentations: CasePresentationModel[] }
>({
  methods,
  presentations,
  renderFormActions,
  onAddPresentation,
  onDeletePresentation,
  onSubmitCallback,
  hideTitle = false,
  presentationStartSortOrder = 0
}: AddPresentationsFormProps<TFormModel>) => {
  const localizer = useLocalization();

  return (
    <form className={styles.AddPresentationsForm} onSubmit={onSubmitCallback}>
      {!hideTitle && <h3 className={styles.formHeader}>{localizer.presentations()}</h3>}
      <div className={styles.formDescription}>
        <p className="small subtle">{localizer.addPresentationHint1()}</p>
        <p className="small subtle">{localizer.addPresentationHint2()}</p>
      </div>
      <div className={styles.formContent}>
        <div className="presentation-cards-container">
          {presentations.map((field, i) => (
            <PresentationCard
              key={field.id}
              index={i}
              startSortOrder={presentationStartSortOrder}
              deleteCallback={() => onDeletePresentation(i)}
            >
              <FormInput
                methods={methods}
                id={`form-presentation-${i}`}
                placeholder={localizer.presentationTitlePlaceholder()}
                name={`presentations.${i}.title`}
              />
            </PresentationCard>
          ))}
          <button type="button" className="btn btn-link" onClick={onAddPresentation}>
            <EmblaIcon iconName="plus" additionalClasses="icon-left" />
            {localizer.addPresentation()}
          </button>
        </div>
      </div>
      {renderFormActions}
    </form>
  );
};
