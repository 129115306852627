import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { FieldValues, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

interface FormRadioProps<T extends FieldValues>
  extends FormControlProps<T, InputHTMLAttributes<HTMLInputElement>> {
  externalOnChange?: (value: unknown) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormRadio = <T extends FieldValues = any>({
  methods: { control },
  label,
  name,
  disabled,
  value: checkboxValue,
  formGroupClassName,
  children,
  externalOnChange,
  ...inputProps
}: FormRadioProps<T>) => {
  // use controller instead of uncontrolled inputs to work with number types
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({ name, control });

  const checked = field.value === checkboxValue;
  const handleChange = () => {
    field.onChange(checkboxValue);
    externalOnChange?.(checkboxValue);
  };

  return (
    <div className={classNames("form-group", formGroupClassName)}>
      <input
        type="radio"
        {...field}
        // register ref only for selected radio, so setFocus would focus it and not the last radio
        ref={checked || !field.value ? ref : undefined}
        {...inputProps}
        checked={checked}
        onChange={handleChange}
      />

      {label && <FormInputLabel htmlFor={inputProps.id} labelText={label} />}
      {children /* for custom element */}

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormRadio;
