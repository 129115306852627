import { useDispatch } from "react-redux";
import { NotificationModule } from "ditmer-embla";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import { useLocalization } from "@components/localization/localizationProvider";
import { ReactComponent as DocumentVerticalLinesAddIcon } from "@content/icons/document-vertical-lines-add.svg";
import { useAddVerticalLinePagesToPresentationMutation } from "@services/api/casePresentation/casePresentationApi";
import { bulkAddStart } from "@pages/pdfviewer/component/pdfViewerSlice";
import styles from "./PresentationDropdownMenu.module.scss";

type PresentationDropdownMenuProps = {
  presentationId: string;
  caseId: string;
  redirectAppUrl: string | undefined;
  documentId?: string;
};

const PresentationDropdownMenu = ({
  presentationId,
  caseId,
  redirectAppUrl,
  documentId
}: PresentationDropdownMenuProps) => {
  const localizer = useLocalization();
  const dispatch = useDispatch();
  const [addVerticalLinePagesToPresentations] = useAddVerticalLinePagesToPresentationMutation();

  const handleAddAllPagesWithVerticalLines = async () => {
    if (documentId != null) {
      await addVerticalLinePagesToPresentations({
        caseId: caseId,
        model: {
          documentId: documentId,
          presentationId: presentationId
        }
      }).unwrap();

      NotificationModule.showSuccessSmall(localizer.pagesAddedToPresentations());
    }
  };

  const handleStartAddPagesToPresentation = () => {
    dispatch(bulkAddStart({ isPresentationsSelectable: false }));
  };

  return (
    <ExpandingDropdownMenu
      menuDropdownPlacement="top"
      buttonNode={
        <EmblaIcon additionalClasses="ml-2" color={IconColor.DarkGray} iconName="overflow" />
      }
    >
      <ExpandingDropdownMenu.ButtonItem onClick={handleStartAddPagesToPresentation}>
        <EmblaIcon iconName="document-add" /> {localizer.selectPagesToAdd()}
      </ExpandingDropdownMenu.ButtonItem>
      <ExpandingDropdownMenu.ButtonItem onClick={handleAddAllPagesWithVerticalLines}>
        <DocumentVerticalLinesAddIcon className={styles.verticalLinesLogo} />{" "}
        {localizer.addAllPagesWithVerticalLines()}{" "}
      </ExpandingDropdownMenu.ButtonItem>
    </ExpandingDropdownMenu>
  );
};

export default PresentationDropdownMenu;
