import { useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { useLocalization } from "@components/localization/localizationProvider";
import { UploadDocuments } from "@pages/case/documents/addDocuments/uploadDocuments/uploadDocuments";

export interface AddDocumentsModalProps {
  caseId: string;
  modalSize?: ModalSizeEnum;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onFinish: () => void;
}

export const AddDocumentsModal = ({
  caseId,
  isOpen,
  setIsOpen,
  modalSize,
  onFinish
}: AddDocumentsModalProps) => {
  const localizer = useLocalization();
  const [isInProgressOfUploading, setIsInProgressOfUploading] = useState(false);
  const [modalActionsContainer, setModalActionsContainer] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (isInProgressOfUploading === false) {
      onFinish();
    }
  }, [isInProgressOfUploading]);

  return (
    <AddButtonSlideInForm
      requireButton={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      locked={isInProgressOfUploading}
      size={ModalSizeEnum.Large}
      onCloseEffect={() => {
        setIsOpen(false);
      }}
    >
      <Modal.Header>{localizer.addDocuments()}</Modal.Header>
      <Modal.Body>
        <DndProvider backend={HTML5Backend}>
          <UploadDocuments
            caseId={caseId}
            setIsInProgressOfUploadingFiles={setIsInProgressOfUploading}
            actionsContainer={modalActionsContainer}
          />
        </DndProvider>
      </Modal.Body>
      <div ref={setModalActionsContainer} />
    </AddButtonSlideInForm>
  );
};
