import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export type TitlePageModel = {
  header?: string;
  contentText?: string;
};

export const TitlePageCreateEditValidation = (localizer: Localizer) =>
  createValidationSchema<TitlePageModel>({
    contentText: yup.string().notRequired(),
    header: yup.string().notRequired().max(255, localizer.maxLength255())
  });
