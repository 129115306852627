import { useCallback, useEffect, useRef, useState } from "react";

const useBroadcastChannel = <T>({
  name,
  onMessage
}: {
  name: string;
  onMessage?: (e: MessageEvent<T>) => void;
}) => {
  const callbackRef = useRef(onMessage);
  callbackRef.current = onMessage;

  const [channel, setChannel] = useState<BroadcastChannel>();

  useEffect(() => {
    const newChannel = new BroadcastChannel(name);
    newChannel.onmessage = (e) => callbackRef.current?.(e);
    setChannel(newChannel);
    return () => newChannel?.close();
  }, [name]);

  return {
    postMessage: useCallback((msg: T) => channel?.postMessage(msg), [channel])
  };
};

export default useBroadcastChannel;
