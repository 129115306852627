import { FieldValues } from "react-hook-form";
import classNames from "classnames";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import Button from "@components/embla/button";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import Badge from "@components/embla/badge";
import { MarkingTag } from "@services/api/sharedModels/markingTagModel";
import MarkingTagsDropdown from "../MarkingTagsDropdown/MarkingTagsDropdown";
import styles from "./MarkingTagsDrropdownModal.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MarkingTagsDropdownProps<TFieldValues extends FieldValues> = FormControlProps<
  TFieldValues,
  any
> & {
  isOpen: boolean;
  onOpen: () => void;
  markingTags?: MarkingTag[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MarkingTagsDropdownModal = <TMarkingModel extends MarkingModel = any>({
  methods,
  id,
  name,
  onOpen,
  isOpen,
  markingTags
}: MarkingTagsDropdownProps<TMarkingModel>) => {
  const tags = methods.getValues(name) as [];
  return (
    <>
      <Button theme="default" borderless className={classNames("pl-0 pr-1 pt-2")} onClick={onOpen}>
        <EmblaIcon iconName="tag" color={IconColor.DarkGray} />
        {tags.length > 0 && (
          <Badge style={{ position: "absolute", right: "0px" }} appearance="primary" pill>
            {tags.length}
          </Badge>
        )}
      </Button>
      {isOpen && (
        <MarkingTagsDropdown
          methods={methods}
          id={id}
          name={name}
          markingTags={markingTags}
          formGroupClassName={styles.dropdown}
        />
      )}
    </>
  );
};

export default MarkingTagsDropdownModal;
