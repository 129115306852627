import useElectronApi from "./useElectronApi";

const useIsOnline = () => {
  const electronApi = useElectronApi();

  const checkOnlineStatus = async (): Promise<boolean> => {
    try {
      if (!navigator.onLine) return false;

      if (electronApi) {
        const request = new URL("https://google.com");
        const response = await fetch(request.toString(), {
          method: "HEAD",
          mode: "no-cors",
          cache: "no-store"
        });
        return response.status < 400;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return checkOnlineStatus;
};

export default useIsOnline;
