import { forwardRef, HTMLAttributes, Ref, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Key } from "@infrastructure/Key";
import {
  ActiveMarkingInfo,
  MarkingActiveStateType
} from "@pages/pdfviewer/component/models/activeMarkingInfo";
import useForwardedRef from "src/hooks/useForwardedRef";
import useShortcut from "src/hooks/useShortcut";
import { MarkingModel, ReplyModel } from "../../../models/markingModel";
import { MarkingType } from "../../../models/markingType";
import {
  removeActiveMarking,
  setActiveMarking,
  setHoveredMarkingId
} from "../../../pdfViewerSlice";
import useMarkingMutation from "../../../hooks/useMarkingMutation";
import useSafeMarkingClose from "../hooks/useSafeMarkingClose";
import { useHandleMarkingDelete } from "../utils/markingUtils";
import MarkingCard from "./markingCard";

interface CanvasMarkingCardProps extends HTMLAttributes<HTMLDivElement> {
  marking: MarkingModel<false>;
  editMarkingClicked: () => void;
  toggleMarkingState: boolean;
  activeInfo?: Omit<ActiveMarkingInfo, "marking">;
}

export const createMarkingCardId = (marking: MarkingModel<false>) => `marking-card-${marking.id}`;

const CanvasMarkingCard = forwardRef(
  (
    {
      marking,
      activeInfo,
      editMarkingClicked,
      toggleMarkingState,
      ...cardProps
    }: CanvasMarkingCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const dispatch = useDispatch();
    const cardRef = useForwardedRef(ref);
    const { createMarking, editMarking, createReply, editReply } = useMarkingMutation();
    const { handleMarkingDelete } = useHandleMarkingDelete(marking);

    const saveMarking = useCallback(
      async (data: MarkingModel<false>, close = true) => {
        if (close) {
          dispatch(removeActiveMarking());
          dispatch(setHoveredMarkingId(undefined));
        }
        if (data.isNew) {
          await createMarking(data);
        } else {
          await editMarking(data);
        }
      },
      [dispatch, createMarking, editMarking]
    );

    const { closeCard } = useSafeMarkingClose();

    useShortcut({ key: Key.Escape, allowOnInputs: true, callback: closeCard });

    useEffect(() => {
      activeInfo &&
        cardRef.current?.scrollIntoView({
          block: "nearest",
          inline: "nearest",
          behavior: "smooth"
        });
    }, [activeInfo, cardRef]);

    const handleChange = useCallback(
      (data: MarkingModel<false>) => {
        dispatch(
          setActiveMarking({
            activeState: MarkingActiveStateType.Edit,
            marking: data
          })
        );
        saveMarking(data, false);
      },
      [dispatch, saveMarking]
    );

    const handleReplyCreateEdit = useCallback(
      async (data: ReplyModel) => {
        if (marking.replies?.some((rep) => rep.id === data.data.id)) {
          await editReply(data);
        } else {
          await createReply(data);
        }
      },
      [marking.replies, editReply, createReply]
    );

    if (marking.isNew && marking.type !== MarkingType.Comment) {
      // don't render card for new automatically saved new markings
      return null;
    }

    return (
      <MarkingCard
        id={createMarkingCardId(marking)}
        ref={cardRef}
        marking={marking}
        activeState={activeInfo?.activeState}
        onMarkingChange={handleChange}
        onReplyCreateEdit={handleReplyCreateEdit}
        onSave={saveMarking}
        onDelete={handleMarkingDelete}
        onClose={closeCard}
        editMarkingClicked={editMarkingClicked}
        toggleMarkingState={toggleMarkingState}
        {...cardProps}
      />
    );
  }
);

export default CanvasMarkingCard;
