import { useEffect, useState } from "react";

/**
 * Retains "truthy" state for minimum amount of time before setting it back to undefined.
 * I.e. boolean saving indicator for minimum 2 seconds for user to see even if request finishes sooner:
 * ```
 * const retainedIsSaving = useRetainedState(isSaving, 2000);
 * ```
 */
const useRetainedState = <T>(toRetain: T, timeMs: number) => {
  const [retained, setRetained] = useState<T>();
  useEffect(() => {
    if (toRetain) {
      setRetained(toRetain);
      setTimeout(() => {
        // clear only if state has not changed in which case latter timeout should handle it
        setRetained((s) => (s === toRetain ? undefined : s));
      }, timeMs);
    }
  }, [toRetain, timeMs]);

  return toRetain || retained;
};

export default useRetainedState;
