import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { nameof } from "ts-simple-nameof";
import { AddButton } from "@components/button/addButton/addButton";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import Card from "@components/embla/card";
import { newBreadcrumb, removeActions, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@components/routing/routes";
import PaginatedTable from "@components/table/paginatedTable";
import { useAppDispatch } from "@hooks";
import { useGetEmployeesQuery } from "@services/api/employee/employeeApi";
import { EmployeePaginatedListInput } from "@services/api/employee/models/employeePaginatedListInput";
import { EmployeePaginatedListModel } from "@services/api/employee/models/employeePaginatedListModel";

const intialEmployeePaginatedListInput: EmployeePaginatedListInput = {
  showInactive: false,
  draw: 0,
  start: 0,
  length: 10,
  search: {
    value: "",
    regex: false
  },
  order: []
};

export const EmployeesPage = () => {
  const localizer = useLocalization();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getEmployeesFilterInput, setGetEmployeesFilterInput] =
    useState<EmployeePaginatedListInput>(intialEmployeePaginatedListInput);
  const { data: employees, isLoading, isFetching } = useGetEmployeesQuery(getEmployeesFilterInput);

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.list(), url: "" }));
    dispatch(setTitle(localizer.userAdministration()));

    return () => {
      dispatch(removeActions());
    };
  }, [dispatch, localizer]);

  const getColumns = useMemo(() => {
    const columns: Column<EmployeePaginatedListModel>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.name(),
        accessor: "name"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.initials(),
        accessor: "initials"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.email(),
        accessor: "email"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.phone(),
        accessor: "phone"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.administrator(),
        accessor: "isAdministratorDisplayString",
        id: nameof<EmployeePaginatedListModel>((e) => e.isAdministrator) // define which column to sort on
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.canLogIn(),
        accessor: "isActiveDisplayString",
        id: nameof<EmployeePaginatedListModel>((e) => e.isActive) // define which column to sort on
      }
    ];
    return columns;
  }, [localizer]);

  const handleToggleShowInactive = () => {
    setGetEmployeesFilterInput((prev) => ({ ...prev, showInactive: !prev.showInactive, start: 0 }));
  };

  const extraFiltersHtml = (
    <CheckboxSwitch
      id={nameof<typeof getEmployeesFilterInput>((input) => input.showInactive)}
      label={localizer.showInactive()}
      onchangeCallback={handleToggleShowInactive}
      externalChecked={getEmployeesFilterInput.showInactive}
    />
  );

  return (
    <>
      <Card>
        <PaginatedTable
          columns={getColumns}
          data={employees?.data ?? []}
          paginatedListInput={getEmployeesFilterInput}
          setPaginatedListInput={setGetEmployeesFilterInput}
          isLoading={isLoading}
          isFetching={isFetching}
          noResultsOptions={{
            noResultsMessage: localizer.noResultsFound()
          }}
          totalRecords={employees?.recordsFiltered ?? 0}
          rowOnClick={(employee) => {
            navigate(RoutePaths.employeeEdit.url(employee.id));
          }}
          extraFiltersHtml={extraFiltersHtml}
          pageSizeOptions={[5, 10, 25]}
        />
      </Card>
      <AddButton onClick={() => navigate(RoutePaths.employeeCreate.url)} />
    </>
  );
};
