import classNames from "classnames";
import { ReactNode, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { MultiValue, OnChangeValue, SingleValue } from "react-select";
import { Dropdown, DropdownOption, DropdownProps } from "@components/dropdown/dropdown";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

type FromDropdownProps<
  Value,
  Option extends DropdownOption<Value>,
  IsCreatable extends boolean = false
> = {
  valueToOptionTransform?: (value: Value) => DropdownOption<Value> | null;
  onChangeCallback?: (oldValue: Value, newValue: Value | undefined) => void; // TODO: Make work with multiValue
} & DropdownProps<Value, Option, IsCreatable, boolean>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormDropdown = <
  Value,
  Option extends DropdownOption<Value>,
  IsCreatable extends boolean = false,
  TFieldValues extends FieldValues = any
>({
  methods: { control },
  label,
  name,
  valueToOptionTransform,
  onChangeCallback,
  required,
  formGroupClassName,
  ...dropdownProps
}: FormControlProps<TFieldValues, FromDropdownProps<Value, Option, IsCreatable>>) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control });
  const [inputValue, setInputValue] = useState("");

  const getNewOptionData =
    "getNewOptionData" in dropdownProps
      ? (dropdownProps as DropdownProps<Value, Option, true, boolean>).getNewOptionData
      : (inputV: string, optionLabel: ReactNode) => ({
          label: optionLabel,
          value: inputV
        });

  const value = Array.isArray(field.value)
    ? field.value.map((v: Value) =>
        valueToOptionTransform ? valueToOptionTransform(v) : { value: v, label: `${v}` }
      )
    : valueToOptionTransform
      ? valueToOptionTransform(field.value)
      : field.value;

  const onBlur = () => {
    if (inputValue && dropdownProps.allowCreate) {
      const val = getNewOptionData?.(inputValue, null);
      if (!dropdownProps.isMulti) {
        onChange(val as unknown as SingleValue<Option>);
      } else {
        const tempMultValues = [...value, val];
        onChange(tempMultValues as unknown as MultiValue<Option>);
      }
    }
    field.onBlur();
  };

  const onChange = (val: OnChangeValue<Option, boolean>) => {
    if (isMulti(val)) {
      field.onChange(val.map((v) => v.value));
    } else {
      if (onChangeCallback) {
        onChangeCallback(field.value, val?.value);
      }
      field.onChange(val?.value);
    }
  };

  return (
    <div className={classNames("form-group", formGroupClassName)}>
      {label && (
        <FormInputLabel htmlFor={dropdownProps.id} labelText={label} isRequired={required} />
      )}

      <Dropdown
        onInputChange={setInputValue}
        {...dropdownProps}
        className={`${error ? "input-validation-error" : ""}`}
        ref={field.ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const isMulti = <Option,>(
  value: MultiValue<Option> | SingleValue<Option>
): value is MultiValue<Option> => {
  return Array.isArray(value);
};

export default FormDropdown;
