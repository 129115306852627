import { NotificationModule } from "ditmer-embla";
import FormInput from "@components/forms/FormInput";
import FormTextarea from "@components/forms/FormTextarea";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useEditPresentationTitlePageMutation } from "@services/api/casePresentation/casePresentationApi";
import useAutosave from "src/hooks/useAutosave";
import { TitlePageCreateEditValidation, TitlePageModel } from "./titlePageModel";

type EditPresentationTitlePageProps = {
  caseId: string;
  presentationId: string;
  presentationPageId: string;
  titlePageHeader?: string;
  titlePageContentText?: string;
};

const EditPresentationTitlePage = ({
  caseId,
  presentationId,
  presentationPageId,
  titlePageHeader,
  titlePageContentText
}: EditPresentationTitlePageProps) => {
  const localizer = useLocalization();
  const [editTitlePage, editRequest] = useEditPresentationTitlePageMutation();

  const methods = useValidatedForm<TitlePageModel>({
    validationSchema: TitlePageCreateEditValidation(localizer),
    defaultValues: {
      header: titlePageHeader,
      contentText: titlePageContentText
    }
  });

  const { getFieldId } = useAutosave({
    queryStatus: editRequest.status,
    fieldIdPrefix: "presentation-title-page",
    watch: methods.watch,
    onSubmit: methods.handleSubmit((model) => {
      editTitlePage({ caseId, presentationId, presentationPageId, model })
        .unwrap()
        .then(() =>
          NotificationModule.showSuccessSmall(localizer.thePresentationTitlePageWasUpdated())
        );
    })
  });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <FormInput
        methods={methods}
        id={getFieldId("header")}
        name="header"
        label={localizer.header()}
      />
      <FormTextarea
        methods={methods}
        id={getFieldId("contentText")}
        name="contentText"
        label={localizer.contentText()}
        useAutoSizeOptions={{
          minRows: 2,
          maxRows: 10
        }}
      />
    </form>
  );
};

export default EditPresentationTitlePage;
