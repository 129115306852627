import classNames from "classnames";
import { forwardRef, HTMLAttributes, PointerEvent, Ref, useEffect, useRef, useState } from "react";
import styles from "./resizable.module.scss";
import ResizeHandles from "./resizeHandles";

export interface DimensionsInput {
  x: number;
  y: number;
  height?: number;
  width?: number;
}

export type Dimensions = Required<DimensionsInput>;

export interface ResizableProps extends HTMLAttributes<HTMLDivElement> {
  dimensions: DimensionsInput;
  active: boolean;
  notClickable?: boolean;
  resizableHeight?: boolean;
  resizableWidth?: boolean;
  onResize: (data: Dimensions) => void;
}

export enum ResizeDirection {
  North,
  NorthEast,
  East,
  SouthEast,
  South,
  SouthWest,
  West,
  NorthWest
}

/**
 * Resizable component, can be tailored to allow height or width resizing.
 *
 * If one of the dimensions is undefined while initializing the components - it will be threated as resize is in progress and start listening for mouse events.
 *
 * Mouse events are listened on absolute positioned element with inset 0, so make sure parent component has some position set.
 * This adds resize boundary to not resize element over the page and avoids side effects, so all listeners are handled by react.
 */
const Resizable = forwardRef(
  (
    {
      dimensions,
      active,
      notClickable,
      resizableHeight,
      resizableWidth,
      onResize,
      className,
      style,
      children,
      ...divProps
    }: ResizableProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [resizeDirection, setResizeDirection] = useState(() => {
      // find out initial resize direction
      if (!active) {
        return undefined;
      } else if (dimensions.height === undefined && dimensions.width === undefined) {
        return ResizeDirection.SouthEast;
      } else if (dimensions.height === undefined) {
        return ResizeDirection.South;
      } else if (dimensions.width === undefined) {
        return ResizeDirection.East;
      }
    });
    const [localDimensions, setLocalDimensions] = useState<Dimensions>({
      x: 0,
      y: 0,
      height: 0,
      width: 0
    });
    const boundaryElRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setLocalDimensions({
        height: dimensions.height ?? 0,
        width: dimensions.width ?? 0,
        x: dimensions.x,
        y: dimensions.y
      });
    }, [dimensions.height, dimensions.width, dimensions.x, dimensions.y]);

    const handlePointerMove = (e: PointerEvent) => {
      if (!boundaryElRef.current || resizeDirection === undefined) {
        return;
      }
      const relativeMouseY = e.clientY - boundaryElRef.current.getBoundingClientRect().top;
      const relativeMouseX = e.clientX - boundaryElRef.current.getBoundingClientRect().left;
      if (
        [ResizeDirection.North, ResizeDirection.NorthEast, ResizeDirection.NorthWest].includes(
          resizeDirection
        )
      ) {
        const y = relativeMouseY;
        const delta = localDimensions.y - y;
        const height = localDimensions.height + delta;
        if (height > 0) {
          setLocalDimensions((d) => ({ ...d, y, height }));
        } else {
          setLocalDimensions((d) => ({ ...d, y: d.y + d.height, height: 0 }));
          setResizeDirection((current) => {
            switch (current) {
              case ResizeDirection.NorthEast:
                return ResizeDirection.SouthEast;
              case ResizeDirection.NorthWest:
                return ResizeDirection.SouthWest;
              default:
                return ResizeDirection.South;
            }
          });
        }
      }
      if (
        [ResizeDirection.South, ResizeDirection.SouthEast, ResizeDirection.SouthWest].includes(
          resizeDirection
        )
      ) {
        const height = relativeMouseY - localDimensions.y;
        if (height > 0) {
          setLocalDimensions((d) => ({ ...d, height }));
        } else {
          setLocalDimensions((d) => ({ ...d, height: 0 }));
          setResizeDirection((current) => {
            switch (current) {
              case ResizeDirection.SouthEast:
                return ResizeDirection.NorthEast;
              case ResizeDirection.SouthWest:
                return ResizeDirection.NorthWest;
              default:
                return ResizeDirection.North;
            }
          });
        }
      }
      if (
        [ResizeDirection.West, ResizeDirection.SouthWest, ResizeDirection.NorthWest].includes(
          resizeDirection
        )
      ) {
        const x = relativeMouseX;
        const delta = localDimensions.x - x;
        const width = localDimensions.width + delta;
        if (width > 0) {
          setLocalDimensions((d) => ({ ...d, x, width }));
        } else {
          setLocalDimensions((d) => ({ ...d, x: d.x + d.width, width: 0 }));
          setResizeDirection((current) => {
            switch (current) {
              case ResizeDirection.SouthWest:
                return ResizeDirection.SouthEast;
              case ResizeDirection.NorthWest:
                return ResizeDirection.NorthEast;
              default:
                return ResizeDirection.East;
            }
          });
        }
      }
      if (
        [ResizeDirection.East, ResizeDirection.SouthEast, ResizeDirection.NorthEast].includes(
          resizeDirection
        )
      ) {
        const width = relativeMouseX - localDimensions.x;
        if (width > 0) {
          setLocalDimensions((d) => ({ ...d, width }));
        } else {
          setLocalDimensions((d) => ({ ...d, width: 0 }));
          setResizeDirection((current) => {
            switch (current) {
              case ResizeDirection.SouthEast:
                return ResizeDirection.SouthWest;
              case ResizeDirection.NorthEast:
                return ResizeDirection.NorthWest;
              default:
                return ResizeDirection.West;
            }
          });
        }
      }
    };

    const stopResizing = () => {
      setResizeDirection(undefined);
      onResize(localDimensions);
    };

    return (
      <>
        {resizeDirection !== undefined && (
          <div
            ref={boundaryElRef}
            className={classNames(styles.resizeBoundary, {
              [styles.resizingWidth]: [ResizeDirection.East, ResizeDirection.West].includes(
                resizeDirection
              ),
              [styles.resizingHeight]: [ResizeDirection.North, ResizeDirection.South].includes(
                resizeDirection
              ),
              [styles.resizingNesw]: [
                ResizeDirection.NorthEast,
                ResizeDirection.SouthWest
              ].includes(resizeDirection),
              [styles.resizingNwse]: [
                ResizeDirection.NorthWest,
                ResizeDirection.SouthEast
              ].includes(resizeDirection)
            })}
            onPointerMove={handlePointerMove}
            onPointerUp={stopResizing}
            onPointerLeave={stopResizing}
          />
        )}
        <div
          ref={ref}
          className={classNames(
            styles.resizable,
            notClickable && styles.notClickable,
            active && styles.active,
            className
          )}
          style={{
            ...style,
            top: localDimensions.y,
            left: localDimensions.x,
            width: localDimensions.width,
            height: localDimensions.height
          }}
          {...divProps}
        >
          <ResizeHandles
            active={active}
            resizableHeight={resizableHeight}
            resizableWidth={resizableWidth}
            onDragStart={setResizeDirection}
          />
          {children}
        </div>
      </>
    );
  }
);

export default Resizable;
