import { useEffect, useState } from "react";
import { usePresentfileExistsMutation } from "@services/api/presentation/presentationApi";
import { CasePresentationEventModel } from "@pages/pdfviewer/component/models/casePresentationEventModel";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { IHubConnectionManager } from "@services/signalRClient/IHubConnectionManager";
import { PresentationHubEventType } from "@services/signalRClient/presentationHubConnectionManager";
import useHubConnection from "./useHubConnection";
import useElectronApi from "./useElectronApi";

export interface UsePresentProps {
  presentationId: string;
  directToApp?: string;
}

const usePresent = ({ presentationId, directToApp }: UsePresentProps) => {
  const [isWating, setIsWating] = useState(false);
  const [hubConnectionManager] = useState<IHubConnectionManager>(
    useHubConnection(HubConnectionType.Presentation)
  );
  const [presentFileExists] = usePresentfileExistsMutation();
  const electronApi = useElectronApi();

  useEffect(() => {
    return () => {
      if (presentationId && hubConnectionManager) {
        setIsWating(false);
        hubConnectionManager.stopConnection(presentationId);
      }
    };
  }, [hubConnectionManager, presentationId]);

  const startPresenting = () => {
    setIsWating(false);
    if (electronApi) {
      electronApi?.present(presentationId);
    } else if (directToApp) {
      window.location.href = directToApp;
    }
  };

  return {
    startPresentation: () => {
      const request = presentFileExists(presentationId);
      setIsWating(true);
      request.then((data) => {
        const fileExist = JSON.parse(JSON.stringify(data)).data.fileExist;
        const fileOutdated = JSON.parse(JSON.stringify(data)).data.fileOutdated;

        if (fileExist && !fileOutdated) {
          startPresenting();
        } else {
          setIsWating(true);
          hubConnectionManager.startConnection(presentationId);
          hubConnectionManager.on(
            presentationId,
            PresentationHubEventType.PresentationEvent,
            (e: CasePresentationEventModel) => {
              hubConnectionManager.stopConnection(presentationId);
              startPresenting();
            }
          );
        }
      });
    },
    isWating: isWating
  };
};
export default usePresent;
