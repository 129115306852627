import { PaginatedListSortOrderInput } from "./paginatedListSortOrderInput";

export const OrderArraysAreEqual = (
  a: PaginatedListSortOrderInput[],
  b: PaginatedListSortOrderInput[]
) => {
  return (
    a.length === b.length &&
    a.every((val, index) => val.columnName === b[index].columnName && val.dir === b[index].dir)
  );
};
