import { useEffect } from "react";
import { useAuth } from "@components/auth/authProvider";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import { newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { useAppDispatch } from "@hooks";
import { AccessRight } from "@infrastructure/AccessRight";
import { CaseList } from "./caseList/caseList";
import { CreateCase } from "./createCase/createCase";

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const localizer = useLocalization();
  const { user } = useAuth();
  const showCreateCaseBtn = user.hasAccessRight(AccessRight.CreateCase);

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.dashboard(), url: "" }));
    dispatch(setTitle(localizer.cases()));
  }, [dispatch, localizer]);

  return (
    <>
      <CaseList />

      {showCreateCaseBtn && (
        <AddButtonSlideInForm size={ModalSizeEnum.ExtraLarge}>
          <Modal.Header>{localizer.createCase()}</Modal.Header>
          <Modal.Body className="p-0">
            <CreateCase />
          </Modal.Body>
        </AddButtonSlideInForm>
      )}
    </>
  );
};
