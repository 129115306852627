import { useNavigate } from "react-router-dom";
import { NotificationModule } from "ditmer-embla";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CasePresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import { DragNDrop } from "@components/dnd/dragNDrop";
import { useLocalization } from "@components/localization/localizationProvider";
import useCaseAccess from "@pages/case/hooks/useCaseAccess";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import { useRemovePresentationPageMutation } from "@services/api/casePresentation/casePresentationApi";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import ThumbnailContainer from "@pages/pdfviewer/component/thumbnail/thumbnailContainer";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { RoutePaths } from "@components/routing/routes";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { setGoToIndex } from "@pages/pdfviewer/component/pdfViewerSlice";
import ModalMessage from "@components/modal/modalMessage";
import { useDropPage } from "../hooks/useDropPage";
import styles from "./PageContainer.module.scss";

type PagesContainerProps = {
  page: CasePresentationPageModel;
  caseId: string;
  activePresentation?: PresentationInfo;
  setPages: React.Dispatch<React.SetStateAction<CasePresentationPageModel[]>>;
  presentationMode?: boolean;
  activePageId?: string | { index: number; caseDocumentId?: string };
  onPageRemoved?: (pageId: string) => void;
  index: number;
  reorder: (droppedIndex: number) => void;
  pageTitle?: string;
  setIsLoadingNewPage: (isLoading: boolean) => void;
  onPageChanged?: (pageIndex: number) => void;
  documentId?: string;
};
const PageContainer = ({
  page,
  caseId,
  activePresentation,
  setPages,
  presentationMode,
  activePageId,
  onPageRemoved,
  reorder,
  index,
  pageTitle,
  setIsLoadingNewPage,
  onPageChanged,
  documentId
}: PagesContainerProps) => {
  const [isConfirmNavigateModalOpen, setIsConfirmNavigateModalOpen] = useState(false);

  const navigate = useNavigate();
  const localizer = useLocalization();
  const dispatch = useDispatch();

  const [removePresentationPage] = useRemovePresentationPageMutation();

  const { canEditDeletePresentations } = useCaseAccess(caseId);

  const dummyPageIdPrefix = "dummy";
  const dummyPageId = `${dummyPageIdPrefix} ${index}`;

  const { drop, isLoading } = useDropPage({
    caseId,
    index,
    page,
    setPages,
    activePresentation,
    dummyPageId,
    dummyPageIdPrefix
  });

  const reorderPreview = (draggedItemIndex: number, hoverIndex: number) => {
    setPages((prev) => {
      const pagesToReorder = [...prev];
      pagesToReorder.move(draggedItemIndex, hoverIndex, pagesToReorder[draggedItemIndex]);
      return pagesToReorder;
    });
  };

  useEffect(() => {
    setIsLoadingNewPage(isLoading);
  }, [isLoading, setIsLoadingNewPage]);

  const isPageActive =
    (typeof activePageId === "string"
      ? activePageId === page.id
      : activePageId?.index === page.pageIndex &&
        activePageId.caseDocumentId === page.caseDocumentId) || page.id === dummyPageId;

  useEffect(() => {
    if (isPageActive) {
      onPageChanged?.(index);
    }
  }, [index, isPageActive, onPageChanged]);

  return (
    <div ref={drop}>
      <DragNDrop
        index={index}
        dndType="presentationPage"
        onDropRow={(droppedIndex) => reorder(droppedIndex)}
        onPreviewDropRow={(draggedItemIndex, hoverIndex) =>
          reorderPreview(draggedItemIndex, hoverIndex)
        }
        disableDrag={!canEditDeletePresentations()}
      >
        <div className="d-flex flex-column align-items-center">
          <ExpandingDropdownMenu
            triggeredBy={"rightclick"}
            menuDropdownPlacement={"right"}
            buttonNode={
              <div
                onClick={() => {
                  if (!activePresentation) {
                    return;
                  }
                  if (presentationMode) {
                    navigate(
                      RoutePaths.casePresentationEdit.url(caseId, activePresentation.id, page.id) +
                        "?back=doc",
                      { replace: presentationMode }
                    );
                  } else {
                    if (documentId === page.caseDocumentId) {
                      dispatch(setGoToIndex(page.pageIndex));
                    } else {
                      setIsConfirmNavigateModalOpen(true);
                    }
                  }
                }}
              >
                <ThumbnailContainer
                  thumbnailOrientation={page.orientation}
                  srcUrl={page.thumbnailUrl}
                  fileName={page.fileName}
                  title={page.isTitlePage ? pageTitle : page.fileName}
                  overlayIcons={page.overlayIconTypes}
                  active={isPageActive}
                />
              </div>
            }
          >
            <ExpandingDropdownMenu.LinkItem
              to={
                presentationMode
                  ? RoutePaths.caseDocumentPage.url(
                      caseId,
                      page.caseDocumentId,
                      page.pageIndex + 1
                    ) + "?back=-1"
                  : RoutePaths.casePresentationEdit.url(
                      caseId,
                      activePresentation?.id ?? "",
                      page.id
                    ) + "?back=doc"
              }
              key={
                presentationMode
                  ? localizer.goToPageInDocument()
                  : localizer.goToPageInPresentation()
              }
              state={{ navigateToPageIndex: page.pageIndex + 1 }}
            >
              <IconTextCallbackItem
                icon={<EmblaIcon iconName="forward" />}
                text={
                  presentationMode
                    ? localizer.goToPageInDocument()
                    : localizer.goToPageInPresentation()
                }
              />
            </ExpandingDropdownMenu.LinkItem>
            <ExpandingDropdownMenu.ButtonItem
              disabled={!canEditDeletePresentations()}
              disabledText={localizer.presentationEditDeleteNotAllowedDescription()}
              key={localizer.removeFromPresentation()}
              onClick={() =>
                removePresentationPage({
                  caseId: caseId,
                  presentationPageId: page.id,
                  presentationId: activePresentation?.id ?? ""
                }).then(() => {
                  NotificationModule.showSuccessSmall(localizer.pageGotRemoved());
                  onPageRemoved?.(page.id);
                })
              }
            >
              <IconTextCallbackItem
                icon={<EmblaIcon iconName="delete" />}
                text={localizer.removeFromPresentation()}
              />
            </ExpandingDropdownMenu.ButtonItem>
          </ExpandingDropdownMenu>

          <p
            className={classNames("small margin-top-xs margin-bottom-0", styles.pageTitle)}
            title={pageTitle}
          >
            {page.isTitlePage ? localizer.presentationTitlePage() : pageTitle}
          </p>
        </div>
      </DragNDrop>

      <ModalMessage
        onClose={() => setIsConfirmNavigateModalOpen(false)}
        open={isConfirmNavigateModalOpen}
        description={localizer.navigateToDifferentDocumentDescription()}
        title={localizer.navigateToDifferentDocumentTitle()}
        acceptAction={() =>
          navigate(
            RoutePaths.caseDocumentPage.url(caseId, page.caseDocumentId, page.pageIndex + 1) +
              "?back=-1"
          )
        }
      />
    </div>
  );
};

export default PageContainer;
