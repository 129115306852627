import { MarkingModel } from "./markingModel";

export type ActiveMarkingInfo = {
  marking: MarkingModel<false>;
  activeState: MarkingActiveStateType;
  // keep card form data separate for comment marking that is not autosaved
  unsaved?: Partial<MarkingModel<false>>;
};

export enum MarkingActiveStateType {
  Replies = 1,
  Edit = 2
}
