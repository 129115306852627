import { CreateMentionRequestModel } from "@services/api/mention/models/mentionModel";
import baseApi, { apiTags } from "../baseApi";

const getEmailTemplatesUrl = "api/mention";

const mentionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createMentions: builder.mutation<string, CreateMentionRequestModel[]>({
      query: (model) => ({
        url: getEmailTemplatesUrl,
        method: "POST",
        body: model
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: apiTags.mention,
                id: result
              }
            ]
          : []
    })
  })
});

export default mentionApi;
export const { useCreateMentionsMutation } = mentionApi;
export const { resetApiState } = mentionApi.util;
