import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import useLocalStorage from "src/hooks/useLocalStorage";
import { pdfPageMargin } from "../page/canvas/canvasService";

const defaultWidth = 600;

// eslint-disable-next-line @typescript-eslint/naming-convention
const PdfSizeProviderContext = createContext<{
  width: number;
  setWidth: (deltaWidth: number) => void;
}>({ width: defaultWidth, setWidth: () => {} });

export const PdfSizeProvider = (props: { children: ReactNode; pdfWidth: number }) => {
  const [desiredWidth, setDesiredWidth] = useLocalStorage("pdf-width", defaultWidth, 500);
  const [width, setWidth] = useState(desiredWidth);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sidebarSize = 150;
  const margins = 50;
  const minCanvasSize = 400;
  const otherGaps = 120;

  const maxWidth = useMemo(() => {
    const calculatedMaxWidth =
      windowInnerWidth - sidebarSize - props.pdfWidth - pdfPageMargin * 2 - margins * 2 + otherGaps;
    return Math.max(calculatedMaxWidth, minCanvasSize);
  }, [props.pdfWidth, windowInnerWidth]);

  useEffect(() => {
    if (width > maxWidth) {
      setWidth(maxWidth);
    } else if (width < minCanvasSize) {
      setWidth(minCanvasSize);
    } else if (width < desiredWidth && desiredWidth < maxWidth) {
      setWidth(desiredWidth);
    }
  }, [maxWidth, minCanvasSize, windowInnerWidth, desiredWidth, width]);

  const handleChangeWidth = (deltaWidth: number) => {
    if (width + deltaWidth < maxWidth && width + deltaWidth > minCanvasSize) {
      setWidth((prev) => prev + deltaWidth);
      setDesiredWidth((prev) => prev + deltaWidth);
    }
  };

  return (
    <PdfSizeProviderContext.Provider value={{ width: width, setWidth: handleChangeWidth }}>
      {props.children}
    </PdfSizeProviderContext.Provider>
  );
};

export const usePdfSize = () => {
  return useContext(PdfSizeProviderContext);
};
