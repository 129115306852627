import { useCallback, useState } from "react";
import { MentionTypeEnum } from "@components/mention/mentionModel";
import { useCreateMentionsMutation } from "@services/api/mention/mentionApi";
import { CreateMentionRequestModel } from "@services/api/mention/models/mentionModel";

const useMentionsHandler = (mentionType: MentionTypeEnum) => {
  const [createMention] = useCreateMentionsMutation();
  const [mentionedUserState, setMentionedUserState] = useState<string[]>([]);

  const addMention = useCallback((userId: string) => {
    setMentionedUserState((x) => [...x, userId]);
  }, []);

  const commitMentions = useCallback(
    async (referenceId: string) => {
      if (mentionedUserState.length > 0) {
        const mentions = mentionedUserState.map(
          (muId) =>
            ({
              mentionedUserId: muId,
              mentionType: mentionType,
              referenceId: referenceId
            }) as CreateMentionRequestModel
        );

        setMentionedUserState([]);

        await createMention(mentions).unwrap();
      }
    },
    [mentionedUserState, createMention, mentionType]
  );

  return {
    addMention: addMention,
    commitMentions: commitMentions,
    mentionState: mentionedUserState
  };
};

export default useMentionsHandler;
