import { FetchBaseQueryError, FetchBaseQueryMeta, skipToken } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { applyPatches, enablePatches, produceWithPatches } from "immer";
import { NotificationModule } from "ditmer-embla";
import { RootState } from "@app";
import baseApi, { apiTags } from "@services/api/baseApi";
import { UserModel } from "@services/api/sharedModels/userModel";
import { ThunkExtras } from "@store";
import { MarkingHubEventType } from "@services/signalRClient/MarkingHubConnectionManager";

import { updateEventHistory } from "@pages/pdfviewer/component/utils/updateEventHistory";
import { useAuth } from "@components/auth/authProvider";
import {
  CreateMarkingEvent,
  DeleteMarkingEvent,
  EditMarkingEvent,
  MarkingEvent,
  MarkingEventType,
  DocumentMarkingEventResultModel
} from "./models/markingEvent";
import { MarkingModel } from "./models/markingModel";
import { PdfType, removeEventsFromLogWithMarkingId, updateEventLog } from "./pdfViewerSlice";
import { UndoRedoToolType } from "./models/pdfTool";

export interface MarkingsStateModel {
  [pageIndex: number]: MarkingModel<false>[];
}

interface GetMarkingsArgs {
  origin: PdfType;
  // DocumentId or presentationId
  pdfTypeId: string;
}

const markingEventsUrl = "/api/document/marking";
const getMarkingsUrl = (args: GetMarkingsArgs) =>
  `/api/${args.origin}/${args.pdfTypeId}/marking/list`;

export const markingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarkings: builder.query<
      MarkingsStateModel,
      { markingsArgs: GetMarkingsArgs; userId: string }
    >({
      query: (args) => getMarkingsUrl(args.markingsArgs),
      transformResponse: (resp: MarkingModel<false>[]) =>
        resp.reduce<MarkingsStateModel>((state, marking) => {
          const newMarking = {
            ...marking
          };

          if (newMarking.presentationPageList) {
            newMarking.presentationList = [];
            for (const presentationPage of newMarking.presentationPageList) {
              if (
                !newMarking.presentationList.some(
                  (m) => m.presentationId === presentationPage.presentationId
                )
              )
                newMarking.presentationList.push({
                  presentationId: presentationPage.presentationId,
                  presentationName: presentationPage.presentationName
                });
            }
          }

          state[marking.page] = [...(state[newMarking.page] ?? []), newMarking];
          return state;
        }, {}),
      providesTags: (state, error, { markingsArgs: { origin } }) => [
        ...Object.values(state ?? {})
          .flat()
          .map(({ id }) => ({ type: apiTags.markings, id })),
        { type: apiTags.markings, id: origin }
      ],
      onCacheEntryAdded: async (
        args,
        { cacheDataLoaded, cacheEntryRemoved, getState, dispatch, extra }
      ) => {
        if (args.markingsArgs.origin === PdfType.CaseDocument) {
          const { markingsHubConnectionManager } = extra as ThunkExtras;
          try {
            // wait for the initial query to resolve before proceeding
            await cacheDataLoaded;
            await markingsHubConnectionManager.startConnection(args.markingsArgs.pdfTypeId);

            const userId = args.userId;

            markingsHubConnectionManager.on(
              args.markingsArgs.pdfTypeId,
              MarkingHubEventType.MarkingEvent,
              (e: MarkingEvent) => {
                if (
                  e.eventType === MarkingEventType.Edit ||
                  e.eventType === MarkingEventType.Delete
                ) {
                  const eventAsEditOrDeleteEvent: EditMarkingEvent | DeleteMarkingEvent = e;
                  dispatch(removeEventsFromLogWithMarkingId(eventAsEditOrDeleteEvent.markingRefId));
                }
                return dispatch(updateMarkingQueryData(e, userId));
              }
            );
          } catch {
            //  "cacheDataLoaded" will throw in case "cacheEntryRemoved" resolves first
          }
          await cacheEntryRemoved;
          // cleanup after the cache subscription is no longer active
          await markingsHubConnectionManager.stopConnection(args.markingsArgs.pdfTypeId);
        }
      }
    }),
    postMarkingEvent: builder.mutation<
      void,
      { event: MarkingEvent; isUndoRedo?: UndoRedoToolType; userId: string }
    >({
      queryFn: (arg, { extra }, _, baseQuery) => {
        const { markingsHubConnectionManager } = extra as ThunkExtras;

        return baseQuery({
          url: markingEventsUrl,
          method: "POST",
          body: [arg.event],
          headers: markingsHubConnectionManager.getSignalRHeader(arg.event.documentId)
        }) as MaybePromise<QueryReturnValue<void, FetchBaseQueryError, FetchBaseQueryMeta>>;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState, extra }) => {
        const { markingsHubConnectionManager, localizer } = extra as ThunkExtras;
        const state = getState() as RootState;

        // the following feels pretty icky, but we can't use 'useQueryState' within this context.
        const cacheKey = `getMarkings(${JSON.stringify({ markingsArgs: { origin: PdfType.CaseDocument, pdfTypeId: arg.event.documentId }, userId: arg.userId })})`;

        const currentMarkingsDataCasted: MarkingsStateModel = state.api.queries[cacheKey]
          ?.data as MarkingsStateModel;

        enablePatches();

        const [nextStateUpdatedEventHistory, inversePatches] = produceWithPatches(
          state.pdfViewer,
          (draft) => {
            updateEventHistory(draft, currentMarkingsDataCasted, arg.event, arg.isUndoRedo);
          }
        );

        dispatch(updateEventLog(nextStateUpdatedEventHistory.sessionEventHistory));

        const { undo } = dispatch(updateMarkingQueryData(arg.event, arg.userId));

        const markingEventResultHandler = (e: DocumentMarkingEventResultModel) => {
          if (e.id === arg.event.id) {
            if (!e.success) {
              undo();
              NotificationModule.showErrorSmall(localizer.errorDocumentMarkingUpdate());
            }
            markingsHubConnectionManager.off(
              arg.event.documentId,
              MarkingHubEventType.MarkingEventResult,
              markingEventResultHandler
            );
          }
        };

        markingsHubConnectionManager.on(
          arg.event.documentId,
          MarkingHubEventType.MarkingEventResult,
          markingEventResultHandler
        );

        try {
          await queryFulfilled;
        } catch (e) {
          undo();
          const undoState = applyPatches(state.pdfViewer, inversePatches);
          dispatch(updateEventLog(undoState.sessionEventHistory));
          throw e;
        }
      },
      invalidatesTags: (result, error) =>
        !error
          ? [
              apiTags.markingTag,
              apiTags.casePresentationPage,
              apiTags.caseDocument,
              { type: apiTags.markings, id: PdfType.Presentation }
            ]
          : []
    })
  })
});

export const usePostMarkingEventMutation = (documentId: string) => {
  // use fixedCacheKey to share progress state across components (i.e. show document save status)
  return markingsApi.usePostMarkingEventMutation({
    fixedCacheKey: `CachePostMarkingEventForDocument:${documentId}`
  });
};

export const useGetMarkingsQuery = (markingArgs: GetMarkingsArgs | typeof skipToken) => {
  const { user } = useAuth();

  return markingsApi.useGetMarkingsQuery(
    markingArgs === skipToken ? skipToken : { markingsArgs: markingArgs, userId: user.id }
  );
};

const updateMarkingQueryData = (event: MarkingEvent, userId: string) => {
  return markingsApi.util.updateQueryData(
    "getMarkings",
    { markingsArgs: { pdfTypeId: event.documentId, origin: PdfType.CaseDocument }, userId: userId },
    (draft) => {
      switch (event.eventType) {
        case MarkingEventType.Create: {
          addMarkingToCache(event, event.id, event.eventCreator, event.eventCreationDate, draft);
          break;
        }
        case MarkingEventType.Edit: {
          const replaceIndex = draft[event.marking.page].findIndex(
            ({ id }) => id === event.markingRefId
          );
          const markingNotAccessible =
            event.marking.isPrivate && event.marking.owner.userId !== userId;

          if (replaceIndex === -1) {
            if (!markingNotAccessible) {
              addMarkingToCache(
                event,
                event.markingRefId,
                event.marking.owner,
                event.marking.creationDate,
                draft
              );
            }
            break;
          }

          if (markingNotAccessible) {
            deleteMarkingFromCache(event, draft);
          } else {
            const originalDraft = draft[event.marking.page][replaceIndex];
            draft[event.marking.page][replaceIndex] = {
              ...event.marking,
              // fill data that does not come from notify event
              id: event.markingRefId,
              owner: originalDraft.owner,
              creationDate: originalDraft.creationDate
            };
          }

          break;
        }
        case MarkingEventType.Delete: {
          deleteMarkingFromCache(event, draft);
          break;
        }
        case MarkingEventType.CreateReply: {
          const replaceIndex = draft[event.marking.page].findIndex(
            ({ id }) => id === event.markingRefId
          );

          const originalDraft = draft[event.marking.page][replaceIndex];
          const newReply = {
            id: event.marking.data.id,
            content: event.marking.data.content,
            creationDate: new Date().toISOString(),
            owner: event.eventCreator,
            hasBeenEdited: false
          };
          draft[event.marking.page][replaceIndex] = {
            ...originalDraft,
            replies: originalDraft.replies?.concat(newReply) ?? [newReply]
          };

          break;
        }
        case MarkingEventType.EditReply: {
          const replaceIndex = draft[event.marking.page].findIndex(
            ({ id }) => id === event.markingRefId
          );

          const originalDraft = draft[event.marking.page][replaceIndex];

          draft[event.marking.page][replaceIndex] = {
            ...originalDraft,
            replies: originalDraft.replies?.map((rep) => {
              if (rep.id === event.marking.data.id) {
                rep.content = event.marking.data.content;
                rep.hasBeenEdited = true;
                return rep;
              } else {
                return rep;
              }
            })
          };

          break;
        }
      }
    }
  );
};

const deleteMarkingFromCache = (
  event: DeleteMarkingEvent | EditMarkingEvent,
  draft: MaybeDrafted<MarkingsStateModel>
) => {
  for (const pageMarkings of Object.values(draft)) {
    const removeIndex = pageMarkings.findIndex((m: MarkingModel) => m.id === event.markingRefId);
    if (removeIndex !== -1) {
      pageMarkings.splice(removeIndex, 1);
      break;
    }
  }
};

const addMarkingToCache = (
  event: CreateMarkingEvent | EditMarkingEvent,
  markingId: string,
  owner: UserModel,
  creationDate: string,
  draft: MaybeDrafted<MarkingsStateModel>
) => {
  const newMarking: MarkingModel = {
    ...event.marking,
    isNew: false,
    id: markingId,
    owner: owner,
    creationDate: creationDate
  };
  draft[event.marking.page] = [...(draft[event.marking.page] ?? []), newMarking];
};
