import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { NotificationModule } from "ditmer-embla";
import FormDropdown from "@components/forms/FormDropdown";
import { useModalContext } from "@components/modal/modal";
import FormInput from "@components/forms/FormInput";
import FormTextarea from "@components/forms/FormTextarea";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useCreateCaseMutation } from "@services/api/case/caseApi";
import { CaseCreateModel } from "@services/api/case/models/caseModel";
import { AddPresentationsForm } from "@components/case/presentation/addPresentation/AddPresentationsForm";
import { SelectParticipantsForm } from "@components/case/participant/selectParticipantsForm";
import { WizardPanel, WizardStep } from "@components/embla/wizard/wizardPanel";
import { createCaseValidation } from "./createCaseValidation";
import styles from "./createCase.module.scss";

export enum CaseCreationStep {
  Summary = 1,
  Presentations = 2,
  Participants = 3
}

export const CreateCase = () => {
  const localizer = useLocalization();
  const [createCase, createCaseRequest] = useCreateCaseMutation();
  const { close } = useModalContext();

  const [currentStep, setCurrentStep] = useState(CaseCreationStep.Summary);

  const initialValues: CaseCreateModel = {
    id: "",
    client: null,
    description: "",
    title: "",
    courtCaseNumber: "",
    internalCaseNumber: "",
    counterparts: [],
    selectedParticipants: [],
    presentations: [
      { title: localizer.presentation() },
      { title: localizer.witnessExamination() },
      { title: localizer.closingArguments() }
    ]
  };

  const methods = useValidatedForm({
    validationSchema: createCaseValidation(localizer),
    defaultValues: initialValues
  });

  const presentationsArray = useFieldArray({
    control: methods.control,
    name: "presentations"
  });

  const renderSummaryStep = () => {
    return (
      <form>
        <div className={styles.formContent}>
          <div className={styles.formDescription}>
            <p className="small subtle">{localizer.createCaseHint1()}</p>
            <p className="small subtle">{localizer.createCaseHint2()}</p>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <FormInput
                methods={methods}
                id="case-form-title"
                label={localizer.title()}
                placeholder={localizer.titlePlaceholder()}
                required
                name="title"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <FormInput
                methods={methods}
                id="case-form-case-number"
                label={localizer.courtCaseNumber()}
                placeholder={localizer.courtCaseNumberPlaceholder()}
                name="courtCaseNumber"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <FormInput
                methods={methods}
                id="case-form-internal-case-number"
                label={localizer.internalCaseNumber()}
                placeholder={localizer.internalCaseNumberPlaceholder()}
                name="internalCaseNumber"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <FormInput
                methods={methods}
                id="case-form-client.name"
                name="client.name"
                label={localizer.clientPlaceholder()}
                placeholder={localizer.clientPlaceholder()}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <FormDropdown
                methods={methods}
                id="case-form-counterparts"
                name="counterparts"
                label={localizer.counterparts()}
                noOptionsMessage={() => localizer.typeNameOfCounterpartToAdd()}
                placeholder={localizer.counterpartsPlaceholder()}
                hideDropdownIndicator
                allowCreate
                isMulti
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <FormTextarea
                methods={methods}
                id="case-form-description"
                label={localizer.description()}
                placeholder={localizer.descriptionPlaceholder()}
                name="description"
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  const handleSubmitCreateCase = async (model: CaseCreateModel) => {
    await createCase(model).unwrap();
    NotificationModule.showSuccessSmall(localizer.theCaseWasCreated());
    close();
  };

  const wizardSteps: WizardStep[] = [
    {
      stepNumber: CaseCreationStep.Summary,
      active: currentStep === CaseCreationStep.Summary,
      title: localizer.summary(),
      content: renderSummaryStep(),
      onNext: methods.handleSubmit(() => setCurrentStep(CaseCreationStep.Presentations))
    },
    {
      stepNumber: CaseCreationStep.Presentations,
      active: currentStep === CaseCreationStep.Presentations,
      title: localizer.presentations(),
      content: (
        <AddPresentationsForm
          methods={methods}
          presentations={presentationsArray.fields}
          onAddPresentation={() => presentationsArray.append({ title: "" })}
          onDeletePresentation={(index: number) => presentationsArray.remove(index)}
          hideTitle
        />
      ),
      onPrevious: methods.handleSubmit(() => setCurrentStep(CaseCreationStep.Summary)),
      onNext: methods.handleSubmit(() => setCurrentStep(CaseCreationStep.Participants))
    },
    {
      stepNumber: CaseCreationStep.Participants,
      active: currentStep === CaseCreationStep.Participants,
      title: localizer.participants(),
      content: (
        <SelectParticipantsForm
          initialValues={methods.getValues("selectedParticipants")}
          onChangeCallback={(participantModel) => {
            methods.setValue("selectedParticipants", participantModel.selectedParticipants);
          }}
        />
      ),
      onPrevious: methods.handleSubmit(() => setCurrentStep(CaseCreationStep.Presentations)),
      onNext: methods.handleSubmit(handleSubmitCreateCase)
    }
  ];

  return (
    <WizardPanel
      steps={wizardSteps}
      submitButtonText={localizer.createCase()}
      submitState={{
        isSubmitting: createCaseRequest.isLoading,
        isSubmitSuccessful: createCaseRequest.isSuccess
      }}
    />
  );
};
