export enum PlatformType {
  Windows = 1,
  MacOs = 2,
  Unknown = 3
}

const useUserAgentPlatform = () => {
  const platform = window.navigator.platform ?? window.navigator.userAgent;
  const platformLower = platform.toLowerCase();

  if (!platformLower) {
    return PlatformType.Unknown;
  }

  if (platformLower.includes("win")) {
    return PlatformType.Windows;
  } else if (platformLower.includes("mac") || platformLower.includes("darwin")) {
    return PlatformType.MacOs;
  } else {
    return PlatformType.Unknown;
  }
};

export default useUserAgentPlatform;
