import classNames from "classnames";
import { useMemo } from "react";
import { NotificationModule } from "ditmer-embla";
import { Tabs } from "@components/tab/tabs";
import { PdfType } from "@pages/pdfviewer/component/pdfViewerSlice";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import usePageMarkings from "@pages/pdfviewer/component/page/canvas/MarkingsPageOverlay/hooks/usePageMarkings";
import useLocalStorage from "src/hooks/useLocalStorage";
import { useLocalization } from "@components/localization/localizationProvider";
import { useEditPresentationPageMarkingsMutation } from "@services/api/casePresentation/casePresentationApi";
import styles from "./MarkingsSidebar.module.scss";
import MarkingsTab from "./MarkingsTab/MarkingsTab";

type TabId = "comments" | "markings";

type MarkingsSidebarProps = {
  additionalClasses?: string;
  pageId: string;
  caseId: string;
  isDrawerOpen?: boolean;
  documentId: string;
  pageIndex: number;
};

const MarkingsSidebar = ({
  additionalClasses,
  caseId,
  pageId,
  isDrawerOpen,
  documentId,
  pageIndex
}: MarkingsSidebarProps) => {
  const [selectedTab, setSelectedTab] = useLocalStorage<TabId>("markingsSidebarTab", "comments");

  const localizer = useLocalization();

  const [editMarkings] = useEditPresentationPageMarkingsMutation();

  const { markings, isFetching, isLoading } = usePageMarkings({
    pageIndex,
    pdfType: PdfType.Presentation,
    documentId
  });

  const commentMarkings = markings.filter((m) => m.type === MarkingType.Comment);
  const allOtherMarkings = markings.filter((m) => m.type !== MarkingType.Comment);

  const markingsInActivePresentationIds = useMemo(
    () =>
      markings
        .filter((m) => m.presentationList?.some((sp) => sp.presentationId === documentId))
        .map((m) => m.id),
    [markings, documentId]
  );

  const updateMarkings = (value: boolean, markingIds: string[]) => {
    const newMarkings = value
      ? [...markingIds, ...markingsInActivePresentationIds]
      : markingsInActivePresentationIds.filter((m) => !markingIds.includes(m));

    editMarkings({
      caseId: caseId,
      presentationId: documentId,
      presentationPageId: pageId,
      model: { markingIds: newMarkings }
    })
      .unwrap()
      .then(() => {
        NotificationModule.showSuccessSmall(localizer.presentationEditted());
      });
  };

  const disableEditing = isFetching || isLoading;

  return (
    <div className={classNames("d-flex flex-column", styles.container, additionalClasses)}>
      <Tabs
        additionalClasses={styles.tabs}
        hideBorder
        tabs={[
          {
            id: "comments",
            title: `${localizer.comments()} (${commentMarkings.length})`,
            active: selectedTab === "comments",
            content: (
              <MarkingsTab
                caseId={caseId}
                documentId={documentId}
                pageId={pageId}
                isDrawerOpen={isDrawerOpen}
                markings={commentMarkings}
                updateMarkings={updateMarkings}
                activeMarkingsIds={markingsInActivePresentationIds}
                disableEditing={disableEditing}
                isCommentTab
              />
            ),
            onClick: () => setSelectedTab("comments")
          },
          {
            id: "markings",
            title: `${localizer.markings()} (${allOtherMarkings.length})`,
            active: selectedTab === "markings",
            content: (
              <MarkingsTab
                caseId={caseId}
                documentId={documentId}
                pageId={pageId}
                isDrawerOpen={isDrawerOpen}
                updateMarkings={updateMarkings}
                markings={allOtherMarkings}
                activeMarkingsIds={markingsInActivePresentationIds}
                disableEditing={disableEditing}
              />
            ),
            onClick: () => setSelectedTab("markings")
          }
        ]}
      />
    </div>
  );
};

export default MarkingsSidebar;
