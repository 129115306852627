import { InputHTMLAttributes, ReactNode } from "react";
import styles from "./checkbox.module.scss";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  children?: ReactNode;
}

const Checkbox = ({ children, ...inputProps }: CheckboxProps) => {
  return (
    <>
      <input type="checkbox" className={styles.input} {...inputProps} />
      <label htmlFor={inputProps.id} className={styles.label}>
        {children}
      </label>
    </>
  );
};

export default Checkbox;
