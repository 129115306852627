import { useDispatch } from "react-redux";
import { StylesConfig } from "react-select";
import MarkingTypeDropdown from "@components/dropdown/markingDropdown/markingTypeDropdown";
import { setMarkingTypeFilters } from "@pages/pdfviewer/component/pdfViewerSlice";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";

type MarkingTypeFilterDropdownProps = {
  className?: string;
  stylesOverride?: StylesConfig;
};

const MarkingTypeFilterDropdown = (props: MarkingTypeFilterDropdownProps) => {
  const dispatch = useDispatch();

  const onSelectedOwnersChanged = (newMarkingTypes: MarkingType[]) => {
    dispatch(setMarkingTypeFilters(newMarkingTypes));
  };

  return <MarkingTypeDropdown onChangeCallback={onSelectedOwnersChanged} {...props} />;
};

export default MarkingTypeFilterDropdown;
