import classNames from "classnames";
import { ReactNode } from "react";
import BackButton, { BackBtnCallbackResult } from "@components/button/BackButton";
import styles from "./toolbar.module.scss";

interface ToolbarProps {
  title?: string;
  titleAddon?: ReactNode;
  subTitle?: string;
  children?: ReactNode;
  toggleCollapse?: () => void;
  backAction?: () => BackBtnCallbackResult;
  centerItems?: ReactNode;
}

const Toolbar = ({
  title,
  titleAddon,
  subTitle,
  children,
  toggleCollapse,
  backAction,
  centerItems
}: ToolbarProps) => {
  return (
    <div
      className={classNames(
        styles.toolbar,
        "align-items-end",
        "bg-white",
        "shadow-1",
        "px-3",
        "py-2"
      )}
    >
      <div>
        <BackButton onClickCallback={backAction} />
      </div>

      <div className="row justify-content-center mb-n1">
        <div className={classNames("col overflow-hidden", styles.pdfInfo)}>
          <div className="d-flex align-items-center gap-m h-100">
            <h3 className={classNames("text-truncate")}>{title}</h3>
            {titleAddon}
          </div>
        </div>
        {centerItems && <div className="col">{centerItems}</div>}
        <div className="col-auto mb-1">
          <div className="row align-items-end justify-content-center mb-n1">{children}</div>
        </div>
      </div>
    </div>
  );
};

interface ToolbarItemProps {
  label?: string;
  children: ReactNode;
  id?: string;
  additionalClasses?: string;
}

const Item = ({ label, children, id, additionalClasses }: ToolbarItemProps) => {
  return (
    <div
      id={id}
      className={classNames(
        styles.item,
        "col-auto d-flex flex-column align-items-center mb-1",
        additionalClasses
      )}
    >
      <div>{children}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(Toolbar, { Item });
