import classNames from "classnames";
import { forwardRef, HTMLAttributes, Ref } from "react";
import {
  LineAppearance,
  MARKING_FADED_COLOR,
  VerticalLinesMarkingModel
} from "@pages/pdfviewer/component/models/markingModel";
import ActivationHandles from "../../../shared/activationHandles";
import ResizableMarking from "../ResizableMarking/ResizableMarking";
import styles from "./VerticalLinesMarking.module.scss";

type VerticalLinesMarkingProps = HTMLAttributes<HTMLDivElement> & {
  marking: VerticalLinesMarkingModel<false>;
  scale: number;
  active: boolean;
  faded: boolean;
};

const linesClassByType = {
  [LineAppearance.Single]: styles.single,
  [LineAppearance.Double]: styles.double,
  [LineAppearance.LeftOnly]: styles.leftOnly,
  [LineAppearance.RightOnly]: styles.rightOnly,
  [LineAppearance.Brackets]: styles.brackets
};

const VerticalLinesMarking = forwardRef(
  (
    { marking, scale, active, faded, className, style, ...childProps }: VerticalLinesMarkingProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const styleWithCssVariable = {
      ...style,
      "--vertical-lines-color": faded ? MARKING_FADED_COLOR : marking.data.color
    };

    return (
      <ResizableMarking
        ref={ref}
        marking={marking}
        scale={scale}
        className={classNames(
          styles.verticalLines,
          linesClassByType[marking.data.lines],
          className
        )}
        style={styleWithCssVariable}
        active={active}
        resizableHeight
        resizableWidth
        notClickable={!active}
        {...childProps}
      >
        <ActivationHandles
          disabled={active}
          east={marking.data.lines !== LineAppearance.LeftOnly}
          west={marking.data.lines !== LineAppearance.RightOnly}
        />
      </ResizableMarking>
    );
  }
);

export default VerticalLinesMarking;
