import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { User } from "@models/User";
import { useGetUserInfoQuery } from "@services/api/user/userApi";
import { UserService } from "@services/userService";
import useIsOnline from "src/hooks/useIsOnline";
import { UserInfoModel } from "@models/userInfoModel";

interface AuthContextType {
  authenticated: boolean;
  user: User;
  signOut: () => void;
  impersonate: (userId: string) => void;
  stopImpersonation: () => void;
  userService: UserService;
  setTokenCallback: (token: string) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used in AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ userService: UserService }> = ({ children, userService }) => {
  const [initialized, setInitialized] = useState(false);
  const isOnlineCheck = useIsOnline();
  const [token, setToken] = useState<string | undefined>();
  const [userState, setUserState] = useState<UserInfoModel | null>(null);

  const { data: userInfoResult, error: userInfoError } = useGetUserInfoQuery(undefined, {
    skip: !token
  });

  useEffect(() => {
    if (!token && !initialized) {
      userService.getUserToken().then((accessToken) => {
        if (accessToken) {
          // This case is when you access the page anew but are already logged in (by refreshing the page for example)
          setToken(accessToken);
        } else {
          // This case is for after you login and get redirected and are waiting for the token to be set by AuthRedirectPage
          setInitialized(true);
        }
      });
    }
  }, [token, initialized, userService]);

  useEffect(() => {
    const handler = () => {
      isOnlineCheck().then((online) => {
        if (online) {
          return userService.logout();
        }
      });
      return Promise.resolve();
    };
    userService.addSilentRenewErrorHandler(handler);
    return () => userService.removeSilentRenewErrorHandler(handler);
  }, [isOnlineCheck, userService]);

  useEffect(() => {
    if (userInfoResult) {
      setUserState(userInfoResult);
      setInitialized(true); // initialized gets set to true for when we are already logged in and are refreshing the page
    }
  }, [userInfoResult]);

  useEffect(() => {
    if (userInfoError) {
      userService.logout();
    }
  }, [userService, userInfoError]);

  const authProviderValue = useMemo(() => {
    const user = new User(userState);
    return {
      signOut: () => userService.logout(),
      impersonate: (userId: string) => userService.impersonate(userId),
      stopImpersonation: () => userService.stopImpersonate(),
      authenticated: user.authenticated,
      user,
      userService: userService,
      setTokenCallback: setToken
    };
  }, [userService, userState]);

  // we block any further of the app tree until initialized gets set to true. This prevents the app from trying to log in again when we already have a valid token.
  if (!initialized) return null;

  return <AuthContext.Provider value={authProviderValue}>{children}</AuthContext.Provider>;
};
