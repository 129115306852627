import classNames from "classnames";
import iconSpriteSvg from "@content/icons/sprite.symbol.svg";
import styles from "./emblaIcon.module.scss";

export enum IconColor {
  Blue,
  White,
  Gray,
  Warning,
  Danger,
  DarkGray,
  Gray70
}

export enum IconSize {
  Small = 1,
  ExtraSmall = 2,
  Medium = 3,
  Large = 4
}

type EmblaIconProps = {
  iconName: string;
  additionalClasses?: string;
  isMenuLinkIcon?: boolean;
  color?: IconColor;
  size?: IconSize;
  containerSize?: IconSize;
};

//TODO: make classes specifc for colors (e.g. blue)

export const EmblaIcon = ({
  iconName,
  additionalClasses,
  isMenuLinkIcon,
  color,
  size,
  containerSize
}: EmblaIconProps) => {
  const renderEmblaIcon = () => {
    if (iconName) {
      return (
        <svg
          className={classNames(
            "embla-icon",
            {
              [styles.colorBlue]: color === IconColor.Blue,
              [styles.colorWhite]: color === IconColor.White,
              [styles.colorGray]: color === IconColor.Gray,
              [styles.colorWarning]: color === IconColor.Warning,
              [styles.colorDanger]: color === IconColor.Danger,
              [styles.colorDarkGray]: color === IconColor.DarkGray,
              [styles.colorGray70]: color === IconColor.Gray70
            },
            {
              [styles.iconSmall]: size === IconSize.Small,
              [styles.iconExtraSmall]: size === IconSize.ExtraSmall,
              [styles.iconMedium]: size === IconSize.Medium,
              [styles.iconLarge]: size === IconSize.Large
            },
            additionalClasses
          )}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href={iconSpriteSvg + "#" + iconName} />
        </svg>
      );
    }
    return;
  };

  if (isMenuLinkIcon) {
    return <div className="menu-link-icon">{renderEmblaIcon()}</div>;
  }

  if (containerSize) {
    return (
      <div
        className={classNames(
          {
            [styles.iconSmall]: containerSize === IconSize.Small,
            [styles.iconExtraSmall]: containerSize === IconSize.ExtraSmall,
            [styles.iconMedium]: containerSize === IconSize.Medium,
            [styles.iconLarge]: containerSize === IconSize.Large
          },
          additionalClasses
        )}
      >
        {renderEmblaIcon()}
      </div>
    );
  }

  return <>{renderEmblaIcon()}</>;
};
