import { useEffect } from "react";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import { useDropPage } from "../hooks/useDropPage";

type AdditionalBottomBarPageDropZoneProps = {
  caseId: string;
  activePresentation?: PresentationInfo;
  setIsLoadingNewPage: (isLoading: boolean) => void;
};

const AdditionalBottomBarPageDropZone = ({
  caseId,
  activePresentation,
  setIsLoadingNewPage
}: AdditionalBottomBarPageDropZoneProps) => {
  const { drop, isLoading } = useDropPage({ caseId, activePresentation });

  useEffect(() => {
    setIsLoadingNewPage(isLoading);
  }, [isLoading, setIsLoadingNewPage]);

  return <div ref={drop} className="flex-1 align-self-stretch width-0"></div>;
};

export default AdditionalBottomBarPageDropZone;
