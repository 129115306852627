import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";

export interface IconTextCallbackItemProps {
  text: string;
  icon?: ReactNode;
  callback?: () => void;
  disabled?: boolean;
}

export const IconTextCallbackItem = forwardRef(
  ({ icon, text, callback, disabled }: IconTextCallbackItemProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={classNames("d-flex", "align-items-center", disabled ? "disabled" : "")}
        onClick={callback}
      >
        {icon && <div className="margin-right-s">{icon}</div>}
        {text}
      </div>
    );
  }
);
