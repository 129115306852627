import { DocumentPageOverlayIconTypeEnum } from "@pages/pdfviewer/component/models/documentPageOverlayIconType";
import { PageNumberModel } from "@services/api/document/models/documentPageInfoModel";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";

export interface MinimalPresentationPageModel {
  markingIds: string[];
  hasDocumentMarkings: boolean;
  fileName: string;
  isTitlePage: boolean;
  titlePageTitle?: string;
  titlePageDescription?: string;
  orientation?: PageOrientation;
}

export interface CasePresentationPageModel extends MinimalPresentationPageModel {
  id: string;
  pageIndex: number;
  notes: CasePresentationPageNoteModel[];
  noteSize: SpeakerNoteSizeEnum;
  thumbnailUrl?: string;
  tags: string[];
  overlayIconTypes: DocumentPageOverlayIconTypeEnum[];
  order: number;
  caseDocumentId: string;
  caseId: string;
  pageNumber?: PageNumberModel;
}

export interface CasePresentationPageNoteModel {
  id: string;
  note: string;
  order: number;
}

export enum SpeakerNoteSizeEnum {
  Smallest = 1,
  Smaller = 2,
  Small = 3,
  Medium = 4,
  Large = 5
}
