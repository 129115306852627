import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { AccessRight } from "@infrastructure/AccessRight";
import { useAuth } from "@components/auth/authProvider";
import { SessionStorageKeys } from "@infrastructure/sessionStorageKeys";
import { isPresentationWindow } from "@components/routing/routes";
import useIsOnline from "src/hooks/useIsOnline";

export const RequireAuth = ({
  children,
  requiredAccessRight
}: {
  children: JSX.Element;
  requiredAccessRight?: AccessRight;
}) => {
  const [loginRedirectFailed, setLoginRedirectFailed] = useState(false);
  const [isOnline, setIsOnline] = useState<boolean | undefined>(undefined);

  const localizer = useLocalization();
  const location = useLocation();
  const auth = useAuth();
  const isOnlineCheck = useIsOnline();

  const { pathname: currentPath } = useLocation();
  const isPresentation = isPresentationWindow(currentPath);

  let { authenticated } = auth;
  const { userService } = auth;

  // When we present we open a new (electron) window and thus have to start the application as normal.
  // since we support presenting while being offline, we therefore have to be able to skip authentication.
  // this still works since the relevant queries for presenting will have been cached
  const isPresentationWindowWhileOffline = isPresentation && !isOnline;

  if (authenticated && requiredAccessRight) {
    authenticated = auth.user.hasAccessRight(requiredAccessRight);
  }

  useEffect(() => {
    isOnlineCheck().then((on) => setIsOnline(on));
  }, [isOnlineCheck]);

  useEffect(() => {
    if (isOnline !== undefined) {
      if (!authenticated && !isPresentationWindowWhileOffline) {
        sessionStorage.setItem(SessionStorageKeys.returnUrl, location.pathname);

        if (isPresentation) {
          userService.silentlyLogin().catch((e) => {
            setLoginRedirectFailed(true);
            throw e;
          });
        } else {
          userService.login().catch((e) => {
            setLoginRedirectFailed(true);
            throw e;
          });
        }
      }
    }
  }, [
    authenticated,
    isOnline,
    isPresentation,
    isPresentationWindowWhileOffline,
    location.pathname,
    userService
  ]);

  return isOnline !== undefined && (authenticated || isPresentationWindowWhileOffline) ? (
    children
  ) : (
    <div className="d-flex flex-column align-items-center margin-top-xl">
      {loginRedirectFailed ? (
        <h4>{localizer.connectionFailed()}</h4>
      ) : (
        <Spinner text={localizer.startingConnection()} />
      )}
    </div>
  );
};
