/* eslint-disable react/jsx-no-useless-fragment */
import { Route, Routes } from "react-router-dom";
import { CasePage } from "@pages/case/casePage";
import { PdfViewerPage } from "@pages/pdfviewer/pdfViewerPage";
import { RoutePaths } from "@routes";
import { ExtractCompositionPage } from "@pages/extractCompositionPage/extractCompositionPage";
import EditPresentationPage from "@pages/case/presentations/editPresentationPages/editPresentationPage";

const CaseRouting = () => {
  return (
    <Routes>
      <Route path={RoutePaths.caseDetails.path}>
        <Route index element={<CasePage />} />
        <Route path={RoutePaths.casePresentation.path}>
          <Route index element={<CasePage />} />
          <Route path={RoutePaths.casePresentationPresent.path} element={<CasePage />} />
          <Route path={RoutePaths.casePresentationEdit.path} element={<EditPresentationPage />} />
          <Route
            path={RoutePaths.casePresentationEditPresent.path}
            element={<EditPresentationPage />}
          />
        </Route>
      </Route>
      <Route path={RoutePaths.caseDocument.path}>
        <Route index element={<PdfViewerPage />} />
        <Route path={RoutePaths.caseDocumentPage.path} element={<PdfViewerPage />} />
        <Route path={RoutePaths.casePresentInDocument.path} element={<PdfViewerPage />} />
      </Route>
      <Route path={RoutePaths.caseExtract.path}>
        <Route index element={<ExtractCompositionPage />} />
      </Route>
    </Routes>
  );
};

export default CaseRouting;
