import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetDocumentPageDownloadUrlQuery } from "@services/api/documentPage/documentPageApi";

const usePresentCaseDocument = (caseDocumentId?: string, pageIndex?: number) => {
  const { data: downloadUrlModel, isLoading } = useGetDocumentPageDownloadUrlQuery(
    caseDocumentId && pageIndex !== undefined
      ? { documentId: caseDocumentId, pageIndex: pageIndex }
      : skipToken
  );

  return {
    isLoading,
    pdfUrl: downloadUrlModel?.downloadUrl,
    title: downloadUrlModel?.fileName
  };
};

export default usePresentCaseDocument;
