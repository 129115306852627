import { DropdownOption } from "@components/dropdown/dropdown";
import { Localizer } from "@components/localization/localizer";

export enum CaseElementTypeEnum {
  Extract = 1,
  HelpAppendix = 2,
  MaterialCollection = 3,
  Other = 4,
  ExtractDraft = 5
}

export enum CaseDocumentTypeEnum {
  HelpAppendix = 2,
  MaterialCollection = 3,
  Other = 4
}

export const elementTypeIsCaseDocumentType = (type: CaseElementTypeEnum) => {
  return getCaseDocumentTypes().findIndex((x) => x === type) >= 0;
};

export const getCaseDocumentTypes = () => {
  return [
    CaseElementTypeEnum.Extract,
    CaseElementTypeEnum.HelpAppendix,
    CaseElementTypeEnum.MaterialCollection,
    CaseElementTypeEnum.Other
  ];
};

export const getCaseDocumentTypesNoExtract = () => {
  return [
    CaseElementTypeEnum.HelpAppendix,
    CaseElementTypeEnum.MaterialCollection,
    CaseElementTypeEnum.Other
  ];
};

export const getCaseElementTypes = () => {
  return [...getCaseDocumentTypes(), CaseElementTypeEnum.ExtractDraft];
};

export const getCaseElementTypeDropdownOptions = (
  localizer: Localizer,
  types: CaseElementTypeEnum[]
) => {
  const result: DropdownOption<number>[] = [];

  types.forEach((type) => {
    switch (type) {
      case CaseElementTypeEnum.Extract:
        result.push({
          label: localizer.extract(),
          value: CaseElementTypeEnum.Extract
        });
        break;
      case CaseElementTypeEnum.HelpAppendix:
        result.push({
          label: localizer.helpAppendix(),
          value: CaseElementTypeEnum.HelpAppendix
        });
        break;
      case CaseElementTypeEnum.MaterialCollection:
        result.push({
          label: localizer.materialCollection(),
          value: CaseElementTypeEnum.MaterialCollection
        });
        break;
      case CaseElementTypeEnum.Other:
        result.push({
          label: localizer.other(),
          value: CaseElementTypeEnum.Other
        });
        break;
      case CaseElementTypeEnum.ExtractDraft:
        result.push({
          label: localizer.draft(),
          value: CaseElementTypeEnum.ExtractDraft
        });
        break;
      default:
        throw new Error(`CaseDocumentTypeEnum.${type} has not been implemented`);
    }
  });

  return result;
};

export const convertCaseElementTypeToString = (type: CaseElementTypeEnum, localizer: Localizer) => {
  switch (type) {
    case CaseElementTypeEnum.Extract:
      return localizer.extract();
    case CaseElementTypeEnum.HelpAppendix:
      return localizer.helpAppendix();
    case CaseElementTypeEnum.MaterialCollection:
      return localizer.materialCollection();
    case CaseElementTypeEnum.Other:
      return localizer.other();
    case CaseElementTypeEnum.ExtractDraft:
      return localizer.draft();
  }
};
