import { useDrop } from "react-dnd";
import {
  CasePresentationPageModel,
  SpeakerNoteSizeEnum
} from "@services/api/case/models/casePresentationPageModel";
import { useBulkAddToPresentationsMutation } from "@services/api/casePresentation/casePresentationApi";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";

type UseDropDocumentProps = {
  activePresentation?: PresentationInfo;
  caseId: string;
  index?: number;
  page?: CasePresentationPageModel;
  setPages?: React.Dispatch<React.SetStateAction<CasePresentationPageModel[]>>;
  dummyPageId?: string;
  dummyPageIdPrefix?: string;
};

export const useDropPage = ({
  caseId,
  index,
  page,
  setPages,
  activePresentation,
  dummyPageId,
  dummyPageIdPrefix
}: UseDropDocumentProps) => {
  const [bulkAddToPresentations, { isLoading }] = useBulkAddToPresentationsMutation();

  const [, drop] = useDrop({
    accept: "page",
    drop: (dropped: { documentId: string; index: number }) => {
      if (activePresentation) {
        bulkAddToPresentations({
          caseId,
          model: {
            documentId: dropped.documentId,
            presentations: [{ id: activePresentation.id, order: index }],
            pageIndexes: [dropped.index]
          }
        });
      }
    },
    hover: (item: { documentId: string; index: number }) => {
      if (
        page?.id !== dummyPageId &&
        setPages &&
        dummyPageIdPrefix &&
        dummyPageId &&
        index !== undefined
      ) {
        setPages?.((prev) => {
          let newPages = [...prev];
          newPages = newPages.filter((p) => !p.id.includes(dummyPageIdPrefix));
          const newPagePreviewItem: CasePresentationPageModel = {
            caseDocumentId: item.documentId,
            fileName: "",
            id: dummyPageId,
            hasDocumentMarkings: false,
            isTitlePage: false,
            pageIndex: 1,
            markingIds: [],
            order: 1,
            overlayIconTypes: [],
            tags: [],
            caseId: "",
            notes: [],
            noteSize: SpeakerNoteSizeEnum.Small
          };
          newPages.splice(index, 0, newPagePreviewItem);
          return newPages;
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return { drop, isLoading };
};
