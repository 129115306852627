import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { CircularProgressbarStyles } from "react-circular-progressbar/dist/types";
import { useLocalization } from "@components/localization/localizationProvider";
import {
  EmblaIllustration,
  IllustrationColor
} from "@components/embla/emblaIllustration/emblaIllustration";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { ExtractDraftExportHubEventType } from "@services/signalRClient/extractDraftExportHubConnectionManager";
import { useLazyGetExtractDraftExportDownloadUrlQuery } from "@services/api/extractDraft/extractDraftApi";
import { ExportBaseResponseModel } from "@services/api/sharedModels/exportBaseResponseModel";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { Spinner } from "@components/spinner/spinner";
import { ExtractDraftExportEventModel } from "@pages/extractCompositionPage/components/export/extractDraftExportEventModel";
import useHubConnection from "../../../../hooks/useHubConnection";
import styles from "./downloadExtractDraftExport.module.scss";
import "react-circular-progressbar/dist/styles.css";
import { ExtractDraftExportMissingFilesEventModel } from "./ExtractDraftExportMissingFilesEventModel";

interface DownloadExtractDraftExportProps {
  caseId: string;
  extractId: string;
  extractExportId: string;
}

export const DownloadExtractDraftExport = ({
  caseId,
  extractId,
  extractExportId
}: DownloadExtractDraftExportProps) => {
  const localizer = useLocalization();

  const [hubConnectionManager] = useState(useHubConnection(HubConnectionType.ExtractDraftExport));
  const [getExportDownloadUrl] = useLazyGetExtractDraftExportDownloadUrlQuery();

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [exportDownloadModels, setExportDownloadModels] = useState<ExportBaseResponseModel[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);

  const downloadIsReady = exportDownloadModels.length > 0;

  useEffect(() => {
    const fetchExport = async () => {
      const exportResult = await getExportDownloadUrl({
        caseId: caseId,
        extractDraftId: extractId,
        extractDraftExportId: extractExportId
      }).unwrap();

      if (exportResult.length > 0) {
        setExportDownloadModels(exportResult);
      } else {
        await hubConnectionManager.startConnection(extractExportId);
        hubConnectionManager.on(
          extractExportId,
          ExtractDraftExportHubEventType.ExtractDraftExportEvent,
          (e: ExtractDraftExportEventModel) => {
            setInfoMessage("");

            if (e.failed) {
              setIsProcessing(false);
              setHasFailed(true);
            } else {
              setIsProcessing(true);
              setProgressPercentage(e.progressPercentage);

              if (e.finished) {
                getExportDownloadUrl({
                  caseId: caseId,
                  extractDraftId: extractId,
                  extractDraftExportId: extractExportId
                })
                  .unwrap()
                  .then((response) => setExportDownloadModels(response));
                hubConnectionManager.stopConnection(extractExportId);
              }
            }
          }
        );

        hubConnectionManager.on(
          extractExportId,
          ExtractDraftExportHubEventType.ExtractDraftExportMissingFilesEvent,
          (e: ExtractDraftExportMissingFilesEventModel) => {
            setInfoMessage(
              localizer.exportExtractDraftMissingFilesHint(
                e.missingFiles.map((f) => f.caseDocumentName)
              )
            );
          }
        );
      }
    };

    fetchExport();

    return () => {
      hubConnectionManager.stopConnection(extractExportId);
    };
  }, [caseId, extractExportId, extractId, getExportDownloadUrl, hubConnectionManager, localizer]);

  const circularProgressbarStyles: CircularProgressbarStyles = {
    text: {
      fill: "#000000",
      fontWeight: 300
    },
    trail: {
      strokeLinecap: "round",
      stroke: "#f7f5f5"
    },
    path: {
      stroke: "#116c9b"
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="subtle m-4">
        {downloadIsReady ? (
          <>
            <p>{localizer.exportExtractDraftReadyHint1()}</p>
            <p>{localizer.exportPresentationDownloadReadyHint2()}</p>
          </>
        ) : (
          <>
            {!hasFailed && (
              <>
                <p>{localizer.exportPresentationDownloadHint1()}</p>
                <p>{localizer.exportPresentationDownloadHint2()}</p>
              </>
            )}
          </>
        )}
      </div>
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center margin-top-l">
        {downloadIsReady ? (
          <>
            <EmblaIllustration illustrationName="business-paper" color={IllustrationColor.Blue} />

            {exportDownloadModels.map((exportDownloadModel) => (
              <a className="margin-top-m" href={exportDownloadModel.downloadUrl}>
                <EmblaIcon iconName="download" color={IconColor.Blue} />
                {exportDownloadModel.fileName + ".pdf"}
              </a>
            ))}
          </>
        ) : isProcessing ? (
          <div className={styles.progressBarContainer}>
            <CircularProgressbar
              value={progressPercentage}
              text={`${progressPercentage}%`}
              strokeWidth={4}
              styles={circularProgressbarStyles}
            />
          </div>
        ) : hasFailed ? (
          <div className="alert alert-danger" role="alert">
            <h5>{localizer.errorOccuredTitle()}</h5>
            {localizer.errorOccuredContent()}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <div className="text-center text-break mt-4">{infoMessage}</div>
    </div>
  );
};
