import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";

type DragPreviewMultipleDocumentProps = {
  documentsAmount: number;
};

const DragPreviewMultipleDocument = ({ documentsAmount }: DragPreviewMultipleDocumentProps) => {
  const localizer = useLocalization();

  return (
    <div className={classNames("flex row gap-8 px-3 py-1")}>
      <EmblaIcon iconName="document" />
      <p className="m-0">
        <b>
          {documentsAmount} {localizer.documents()}
        </b>
      </p>
    </div>
  );
};

export default DragPreviewMultipleDocument;
