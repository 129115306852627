import React from "react";
import { Row } from "react-table";
import { PaginatedListInput } from "@models/paginatedList/paginatedListInput";
import { OrderArraysAreEqual } from "@models/paginatedList/utitlity";
import { PaginatedListSortOrderInput } from "@models/paginatedList/paginatedListSortOrderInput";
import BaseTable, { ColumnSortInfo, DefaultTableProps } from "./baseTable";

type PaginatedTableProps<T extends object, D extends PaginatedListInput> = {
  paginatedListInput: Readonly<PaginatedListInput>;
  setPaginatedListInput: React.Dispatch<React.SetStateAction<D>>;
  totalRecords: number;
  isLoading: boolean;
  isFetching: boolean;
  hidePagination?: boolean;
  hideSearchFilter?: boolean;
  hidePageSizeFilter?: boolean;
  hideSearchLabel?: boolean;
  extraFiltersHtml?: React.ReactNode;
  pageSizeOptions?: number[];
  initialSort?: ColumnSortInfo;
  additionalClasses?: string;
  onSelectedRowsChange?: (selectedRows: Row<T>[]) => void;
  handleDelete?: (row: Row<T>[]) => void;
} & DefaultTableProps<T>;

const PaginatedTable = <T extends object, D extends PaginatedListInput>({
  columns,
  data,
  paginatedListInput,
  setPaginatedListInput,
  totalRecords,
  rowOnClick,
  noResultsOptions,
  isLoading,
  isFetching,
  hidePagination = false,
  hideSearchFilter = false,
  hidePageSizeFilter = false,
  hideSearchLabel = false,
  extraFiltersHtml,
  pageSizeOptions,
  initialSort,
  additionalClasses,
  onSelectedRowsChange,
  handleDelete
}: PaginatedTableProps<T, D>) => {
  const handlePageChange = (page: number) => {
    setPaginatedListInput((prev) => ({ ...prev, start: (page - 1) * prev.length }));
  };

  const handleSort = (sortInfos: ColumnSortInfo[]) => {
    const orderInput = sortInfos.map((sortInfo) => {
      return {
        columnName: sortInfo.columnName,
        dir: sortInfo.direction
      } as PaginatedListSortOrderInput;
    });

    if (!OrderArraysAreEqual(orderInput, paginatedListInput.order)) {
      setPaginatedListInput((prev) => ({ ...prev, order: orderInput }));
    }
  };

  const handlePageSizeChanged = (pageSize: number) => {
    setPaginatedListInput((prev) => ({ ...prev, length: pageSize, start: 0 }));
  };

  const handleSearchQueryChanged = (search: string) => {
    setPaginatedListInput((prev) => ({
      ...prev,
      search: { value: search, regex: false },
      start: 0
    }));
  };

  const filterOptions =
    !hideSearchFilter || !hidePageSizeFilter || extraFiltersHtml
      ? {
          usePageSizeFilter: !hidePagination && !hidePageSizeFilter,
          onPageSizeChangedCallback: handlePageSizeChanged,
          pageSizeOptions: pageSizeOptions,
          useSearchQueryFilter: !hideSearchFilter,
          hideSearchLabel: hideSearchLabel,
          onSearchQueryChangedCallback: handleSearchQueryChanged,
          extraFiltersHtml: extraFiltersHtml
        }
      : undefined;

  return (
    <BaseTable
      handleDelete={handleDelete}
      onSelectedRowsChange={onSelectedRowsChange}
      columns={columns}
      data={data}
      isAsync={true}
      noResultsOptions={noResultsOptions}
      rowOnClick={rowOnClick}
      isLoading={isLoading}
      isFetching={isFetching}
      paginationOverride={{
        currentPage: Math.floor(paginatedListInput.start / paginatedListInput.length) + 1,
        pageSize: paginatedListInput.length,
        totalRecords: totalRecords,
        onPageChangeCallback: handlePageChange
      }}
      filterOptions={filterOptions}
      onSortCallback={handleSort}
      isPaginated={!hidePagination}
      isSortable={true}
      initialSort={initialSort}
      additionalClasses={additionalClasses}
    />
  );
};

export default PaginatedTable;
