import { useMemo } from "react";
import classNames from "classnames";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import { useLocalization } from "@components/localization/localizationProvider";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { Spinner } from "@components/spinner/spinner";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import CommentCard from "./CommentCard/CommentCard";
import MarkingSwitchCard from "./MarkingSwitchCard/MarkingSwitchCard";
import styles from "./MarkingsTab.module.scss";

type MarkingsTabProps = {
  markings: MarkingModel<false>[];
  updateMarkings: (value: boolean, markingIds: string[]) => void;
  activeMarkingsIds: string[];
  disableEditing?: boolean;
  isDrawerOpen?: boolean;
  isCommentTab?: boolean;
  caseId: string;
  documentId: string;
  pageId: string;
};

const MarkingsTab = ({
  markings,
  updateMarkings,
  activeMarkingsIds,
  disableEditing,
  isDrawerOpen,
  isCommentTab,
  caseId,
  documentId,
  pageId
}: MarkingsTabProps) => {
  const localizer = useLocalization();

  const orderedMarkings = useMemo(() => {
    return [...markings].sort((a, b) => {
      const aIsActive = activeMarkingsIds.includes(a.id);
      const bIsActive = activeMarkingsIds.includes(b.id);
      return aIsActive === bIsActive ? 0 : aIsActive ? -1 : 1;
    });
  }, [markings, activeMarkingsIds]);

  return (
    <div
      className={classNames(
        "p-3",
        styles.scrollable,
        isDrawerOpen ? styles.bottomBarOpenHeight : styles.bottomBarClosedHeight
      )}
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="m-0">
          {isCommentTab
            ? localizer.activeCommentsAppearOnScreen()
            : localizer.activeMarkingsDisplayedInTrial()}
        </p>
        <ExpandingDropdownMenu buttonNode={<MenuDotButton className="ml-2" defaultBorder />}>
          <ExpandingDropdownMenu.ButtonItem
            onClick={() =>
              updateMarkings(
                true,
                orderedMarkings.map((m) => m.id)
              )
            }
          >
            {localizer.selectAll()}
          </ExpandingDropdownMenu.ButtonItem>
          <ExpandingDropdownMenu.ButtonItem
            onClick={() =>
              updateMarkings(
                false,
                orderedMarkings.map((m) => m.id)
              )
            }
          >
            {localizer.selectNone()}
          </ExpandingDropdownMenu.ButtonItem>
        </ExpandingDropdownMenu>
      </div>
      <div>
        {markings ? (
          orderedMarkings.map((marking) => {
            const isMarkingActiveInPresentation = activeMarkingsIds.includes(marking.id);
            return marking.type === MarkingType.Comment ? (
              <CommentCard
                disableEditing={disableEditing}
                key={marking.id}
                updateMarkings={updateMarkings}
                commentData={marking}
                isActive={isMarkingActiveInPresentation}
                caseId={caseId}
                documentId={documentId}
                pageId={pageId}
              />
            ) : (
              <MarkingSwitchCard
                key={marking.id}
                marking={marking}
                updateMarkings={updateMarkings}
                isActive={isMarkingActiveInPresentation}
                disableEditing={disableEditing}
              />
            );
          })
        ) : (
          <Spinner />
        )}
        {markings.length === 0 && <p>{localizer.noMarkingsOnPage()}</p>}
      </div>
    </div>
  );
};

export default MarkingsTab;
