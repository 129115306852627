import { createSlice, miniSerializeError, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@app";

export interface ErrorState {
  hasError: boolean;
  error?: unknown;
}

const initialState: ErrorState = {
  hasError: false,
  error: undefined
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ hasError: boolean; error?: unknown }>) => {
      state.hasError = action.payload.hasError;

      if (state.hasError) state.error = miniSerializeError(action.payload.error);
      else state.error = null;
    }
  }
});

export const { setError } = errorSlice.actions;
export const errorStateSelector = (state: RootState) => state.error;
export default errorSlice.reducer;
