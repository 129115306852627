import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";

type DragPreviewSingleDocumentProps = {
  document: CaseDocumentModel | ReorderExtractDraftSectionDocument;
};

const DragPreviewSingleDocument = ({ document }: DragPreviewSingleDocumentProps) => {
  const getDocumentName = () => {
    if ("documentName" in document) {
      return document.documentName;
    } else {
      return document.document.caseDocumentName;
    }
  };

  return (
    <div className={"px-3 py-1"}>
      <p className="m-0">{getDocumentName()}</p>
    </div>
  );
};

export default DragPreviewSingleDocument;
