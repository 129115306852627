import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dimensions } from "@components/resizable/resizable";
import { ReactComponent as LaserPointer } from "@content/images/laser-pointer.svg";
import usePresentPresentationDocument from "@pages/present/usePresentPresentationDocument";
import baseApi, { apiTags } from "@services/api/baseApi";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import styles from "./presentBigScreen.module.scss";
import PresentPage from "./presentPage";
import {
  PRESENTATION_BROADCAST_CHANNEL,
  PresentationAction,
  PresentationActionType
} from "./presentationBroadcastActions";
import useDynamicPresentationPages from "./useDynamicPresentationPages";
import usePresentNavigation from "./usePresentNavigation";
import useTempMarkings from "./hooks/useTempMarkings";
import usePresentCaseDocument from "./usePresentCaseDocument";

const pointerRadius = 6;

const PresentBigScreen = () => {
  const { presentationId, ...params } = useParams();

  let pageIndex = parseInt(params.pageIndex ?? "");
  if (!presentationId || Number.isNaN(pageIndex)) {
    throw Error("presentationId and pageIndex params are required");
  }
  const dispatch = useDispatch();
  const pointer = useRef<SVGSVGElement>(null);
  const [zoomArea, setZoomArea] = useState<Dimensions>();
  const { isLoading, pdfUrl, totalPages, handleLoadSuccess } =
    usePresentPresentationDocument(presentationId);

  const { pagesData, isPagesLoading, setPageMarkingsOverride } =
    useDynamicPresentationPages(presentationId);

  if (pagesData && pageIndex >= pagesData.length) {
    pageIndex = pagesData.length - 1;
  }

  const { tempMarkings, handleTempMarkingAction } = useTempMarkings({ pageIndex });

  const [tempPageInfo, setTempPageInfo] = useState<
    { caseDocumentId: string; pageIndex: number } | undefined
  >();

  const {
    isLoading: isLoadingTemp,
    pdfUrl: pdfUrlTemp,
    title: filename
  } = usePresentCaseDocument(tempPageInfo?.caseDocumentId, tempPageInfo?.pageIndex);

  const { postMessage } = useBroadcastChannel<PresentationAction>({
    name: PRESENTATION_BROADCAST_CHANNEL,
    onMessage: ({ data: action }) => {
      switch (action.type) {
        case PresentationActionType.Close:
          window.close();
          break;
        case PresentationActionType.PageChange:
          goToPage(action.pageIndex, false);
          setZoomArea(undefined);
          break;
        case PresentationActionType.MarkingsChange:
          setPageMarkingsOverride(action.pageIndex, action.markingIds);
          break;
        case PresentationActionType.Refresh:
          dispatch(
            baseApi.util.invalidateTags([
              apiTags.casePresentation,
              apiTags.casePresentationPdfFilesUrl,
              apiTags.casePresentationPage,
              apiTags.markings
            ])
          );
          break;
        case PresentationActionType.ZoomChange:
          setZoomArea(action.area);
          break;
        case PresentationActionType.PointerPositionChange:
          // control pointer element trough ref, because state can't handle so frequent updates
          if (pointer.current) {
            if (action.position) {
              const scale = parseFloat(pointer.current.dataset.scale ?? "1");
              pointer.current.style.display = "block";
              pointer.current.style.left = action.position.x * scale - pointerRadius + "px";
              pointer.current.style.top = action.position.y * scale - pointerRadius + "px";
            } else {
              pointer.current.style.display = "";
              pointer.current.style.left = "";
              pointer.current.style.top = "";
            }
          }
          break;
        case PresentationActionType.TempMarkingChange:
          handleTempMarkingAction(action.action);
          break;
        case PresentationActionType.ShowTempPage:
          setTempPageInfo({
            caseDocumentId: action.action.caseDocumentId,
            pageIndex: action.action.pageIndex
          });
          break;
        case PresentationActionType.StopShowTempPage:
          setTempPageInfo(undefined);
          break;
      }
    }
  });
  const { goToPage } = usePresentNavigation({
    currentPageIndex: pageIndex,
    totalPages,
    isBigScreen: true,
    broadcastPageChange: postMessage
  });

  return tempPageInfo ? (
    <PresentPage
      documentId={tempPageInfo.caseDocumentId}
      pdfUrl={pdfUrlTemp}
      pageIndex={0}
      pageIndexDisplay={tempPageInfo.pageIndex}
      pageData={{
        markingIds: [],
        hasDocumentMarkings: false,
        fileName: filename ?? "",
        isTitlePage: false
      }}
      isLoading={isLoadingTemp}
      pageOverlay={(scale) => (
        <LaserPointer ref={pointer} className={styles.laserPointer} data-scale={scale} />
      )}
    />
  ) : (
    <PresentPage
      documentId={presentationId}
      pdfUrl={pdfUrl}
      pageIndex={pageIndex}
      zoomArea={zoomArea}
      pageData={pagesData?.[pageIndex]}
      isLoading={isLoading || isPagesLoading}
      onLoadSuccess={handleLoadSuccess}
      pageOverlay={(scale) => (
        <LaserPointer ref={pointer} className={styles.laserPointer} data-scale={scale} />
      )}
      tempMarkings={tempMarkings}
    />
  );
};

export default PresentBigScreen;
