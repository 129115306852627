import { ReactNode, useEffect, useRef, useState } from "react";
import Card from "@components/embla/card";
import styles from "./expandableCard.module.scss";

interface ExpandableCardProps {
  header: ReactNode;
  children: ReactNode;
  duration?: number;
  defaultExpanded?: boolean;
}

/**
 * Expandable card that animates height property while expanding/collapsing.
 */
const ExpandableCard = ({
  header,
  children,
  duration = 300,
  defaultExpanded = false
}: ExpandableCardProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [renderContent, setRenderContent] = useState(defaultExpanded);
  const [height, setHeight] = useState<number | undefined>(0);
  const ref = useRef<HTMLDivElement>(null);

  const toggle = () => {
    if (!expanded) {
      // render content right away to get height to expand to in effect
      setRenderContent(true);
    } else {
      // set fixed height before collapse animation starts
      setHeight(ref.current?.scrollHeight);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    setHeight(expanded ? ref.current?.scrollHeight : 0);
    const afterAnimation = setTimeout(() => {
      if (expanded) {
        // remove fixed height after expand animation finishes
        // so if content changes - div would too.
        setHeight(undefined);
      } else {
        // remove rendered content after collapse animation finishes
        setRenderContent(false);
      }
    }, duration);
    return () => clearTimeout(afterAnimation);
  }, [expanded, duration]);

  return (
    <Card>
      <Card.Body onClick={toggle} role="button">
        {header}
      </Card.Body>
      <div
        ref={ref}
        className={styles.expandable}
        style={{ height, transitionDuration: `${duration}ms` }}
      >
        <Card.Body className="pt-0">{renderContent && children}</Card.Body>
      </div>
    </Card>
  );
};

export default ExpandableCard;
