import classNames from "classnames";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { Tooltip } from "@components/tooltip/tooltip";
import { Key } from "@infrastructure/Key";
import useShortcut from "src/hooks/useShortcut";
import FormattedInput, { ParseError } from "../../pages/pdfviewer/component/toolbar/FormattedInput";
import styles from "./pagesControl.module.scss";

interface PagesControlProps {
  disabled?: boolean;
  className?: string;
  currentPageIndex: number;
  totalPages: number;
  alternativeDirections?: boolean;
  onPageChange: (pageIndex: number) => void;
}

const PagesControl = ({
  disabled,
  className,
  currentPageIndex,
  totalPages,
  alternativeDirections,
  onPageChange
}: PagesControlProps) => {
  const localizer = useLocalization();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputSize, setInputSize] = useState<number>();

  const previousLabel = `${localizer.previous()} (Page Up)`;
  const goToLabel = `${localizer.goToPage()} (G)`;
  const nextLabel = `${localizer.next()} (Page Down)`;

  const formatDisplayValue = useCallback(
    (value: number) => {
      return totalPages ? `${value} ${localizer.pdfToolbarOf()} ${totalPages}` : "";
    },
    [localizer, totalPages]
  );

  useEffect(() => {
    setInputSize(formatDisplayValue(currentPageIndex).length);
  }, [currentPageIndex, formatDisplayValue]);

  const parseInput = useCallback(
    (inputValue: string) => {
      const parsed = Number.parseInt(inputValue);
      if (Number.isNaN(parsed)) {
        throw new ParseError();
      }
      return Math.min(totalPages, Math.max(1, parsed));
    },
    [totalPages]
  );

  const onInputChange = (pageNumber: number) => {
    setInputSize(formatDisplayValue(pageNumber).length);
    onPageChange(pageNumber - 1);
  };

  const onInputClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  const nextPage = () => onPageChange(currentPageIndex + 1);
  const previousPage = () => onPageChange(currentPageIndex - 1);

  useShortcut({ key: Key.PageUp, callback: previousPage });
  useShortcut({ key: Key.PageDown, callback: nextPage });
  useShortcut({ key: "g", callback: () => inputRef.current?.select() });

  return (
    <div className={classNames("input-group", "flex-nowrap", className)}>
      <div className="input-group-prepend">
        <Tooltip message={previousLabel} placement="bottom">
          <button
            disabled={disabled || currentPageIndex === 0}
            type="button"
            className="btn btn-default btn-icon"
            aria-label={previousLabel}
            onClick={previousPage}
          >
            <EmblaIcon
              iconName={alternativeDirections ? "arrow-left" : "arrow-up"}
              color={IconColor.DarkGray}
            />
          </button>
        </Tooltip>
      </div>
      <Tooltip message={goToLabel} placement="bottom">
        <FormattedInput
          ref={inputRef}
          disabled={disabled}
          className={classNames(styles.input, "form-control", "pl-2", "text-center")}
          value={currentPageIndex + 1}
          onChange={onInputChange}
          onClick={onInputClick}
          formatDisplayValue={formatDisplayValue}
          size={inputSize}
          parseValue={parseInput}
        />
      </Tooltip>
      <div className="input-group-append">
        <Tooltip message={nextLabel} placement="bottom">
          <button
            disabled={disabled || currentPageIndex + 1 >= totalPages}
            type="button"
            className="btn btn-default btn-icon"
            aria-label={nextLabel}
            onClick={nextPage}
          >
            <EmblaIcon
              iconName={alternativeDirections ? "arrow-right" : "arrow-down"}
              color={IconColor.DarkGray}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PagesControl;
