import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@app";
import { CaseCreateModel } from "@services/api/case/models/caseModel";
import { CaseCreationStep } from "./createCase";

export interface CreateCaseState {
  currentStep: CaseCreationStep;
  caseModel: CaseCreateModel;
}

const initialState: CreateCaseState = {
  currentStep: CaseCreationStep.Summary,
  caseModel: {} as CaseCreateModel
};

export const createCaseSlice = createSlice({
  name: "createCase",
  initialState,
  reducers: {
    stepForward: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    stepBack: (state) => {
      state.currentStep = state.currentStep - 1;
    }
  }
});

export const { stepForward, stepBack } = createCaseSlice.actions;
export const createCaseStateSelector = (state: RootState) => state.createCase;
export default createCaseSlice.reducer;
