import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import ReactDOM from "react-dom";
import Modal, { useModalContext } from "@components/modal/modal";
import { useLocalization } from "@components/localization/localizationProvider";
import Button from "@components/embla/button";
import { DropSelectFiles } from "@pages/case/documents/addDocuments/uploadDocuments/dropSelectFiles";
import { DocumentUploadRow } from "./documentUploadRow";
import styles from "./uploadDocuments.module.scss";

export interface UploadDocumentsProps {
  caseId: string;
  setIsInProgressOfUploadingFiles: (isInProgress: boolean) => void;
  actionsContainer?: Element | null;
}

type FileToUpload = {
  file: File;
  id: string;
  finished: boolean;
  failed: boolean;
  canceled: boolean;
  beginUpload: boolean;
};

export const UploadDocuments: React.FC<UploadDocumentsProps> = (props: UploadDocumentsProps) => {
  const localizer = useLocalization();
  const { close } = useModalContext();
  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
  const { setIsInProgressOfUploadingFiles } = props;
  const [currentFilesInProgress, setCurrentFilesInProgress] = useState<number>(0);
  const [currentFileQueueIndex, setCurrentFileQueueIndex] = useState<number>(0);
  const maxFilesInProgress = 3;

  const handleNewFilesChosenByDialog = async (files: FileList) => {
    if (files?.length > 0) {
      const newFiles: File[] = [];

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }

      if (newFiles.some((fl) => fl.type !== "application/pdf")) {
        NotificationModule.showErrorSmall(localizer.uploadFilesHint2());
        return;
      }

      await handleNewFiles(newFiles);
    }
  };

  const handleNewFiles = async (newFiles: File[]) => {
    const newFilesWithIds = newFiles.map((nf) => {
      return {
        file: nf,
        id: uuid(),
        finished: false,
        failed: false,
        canceled: false,
        beginUpload: false
      };
    });

    setFilesToUpload((prevState) => [...prevState, ...newFilesWithIds]);
  };

  const setUploadFile = useCallback((fileId: string, fileToUpload: Partial<FileToUpload>) => {
    setFilesToUpload((prev) =>
      prev.map((f) => {
        if (f.id === fileId) {
          return { ...f, ...fileToUpload };
        } else {
          return f;
        }
      })
    );
  }, []);

  const updateUploadQueueOnFinished = () => {
    if (currentFilesInProgress > 0) {
      setCurrentFilesInProgress(currentFilesInProgress - 1);
    }
  };

  const onUploadFinished = useCallback(
    (fileId: string) => {
      setUploadFile(fileId, { finished: true, failed: false });
      updateUploadQueueOnFinished();
    },
    [setUploadFile]
  );

  const onUploadFailed = useCallback(
    (fileId: string) => setUploadFile(fileId, { finished: false, failed: true }),
    [setUploadFile]
  );
  const onUploadReset = useCallback(
    (fileId: string) => setUploadFile(fileId, { finished: false, failed: false }),
    [setUploadFile]
  );
  const onCancelUpload = useCallback(
    (fileId: string) => setUploadFile(fileId, { finished: false, failed: false, canceled: true }),
    [setUploadFile]
  );

  const OnCurrentFilesInProgressCallback = useCallback(() => {
    setCurrentFilesInProgress(currentFilesInProgress - 1);
  }, [currentFilesInProgress]);

  useEffect(() => {
    const isInProgress =
      filesToUpload && filesToUpload.some((f) => !f.finished && !f.failed && !f.canceled);
    setIsInProgressOfUploadingFiles(isInProgress);
  }, [filesToUpload, setIsInProgressOfUploadingFiles]);

  useEffect(() => {
    const beginNextFileUpload = () => {
      if (
        currentFileQueueIndex < filesToUpload.length &&
        currentFilesInProgress < maxFilesInProgress
      ) {
        filesToUpload[currentFileQueueIndex].beginUpload = true;
        setCurrentFileQueueIndex(currentFileQueueIndex + 1);
        setCurrentFilesInProgress(currentFilesInProgress + 1);
      }
    };
    beginNextFileUpload();
  }, [currentFileQueueIndex, currentFilesInProgress, filesToUpload]);

  return (
    <div className="margin-top-m">
      <div className="subtle">{localizer.uploadFilesDescription1()}</div>
      <div className="subtle">{localizer.uploadFilesDescription2()}</div>

      <DropSelectFiles
        filesDroppedCallback={handleNewFiles}
        filesSelectedCallback={handleNewFilesChosenByDialog}
      />

      <div className={classNames("margin-top-l")}>
        {filesToUpload.length > 0 && (
          <>
            <h4 className="mb-3">{localizer.filesAdded()}</h4>
            <div
              className={classNames(
                styles.columHeaderContainer,
                "d-flex gap-s justify-content-start "
              )}
            >
              <h5 className={styles.columnHeader}>{localizer.documentName()}</h5>
              <h5 className={styles.columnHeader}>{localizer.exhibitNumber()}</h5>
              <h5 className={styles.columnHeader}>{localizer.documentType()}</h5>
              <h5 className={styles.columnHeader}>{localizer.timeStamp()}</h5>
            </div>
          </>
        )}
        <div className="margin-top-s files-uploaded">
          {filesToUpload.map(
            (fileDocument, i) =>
              !fileDocument.canceled && (
                <div key={fileDocument.id}>
                  <DocumentUploadRow
                    uploadFinishedCallback={onUploadFinished}
                    uploadFailedCallback={onUploadFailed}
                    uploadResetCallback={onUploadReset}
                    uploadCancelCallback={onCancelUpload}
                    onCurrentFilesInProgressCallback={OnCurrentFilesInProgressCallback}
                    caseId={props.caseId}
                    fileId={fileDocument.id}
                    file={fileDocument.file}
                    index={i}
                    key={fileDocument.id}
                    beginUpload={fileDocument.beginUpload}
                  />
                </div>
              )
          )}
        </div>
      </div>
      {props.actionsContainer &&
        ReactDOM.createPortal(
          <Modal.Footer>
            {({ locked }) => (
              <Button onClick={close} disabled={locked}>
                {localizer.close()}
              </Button>
            )}
          </Modal.Footer>,
          props.actionsContainer
        )}
    </div>
  );
};
