import { useState } from "react";
import Resizable, { Dimensions, DimensionsInput } from "@components/resizable/resizable";
import styles from "./liveZoom.module.scss";

interface LiveZoomProps {
  scale: number;
  onZoomSelect: (area?: Dimensions) => void;
}

const minAreaSize = 20;

const LiveZoom = ({ scale, onZoomSelect }: LiveZoomProps) => {
  const [zoomArea, setZoomArea] = useState<DimensionsInput>();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setZoomArea({
      x: e.nativeEvent.offsetX / scale,
      y: e.nativeEvent.offsetY / scale
    });
  };

  const handleSelection = ({ x, y, width, height }: Dimensions) => {
    const nonScaled: Dimensions | undefined =
      height > minAreaSize && width > minAreaSize
        ? {
            x: x / scale,
            y: y / scale,
            height: height / scale,
            width: width / scale
          }
        : undefined;
    setZoomArea(nonScaled);
    onZoomSelect?.(nonScaled);
  };

  const scaledArea = zoomArea && {
    x: zoomArea.x * scale,
    y: zoomArea.y * scale,
    height: zoomArea.height && zoomArea.height * scale,
    width: zoomArea.width && zoomArea.width * scale
  };

  return (
    <div className={styles.container} onMouseDown={handleMouseDown}>
      {scaledArea && (
        <Resizable
          active
          notClickable
          key={`${zoomArea.x}${zoomArea.y}`}
          dimensions={scaledArea}
          onResize={handleSelection}
        />
      )}
    </div>
  );
};

export default LiveZoom;
