import * as yup from "yup";
import { Assign, ObjectShape, TypeOfShape } from "yup/lib/object";

type CustomYupObjectShape<T> = ObjectShape & { [P in keyof T]: unknown };
export type CreateValidationSchemaReturnType<T> = yup.ObjectSchema<
  Assign<ObjectShape, CustomYupObjectShape<T>>,
  Record<string, unknown>,
  TypeOfShape<Assign<ObjectShape, CustomYupObjectShape<T>>>
>;

export const createValidationSchema = <T>(yupShape: CustomYupObjectShape<T>) =>
  yup.object().shape(yupShape);
export const extendValidationSchema = <T, R extends T>(
  base: CreateValidationSchemaReturnType<T>,
  newKeys: CustomYupObjectShape<Omit<R, keyof T>>
) => base.shape(newKeys);

/**
 * YUP validator for fields with object values (i.e. dropdown with such values as "{id: string, name: string}").
 * It assigns first occurred error for provided shape as error of current field instead of deep path to exact object key that failed.
 * @param yupShape shape to test field value with
 */
export const testValueShape = <T>(yupShape: CustomYupObjectShape<T>) => {
  return yup.object().test({
    name: "value-shape",
    test: (value, { createError, path }) =>
      yup
        .object(yupShape)
        .validate(value)
        .then(() => true)
        // map deeper validation error to current path
        .catch((e: yup.ValidationError) => createError({ path, message: e.message, type: e.type }))
  });
};

/**
 * YUP validator for fields with array values (i.e. multi select dropdown).
 * It assigns first occurred error in array as error of current field instead of deep path to exact array index that failed.
 * @param schema schema to test array values
 */
export const testValueArray = (schema: yup.AnySchema) => {
  return yup.array().test({
    name: "value-array",
    test: (value, { createError, path }) =>
      yup
        .array(schema)
        .validate(value)
        .then(() => true)
        // map deeper validation error to current path
        .catch((e: yup.ValidationError) => createError({ path, message: e.message, type: e.type }))
  });
};
