import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { CasePresentationModel } from "@components/case/presentation/casePresentationModel";
import { LengthValidations } from "src/utility/constants";
import { createValidationSchema } from "../createValidationSchema";

export const presentationValidation = (localizer: Localizer) =>
  createValidationSchema<CasePresentationModel>({
    title: yup
      .string()
      .required(localizer.presentationNameIsRequired())
      .max(LengthValidations.Length150, localizer.maxLength150)
  });
