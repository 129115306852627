import { ChangeEvent } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

type UseCheckedStateProps<T extends FieldValues = FieldValues> = {
  field: ControllerRenderProps<T, Path<T>>;
  checkboxValue?: string | number | readonly string[];
};

// For easy reuse across formCheckbox and formSwitch, for working with number
const useCheckedState = <T extends FieldValues>({
  field,
  checkboxValue
}: UseCheckedStateProps<T>) => {
  const isChecked = Array.isArray(field.value) ? field.value.includes(checkboxValue) : field.value;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(field.value)) {
      field.onChange(
        e.target.checked
          ? [...field.value, checkboxValue]
          : field.value.filter((v: number) => v !== checkboxValue)
      );
    } else {
      field.onChange(e.target.checked);
    }
  };

  return {
    isChecked,
    onChange
  };
};

export default useCheckedState;
