import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { createVerticalStrength, useDndScrolling } from "react-dnd-scrolling";
import { debounce } from "lodash";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import EditPresentationTitlePage from "@components/case/presentationPage/editPresentationTitlePage";
import { useLocalization } from "@components/localization/localizationProvider";
import PagesControl from "@components/pagesControl/pagesControl";
import Toolbar from "@components/toolbar/toolbar";
import { Key } from "@infrastructure/Key";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import PresentPage from "@pages/present/presentPage";
import useShortcut from "src/hooks/useShortcut";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { RoutePaths } from "@components/routing/routes";
import { presentationsTabId } from "@pages/case/casePage";
import { PresentationPageFileUrlModel } from "@services/api/case/models/PresentationFileUrlViewModel";
import {
  CasePresentationPageModel,
  CasePresentationPageNoteModel
} from "@services/api/case/models/casePresentationPageModel";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { AddButton } from "@components/button/addButton/addButton";
import {
  useChangePageOrientationMutation,
  useMovePresentationNoteMutation
} from "@services/api/casePresentation/casePresentationApi";
import { PresentationSpeakerNoteCreateEditCard } from "@pages/case/presentations/editPresentationPages/presentationCrud/presentationSpeakerNoteCreateEditCard";
import Card from "@components/embla/card";
import Button from "@components/embla/button";
import { ReactComponent as FlipIcon } from "@content/icons/flip-down.svg";
import { dndItemTypes } from "@pages/extractCompositionPage/components/ExtractSections/DndItemTypes";
import { Tooltip } from "@components/tooltip/tooltip";
import MarkdownRenderer from "@components/markdown/markdownRenderer";
import MarkdownSizeButtons from "@components/markdown/markdownSizeButtons";
import { useConfig } from "@components/config/configProvider";
import DragNDropIndicator, {
  DragNDropIndicatorPosition
} from "@components/dnd/DragNDropIndicator/DragNDropIndicator";
import { useGetPresentationPagesQuery } from "@services/api/presentation/presentationApi";
import DragLayer from "@components/dnd/DragNDropIndicator/DragLayer/DragLayer";
import SinglePagePdfView from "@pages/pdfviewer/component/SinglePagePdfView/SinglePagePdfView";
import NewMarkingsPageOverlay from "@pages/pdfviewer/component/page/canvas/MarkingsPageOverlay/MarkingsPageOverlay";
import { PdfType, setTotalPages } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useAppDispatch } from "@hooks";
import BottomBar from "@components/bottomBar/bottomBar";
import usePageMarkings from "@pages/pdfviewer/component/page/canvas/MarkingsPageOverlay/hooks/usePageMarkings";
import useElectronApi from "../../../../hooks/useElectronApi";
import { usePresentationPageContext } from "../presentationPageContext/PresentationPageContextProvider";
import styles from "./editPresentationPages.module.scss";
import MarkingsSidebar from "./MarkingsSidebar/MarkingsSidebar";
import { MarkingsPageProvider } from "./MarkingsPageContext/MarkingsPageContext";

type EditPresentationPagesProps = {
  presentationId: string;
  pageId: string;
  caseId: string;
  pageFile: PresentationPageFileUrlModel;
  pageData: CasePresentationPageModel;
  data: CasePresentationPageModel[];
  title: string | undefined;
  present: boolean;
};

const EditPresentationPages = ({
  presentationId,
  pageId,
  caseId,
  pageFile,
  pageData,
  data,
  title,
  present
}: EditPresentationPagesProps) => {
  const config = useConfig();

  const [previewMode, setPreviewMode] = useState(false);
  const [showDrawer, setShowDrawer] = useState(true);
  const [redirectAppUrl, setRedirectAppUrl] = useState<string>();
  const [editSpeechnoteState, setEditSpeechnoteState] = useState<
    CasePresentationPageNoteModel | undefined
  >();
  const [createSpeechnoteState, setCreateSpeechnoteState] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const localizer = useLocalization();
  const electronApi = useElectronApi();

  const {
    setTotalPresentationPages,
    setMostVisiblePageIndex,
    setScale,
    scale,
    mostVisiblePageIndex
  } = usePresentationPageContext();
  const dispatch = useAppDispatch();
  const notesContainerRef = useRef<HTMLDivElement>(null);

  const [changeOrientation, { isLoading: isLoadingChangeOrientation }] =
    useChangePageOrientationMutation();

  const sortedNotes = useMemo(
    () => [...pageData.notes].sort((a, b) => a.order - b.order),
    [pageData.notes]
  );

  const { markings: allPageMarkings } = usePageMarkings({
    pageIndex: pageData.order,
    pdfType: PdfType.Presentation,
    documentId: presentationId
  });

  const presentationInfo: PresentationInfo = useMemo(
    () => ({ id: presentationId, title: title ?? "" }),
    [presentationId, title]
  );

  useShortcut({ key: Key.Escape, callback: () => previewMode && setPreviewMode(false) });

  const [reorderNote, { isLoading: isLoadingReorder }] = useMovePresentationNoteMutation();
  const { isFetching: isFetchingPresentation } = useGetPresentationPagesQuery(presentationId);

  const linearVerticalStrength = createVerticalStrength(150);
  useDndScrolling(notesContainerRef, { verticalStrength: linearVerticalStrength });

  // Used when deeplink to elektron app and we want the presentation to start automaticaly
  useEffect(() => {
    if (electronApi && present) {
      electronApi.present(presentationInfo.id);
    }
  }, [electronApi, present, presentationInfo.id]);

  useEffect(() => {
    setRedirectAppUrl(
      `${config.appProtocol}:/${RoutePaths.casePresentationEditPresent.url(caseId, presentationId, pageId)}`
    );
  }, [caseId, config.appProtocol, pageId, presentationId]);

  if (!data || !pageData || !pageFile) {
    return null;
  }

  const changePage = (newPageIndex: number) => {
    const newPage = data.find(({ order }) => order === newPageIndex);
    if (newPage) {
      navigate(`../edit/${newPage.id}`, { replace: true });
    }
  };

  const onPageRemoved = (removedPageId: string) => {
    if (pageId === removedPageId) {
      if (data.length === 1) {
        navigate(RoutePaths.caseDetails.url(caseId, presentationsTabId));
      } else {
        const current = pageData.order;
        changePage(current >= data.length - 1 ? current - 1 : current + 1);
      }
    }
  };

  const reorder = async (
    droppedNote: CasePresentationPageNoteModel,
    position: DragNDropIndicatorPosition,
    droppedIndex?: number
  ) => {
    if (
      droppedNote.order === droppedIndex ||
      droppedIndex === undefined ||
      (droppedNote.order === droppedIndex + 1 && position === "below")
    ) {
      return;
    }
    const model = {
      newSortOrder: droppedIndex,
      noteId: droppedNote.id
    };

    if (droppedNote.order < droppedIndex && position === "above") {
      model.newSortOrder -= 1;
    }

    reorderNote({
      caseId,
      presentationId: presentationInfo.id,
      presentationPageId: pageData.id,
      model
    });
  };

  const handleLoadSuccess = (pdf: DocumentCallback) => {
    setTotalPresentationPages(pdf.numPages);
    dispatch(setTotalPages(pdf.numPages));
  };

  if (previewMode) {
    return (
      <PresentPage
        documentId={presentationId}
        pdfUrl={pageFile.downloadUrl}
        pageIndex={0}
        onLoadSuccess={handleLoadSuccess}
        pageData={pageData}
        isLoading={false}
        onClick={() => setPreviewMode(false)}
      />
    );
  }

  const onChangeOrientation = debounce(() => {
    const newOrientation = !pageData.orientation
      ? 90
      : pageData.orientation === 270
        ? 0
        : pageData.orientation + 90;
    changeOrientation({
      caseId: caseId,
      presentationId: presentationInfo.id,
      presentationPageId: pageData.id,
      model: { orientation: newOrientation }
    }).unwrap();
  }, 200);

  const handleBackAction = () => {
    const query = new URLSearchParams(location.search);
    const back = query.get("back");

    if (back === "doc") {
      navigate(-1);
      return "";
    }

    return RoutePaths.casePresentation.url(caseId, presentationId);
  };

  return (
    <div className={classNames("d-flex flex-column", styles.container)}>
      <Toolbar
        backAction={handleBackAction}
        subTitle={
          pageData && !pageData.isTitlePage
            ? `${localizer.pdfPage()} ${pageData.pageIndex + 1}`
            : undefined
        }
        title={pageData.isTitlePage ? localizer.presentationTitlePage() : pageData.fileName}
      >
        <Toolbar.Item>
          <div className="py-1">
            <Tooltip message={allPageMarkings.length > 0 && localizer.cantRotatePageWhenMarkings()}>
              <Button
                borderless
                className={classNames("btn btn-default btn-icon")}
                disabled={
                  allPageMarkings.length > 0 || isLoadingChangeOrientation || isFetchingPresentation
                }
                onClick={onChangeOrientation}
              >
                <FlipIcon />
              </Button>
            </Tooltip>
          </div>
        </Toolbar.Item>
        <Toolbar.Item>
          <div className="py-1">
            <InlineSwitch
              id="show-overview"
              checked={showDrawer}
              onChange={(e) => setShowDrawer(e.currentTarget.checked)}
            />
            <label htmlFor="show-overview">{localizer.showDrawer()}</label>
          </div>
        </Toolbar.Item>
        <Toolbar.Item label={localizer.steps()}>
          <PagesControl
            currentPageIndex={pageData.order}
            totalPages={data.length}
            onPageChange={changePage}
            alternativeDirections
          />
        </Toolbar.Item>
      </Toolbar>
      <div className="flex-1 container-fluid overflow-hidden">
        <div className="row h-100">
          <MarkingsPageProvider>
            <MarkingsSidebar
              documentId={presentationId}
              caseId={caseId}
              pageId={pageData.id}
              additionalClasses="col-3"
              isDrawerOpen={showDrawer}
              pageIndex={mostVisiblePageIndex}
            />
            <div className="col-6 d-none d-sm-block flex-shrink-0">
              {pageFile && (
                <NewMarkingsPageOverlay
                  disableEditing
                  pageIndex={pageData.order}
                  scale={scale}
                  documentId={presentationId}
                  pageNumber={pageData.pageNumber}
                >
                  <SinglePagePdfView
                    pdfUrl={pageFile?.downloadUrl}
                    docPageIndex={pageData.order}
                    pdfPageIndex={0}
                    pageMargins={16}
                    orientation={pageData.orientation}
                    onDocumentLoadSuccess={handleLoadSuccess}
                    setScale={setScale}
                    setMostVisiblePageIndex={setMostVisiblePageIndex}
                  />
                </NewMarkingsPageOverlay>
              )}
            </div>
          </MarkingsPageProvider>
          <div
            className={classNames(
              "col pt-3 h-100 overflow-hidden shadow-2",
              styles.speakerNotesContainer
            )}
          >
            {pageData.isTitlePage && (
              <div className="mb-5">
                <h3>{localizer.presentationTitlePage()}</h3>

                <EditPresentationTitlePage
                  caseId={caseId}
                  presentationId={presentationId}
                  presentationPageId={pageId}
                  titlePageHeader={pageData.titlePageTitle}
                  titlePageContentText={pageData.titlePageDescription}
                />
              </div>
            )}

            <div className="d-flex justify-content-between mb-3">
              <h3>
                {localizer.speakerNotes()} <EmblaIcon iconName={"info"} />
              </h3>
              <span className="d-flex gap-s align-items-center">
                <MarkdownSizeButtons
                  presentationId={presentationInfo.id}
                  presentationPageId={pageData.id}
                  caseId={caseId}
                  noteSize={pageData.noteSize}
                />
                <AddButton
                  fixedPosition={false}
                  defaultSize
                  onClick={() => {
                    setCreateSpeechnoteState(true);
                    setEditSpeechnoteState(undefined);
                  }}
                />
              </span>
            </div>

            <div
              className={classNames(
                createSpeechnoteState
                  ? styles.speakerNoteCreateCardVisible
                  : styles.speakerNoteCreateCardHidden
              )}
            >
              <PresentationSpeakerNoteCreateEditCard
                presentationId={presentationInfo.id}
                pageId={pageId}
                caseId={caseId}
                key={pageData.id}
                note={editSpeechnoteState}
                onActionFinishedCallback={() => setCreateSpeechnoteState(false)}
                onDiscardCallback={() => setCreateSpeechnoteState(false)}
                index={0}
              />
            </div>
            <DndProvider backend={HTML5Backend}>
              <div
                ref={notesContainerRef}
                className={classNames(
                  "scrollbar overflow-auto",
                  styles.speakerNoteContainer,
                  (isFetchingPresentation || isLoadingReorder) && styles.disabled
                )}
              >
                {pageData?.notes.length > 0 ? (
                  sortedNotes.map((note, index) => (
                    <DragNDropIndicator
                      disableDrag={!!editSpeechnoteState}
                      droppedIndex={index}
                      item={note}
                      onDrop={reorder}
                      dndType={dndItemTypes.speakerNote}
                      key={note.id}
                    >
                      {editSpeechnoteState?.id === note.id ? (
                        <PresentationSpeakerNoteCreateEditCard
                          key={note.id}
                          presentationId={presentationInfo.id}
                          pageId={pageId}
                          caseId={caseId}
                          note={note}
                          onActionFinishedCallback={() => setEditSpeechnoteState(undefined)}
                          onDiscardCallback={() => setEditSpeechnoteState(undefined)}
                          index={index + 1}
                        />
                      ) : (
                        <Card
                          className="my-2"
                          onClick={() => {
                            setEditSpeechnoteState(note);
                            setCreateSpeechnoteState(false);
                          }}
                          role={"button"}
                        >
                          <Card.Body>
                            <MarkdownRenderer size={pageData.noteSize}>
                              {note.note}
                            </MarkdownRenderer>
                          </Card.Body>
                        </Card>
                      )}
                    </DragNDropIndicator>
                  ))
                ) : (
                  <div className="subtle">
                    <p>{localizer.presentationEditSpeechNotesHint1()}</p>
                    <p>{localizer.presentationEditSpeechNotesHint2()}</p>
                  </div>
                )}
              </div>
            </DndProvider>
          </div>
        </div>
      </div>
      {showDrawer && (
        <BottomBar
          onPageChanged={(pageIndex) => setMostVisiblePageIndex(pageIndex)}
          activePresentation={presentationInfo}
          activePageId={pageId}
          redirectAppUrl={redirectAppUrl}
          onPageRemoved={onPageRemoved}
          presentationMode
        />
      )}
      <DragLayer
        acceptedDndTypes={[dndItemTypes.speakerNote]}
        render={(item) => (
          <Card>
            <Card.Body>
              <p className="ellipsisContainer">{item.note}</p>
            </Card.Body>
          </Card>
        )}
      />
    </div>
  );
};

export default EditPresentationPages;
