import { Link } from "react-router-dom";
import { useAppSelector } from "@hooks";
import sprite from "@content/icons/sprite.symbol.svg";
import BackButton from "@components/button/BackButton";
import { titlebarStateSelector } from "./titlebarSlice";

export const TitleBar = (props: { simple: boolean }) => {
  const titleBar = useAppSelector(titlebarStateSelector);

  const renderArrow = () => {
    return (
      <span>
        <svg
          className="embla-icon breadcrumb-arrow"
          aria-hidden="true"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href={`${sprite}#arrow-right`} />
        </svg>
      </span>
    );
  };

  const renderBreadcrumbs = () => {
    if (titleBar.breadcrumbs.some((breadcrumb) => breadcrumb.text)) {
      return titleBar.breadcrumbs.map((breadcrumb) => {
        if (breadcrumb.url) {
          return (
            <div key={breadcrumb.url} style={{ display: "inline" }}>
              <Link to={breadcrumb.url} className="breadcrumb-title">
                {breadcrumb.text}
              </Link>

              {titleBar.breadcrumbs.indexOf(breadcrumb) < titleBar.breadcrumbs.length - 1 &&
                renderArrow()}
            </div>
          );
        }

        return (
          <div key={breadcrumb.text} className="breadcrumb-title" style={{ display: "inline" }}>
            {breadcrumb.text}
            {titleBar.breadcrumbs.indexOf(breadcrumb) < titleBar.breadcrumbs.length - 1 &&
              renderArrow()}
          </div>
        );
      });
    }

    return null;
  };

  const renderActions = () => {
    if (titleBar.actions.some((action) => action.text)) {
      const actions = titleBar.actions.map((action) => {
        if (action.url) {
          return (
            <div key={action.url} style={{ display: "inline" }}>
              <Link to={action.url} className={`btn btn-${action.type}`}>
                {action.text}
              </Link>
            </div>
          );
        }

        return null;
      });

      return <div className="title-bar-actions">{actions}</div>;
    }
    return null;
  };

  if (props.simple) {
    return (
      <div className="title-bar-container">
        {titleBar.withBackButton && <BackButton className="margin-right-s" />}
        <h1 className="text-truncate">{titleBar.title}</h1>
        {renderActions()}
      </div>
    );
  }

  return (
    <div className="title-bar">
      <div className="breadcrumbs-container">{renderBreadcrumbs()}</div>

      <div className="title-bar-header">
        <div className="title-bar-header-text">
          <h2>{titleBar.title}</h2>
        </div>

        {renderActions()}
      </div>
    </div>
  );
};
