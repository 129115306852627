import { MouseEvent, useMemo } from "react";
import { useParams } from "react-router-dom";
import ClampedParagraph from "@components/clampedParagraph/clampedParagraph";
import ShowMentions from "@components/mention/showMentions";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAddPresentationPageNoteMutation } from "@services/api/casePresentation/casePresentationApi";
import { StatusType } from "@components/embla/statusIcon";
import { CommentMarkingModel, ReplyModel } from "../../../models/markingModel";
import MarkingPreview from "../shared/markingPreview";

interface CommentPreviewProps {
  marking: CommentMarkingModel;
  repliesOpen: boolean;
  onReplyCreateEdit?: (data: ReplyModel) => void;
  editMarkingClicked?: () => void;
}

const CommentPreview = ({
  marking,
  repliesOpen,
  onReplyCreateEdit,
  editMarkingClicked
}: CommentPreviewProps) => {
  const localizer = useLocalization();
  const { seqId, pageId, caseId } = useParams();

  const [addNote, addNoteRequest] = useAddPresentationPageNoteMutation();

  const handleToggle = (collapsed: boolean, e: MouseEvent) => {
    e.stopPropagation();
  };

  const createSpeechnoteFromComment = () => {
    if (seqId && pageId && caseId) {
      addNote({
        caseId: caseId,
        presentationId: seqId,
        presentationPageId: pageId,
        model: {
          note: marking.data.text
        }
      });
    }
  };

  const createSpeakerNoteStatus: StatusType | undefined = useMemo(() => {
    if (addNoteRequest.isLoading) return "loading";
    else if (addNoteRequest.isSuccess) return "success";
    else if (addNoteRequest.isError) return "error";

    return undefined;
  }, [addNoteRequest.isError, addNoteRequest.isLoading, addNoteRequest.isSuccess]);

  return (
    <MarkingPreview
      marking={marking}
      editMarkingClicked={editMarkingClicked}
      repliesOpen={repliesOpen}
      onReplyCreateEdit={onReplyCreateEdit}
    >
      <ClampedParagraph
        className="m-0"
        lineClamp={4}
        onToggle={handleToggle}
        isInitiallyClamped={!repliesOpen}
      >
        <ShowMentions content={marking.data.text} />
      </ClampedParagraph>
      {seqId && pageId && caseId && (
        <Button
          theme={"default"}
          className="mt-4"
          onClick={createSpeechnoteFromComment}
          disabled={addNoteRequest.isLoading}
          status={createSpeakerNoteStatus}
        >
          {localizer.createAsSpeakerNote()}
        </Button>
      )}
    </MarkingPreview>
  );
};

export default CommentPreview;
