import Modal, { ModalSizeEnum } from "@components/modal/modal";
import SavePresentationForOfflineUse from "./savePresentationForOfflineUse";

type SavePresentationForOfflineUseModalProps = {
  openModal: boolean;
  onCloseCallback: () => void;
  caseId: string;
  presentationId: string;
  presentationTitle: string;
  presentPresentation?: boolean;
};

const SavePresentationForOfflineUseModal = ({
  openModal,
  onCloseCallback,
  presentPresentation,
  presentationTitle,
  presentationId,
  caseId,
  ...sharedProps
}: SavePresentationForOfflineUseModalProps) => {
  const onCloseModal = () => {
    onCloseCallback();
  };

  return (
    <Modal open={openModal} onClose={onCloseModal} size={ModalSizeEnum.Large}>
      <SavePresentationForOfflineUse
        onBackClicked={onCloseModal}
        caseId={caseId}
        presentationId={presentationId}
        presentationTitle={presentationTitle}
      ></SavePresentationForOfflineUse>
    </Modal>
  );
};

export default SavePresentationForOfflineUseModal;
