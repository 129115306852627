import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef, Ref } from "react";
import styles from "./button.module.scss";
import StatusIcon, { StatusType } from "./statusIcon";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "default" | "primary" | "secondary" | "danger";
  linkBtn?: boolean;
  iconBtn?: boolean;
  status?: StatusType;
  borderless?: boolean;
  noTheme?: boolean;
  inverted?: boolean;
}

const Button = forwardRef(
  (
    {
      theme = "default",
      status,
      linkBtn,
      iconBtn,
      className,
      children,
      borderless,
      disabled,
      noTheme,
      inverted,
      ...htmlButtonProps
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled || status === "loading"}
        className={classNames(
          "btn",
          !noTheme && `btn-${theme}`,
          {
            "btn-inverted": inverted,
            "btn-link": linkBtn,
            "btn-icon": iconBtn,
            [styles.borderless]: borderless
          },
          styles.button,
          className
        )}
        {...htmlButtonProps}
      >
        {status && (
          <div className={styles.status}>
            <StatusIcon status={status} inverted={theme === "primary"} />
          </div>
        )}
        <span className={styles.content}>{children}</span>
      </button>
    );
  }
);

export default Button;
