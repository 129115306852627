import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { presentationValidation } from "@components/validation/sharedValidation/presentationValidation";
import { CreatePresentationsModel } from "@services/api/casePresentation/models/createPresentationsModel";

export const addPresentationsValidation = (localizer: Localizer) =>
  createValidationSchema<CreatePresentationsModel>({
    presentations: yup.array().of(presentationValidation(localizer))
  });
