import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { createContext, Fragment, HTMLAttributes, ReactNode, useContext } from "react";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import styles from "./modal.module.scss";

interface ChildProps {
  locked: boolean;
}

interface WithRequiredChildren extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ((props: ChildProps) => ReactNode);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ModalContext = createContext<{ close: () => void; locked: boolean } | null>(null);
export const useModalContext = () => {
  const ctx = useContext(ModalContext);
  if (!ctx) {
    throw new Error("ModalContext must be used in Modal only");
  }
  return ctx;
};

export interface ModalProps extends WithRequiredChildren {
  open: boolean;
  onClose: () => void;
  size?: ModalSizeEnum;
  type?: undefined | "original" | "message";
  locked?: boolean;
}

export enum ModalSizeEnum {
  Small = 1,
  Large = 2,
  ExtraLarge = 3,
  Undefined = 4
}

export const ConvertModalSizeToClass = (modalEnum: ModalSizeEnum) => {
  switch (modalEnum) {
    case ModalSizeEnum.Small:
      return "sm";
    case ModalSizeEnum.Large:
      return "lg";
    case ModalSizeEnum.ExtraLarge:
      return "xl";
    case ModalSizeEnum.Undefined:
      return "Undefined";

    default:
      throw new Error(
        `ModalSizeEnum ${modalEnum}  has not been handled in ConvertModalSizeToClass method`
      );
  }
};

const renderChildren = (children: ModalProps["children"], props: ChildProps) => {
  if (typeof children === "function") return children(props);

  return children;
};

const Modal = ({
  open,
  onClose,
  size,
  type,
  children,
  className,
  locked = false,
  ...divProps
}: ModalProps) => (
  <ModalContext.Provider value={{ close: () => onClose(), locked: locked }}>
    <Transition show={open} appear enterTo="show" leaveFrom="show" as={Fragment}>
      <Dialog
        onClose={!locked ? onClose : () => {}}
        className={classNames(
          "modal",
          "fade",
          "d-block",
          type && `modal-${type}`,
          className,
          styles.modal
        )}
        {...divProps}
      >
        <Transition.Child as={Fragment}>
          <Dialog.Panel
            className={classNames("modal-dialog", size && `modal-${ConvertModalSizeToClass(size)}`)}
          >
            <div className="modal-content">{children}</div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  </ModalContext.Provider>
);

const Header = ({ children, className, ...divProps }: WithRequiredChildren) => {
  const localizer = useLocalization();
  const context = useModalContext();

  return (
    <div className={classNames("modal-header", className)} {...divProps}>
      <Button
        iconBtn
        className="close"
        aria-label={localizer.close()}
        onClick={context?.close}
        disabled={context.locked}
      >
        <EmblaIcon iconName={"close"} />
      </Button>
      <Dialog.Title as="h4" className="modal-title">
        {renderChildren(children, { locked: context.locked })}
      </Dialog.Title>
    </div>
  );
};

const Body = ({ children, className, ...divProps }: WithRequiredChildren) => {
  const { locked } = useModalContext();

  return (
    <div className={classNames("modal-body", className)} {...divProps}>
      {renderChildren(children, { locked })}
    </div>
  );
};

const Footer = ({ children, className, ...divProps }: WithRequiredChildren) => {
  const { locked } = useModalContext();

  return (
    <div className={classNames("modal-footer", className)} {...divProps}>
      {renderChildren(children, { locked })}
    </div>
  );
};

/* eslint-disable @typescript-eslint/naming-convention */
export default Object.assign(Modal, {
  Header,
  Body,
  Footer,
  Title: Dialog.Title,
  Description: Dialog.Description
});
