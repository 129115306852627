import * as yup from "yup";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export interface AddOrUpdateNoteModel {
  note: string;
}

export const AddOrUpdateNoteModelValidation = () =>
  createValidationSchema<AddOrUpdateNoteModel>({
    note: yup.string().required()
  });
