import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./disableCanvasHWAcceleration.ts";

declare global {
  interface Array<T> {
    move(fromIndex: number, toIndex: number, item: T): void;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.move = function <T>(from: number, to: number, item: T) {
  this.splice(from, 1);
  this.splice(to, 0, item);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
