export enum FilterMarkingTypeEnum {
  Comment = 1,
  Highlight = 2,
  VerticalLines = 3,
  FocusBox = 4,
  All = 5,
  None = 6
}

export type MarkingTypeFilterOption = {
  label: string;
  value: string;
  type: FilterMarkingTypeEnum;
};
