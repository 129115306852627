import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";

export const UseIsPageRotatedDifferentlyInPresentation = (
  caseId: string | undefined,
  documentId: string | undefined,
  pageIndex: number
) => {
  const { data: pagesInfo, isLoading } = useGetDocumentPagesQuery(
    caseId && documentId ? { caseId: caseId, documentId: documentId } : skipToken
  );
  const isRotatedDifferentlyInPresentation = pagesInfo?.find(
    (t) => t.pageIndex === pageIndex
  )?.hasPresentationWithDifferentPageOrientation;

  return {
    isRotatedDifferentlyInPresentation,
    isLoading
  };
};
