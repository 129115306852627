import classNames from "classnames";
import Thumbnail from "@pages/pdfviewer/component/thumbnail/thumbnail";
import { ExtractDraftDocumentPageIntervalModel } from "@services/api/extractDraft/models/extractDraftDocumentPageIntervalModel";
import StatusIcon, { StatusType } from "@components/embla/statusIcon";
import Badge from "@components/embla/badge";
import PagePlaceholder from "@components/pagePlaceholder/pagePlaceholder";
import { useGetDocumentPageThumbnailUrlQuery } from "@services/api/document/caseDocumentApi";
import styles from "./extractDocumentInterval.module.scss";

interface ExtractDocumentIntervalProps {
  pageInterval: ExtractDraftDocumentPageIntervalModel;
  additionalClasses?: string;
  status?: StatusType;
  caseId: string;
  caseDocumentId: string;
}

const ExtractDocumentInterval = ({
  pageInterval,
  additionalClasses,
  status,
  caseId,
  caseDocumentId
}: ExtractDocumentIntervalProps) => {
  const { data: pageIntervalThumbnailUrl } = useGetDocumentPageThumbnailUrlQuery({
    caseId,
    documentId: caseDocumentId,
    pageIndex: pageInterval.firstPageIndex
  });

  const firstPageNumber = pageInterval.firstPageIndex + 1;
  const lastPageNumber = pageInterval.lastPageIndex + 1;

  const pageCount = lastPageNumber - firstPageNumber + 1;

  const pageIntervalText =
    firstPageNumber === lastPageNumber ? firstPageNumber : `${firstPageNumber}-${lastPageNumber}`;

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (status === "loading") {
      e.stopPropagation();
    }
  };

  return (
    <div
      className={classNames(
        "text-center relative",
        additionalClasses,
        status === "loading" && styles.loading
      )}
      onClick={onClick}
    >
      {pageCount > 1 && pageIntervalThumbnailUrl && (
        <>
          <Badge
            appearance="dark"
            size={"wide"}
            pill
            className={classNames(styles.floatingPageCount)}
          >
            {pageCount}
          </Badge>

          <div className={styles.secondPage}></div>
        </>
      )}
      {status && (
        <div className={styles.status}>
          <StatusIcon status={status} />
        </div>
      )}
      {pageIntervalThumbnailUrl ? (
        <Thumbnail
          srcUrl={pageIntervalThumbnailUrl}
          size={{ heigth: 120, width: 85 }}
          additionalClasses={styles.thumbnailOverride}
        />
      ) : (
        <PagePlaceholder emptyPlaceholder />
      )}
      <p className="margin-top-xs">{pageIntervalText}</p>
    </div>
  );
};

export default ExtractDocumentInterval;
