import { useEffect, useState } from "react";
import { Config, ConfigManager } from "@config/configManager";

type State =
  | { isLoading: true; config?: undefined; isError: false }
  | { isLoading: false; config?: undefined; isError: true }
  | { isLoading: false; config: Config; isError: false };

const useConfigLoader = () => {
  const [state, setState] = useState<State>({ isLoading: true, isError: false });

  useEffect(() => {
    new ConfigManager()
      .loadConfig()
      .then((config) => setState({ config, isLoading: false, isError: false }))
      .catch(() => setState({ isLoading: false, isError: true }));
  }, []);

  return state;
};

export default useConfigLoader;
