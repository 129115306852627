import classNames from "classnames";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { BadgeSize } from "src/utility/constants";
import { DocumentPageOverlayIconTypeEnum } from "../models/documentPageOverlayIconType";
import styles from "./thumbnail.module.scss";
import ThumbnailOverlay from "./thumbnailOverlay/thumbnailOverlay";

type ThumbnailProps = {
  srcUrl?: string;
  fileName?: string;
  title?: string;
  additionalClasses?: string;
  overlayIcons?: DocumentPageOverlayIconTypeEnum[];
  size?: { width: number; heigth: number };
  active?: boolean;
  thumbnailOrientation?: PageOrientation;
};

const Thumbnail = ({
  srcUrl,
  fileName,
  title,
  overlayIcons,
  size,
  additionalClasses,
  active,
  thumbnailOrientation
}: ThumbnailProps) => {
  const defaultSize = { width: 43, heigth: 60 };

  const isRotationHorizontal = thumbnailOrientation === 90 || thumbnailOrientation === 270;

  return (
    <div
      style={{ transform: `rotate(${thumbnailOrientation}deg)` }}
      className={classNames("relative", styles.wrapper, isRotationHorizontal && "mx-2")}
    >
      {overlayIcons && (
        <ThumbnailOverlay
          iconTypes={overlayIcons}
          badgeSize={BadgeSize.ExtraExtraSmall}
          hidePresentationIcon
          alignRight
        />
      )}
      <div className={classNames(styles.thumbnail, active && styles.active, additionalClasses)}>
        {srcUrl && (
          <img
            src={srcUrl}
            alt="presentation-page-thumbnail"
            title={title}
            width={size?.width ?? defaultSize.width}
            height={size?.heigth ?? defaultSize.heigth}
          />
        )}
      </div>
    </div>
  );
};

export default Thumbnail;
