import { MouseEvent, RefObject, useRef } from "react";
import { XYCoord, useDrag, useDragLayer, useDrop } from "react-dnd";
import {
  useChangePageNumberCoordinatesMutation,
  useGetDocumentPagesQuery
} from "@services/api/document/caseDocumentApi";
import { PageNumberCoordinatesModel } from "@services/api/document/models/pageNumber";
import styles from "./usePageNumberDnd.module.scss";

const itemType = "PageNumberDnd";
const emptyDragPreview = new Image();

type UsePageNumberDndProps<T extends HTMLElement> = {
  scale: number;
  numberElRef: RefObject<T>;
  number: number;
  canDrag: boolean;
  numberXY: XYCoord;
  documentId?: string;
  caseId?: string;
  moveOnlyThisNumber: boolean;
};

export const usePageNumberDnd = <T extends HTMLElement>({
  scale,
  numberElRef,
  number,
  canDrag,
  numberXY,
  caseId,
  documentId,
  moveOnlyThisNumber
}: UsePageNumberDndProps<T>) => {
  const dropBoundaryRef = useRef<HTMLDivElement>(null);
  const [changeCoordinates, { isLoading }] = useChangePageNumberCoordinatesMutation(
    documentId ?? ""
  );
  const { isFetching } = useGetDocumentPagesQuery(
    { caseId: caseId!, documentId: documentId! },
    { skip: !caseId || !documentId }
  );
  const [{ isDragging }, connectDragEl, dragPreview] = useDrag(
    {
      type: itemType,
      item: () => {
        return { number };
      },
      canDrag,
      collect: (m) => ({ isDragging: m.isDragging() })
    },
    [canDrag]
  );

  dragPreview(emptyDragPreview);
  connectDragEl(numberElRef);

  const [, connectDropEl] = useDrop(
    () => ({
      accept: itemType,
      drop: (_, monitor) => {
        if (caseId && documentId) {
          const newCoordinates = calculateNewNumberPosition(
            monitor.getSourceClientOffset(),
            scale,
            numberElRef.current,
            dropBoundaryRef.current
          );
          if (newCoordinates) {
            const model: PageNumberCoordinatesModel = {
              caseDocumentId: documentId,
              isMultiplePages: !moveOnlyThisNumber,
              number,
              x: newCoordinates.x,
              y: newCoordinates.y
            };

            changeCoordinates({ caseId, model, skipRefetch: moveOnlyThisNumber });
          }
        }
      }
    }),
    [scale, moveOnlyThisNumber]
  );

  const { sourceOffset } = useDragLayer((m) => ({
    sourceOffset: m.getSourceClientOffset(),
    dropped: m.getClientOffset()
  }));

  const dragPosition = isDragging
    ? calculateNewNumberPosition(sourceOffset, scale, numberElRef.current, dropBoundaryRef.current)
    : null;

  const pageNumberClassName = canDrag && styles.draggable;

  const { width, height } = getNumberElementSize(numberElRef);

  const boundaryStyles = {
    top: `-${height / 2}px`,
    bottom: `-${height / 2}px`,
    left: `-${width / 2}px`,
    right: `-${width / 2}px`
  };

  const dropBoundaryEl = isDragging && (
    <div ref={dropBoundaryRef} className={styles.boundary} style={boundaryStyles} />
  );

  connectDropEl(dropBoundaryRef);

  const numberPosition = {
    x: (dragPosition?.x ?? numberXY.x) * scale,
    y: (dragPosition?.y ?? numberXY.y) * scale
  };

  const onPointerDown = (e: MouseEvent<T>) => {
    if (canDrag) {
      // prevent close on outside click
      e.stopPropagation();
    }
  };

  return {
    dropBoundaryEl,
    pageNumberClassName,
    numberPosition,
    onPointerDown,
    isLoading: isLoading || isFetching
  };
};

const getNumberElementSize = (elementRef: RefObject<HTMLElement>) => {
  if (!elementRef.current) {
    return { width: 0, height: 0 };
  }

  const { width, height } = elementRef.current.getBoundingClientRect();
  return { width, height };
};

const calculateNewNumberPosition = (
  to: XYCoord | null,
  scale: number,
  source: HTMLElement | null,
  container: HTMLElement | null
) => {
  if (!to || !source || !container) {
    return null;
  }
  const containerRect = container.getBoundingClientRect();
  const sourceRect = source.getBoundingClientRect();

  const absoluteX =
    to.x < containerRect.x
      ? containerRect.x
      : // don't allow to overflow right
        to.x > containerRect.right - sourceRect.width
        ? containerRect.right - sourceRect.width
        : // is fully inside container
          to.x;

  const absoluteY =
    // dot allow to overflow top
    to.y < containerRect.y
      ? containerRect.y
      : // don't allow to overflow bottom
        to.y > containerRect.bottom - sourceRect.height
        ? containerRect.bottom - sourceRect.height
        : // is fully inside container
          to.y;

  return {
    x: (absoluteX - containerRect.x) / scale,
    y: (absoluteY - containerRect.y) / scale
  };
};
