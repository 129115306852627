import { useDispatch } from "react-redux";
import useMarkingMutation from "@pages/pdfviewer/component/hooks/useMarkingMutation";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import {
  removeActiveMarking,
  setHoveredMarkingId
} from "@pages/pdfviewer/component/pdfViewerSlice";

export const useHandleMarkingDelete = (marking?: MarkingModel) => {
  const dispatch = useDispatch();
  const { deleteMarking } = useMarkingMutation();

  return {
    handleMarkingDelete: async () => {
      dispatch(removeActiveMarking());
      dispatch(setHoveredMarkingId(undefined));
      if (marking !== undefined && !marking.isNew) {
        await deleteMarking(marking);
      }
    }
  };
};
