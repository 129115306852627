import { NotificationModule } from "ditmer-embla";
import React, { useEffect, useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { useNavigate } from "react-router-dom";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import Card from "@components/embla/card";
import { IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Table from "@components/table/table";
import {
  useGetCasePresentationsQuery,
  useReorderPresentationsMutation
} from "@services/api/casePresentation/casePresentationApi";
import { CasePresentationListModel } from "@services/api/casePresentation/models/casePresentationListModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import ModalMessage from "@components/modal/modalMessage";
import { RoutePaths } from "@components/routing/routes";
import useElectronApi from "src/hooks/useElectronApi";
import { StorageKeys } from "@infrastructure/storageKeys";
import { TableCell } from "@components/table/TableCell";
import { TableIconCell } from "@components/table/TableIconCell";
import useCaseAccess from "../hooks/useCaseAccess";
import { AddPresentations } from "./addPresentations/addPresentations";
import PresentationListRowActions from "./presentationListRowActions";

type PresentationListProps = {
  caseId: string;
  initiatePresent?: boolean;
  initiatePresentPresentationId?: string;
};

export const PresentationList: React.FC<PresentationListProps> = (props) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();

  const navigate = useNavigate();
  const { canEditDeletePresentations } = useCaseAccess(props.caseId);
  const [showMessageInfoModal, setShowMessageInfoModal] = useState(false);
  const { data: casePresentations, isLoading: casePresentationsIsLoading } =
    useGetCasePresentationsQuery(props.caseId);
  const [reorderPresentations] = useReorderPresentationsMutation();
  const electronApi = useElectronApi();

  const onRowDropped = async (draggedRow: CasePresentationListModel, newIndex: number) => {
    await reorderPresentations({
      caseId: props.caseId,
      draggedPresentationId: draggedRow.id,
      newIndex: newIndex
    });
    NotificationModule.showSuccessSmall(localizer.orderChanged());
  };

  const onPresentationRowClicked = (rowData: CasePresentationListModel) => {
    if (rowData.presentationPageIds && rowData.presentationPageIds.length > 0) {
      canEditDeletePresentations() &&
        navigate(
          RoutePaths.casePresentationEdit.url(
            props.caseId,
            rowData.id,
            rowData.presentationPageIds[0]
          )
        );
    } else {
      setShowMessageInfoModal(true);
    }
  };

  // Used when deeplink to elektron app and we want the presentation to start automaticaly
  useEffect(() => {
    if (electronApi && props.initiatePresent && props.initiatePresentPresentationId) {
      electronApi.present(props.initiatePresentPresentationId);
    }
  }, [electronApi, props.initiatePresent, props.initiatePresentPresentationId]);

  const getColumns = useMemo(() => {
    const columns: Column<CasePresentationListModel>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.title(),
        accessor: "title",

        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => (
          <TableIconCell {...cellProps} iconName="lightning" color={IconColor.Blue} />
        )
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.steps(),
        accessor: "stepsAmount"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.lastChanged(),
        accessor: "lastChangedByUserAt",

        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => {
          const lastModifiedValue =
            dateFormatter.timeSince(cellProps.cell.value) +
            " " +
            localizer.byLowerCase() +
            " " +
            cellProps.row.original.lastChangedByUserName;
          return <TableCell {...cellProps} value={lastModifiedValue} />;
        }
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.createdBy(),
        accessor: "createdByName",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellProps) => <TableCell {...cellProps}>{cellProps.cell.value}</TableCell>
      }
    ];
    if (electronApi) {
      columns.push({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.presentationLastSave(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: ({ row }: CellProps<CasePresentationListModel, string>) => {
          const lastSaveValue = window.localStorage.getItem(
            StorageKeys.offlineLastPresentationSaveStorage(row.original.id)
          );
          return !lastSaveValue
            ? ""
            : dateFormatter.shortDateWithoutYearWithShortTime(lastSaveValue);
        },
        width: 100
      });
    }
    columns.push({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Header: () => null,
      disableSortBy: true,
      disableClick: true,
      id: "actions",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: ({ row }: CellProps<CasePresentationListModel, string>) => (
        <PresentationListRowActions caseId={props.caseId} row={row} />
      ),
      width: 20
    });

    return columns;
  }, [localizer, electronApi, dateFormatter, props.caseId]);

  return (
    <>
      <Card className="pt-2 mt-3">
        <h3 className="pl-2">{localizer.presentations()}</h3>
        <Table
          columns={getColumns}
          data={casePresentations ?? []}
          isLoading={casePresentationsIsLoading}
          noResultsOptions={{
            noResultsMessage: localizer.noResultsFound()
          }}
          dragAndDropOptions={{
            onDragDropRow: (draggedPage, index) => onRowDropped(draggedPage, index),
            disableDrag: !canEditDeletePresentations()
          }}
          rowOnClick={onPresentationRowClicked}
        />
      </Card>
      <AddButtonSlideInForm>
        <AddPresentations caseId={props.caseId} startSortOrder={casePresentations?.length ?? 0} />
      </AddButtonSlideInForm>
      <ModalMessage
        open={showMessageInfoModal}
        onClose={() => setShowMessageInfoModal(false)}
        title={localizer.presentationEmpty()}
        description={localizer.presentationEmptyDescription()}
        modalAcceptType="primary"
        acceptButtonText={localizer.ok()}
        showCancelButton={false}
      />
    </>
  );
};
