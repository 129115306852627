import { Location, matchPath } from "react-router-dom";
import { AccessRight } from "@infrastructure/AccessRight";
import { documentTabId, participantTabId, presentationsTabId } from "@pages/case/casePage";

export const KNOWLEDGE_BASE_URL = "https://legatize.twoday.dk/knowledge-base";
export const SUPPORT_PAGE_URL = "https://legatize.twoday.dk/support";

type TabIdType = typeof documentTabId | typeof presentationsTabId | typeof participantTabId;
export class RoutePaths {
  public static index = { path: "/", url: "/" };
  public static signinRedirect = { path: "/signin-oidc", url: "/signin-oidc" };
  public static impersonateRedirect = { path: "/impersonate", url: "/impersonate" };
  public static login = { path: "/login" };

  public static employees = {
    path: "/employees/*",
    url: "/employees",
    accessRight: AccessRight.AdministerOrg
  };

  public static employeeCreate = {
    path: "create",
    url: "/employees/create",
    accessRight: AccessRight.AdministerOrg
  };

  public static employeeEdit = {
    path: "edit/:id",
    url: (id: string) => `/employees/edit/${id}`,
    accessRight: AccessRight.AdministerOrg
  };

  public static emailTemplates = {
    path: "/emailTemplates/*",
    url: "/emailTemplates",
    accessRight: AccessRight.SysAdmin
  };

  public static emailTemplateEdit = {
    path: ":type/:culture",
    url: (type: number, culture: string) => `/emailTemplates/${type}/${culture}`,
    accessRight: AccessRight.AdministerOrg
  };

  public static profile = { path: "/profile", url: "/profile" };

  public static case = { path: "/case/*", url: "/case" };
  public static caseDetails = {
    path: ":caseId/:tabId",
    url: (id: string, tabId: TabIdType) => `/case/${id}/${tabId}`
  };

  public static casePresentation = {
    path: ":seqId",
    url: (id: string, seqId: string) => `${this.caseDetails.url(id, "presentations")}/${seqId}`
  };

  public static casePresentationPresent = {
    path: ":present",
    url: (id: string, seqId: string) => `${this.casePresentation.url(id, seqId)}/present`
  };

  public static casePresentationEdit = {
    path: "edit/:pageId",
    url: (id: string, seqId: string, pageId: string) =>
      `${this.casePresentation.url(id, seqId)}/edit/${pageId}`
  };

  public static casePresentationEditPresent = {
    path: "edit/:pageId/:present",
    url: (id: string, seqId: string, pageId: string) =>
      `${this.casePresentationEdit.url(id, seqId, pageId)}/present`
  };

  public static caseExtract = {
    path: ":caseId/extracts/:extractId",
    url: (id: string, extractId: string) => `/case/${id}/extracts/${extractId}`
  };

  public static caseDocument = {
    path: ":caseId/documents/:documentId",
    url: (id: string, documentId: string) => `/case/${id}/documents/${documentId}`
  };

  public static caseDocumentPage = {
    path: "page/:initialPageNumber",
    url: (id: string, documentId: string, initialPageNumber: number) =>
      `${this.caseDocument.url(id, documentId)}/page/${initialPageNumber}`
  };

  public static casePresentInDocument = {
    path: ":seqId",
    url: (id: string, documentId: string, presentationId: string) =>
      `${this.caseDocument.url(id, documentId)}/${presentationId}`
  };

  public static present = {
    path: "present/:presentationId/:pageIndex/*",
    url: (presentationId: string, pageIndex = 0) => `/present/${presentationId}/${pageIndex}`
  };

  public static presentBigScreen = {
    path: "big-screen",
    url: (presentationId: string, pageIndex = 0) =>
      `${this.present.url(presentationId, pageIndex)}/big-screen`
  };
}

interface RedirectState {
  from?: Location;
}

export const fullscreenMode = (url: string): boolean => {
  return (
    null ===
    (matchPath({ path: RoutePaths.caseDocument.url(":p1", ":p2"), end: false }, url) ||
      matchPath(
        { path: RoutePaths.casePresentationEdit.url(":p1", ":p2", ":p3"), end: false },
        url
      ) ||
      matchPath({ path: RoutePaths.caseExtract.url(":p1", ":p2"), end: false }, url))
  );
};

export const isPresentationWindow = (url: string): boolean => {
  return url.includes(RoutePaths.presentBigScreen.path);
};

export const isDocumentViewerPage = (url: string): boolean => {
  return null !== matchPath({ path: RoutePaths.caseDocument.url(":p1", ":p2"), end: false }, url);
};

export const isExtractPage = (url: string): boolean => {
  return url.includes("case") && url.includes("extracts") && !url.includes("tabs");
};

export const getRedirectPath = (
  location: Location,
  fallbackPath: string = RoutePaths.index.path
) => {
  const redirectState = location.state as RedirectState | undefined | null;
  return redirectState?.from?.pathname || fallbackPath;
};
