import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { VerticalLinesMarkingModel } from "../../../models/markingModel";
import CardProps from "../shared/cardProps.model";
import MarkingPreview from "../shared/markingPreview";
import MarkingPresentationPreview from "../shared/markingPresentationPreview";
import VerticalLinesEdit from "./verticalLinesEdit";

const VerticalLinesCardContent = ({
  activeState,
  ...props
}: CardProps<VerticalLinesMarkingModel>) => {
  return activeState === MarkingActiveStateType.Edit ? (
    <VerticalLinesEdit {...props} />
  ) : props.isPresentationEdit ? (
    <MarkingPresentationPreview
      marking={props.marking}
      toggleMarkingClicked={props.toggleMarkingClicked}
      toggleMarkingState={props.toggleMarkingState ?? false}
      disableCheckbox={props.disableCheckbox}
    />
  ) : (
    <MarkingPreview
      marking={props.marking}
      onReplyCreateEdit={props.onReplyCreateEdit}
      editMarkingClicked={props.editMarkingClicked}
      repliesOpen={activeState === MarkingActiveStateType.Replies}
    />
  );
};

export default VerticalLinesCardContent;
