import { useLayoutEffect, useState } from "react";

export interface ElementSize {
  width: number;
  height: number;
}

const useElementSize = <T extends HTMLElement>(): [
  (node: T | null) => void,
  ElementSize,
  T | null
] => {
  // Use state instead of ref to rerun effects if element changes or is set first time
  const [element, setElement] = useState<T | null>(null);
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    const { width, height } = element.getBoundingClientRect();
    setSize({ width, height });

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setSize({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height
      });
    });
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [setElement, size, element];
};

export default useElementSize;
