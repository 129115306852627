import classNames from "classnames";
import { Tooltip } from "@components/tooltip/tooltip";
import { BadgeSize } from "src/utility/constants";
import { DocumentPageOverlayIconTypeEnum } from "../../models/documentPageOverlayIconType";
import ThumbnailIcon from "../thumbnailIcon";
import styles from "./thumbnailOverlay.module.scss";

type ThumbnailOverlayProps = {
  iconTypes: DocumentPageOverlayIconTypeEnum[];
  badgeSize?: BadgeSize;
  hidePresentationIcon?: boolean;
  alignRight?: boolean;
  tooltipText?: string;
};

const ThumbnailOverlay = ({
  iconTypes,
  badgeSize,
  hidePresentationIcon,
  alignRight,
  tooltipText
}: ThumbnailOverlayProps) => {
  const filteredIconTypes = !hidePresentationIcon
    ? iconTypes
    : iconTypes.filter((x) => x !== DocumentPageOverlayIconTypeEnum.Presentation);

  const iconOverlay = () => {
    return (
      <div className={classNames(styles.thumbnailIcons, alignRight && styles.alignRight)}>
        {filteredIconTypes.map((iconType, index) => (
          <ThumbnailIcon
            key={index}
            iconType={iconType}
            badgeSize={badgeSize ?? BadgeSize.ExtraSmall}
          />
        ))}
      </div>
    );
  };

  return tooltipText ? <Tooltip message={tooltipText}>{iconOverlay()}</Tooltip> : iconOverlay();
};

export default ThumbnailOverlay;
