import { MultiPolygon } from "polygon-clipping";
import { UserModel } from "@services/api/sharedModels/userModel";
import { MarkingTag } from "@services/api/sharedModels/markingTagModel";
import { MarkingType } from "./markingType";

type UndefinedInTemplate<IsTemplate extends boolean, T> = IsTemplate extends true ? undefined : T;

type PresentationPageMarkingModel = {
  presentationPageMarkingId: string;
  presentationPageId: string;
  presentationId: string;
  presentationName: string;
};

type PresentationModel = {
  presentationId: string;
  presentationName: string;
};

export type MarkingReplyModel = {
  id: string;
  creationDate: string;
  content: string;
  hasBeenEdited: boolean;
  profilePictureUrl?: string;
  owner: UserModel;
};

export interface MarkingModelBase<IsTemplate extends boolean = true | false> {
  type: MarkingType;
  isPrivate: boolean;
  tags: MarkingTag[];
  owner: UserModel;
  isNew?: boolean;
  isTemporary?: boolean;
  id: UndefinedInTemplate<IsTemplate, string>;
  page: UndefinedInTemplate<IsTemplate, number>;
  x: UndefinedInTemplate<IsTemplate, number>;
  y: UndefinedInTemplate<IsTemplate, number>;
  creationDate: UndefinedInTemplate<IsTemplate, string>;
  presentationPageList: PresentationPageMarkingModel[];
  presentationList?: UndefinedInTemplate<IsTemplate, PresentationModel[]>;
  replies?: UndefinedInTemplate<IsTemplate, MarkingReplyModel[]>;
}

export interface CommentMarkingModel<IsTemplate extends boolean = true | false>
  extends MarkingModelBase<IsTemplate> {
  type: MarkingType.Comment;
  data: {
    text: string;
  };
}

const COMMON_COLORS = {
  red: "#FC5B5F",
  green: "#52CF92",
  purple: "#9D6FCD",
  blue: "#2791F5",
  brown: "#CD9832"
} as const;

export const HIGHLIGHT_COLORS = {
  yellow: "#FCDE53",
  ...COMMON_COLORS
} as const;

export const HIGHLIGHT_COLOR_VALUES = Object.values(HIGHLIGHT_COLORS);

export const MARKING_FADED_COLOR = "#d5d5d5";

export interface HighlightMarkingModel<IsTemplate extends boolean = true | false>
  extends MarkingModelBase<IsTemplate> {
  type: MarkingType.Highlight;
  data: {
    color: string;
    width: UndefinedInTemplate<IsTemplate, number>;
    height: UndefinedInTemplate<IsTemplate, number>;
    polygons: UndefinedInTemplate<IsTemplate, MultiPolygon>;
  };
}

export enum LineAppearance {
  Single = 1,
  Double = 2,
  LeftOnly = 3,
  RightOnly = 4,
  Brackets = 5
}

export const LINE_COLORS = {
  black: "#000000",
  ...COMMON_COLORS
} as const;

export interface VerticalLinesMarkingModel<IsTemplate extends boolean = true | false>
  extends MarkingModelBase<IsTemplate> {
  type: MarkingType.VerticalLines;
  data: {
    lines: LineAppearance;
    color: string;
    width: UndefinedInTemplate<IsTemplate, number>;
    height?: number; // new marking height is undefined to trigger resizing
  };
}

export interface FocusBoxMarkingModel<IsTemplate extends boolean = true | false>
  extends MarkingModelBase<IsTemplate> {
  type: MarkingType.FocusBox;
  data: {
    // new marking width/height is undefined to trigger resizing
    width?: number;
    height?: number;
  };
}

export interface ReplyCreateModel extends MarkingModelBase<false> {
  data: {
    id: string;
    content: string;
  };
}

export interface ReplyEditModel extends MarkingModelBase<false> {
  data: {
    id: string;
    content: string;
  };
}

export type ReplyModel = ReplyCreateModel | ReplyEditModel;

export type MarkingModel<IsTemplate extends boolean = true | false> =
  | CommentMarkingModel<IsTemplate>
  | HighlightMarkingModel<IsTemplate>
  | VerticalLinesMarkingModel<IsTemplate>
  | FocusBoxMarkingModel<IsTemplate>;
