import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths, SUPPORT_PAGE_URL } from "@routes";

const GenericErrorPage = (props: { message?: string }) => {
  const localizer = useLocalization();

  return (
    <div className="body-container">
      <div className="page-content">
        <div className="main-content">
          <div className="body-content">
            <h1>{localizer.errorOccuredTitle()}</h1>
            <div className="mt-2">
              <p>
                {props.message ?? localizer.errorOccuredContent()}
                <a target="_blank" href={SUPPORT_PAGE_URL} rel="noreferrer">
                  {" "}
                  {localizer.contactSupport()}
                </a>
              </p>
            </div>
            <button
              onClick={() => (window.location.href = RoutePaths.index.url)}
              className="btn btn-primary"
            >
              {localizer.backToIndex()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericErrorPage;
