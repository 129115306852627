import { MouseEvent, RefObject, useRef } from "react";
import { useDrag, useDragLayer, useDrop, XYCoord } from "react-dnd";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import useMarkingMutation from "@pages/pdfviewer/component/hooks/useMarkingMutation";
import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import styles from "./useMarkingDnd.module.scss";

interface UseMarkingDndProps<T extends HTMLElement> {
  marking: MarkingModel<false>;
  scale: number;
  markingElRef: RefObject<T>;
  canDrag: boolean;
  onlyVertical?: boolean;
}

const itemType = "MarkingDnd";
const emptyDragPreview = new Image();

const useMarkingDnd = <T extends HTMLElement>({
  marking,
  scale,
  markingElRef,
  canDrag,
  onlyVertical = false
}: UseMarkingDndProps<T>) => {
  const dropBoundaryRef = useRef<HTMLDivElement>(null);
  const { editMarking } = useMarkingMutation();
  const { setIsDraggingMarking, setActiveMarking } = useMarkingsPageContext();

  const [{ isDragging }, connectDragEl, dragPreview] = useDrag({
    type: itemType,
    item: () => {
      setIsDraggingMarking(true);
      return { id: marking.id };
    },
    canDrag,
    end: () => setIsDraggingMarking(true),
    collect: (m) => ({ isDragging: m.isDragging() })
  });
  connectDragEl(markingElRef);
  dragPreview(emptyDragPreview);

  const [, connectDropEl] = useDrop(
    () => ({
      accept: itemType,
      drop: (_, monitor) => {
        const newModel: MarkingModel = {
          ...marking,
          ...calculateNewMarkingPosition(
            monitor.getSourceClientOffset(),
            scale,
            onlyVertical,
            markingElRef.current,
            dropBoundaryRef.current
          )
        };
        setActiveMarking({
          marking: newModel,
          activeState: MarkingActiveStateType.Edit
        });
        if (!marking.isNew) {
          editMarking(newModel);
        }
      }
    }),
    [marking, scale]
  );
  connectDropEl(dropBoundaryRef);

  const { sourceOffset } = useDragLayer((m) => ({
    sourceOffset: m.getSourceClientOffset(),
    dropped: m.getClientOffset()
  }));

  const dragPosition = isDragging
    ? calculateNewMarkingPosition(
        sourceOffset,
        scale,
        onlyVertical,
        markingElRef.current,
        dropBoundaryRef.current
      )
    : null;

  const markingPosition = {
    x: (dragPosition?.x ?? marking.x) * scale,
    y: (dragPosition?.y ?? marking.y) * scale
  };

  const markingClassName = canDrag && styles.draggable;

  const onPointerDown = (e: MouseEvent<T>) => {
    if (canDrag) {
      // prevent close on outside click
      e.stopPropagation();
    }
  };

  const dropBoundaryEl = isDragging && <div ref={dropBoundaryRef} className={styles.boundary} />;

  return {
    markingPosition,
    markingClassName,
    onPointerDown,
    dropBoundaryEl
  };
};

const calculateNewMarkingPosition = (
  to: XYCoord | null,
  scale: number,
  onlyVertical: boolean,
  source: HTMLElement | null,
  container: HTMLElement | null
) => {
  if (!to || !source || !container) {
    return null;
  }
  const containerRect = container.getBoundingClientRect();
  const sourceRect = source.getBoundingClientRect();

  const absoluteX = onlyVertical
    ? sourceRect.x
    : // dot allow to overflow left
      to.x < containerRect.x
      ? containerRect.x
      : // don't allow to overflow right
        to.x > containerRect.right - sourceRect.width
        ? containerRect.right - sourceRect.width
        : // is fully inside container
          to.x;

  const absoluteY =
    // dot allow to overflow top
    to.y < containerRect.y
      ? containerRect.y
      : // don't allow to overflow bottom
        to.y > containerRect.bottom - sourceRect.height
        ? containerRect.bottom - sourceRect.height
        : // is fully inside container
          to.y;

  return {
    x: (absoluteX - containerRect.x) / scale,
    y: (absoluteY - containerRect.y) / scale
  };
};

export default useMarkingDnd;
