import { DropdownOption } from "@components/dropdown/dropdown";
import { Localizer } from "@components/localization/localizer";

export enum CaseStatusEnum {
  Open = 1,
  Closed = 2,
  Archived = 3
}

export const getCaseStatusDropdownOptions = (localizer: Localizer) => {
  return [
    {
      label: localizer.open(),
      value: CaseStatusEnum.Open
    },
    {
      label: localizer.closed(),
      value: CaseStatusEnum.Closed
    }
  ] as DropdownOption<number>[];
};

export const convertCaseStatusToString = (type: CaseStatusEnum, localizer: Localizer) => {
  switch (type) {
    case CaseStatusEnum.Open:
      return localizer.open();
    case CaseStatusEnum.Closed:
      return localizer.closed();
    case CaseStatusEnum.Archived:
      return localizer.archived();
  }
};
