import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Document } from "react-pdf";
import {
  FixedSizeGrid,
  GridOnItemsRenderedProps,
  ListOnItemsRenderedProps,
  VariableSizeList
} from "react-window";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks";
import { useVisiblePages } from "@pages/pdfviewer/component/hooks/useVisiblePages";
import {
  pdfViewerStateSelector,
  setMostVisiblePageIndex
} from "@pages/pdfviewer/component/pdfViewerSlice";
import useElementSize from "src/hooks/useElementSize";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";
import { PageDimensions, PageDimensionsArray } from "../pageDimensions";
import PageGrid from "../page/PageGrid/PageGrid";
import PageList from "../page/PageList/PageList";
import { PageListChildData } from "../page/PageRenderer/PageRenderer";
import styles from "./pdfSidebar.module.scss";
import { pdfSidebarStateSelector } from "./pdfSidebarSlice";
import { useNavigateWithArrowKeys } from "./hooks/useNavigateWithArrowKeys";

const sidebarGridColumnCount = 4;
const thumbnailSize = {
  width: 85,
  height: 110,
  margin: 12,
  numberHeight: 22
};
const gridSizes = {
  columnWidth: thumbnailSize.height + thumbnailSize.margin,
  rowHeight: thumbnailSize.height + thumbnailSize.margin + thumbnailSize.numberHeight
};

export const PdfSidebarPages = (props: {
  fileUrl: string;
  pdfDimensions: PageDimensionsArray;
  className?: string;
  documentId?: string;
}) => {
  const [items, setItems] = useState<ListOnItemsRenderedProps | GridOnItemsRenderedProps>();
  const [pagesVisible] = useVisiblePages(items, sidebarGridColumnCount);
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const [scrollContainerRef, scrollContainerSize] = useElementSize();
  const pdfSidebarState = useAppSelector(pdfSidebarStateSelector);
  const { caseId } = useParams();

  const arrowKeyNavigationHandlerRef = useNavigateWithArrowKeys<HTMLDivElement>(
    sidebarGridColumnCount,
    !pdfSidebarState.sidebarCollapsed
  );

  const [pageView, setPageView] = useState<VariableSizeList | FixedSizeGrid | null>(null);

  const { data: pagesInfo } = useGetDocumentPagesQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { caseId: caseId!, documentId: props.documentId! },
    { skip: !caseId || !props.documentId }
  );

  useEffect(() => {
    const pageIndex = pdfViewerState.mostVisiblePageIndex;
    if (pageView instanceof VariableSizeList) {
      pageView?.scrollToItem(pageIndex);
    } else {
      pageView?.scrollToItem({
        rowIndex: Math.trunc(pageIndex / sidebarGridColumnCount),
        columnIndex: pageIndex % sidebarGridColumnCount
      });
    }
  }, [pageView, pdfViewerState.mostVisiblePageIndex]);

  const scaledDimensions = useMemo(
    () =>
      props.pdfDimensions.map(({ width, height, rotation }): PageDimensions => {
        let scaleToFit = thumbnailSize.width / width;
        if (!pdfSidebarState.sidebarCollapsed) {
          // fit to height in grid too
          scaleToFit = Math.min(scaleToFit, thumbnailSize.height / height);
        }

        const scaledWidth = width * scaleToFit;
        const scaledHeight = height * scaleToFit;

        return {
          width: scaledWidth,
          height: scaledHeight,
          rotation: rotation
        };
      }),
    [props.pdfDimensions, pdfSidebarState.sidebarCollapsed]
  );

  const dispatch = useDispatch();

  const handleSetMostVisiblePageIndex = useCallback(
    (index: number) => {
      dispatch(setMostVisiblePageIndex(index));
    },
    [dispatch]
  );

  useEffect(() => {
    if (pageView instanceof VariableSizeList) {
      pageView.resetAfterIndex(0);
    }
  }, [scaledDimensions, pageView]);

  const calculateRowCount = useCallback((): number => {
    return Math.ceil(pdfViewerState.totalPages / sidebarGridColumnCount);
  }, [pdfViewerState.totalPages]);

  const getListItemHeight = useCallback(
    (index: number): number => {
      const lastPageMargin = index === pdfViewerState.totalPages - 1 ? thumbnailSize.margin : 0;
      return (
        (scaledDimensions?.[index].height ?? 0) +
        thumbnailSize.margin +
        thumbnailSize.numberHeight +
        lastPageMargin
      );
    },
    [pdfViewerState.totalPages, scaledDimensions]
  );

  const itemData: PageListChildData & { documentId: string } = useMemo(
    () => ({
      getPageClassName: (index) =>
        classNames(
          "rounded overflow-hidden",
          index === pdfViewerState.mostVisiblePageIndex && styles.active
        ),
      isThumbnail: true,
      pdfDimensions: scaledDimensions,
      visiblePages: pagesVisible,
      pageMargin: thumbnailSize.margin,
      documentId: props.documentId || "",
      pageOrientations: pagesInfo?.map((page) => page.pageOrientation) || []
    }),
    [
      pagesInfo,
      pagesVisible,
      pdfViewerState.mostVisiblePageIndex,
      props.documentId,
      scaledDimensions
    ]
  );

  return (
    <div className={classNames(styles.sidebarScroll, props.className)} ref={scrollContainerRef}>
      {!pdfSidebarState.sidebarCollapsed ? (
        <div className={styles.noOutline} tabIndex={0} ref={arrowKeyNavigationHandlerRef}>
          <Document
            className={classNames("card-body", styles.document)}
            file={props.fileUrl}
            loading={<div />}
          >
            <FixedSizeGrid
              ref={setPageView}
              columnCount={sidebarGridColumnCount}
              rowCount={calculateRowCount()}
              columnWidth={gridSizes.columnWidth}
              rowHeight={gridSizes.rowHeight}
              className={classNames(styles.documentFixedListScroll, "scrollbar")}
              height={scrollContainerSize.height}
              width={scrollContainerSize.width}
              onItemsRendered={setItems}
              overscanRowCount={3}
              itemData={{
                ...itemData,
                gridColumnCount: sidebarGridColumnCount,
                draggable: true,
                pageTextDataRequired: false
              }}
              useIsScrolling
            >
              {PageGrid}
            </FixedSizeGrid>
          </Document>
        </div>
      ) : (
        <Document className={classNames("card-body", styles.document)} file={props.fileUrl}>
          <VariableSizeList
            ref={setPageView}
            className={classNames(styles.documentFixedListScroll, "scrollbar")}
            height={scrollContainerSize.height}
            itemCount={pdfViewerState.totalPages}
            itemSize={getListItemHeight}
            estimatedItemSize={getListItemHeight(0)}
            width="100%"
            onItemsRendered={setItems}
            overscanCount={10}
            itemData={{
              ...itemData,
              pageTextDataRequired: false,
              scale: 1,
              draggable: true,
              disableCanvas: true,
              setMostVisiblePageIndex: handleSetMostVisiblePageIndex
            }}
            useIsScrolling
          >
            {PageList}
          </VariableSizeList>
        </Document>
      )}
    </div>
  );
};
