import { useMemo } from "react";
import { useGetDocumentPagesQuery } from "@services/api/document/caseDocumentApi";

type PageNumberProps = {
  pageIndex: number;
  documentId?: string;
  caseId?: string;
};

export const usePageNumbers = ({ caseId, documentId, pageIndex }: PageNumberProps) => {
  const { data: pages, isLoading } = useGetDocumentPagesQuery(
    { caseId: caseId!, documentId: documentId! },
    { skip: !caseId || !documentId }
  );

  const allPageNumbers = useMemo(() => {
    return pages?.map((x) => x.pageNumber);
  }, [pages]);

  const pageNumber = useMemo(() => {
    return allPageNumbers?.[pageIndex];
  }, [allPageNumbers, pageIndex]);

  return {
    pageNumber,
    font: pages?.[pageIndex]?.font,
    isLoading
  };
};
