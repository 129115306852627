import classNames from "classnames";
import React from "react";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import styles from "./pagePlaceholder.module.scss";

interface PagePlaceholderProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  emptyPlaceholder?: boolean;
  size?: "normal" | "small";
  margins?: number;
  active?: boolean;
  additionalClasses?: string;
}

const PagePlaceholder = ({
  onClick,
  emptyPlaceholder,
  active,
  size = "normal",
  margins = 0,
  additionalClasses
}: PagePlaceholderProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "d-flex align-items-center justify-content-center",
        active && styles.active,
        styles.emptyPage,
        size === "normal" && styles.sizeNormal,
        size === "small" && styles.sizeSmall,
        emptyPlaceholder ? styles.noThumbnailPlaceholder : styles.addNewPage,
        additionalClasses
      )}
      role={onClick && "button"}
      style={{ "--margins": `${margins}px` } as React.CSSProperties}
    >
      {!emptyPlaceholder && <EmblaIcon iconName="plus" color={IconColor.Gray} />}
    </div>
  );
};

export default PagePlaceholder;
