import React, { createContext, useContext, useReducer } from "react";

interface ExpandingDropdownMenuContextType {
  currentOpenExpandingMenu: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ExpandingDropdownMenuContext = createContext<
  | {
      state: ExpandingDropdownMenuContextType | null;
      dispatch: React.Dispatch<ExpandingDropdownMenuContextType>;
    }
  | undefined
>(undefined);

const menuContextReducer = (
  state: ExpandingDropdownMenuContextType,
  action: ExpandingDropdownMenuContextType
): ExpandingDropdownMenuContextType => {
  return { ...state, currentOpenExpandingMenu: action.currentOpenExpandingMenu };
};

export const useExpandingDropdownMenuContext = () => {
  const context = useContext(ExpandingDropdownMenuContext);
  if (!context) {
    throw new Error("useMenuContext must be used in ExpandingDropdownMenuContextProvider");
  }
  return context;
};

const ExpandingDropdownMenuContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(menuContextReducer, {
    currentOpenExpandingMenu: undefined
  } as ExpandingDropdownMenuContextType);

  return (
    <ExpandingDropdownMenuContext.Provider value={{ state, dispatch }}>
      {children}
    </ExpandingDropdownMenuContext.Provider>
  );
};
export default ExpandingDropdownMenuContextProvider;
