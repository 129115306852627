import { renderToStaticMarkup } from "react-dom/server";
import styles from "./PageRenderer.module.scss";

export const highlightText = (text: string, patterns: RegExp[]): string => {
  if (patterns.length) {
    let textStringWithMarks = text;
    for (const pattern of patterns) {
      const splitText = textStringWithMarks.split(pattern);

      if (splitText.length <= 1) {
        return text;
      }

      const matches = text.match(pattern);

      const withMarks = splitText.reduce<string[]>((arr, element, index) => {
        const isMatch = matches?.[index];
        if (isMatch) {
          const stringWithMarks = [...arr, element, `<em>${matches[index]}</em>`];
          return stringWithMarks;
        } else {
          return [...arr, element];
        }
      }, []);

      textStringWithMarks = withMarks.join("");
    }

    const splitOnMarks = textStringWithMarks.split("<em>");

    const elementsToReturn = splitOnMarks.map((el, i) => {
      if (el.match("</em>")) {
        const withoutEndMarkSplit = el.split("</em>");

        // if there are overlapping matches, there will be multiple </em> tags and thus withoutEndMarkSplit will be longer than 2.
        // in this case all elements besides the last should be highlighted.
        const partOfStringToHighlight = withoutEndMarkSplit
          .slice(0, withoutEndMarkSplit.length - 1)
          .join();

        return [
          <mark className={styles.markedText} key={i}>
            {partOfStringToHighlight}
          </mark>,

          withoutEndMarkSplit[withoutEndMarkSplit.length - 1]
        ];
      } else {
        return [el];
      }
    });

    return renderToStaticMarkup(<>{elementsToReturn.flat()}</>);
  } else {
    return text;
  }
};
