import {
  AddDocumentsToDraftModel,
  IntervalExtractionType
} from "@components/case/extractDraft/addDocumentsToDraftModel";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { AddPageIntervalToDraftDocumentModel } from "@services/api/extractDraft/models/addPageIntervalToDraftDocumentModel";
import { ExtractDraftSectionCreateModel } from "@services/api/extractDraft/models/extractDraftSectionCreateModel";
import {
  ExtractDraftSectionOrderUpdateModel,
  ExtractDraftSectionUpdateModel
} from "@services/api/extractDraft/models/extractDraftSectionUpdateModel";
import { UpdateCaseExtractDraftModel } from "@services/api/extractDraft/models/updateCaseExtractDraftModel";
import { ExtractDraftDocumentUpdateModel } from "@services/api/extractDraft/models/extractDraftDocumentUpdateModel";
import { CreateCaseExtractDraftFrontpageModel } from "@services/api/extractDraft/models/createCaseExtractDraftFrontpageModel";
import { ExtractDraftReorderTreeNodeModel } from "@services/api/extractDraft/models/extractDraftReorderTreeNodeModel";
import { UpdateCaseExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/UpdateCaseExtractDraftTableOfContentsModel";
import { ExportBaseResponseModel } from "@services/api/sharedModels/exportBaseResponseModel";
import {
  ExportExtractDraftModel,
  ExtractDraftBundleModel
} from "@services/api/case/models/exportExtractDraftModel";
import { CompleteExtractResponseModel } from "@services/api/extractDraft/models/completeExtractResponseModel";
import { TableOfContentsInfoResponseModel } from "@services/api/extractDraft/models/tableOfContentsInfoResponseModel";
import baseApi, { apiTags } from "../baseApi";
import { CreateCaseExtractDraftModel } from "./models/createCaseExtractDraftModel";
import { ExtractDraftTreeNodeSortModel } from "./models/extractDraftTreeNodeSortModel";
import { ExtractDraftSectionDocumentUpdateModel } from "./models/extractDraftSectionDocumentUpdateModel";
import { SetSectionSortOrderModel } from "./models/setSectionSortOrderModel";
import { FrontpageSettingsModel } from "./models/frontpageSettingsModel";

const caseUrl = "/api/case";
const extractDraftUrl = (caseId: string) => `${caseUrl}/${caseId}/extractDraft`;
const getExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}`;
const deleteExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}`;
const updateExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}`;
const frontpageExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/frontpage`;
const tableOfContentsExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/tableOfContents`;
const tableOfContentsInfoExtractDraftUrl = (caseId: string, extractDraftId: string) =>
  `${tableOfContentsExtractDraftUrl(caseId, extractDraftId)}/info`;
const deleteExtractDraftDocumentUrl = (caseId: string, draftDocumentId: string) =>
  `${extractDraftUrl(caseId)}/document/${draftDocumentId}`;
const addPageIntervalToDocumentUrl = (caseId: string, draftDocumentId: string) =>
  `${extractDraftUrl(caseId)}/document/${draftDocumentId}`;
const updateExtractDocumentUrl = (caseId: string, draftDocumentId: string) =>
  `${extractDraftUrl(caseId)}/document/${draftDocumentId}`;
const createSectionUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section`;
const deleteSectionUrl = (caseId: string, extractDraftId: string, sectionId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section/${sectionId}`;
const updateSectionUrl = (caseId: string, extractDraftId: string, sectionId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section/${sectionId}`;
const reorderSectionUrl = (caseId: string, extractDraftId: string, sectionId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section/reorder/${sectionId}`;
const reorderSectionDocumentUrl = (
  caseId: string,
  extractDraftId: string,
  sectionId: string,
  documentId: string,
  newSortOrder: number
) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section/${sectionId}/document/${documentId}/move/${newSortOrder}`;
const reorderTreeNodeUrl = (caseId: string, extractDraftId: string, treeNodeId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/treenode/${treeNodeId}/reorder`;
const deleteExtractDraftDocumentPageIntervalUrl = (
  caseId: string,
  draftDocumentId: string,
  pageIntervalId: string
) => `${deleteExtractDraftDocumentUrl(caseId, draftDocumentId)}/pageInterval/${pageIntervalId}`;
const extractDraftDocumentsUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/documents`;
const extractExportUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/export`;
const getExtractExportUrl = (
  caseId: string,
  extractDraftId: string,
  extractDraftExportId: string
) => `${extractDraftUrl(caseId)}/${extractDraftId}/export/${extractDraftExportId}`;
const extractCompleteUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/complete`;
const getExtractExportDocument = (
  caseId: string,
  extractDraftId: string,
  extractDraftExportId: string
) => `${extractDraftUrl(caseId)}/${extractDraftId}/complete/${extractDraftExportId}`;
const treeNodeSort = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/treenode/sort`;
const updateExtractDraftSectionDocument = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/document`;
const setDocumentSortOrderUrl = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/documents/setDocumentSortOrder`;
const frontPageSettings = (caseId: string, extractDraftId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/frontpageSettings`;
const transformTitleUrl = (caseId: string, extractDraftId: string, sectionId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/section/${sectionId}/transformTitle`;
const revertDocumentTitleUrl = (caseId: string, extractDraftId: string, documentId: string) =>
  `${extractDraftUrl(caseId)}/${extractDraftId}/document/${documentId}/revert`;

const extractDraftApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getExtractDraft: builder.query<ExtractDraftModel, { caseId: string; extractDraftId: string }>({
      query: ({ caseId, extractDraftId }) => ({
        url: getExtractDraftUrl(caseId, extractDraftId),
        method: "GET"
      }),
      providesTags: (result, error) =>
        result
          ? [
              apiTags.caseExtractDraft,
              {
                type: apiTags.caseExtractDraft,
                id: result.id
              }
            ]
          : [],
      transformResponse: (original: ExtractDraftModel) => {
        if (!original.frontpage?.documentUrl) {
          return { ...original };
        }

        return fetch(original.frontpage.documentUrl)
          .then((response) => response.blob())
          .then(
            (blob) =>
              ({
                ...original,
                frontpage: {
                  ...original.frontpage,
                  documentUrl: URL.createObjectURL(blob)
                }
              }) as ExtractDraftModel
          );
      },
      onCacheEntryAdded: async (
        _: unknown,
        api: {
          cacheDataLoaded: Promise<{ data: ExtractDraftModel }>;
          cacheEntryRemoved: Promise<void>;
        }
      ) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        if (data.frontpage?.documentUrl) {
          URL.revokeObjectURL(data.frontpage?.documentUrl);
        }
      }
    }),

    createExtractDraft: builder.mutation<
      string,
      { caseId: string; model: CreateCaseExtractDraftModel }
    >({
      query: ({ caseId, model }) => ({
        url: extractDraftUrl(caseId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (result, error) => (!error ? [apiTags.caseDocument] : [])
    }),

    updateExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: UpdateCaseExtractDraftModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: updateExtractDraftUrl(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error
          ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }, apiTags.caseDocument]
          : []
    }),

    transformSectionDocumentsTitle: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; sectionId: string }
    >({
      query: ({ caseId, extractDraftId, sectionId }) => ({
        url: transformTitleUrl(caseId, extractDraftId, sectionId),
        method: "PUT"
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    revertDocumentTitle: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; documentId: string }
    >({
      query: ({ caseId, extractDraftId, documentId }) => ({
        url: revertDocumentTitleUrl(caseId, extractDraftId, documentId),
        method: "PUT"
      }),
      // Optimistic updates
      onQueryStarted: async (
        { caseId, extractDraftId, documentId },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extractDraftApi.util.updateQueryData(
            "getExtractDraft",
            { caseId, extractDraftId },
            (draft) => {
              const section = draft.sections.find((sec) =>
                sec.documents.some((doc) => doc.id === documentId)
              );
              if (section) {
                const document = section.documents.find((doc) => doc.id === documentId);
                if (document) {
                  document.caseDocumentName = document.originalCaseDocumentName;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.extractTableOfContents, id: input.extractDraftId }] : []
    }),

    updateExtractDraftFrontpageSettings: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: FrontpageSettingsModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: frontPageSettings(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),
    createFrontpageExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: CreateCaseExtractDraftFrontpageModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: frontpageExtractDraftUrl(caseId, extractDraftId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),
    completeExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: ExportExtractDraftModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: extractCompleteUrl(caseId, extractDraftId),
        method: "POST",
        body: model
      })
    }),
    getExtractDraftExportDocument: builder.query<
      CompleteExtractResponseModel[],
      { caseId: string; extractDraftId: string; extractDraftExportId: string }
    >({
      query: ({ caseId, extractDraftId, extractDraftExportId }) => ({
        url: getExtractExportDocument(caseId, extractDraftId, extractDraftExportId),
        method: "GET"
      })
    }),
    exportExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: ExportExtractDraftModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: extractExportUrl(caseId, extractDraftId),
        method: "POST",
        body: model
      })
    }),

    getExtractDraftExportDownloadUrl: builder.query<
      ExportBaseResponseModel[],
      { caseId: string; extractDraftId: string; extractDraftExportId: string }
    >({
      query: ({ caseId, extractDraftId, extractDraftExportId }) => ({
        url: getExtractExportUrl(caseId, extractDraftId, extractDraftExportId),
        method: "GET"
      })
    }),

    updateTableOfContentsExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: UpdateCaseExtractDraftTableOfContentsModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: tableOfContentsExtractDraftUrl(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    getTableOfContentsUrlExtractDraft: builder.query<
      string,
      { caseId: string; extractDraftId: string }
    >({
      query: ({ caseId, extractDraftId }) => ({
        url: tableOfContentsExtractDraftUrl(caseId, extractDraftId),
        method: "GET",
        responseHandler: async (response) =>
          response.blob().then((blob) => URL.createObjectURL(blob))
      }),
      providesTags: (result, error, input) =>
        result
          ? [
              {
                type: apiTags.caseExtractDraft,
                id: input.extractDraftId
              },
              {
                type: apiTags.extractTableOfContents,
                id: input.extractDraftId
              }
            ]
          : [],
      onCacheEntryAdded: async (
        _: unknown,
        api: { cacheDataLoaded: Promise<{ data: string }>; cacheEntryRemoved: Promise<void> }
      ) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        URL.revokeObjectURL(data);
      }
    }),

    getTableOfContentsInfoExtractDraft: builder.query<
      TableOfContentsInfoResponseModel,
      { caseId: string; extractDraftId: string; bundles: ExtractDraftBundleModel[] }
    >({
      query: ({ caseId, extractDraftId, bundles }) => ({
        url: tableOfContentsInfoExtractDraftUrl(caseId, extractDraftId),
        method: "POST",
        body: bundles
      }),
      providesTags: (result, error, input) =>
        result
          ? [
              {
                type: apiTags.caseExtractDraft,
                id: input.extractDraftId
              }
            ]
          : []
    }),

    deleteFrontpageExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string }
    >({
      query: ({ caseId, extractDraftId }) => ({
        url: frontpageExtractDraftUrl(caseId, extractDraftId),
        method: "DELETE"
      }),
      // Optimistic update
      onQueryStarted: async ({ caseId, extractDraftId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          extractDraftApi.util.updateQueryData(
            "getExtractDraft",
            { caseId, extractDraftId },
            (draft) => {
              delete draft.frontpage.documentUrl;
              delete draft.frontpage.caseDocumentId;
              delete draft.frontpage.caseDocumentName;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, revert to the original value
          patchResult.undo();
        }
      }
      // invalidatesTags: (_, error, input) => !error ? [
      //   { type: apiTags.caseExtractDraft, id: input.extractDraftId },
      // ] : [],
    }),
    deleteDocumentFromSectionExtractDraft: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        documentId: string;
        sectionId: string;
        shouldInvalidate?: boolean;
      }
    >({
      query: ({ caseId, documentId, sectionId }) => ({
        url: `${extractDraftUrl(caseId)}/document/${sectionId}/${documentId}`,
        method: "DELETE"
      }),
      // Optimistic update
      onQueryStarted: async (
        { caseId, extractDraftId, sectionId, documentId },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extractDraftApi.util.updateQueryData(
            "getExtractDraft",
            { caseId, extractDraftId },
            (draft) => {
              const section = draft.sections.find((sec) => sec.id === sectionId);
              if (section) {
                section.documents = section.documents.filter(
                  (document) => document.id !== documentId
                );
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, revert to the original value
          patchResult.undo();
        }
      },
      invalidatesTags: (_, error, input) => {
        const { shouldInvalidate = true } = input || {};

        if (error) {
          return [];
        }

        return shouldInvalidate
          ? [
              { type: apiTags.caseExtractDraft, id: input.extractDraftId },
              apiTags.caseDocument,
              { type: apiTags.extractTableOfContents, id: input.extractDraftId }
            ]
          : [{ type: apiTags.extractTableOfContents, id: input.extractDraftId }];
      }
    }),

    updateFrontpageExtractDraft: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; caseDocumentId: string }
    >({
      query: ({ caseId, extractDraftId, caseDocumentId }) => ({
        url:
          frontpageExtractDraftUrl(caseId, extractDraftId) + `/?caseDocumentId=${caseDocumentId}`,
        method: "PUT"
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    getFrontpageUrlExtractDraft: builder.query<
      string,
      { caseId: string; extractDraftId: string; caseDocumentId: string }
    >({
      query: ({ caseId, extractDraftId, caseDocumentId }) => ({
        url:
          frontpageExtractDraftUrl(caseId, extractDraftId) + `/?caseDocumentId=${caseDocumentId}`,
        method: "GET"
      }),
      transformResponse: (documentUrl: string) => {
        return fetch(documentUrl)
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob));
      },
      onCacheEntryAdded: async (
        _: unknown,
        api: {
          cacheDataLoaded: Promise<{ data: string }>;
          cacheEntryRemoved: Promise<void>;
        }
      ) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        if (data) {
          URL.revokeObjectURL(data);
        }
      }
    }),

    addPageIntervalToExtractDocument: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        extractDocumentId: string;
        model: AddPageIntervalToDraftDocumentModel;
      }
    >({
      query: ({ caseId, extractDocumentId, model }) => ({
        url: addPageIntervalToDocumentUrl(caseId, extractDocumentId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error
          ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }, apiTags.caseDocument]
          : []
    }),

    updateExtractDocument: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        extractDocumentId: string;
        model: ExtractDraftDocumentUpdateModel;
      }
    >({
      query: ({ caseId, extractDocumentId, model }) => ({
        url: updateExtractDocumentUrl(caseId, extractDocumentId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    createSection: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: ExtractDraftSectionCreateModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: createSectionUrl(caseId, extractDraftId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    updateSection: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        sectionId: string;
        model: ExtractDraftSectionUpdateModel;
      }
    >({
      query: ({ caseId, extractDraftId, sectionId, model }) => ({
        url: updateSectionUrl(caseId, extractDraftId, sectionId),
        method: "PUT",
        body: model
      }),
      // Optimistic updates
      onQueryStarted: async (
        { caseId, extractDraftId, sectionId, model },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          extractDraftApi.util.updateQueryData(
            "getExtractDraft",
            { caseId, extractDraftId },
            (draft) => {
              const section = draft.sections.find((sec) => sec.id === sectionId);
              if (section) {
                section.title = model.title;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    reorderTreeNode: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        treeNodeId: string;
        model: ExtractDraftReorderTreeNodeModel;
      }
    >({
      query: ({ caseId, extractDraftId, treeNodeId, model }) => ({
        url: reorderTreeNodeUrl(caseId, extractDraftId, treeNodeId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    sortTreeNode: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: ExtractDraftTreeNodeSortModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: treeNodeSort(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    deleteSection: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; sectionId: string }
    >({
      query: ({ caseId, extractDraftId, sectionId }) => ({
        url: deleteSectionUrl(caseId, extractDraftId, sectionId),
        method: "DELETE"
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    deleteExtractDraft: builder.mutation<string, { caseId: string; extractDraftId: string }>({
      query: ({ caseId, extractDraftId }) => ({
        url: deleteExtractDraftUrl(caseId, extractDraftId),
        method: "DELETE"
      }),
      invalidatesTags: (_, error, input) => (!error ? [apiTags.caseDocument] : [])
    }),

    deleteExtractDraftDocumentPageInterval: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        extractDraftDocumentId: string;
        extractDraftDocumentPageIntervalId: string;
      }
    >({
      query: ({ caseId, extractDraftDocumentId, extractDraftDocumentPageIntervalId }) => ({
        url: deleteExtractDraftDocumentPageIntervalUrl(
          caseId,
          extractDraftDocumentId,
          extractDraftDocumentPageIntervalId
        ),
        method: "DELETE"
      }),
      invalidatesTags: (_, error, input) =>
        !error
          ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }, apiTags.caseDocument]
          : []
    }),

    addExtractDraftDocuments: builder.mutation<
      string[],
      { caseId: string; extractDraftId: string; model: AddDocumentsToDraftModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: extractDraftDocumentsUrl(caseId, extractDraftId),
        method: "POST",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error
          ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }, apiTags.caseDocument]
          : []
    }),
    updateExtractDraftDocument: builder.mutation<
      string,
      { caseId: string; extractDraftId: string; model: ExtractDraftSectionDocumentUpdateModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: updateExtractDraftSectionDocument(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      onQueryStarted: async ({ caseId, extractDraftId, model }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          extractDraftApi.util.updateQueryData(
            "getExtractDraft",
            { caseId, extractDraftId },
            (draft) => {
              const section = draft.sections.find((sec) =>
                sec.documents.some((doc) => doc.id === model.id)
              );
              if (section) {
                const document = section.documents.find((doc) => doc.id === model.id);
                if (document) {
                  if (
                    model.intervalExtractionType === IntervalExtractionType.NoDocuments ||
                    model.intervalExtractionType === IntervalExtractionType.PagesWithVerticalLines
                  ) {
                    document.pageIntervals = [];
                  } else {
                    document.pageIntervals = model.pageIntervals.split(",").map((interval) => {
                      const [firstPageIndex, lastPageIndex] = interval
                        .split("-")
                        .map((index) => parseInt(index, 10));
                      return {
                        firstPageIndex: firstPageIndex - 1,
                        lastPageIndex: lastPageIndex - 1,
                        caseExtractDraftDocumentId: model.id
                      };
                    });
                  }
                  document.caseDocumentName = model.title;
                  document.intervalExtractionType = model.intervalExtractionType;
                  document.caseDocumentDateType = model.dateType;
                  document.caseDocumentNumber = model.caseReferenceNumber;
                  document.includeInToC = model.includeInToC;
                  document.includeMarkings = model.includeMarkings;
                  document.caseDocumentDate = model.date;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      // we invalidate the caseDocument tag because that tag includes the extract in the document list which shows the total amount of pages.
      // this mutation can change the pageintervals which in turn changes the total amount of pages
      // Only refetch when IntervalExtractionType is PagesWithVerticalLines since we don't know the new page count
      invalidatesTags: (_, error, input) => {
        const tags: {
          type:
            | typeof apiTags.caseDocument
            | typeof apiTags.caseExtractDraft
            | typeof apiTags.extractTableOfContents;
          id?: string;
        }[] = [
          { type: apiTags.caseDocument },
          { type: apiTags.extractTableOfContents, id: input.extractDraftId }
        ];
        if (
          !error &&
          input.model.intervalExtractionType === IntervalExtractionType.PagesWithVerticalLines
        ) {
          tags.push({ type: apiTags.caseExtractDraft, id: input.extractDraftId });
        }
        return tags;
      }
    }),

    reorderExtractDraftSection: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        sectionId: string;
        model: ExtractDraftSectionOrderUpdateModel;
      }
    >({
      query: ({ caseId, extractDraftId, sectionId, model }) => ({
        url: reorderSectionUrl(caseId, extractDraftId, sectionId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    reorderExtractDraftSectionDocument: builder.mutation<
      string,
      {
        caseId: string;
        extractDraftId: string;
        sectionId: string;
        documentId: string;
        newSortOrder: number;
      }
    >({
      query: ({ caseId, extractDraftId, sectionId, documentId, newSortOrder }) => ({
        url: reorderSectionDocumentUrl(caseId, extractDraftId, sectionId, documentId, newSortOrder),
        method: "PUT"
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    }),

    setSectionSortOrder: builder.mutation<
      undefined,
      { caseId: string; extractDraftId: string; model: SetSectionSortOrderModel }
    >({
      query: ({ caseId, extractDraftId, model }) => ({
        url: setDocumentSortOrderUrl(caseId, extractDraftId),
        method: "PUT",
        body: model
      }),
      invalidatesTags: (_, error, input) =>
        !error ? [{ type: apiTags.caseExtractDraft, id: input.extractDraftId }] : []
    })
  })
});

export default extractDraftApi;

export const {
  useGetExtractDraftQuery,
  useGetExtractDraftExportDownloadUrlQuery,
  useLazyGetExtractDraftExportDownloadUrlQuery,
  useCreateExtractDraftMutation,
  useAddExtractDraftDocumentsMutation,
  useDeleteExtractDraftDocumentPageIntervalMutation,
  useDeleteExtractDraftMutation,
  useAddPageIntervalToExtractDocumentMutation,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
  useUpdateExtractDraftMutation,
  useUpdateExtractDocumentMutation,
  useCreateFrontpageExtractDraftMutation,
  useDeleteFrontpageExtractDraftMutation,
  useReorderTreeNodeMutation,
  useSortTreeNodeMutation,
  useUpdateTableOfContentsExtractDraftMutation,
  useGetTableOfContentsUrlExtractDraftQuery,
  useUpdateFrontpageExtractDraftMutation,
  useExportExtractDraftMutation,
  useCompleteExtractDraftMutation,
  useGetExtractDraftExportDocumentQuery,
  useLazyGetExtractDraftExportDocumentQuery,
  useGetFrontpageUrlExtractDraftQuery,
  useUpdateExtractDraftFrontpageSettingsMutation,
  useDeleteDocumentFromSectionExtractDraftMutation,
  useUpdateExtractDraftDocumentMutation,
  useReorderExtractDraftSectionMutation,
  useReorderExtractDraftSectionDocumentMutation,
  useSetSectionSortOrderMutation,
  useGetTableOfContentsInfoExtractDraftQuery,
  useLazyGetTableOfContentsInfoExtractDraftQuery,
  useTransformSectionDocumentsTitleMutation,
  useRevertDocumentTitleMutation
} = extractDraftApi;

export const { resetApiState } = extractDraftApi.util;
