import classNames from "classnames";
import { PointerEvent } from "react";
import { ResizeDirection } from "./resizable";
import styles from "./resizeHandles.module.scss";

interface ResizeHandlesProps {
  active: boolean;
  resizableHeight?: boolean;
  resizableWidth?: boolean;
  onDragStart: (direction: ResizeDirection) => void;
}

const ResizeHandles = ({
  active,
  resizableHeight,
  resizableWidth,
  onDragStart
}: ResizeHandlesProps) => {
  if (!active) {
    return null;
  }

  const pointerDown = (e: PointerEvent, direction: ResizeDirection) => {
    if (e.button !== 0) {
      return;
    }
    e.preventDefault(); // prevent drag
    onDragStart(direction);
  };

  return (
    <>
      {resizableHeight && (
        <>
          <div
            className={classNames(styles.edgeHandle, styles.northHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.North)}
          />
          <div
            className={classNames(styles.edgeHandle, styles.southHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.South)}
          />
        </>
      )}
      {resizableWidth && (
        <>
          <div
            className={classNames(styles.edgeHandle, styles.eastHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.East)}
          />
          <div
            className={classNames(styles.edgeHandle, styles.westHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.West)}
          />
        </>
      )}
      {resizableHeight && resizableWidth && (
        <>
          <div
            className={classNames(styles.cornerHandle, styles.northWestHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.NorthWest)}
          />
          <div
            className={classNames(styles.cornerHandle, styles.northEastHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.NorthEast)}
          />
          <div
            className={classNames(styles.cornerHandle, styles.southWestHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.SouthWest)}
          />
          <div
            className={classNames(styles.cornerHandle, styles.southEastHandle)}
            onPointerDown={(e) => pointerDown(e, ResizeDirection.SouthEast)}
          />
        </>
      )}
    </>
  );
};

export default ResizeHandles;
