import { RoundBadge } from "@components/badge/RoundBadgge";
import { EmblaIcon, IconColor, IconSize } from "@components/embla/emblaIcon";
import { ReactComponent as PresentationIcon } from "@content/icons/presentation-icon.svg";
import { ReactComponent as MarkIcon } from "@content/icons/mark-icon.svg";
import { BadgeSize } from "src/utility/constants";
import { DocumentPageOverlayIconTypeEnum } from "../models/documentPageOverlayIconType";

type ThumbnailIconProps = {
  iconType: DocumentPageOverlayIconTypeEnum;
  badgeSize: BadgeSize;
};

const ThumbnailIcon = ({ iconType, badgeSize }: ThumbnailIconProps) => {
  const svgIconSize = badgeSize === BadgeSize.ExtraExtraSmall ? 12 : 14;
  const emblaIconSize =
    badgeSize === BadgeSize.ExtraExtraSmall ? IconSize.ExtraSmall : IconSize.Small;

  const renderIcon = () => {
    switch (iconType) {
      case DocumentPageOverlayIconTypeEnum.Presentation:
        return (
          <PresentationIcon height={svgIconSize} width={svgIconSize} className="color-white" />
        );
      case DocumentPageOverlayIconTypeEnum.Comment:
        return <EmblaIcon iconName="dialog" color={IconColor.White} size={emblaIconSize} />;
      case DocumentPageOverlayIconTypeEnum.Highlight:
        return <MarkIcon height={svgIconSize} width={svgIconSize} className="color-white" />;
      case DocumentPageOverlayIconTypeEnum.Rotation:
        return <EmblaIcon iconName="warning" color={IconColor.Warning} size={emblaIconSize} />;
      default:
        break;
    }
  };

  return (
    <RoundBadge key={iconType} size={badgeSize}>
      {renderIcon()}
    </RoundBadge>
  );
};

export default ThumbnailIcon;
