type FormDataPrimitive = string | number | boolean | Blob | File;

interface FormDataNest {
  [x: string]: FormVal;
}

type FormVal = FormDataNest | FormVal[] | FormDataPrimitive;

const buildFormData = (formData: FormData, data: FormVal, parentKey?: string) => {
  if (data === null || data === undefined) {
    return;
  } else if (Array.isArray(data)) {
    data.forEach((el) => {
      buildFormData(formData, el, parentKey);
    });
  } else if (typeof data === "object" && !(data instanceof File || data instanceof Blob)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, (data as FormDataNest)[key], parentKey ? `${parentKey}.${key}` : key);
    });
  } else {
    const value = typeof data === "boolean" || typeof data === "number" ? data.toString() : data;
    formData.append(parentKey as string, value);
  }
};

export const getFormData = (data: FormDataNest) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};
