import { useEffect } from "react";
import { Event } from "@infrastructure/Event";

/**
 * Version of outside click listener that does not require element reference.
 * Instead it uses html element id.
 * Useful for virtualized elements such as react-window list.
 */
export const useVirtualClickOutside = (elementId?: string, handler?: () => void) => {
  useEffect(() => {
    if (!elementId || !handler) {
      return;
    }
    const listener = (event: MouseEvent | TouchEvent) => {
      // call handler if clicked element is not current element or it's descendant
      if (elementId && event.target instanceof Element && !event.target.closest(`#${elementId}`)) {
        handler();
      }
    };
    document.addEventListener(Event.Pointerdown, listener);
    return () => {
      document.removeEventListener(Event.Pointerdown, listener);
    };
  }, [handler, elementId]);
};
