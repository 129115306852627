import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef, ReactNode, Ref, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./navItemDropdown.module.scss";

interface ChildrenProps {
  children: ReactNode;
}

const NavItemDropdown = ({ children }: ChildrenProps) => (
  <Menu as="li" className="nav-item relative">
    {children}
  </Menu>
);

const Button = ({
  children,
  isIcon,
  buttonStyling
}: ChildrenProps & { isIcon?: boolean; buttonStyling?: string }) => (
  <Menu.Button
    as="a"
    href="#"
    role="button"
    className={classNames("nav-link", isIcon && "btn-icon", buttonStyling)}
  >
    {children}
  </Menu.Button>
);

const Items = ({ children }: ChildrenProps) => (
  <Menu.Items as="ul" className={classNames("dropdown-menu", "show", styles.items)}>
    {children}
  </Menu.Items>
);

const ButtonItem = forwardRef(
  (
    { className, ...buttonProps }: ButtonHTMLAttributes<HTMLButtonElement>,
    ref: Ref<HTMLButtonElement>
  ) => (
    <li>
      <Menu.Item>
        {({ active }) => (
          <button
            ref={ref}
            type="button"
            className={classNames("dropdown-item", styles.item, active && styles.active, className)}
            {...buttonProps}
          />
        )}
      </Menu.Item>
    </li>
  )
);

export interface ExpandingItemProps {
  children: ReactNode;
  buttonText?: string;
  subtext?: string;
}

const ExpandingItem = ({ buttonText, subtext, children }: ExpandingItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ButtonItem
        className="d-flex align-items-center gap-s"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <span>{buttonText}</span>
        <span className="subtle">{subtext}</span>
        <EmblaIcon iconName={open ? "arrow-up" : "arrow-down"} additionalClasses="ml-auto" />
      </ButtonItem>
      {open && <div className={styles.expandedItems}>{children}</div>}
    </>
  );
};

const LinkItem = ({
  className,
  ...linkProps
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) => (
  <li>
    <Menu.Item>
      {({ active }) => (
        <Link
          className={classNames("dropdown-item", styles.item, active && styles.active, className)}
          {...linkProps}
        />
      )}
    </Menu.Item>
  </li>
);

const ItemDivider = () => <div className="dropdown-divider" />;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(NavItemDropdown, {
  Button,
  Items,
  ItemDivider,
  ButtonItem,
  ExpandingItem,
  LinkItem
});
