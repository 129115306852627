import classNames from "classnames";
import { forwardRef, Ref } from "react";
import StateManagedSelect, {
  ActionMeta,
  GroupBase,
  mergeStyles,
  OnChangeValue,
  Props,
  SelectInstance,
  StylesConfig
} from "react-select";
import Creatable, { CreatableProps } from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { useLocalization } from "@components/localization/localizationProvider";
import useForwardedRef from "src/hooks/useForwardedRef";
import MultiValuesAsTextValueContainer from "./components/multiValuesAsTextValueContainer";
import styles from "./dropdown.module.scss";

export type DropdownOption<T = unknown> = {
  label: string;
  value: T;
};

export type DropdownProps<
  T,
  Option extends DropdownOption<T>,
  IsCreatable extends boolean,
  IsMulti extends boolean
> = {
  allowCreate?: IsCreatable;
  hideDropdownIndicator?: boolean;
  renderMultiValuesAsText?: boolean;
  minWidth?: string | number;
  colorAsSubjects?: boolean;
  closeMenuOnOptions?: T[];
  stylesOverride?: StylesConfig;
} & (IsCreatable extends true
  ? CreatableProps<Option, IsMulti, GroupBase<Option>>
  : Props<Option, IsMulti>);

export const Dropdown = forwardRef(
  <
    T,
    Option extends DropdownOption<T>,
    IsCreatable extends boolean = false,
    IsMulti extends boolean = false
  >(
    {
      allowCreate,
      hideDropdownIndicator,
      renderMultiValuesAsText = false,
      minWidth,
      colorAsSubjects = false,
      onChange,
      closeMenuOnOptions,
      className,
      stylesOverride,
      ...props
    }: DropdownProps<T, Option, IsCreatable, IsMulti>,
    forwardedRef: Ref<SelectInstance<Option, IsMulti>>
  ) => {
    const dropdownRef = useForwardedRef(forwardedRef);
    const localizer = useLocalization();

    const componentsOverride: Partial<SelectComponents<Option, IsMulti, GroupBase<Option>>> = {
      /* eslint-disable @typescript-eslint/naming-convention */
      IndicatorSeparator: null,
      ...(props.isMulti && renderMultiValuesAsText
        ? { ValueContainer: MultiValuesAsTextValueContainer }
        : {}),
      ...(hideDropdownIndicator ? { DropdownIndicator: null } : {}),
      ...props.components
    };

    const DropdownComponent = allowCreate ? Creatable : StateManagedSelect;

    if (!props.placeholder) {
      props.placeholder = "";
    }

    const handleChange = (
      newValue: OnChangeValue<Option, IsMulti>,
      actionMeta: ActionMeta<Option>
    ) => {
      onChange?.(newValue, actionMeta);
      if (
        closeMenuOnOptions &&
        actionMeta.action === "select-option" &&
        actionMeta.option &&
        closeMenuOnOptions.includes(actionMeta.option.value)
      ) {
        dropdownRef.current?.onMenuClose();
      }
    };

    return (
      <DropdownComponent
        ref={dropdownRef}
        onChange={handleChange}
        noOptionsMessage={() => localizer.noResultsFound()}
        formatCreateLabel={(input) => `${localizer.create()} "${input}"`}
        styles={mergeStyles(buildBaseStyles(minWidth, colorAsSubjects), stylesOverride)}
        className={classNames(styles.dropdown, className)}
        components={componentsOverride}
        closeMenuOnSelect={!closeMenuOnOptions}
        {...props}
      />
    );
  }
);

const buildBaseStyles = (
  minWidth: string | number | undefined,
  colorAsSubjects: boolean
): StylesConfig => ({
  container: (provided) => ({
    ...provided,
    pointerEvents: "all",
    fontWeight: 400,
    minWidth: minWidth,
    fontSize: "var(--dropdown-font-size)",
    lineHeight: "var(--dropdown-line-height)"
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    cursor: isDisabled ? "not-allowed" : "pointer",
    borderColor: "var(--dropdown-border-color) !important",
    minHeight: "var(--dropdown-height)",
    boxShadow: isFocused ? "var(--dropdown-focus-shadow)" : undefined,
    backgroundColor: isDisabled
      ? "var(--dropdown-disabled-background-color)"
      : provided.backgroundColor
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dropdown-placeholder-color)"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
    lineHeight: "17px"
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0 4px"
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "var(--dropdown-indicator-color) !important",
    width: "16px",
    padding: 0
  }),
  dropdownIndicator: (provided, { selectProps }) => ({
    ...provided,
    color: `var(${selectProps.menuIsOpen ? "--dropdown-caret-open-color" : "--dropdown-indicator-color"}) !important`,
    transform: selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
    width: "16px",
    padding: 0
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1001
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: "200px"
  }),
  option: (provided, { isSelected, isFocused }) => {
    const style = {
      ...provided,
      padding: "6px 8px",
      color: undefined
    };
    if (isSelected) {
      style.backgroundColor = "var(--dropdown-selected-item-background-color)";
      style.borderLeft = "2px solid var(--dropdown-selected-item-border-color)";
      style.paddingLeft = "6px";
    }
    if (isFocused) {
      style.backgroundColor = "var(--dropdown-item-highlighted-background-color)";
    }
    return style;
  },
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled
      ? "var(--dropdown-multi-item-selected-disabled-background-color)"
      : colorAsSubjects
        ? "var(--dropdown-multi-item-selected-background-color-as-subjects)"
        : "var(--dropdown-multi-item-selected-background-color)",
    color: "var(--dropdown-multi-item-selected-text-color)",
    borderRadius: "20px",
    padding: "3px 8px 2px",
    display: "flex",
    alignItems: "center"
  }),
  multiValueLabel: () => ({
    fontSize: "12px",
    padding: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    overflowWrap: "normal"
  }),
  multiValueRemove: (_, { isDisabled }) => ({
    display: isDisabled ? "none" : "flex",
    marginLeft: "4px",
    width: "12px"
  }),
  group: (provided, { data }) => {
    const style = {
      ...provided
    };

    if (!data.label) {
      style.borderTop = "solid 1px #dcdcdc";
      style.paddingTop = "0";
      style.paddingBottom = "0";
      style.marginTop = "0";
    }

    return style;
  },
  groupHeading: (provided, { data }) => ({
    ...provided,
    height: data.label === undefined ? "0" : provided.height,
    marginBottom: data.label === undefined ? "0" : provided.marginBottom
  })
});
