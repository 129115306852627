import {
  PresentationFileUrlViewModel,
  PresentationPageFileUrlModel
} from "@services/api/case/models/PresentationFileUrlViewModel";
import { DownloadFileUrlViewModel } from "./document/models/downloadFileUrlViewModel";

const cacheFileDownloadUrl = {
  transformResponse: (original: DownloadFileUrlViewModel) => {
    return fetch(original.downloadUrl)
      .then((response) => response.blob())
      .then((blob) => ({
        ...original,
        downloadUrl: URL.createObjectURL(blob)
      }));
  },
  onCacheEntryAdded: async (
    _: unknown,
    api: {
      cacheDataLoaded: Promise<{ data: DownloadFileUrlViewModel }>;
      cacheEntryRemoved: Promise<void>;
    }
  ) => {
    const { data } = await api.cacheDataLoaded;
    await api.cacheEntryRemoved;
    URL.revokeObjectURL(data.downloadUrl);
  }
};

export const cachePresentationFilesDownloadUrl = {
  transformResponse: (original?: PresentationFileUrlViewModel) => {
    if (original && original.presentationPageFileUrlModels?.length > 0) {
      return Promise.all(
        original.presentationPageFileUrlModels.map((page) =>
          fetch(page.downloadUrl)
            .then((response) => response.blob())
            .then((blob) => ({
              ...page,
              downloadUrl: URL.createObjectURL(blob)
            }))
        )
      ).then((result) => ({
        ...original,
        presentationPageFileUrlModels: result
      }));
    } else {
      return original;
    }
  },
  onCacheEntryAdded: async (
    _: unknown,
    api: {
      cacheDataLoaded: Promise<{ data?: PresentationFileUrlViewModel }>;
      cacheEntryRemoved: Promise<void>;
    }
  ) => {
    const { data } = await api.cacheDataLoaded;
    await api.cacheEntryRemoved;
    if (data) {
      data.presentationPageFileUrlModels.forEach((page) => URL.revokeObjectURL(page.downloadUrl));
    }
  }
};

export const cachePresentationPageFileDownloadUrl = {
  transformResponse: (original: PresentationPageFileUrlModel) => {
    return fetch(original.downloadUrl)
      .then((response) => response.blob())
      .then((blob) => ({
        ...original,
        downloadUrl: URL.createObjectURL(blob)
      }));
  },
  onCacheEntryAdded: async (
    _: unknown,
    api: {
      cacheDataLoaded: Promise<{ data: PresentationPageFileUrlModel }>;
      cacheEntryRemoved: Promise<void>;
    }
  ) => {
    const { data } = await api.cacheDataLoaded;
    await api.cacheEntryRemoved;
    URL.revokeObjectURL(data.downloadUrl);
  }
};

export default cacheFileDownloadUrl;
