import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAppDispatch } from "@hooks";
import { useGetEmailTemplatesQuery } from "@services/api/emailTemplate/emailTemplateApi";
import Table from "@components/table/table";
import Card from "@components/embla/card";
import { RoutePaths } from "@components/routing/routes";
import { EmailTemplateModel } from "@services/api/emailTemplate/models/emailTemplateModel";

export const EmailTemplatesPage: React.FC = () => {
  const { data: emailTemplates, isLoading } = useGetEmailTemplatesQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const localizer = useLocalization();

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.list(), url: "" }));
    dispatch(setTitle(localizer.emailTemplate()));
  }, [dispatch, localizer]);

  const getColumns = useMemo(() => {
    const columns: Column<EmailTemplateModel>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.type(),
        accessor: "readableType"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.language(),
        accessor: "readableCulture"
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.subject(),
        accessor: "subject",
        width: 350
      }
    ];
    return columns;
  }, [localizer]);

  return (
    <Card>
      <Table
        columns={getColumns}
        data={emailTemplates ?? []}
        rowOnClick={(emailTemplate) => {
          navigate(RoutePaths.emailTemplateEdit.url(emailTemplate.type, emailTemplate.culture));
        }}
        noResultsOptions={{
          noResultsMessage: localizer.noResultsFound()
        }}
        isLoading={isLoading}
        usePages={true}
        useSort={true}
        useSearch={true}
        pageOptions={{
          hidePageSizeFilter: true,
          initialPageSize: 25
        }}
      />
    </Card>
  );
};
