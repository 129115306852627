import React from "react";
import { mentionConstants } from "@components/mention/mentionConstants";
import styles from "./showMentions.module.scss";

export type ShowMentionsProps = {
  content: string;
};

const ShowMentions = ({ content }: ShowMentionsProps) => {
  const parseContent = () => {
    const contentSplit = content.split(mentionConstants.mentionBeginTag);
    const transformedContent = contentSplit.map((c, i) => {
      if (c.match(mentionConstants.mentionEndTag)) {
        const endSplit = c.split(mentionConstants.mentionEndTag);
        return [
          <span key={i} className={styles.mention}>
            {endSplit[0]}
          </span>,
          endSplit[1]
        ];
      } else {
        return c;
      }
    });

    return transformedContent.flat();
  };

  return <span>{parseContent()}</span>;
};

export default ShowMentions;
