export enum CaseRight {
  AddUsers = 1,
  DeleteDocuments = 2,
  EditDeleteAllMarkings = 3,
  EditDeletePresentations = 4
}

// https://bobbyhadz.com/blog/typescript-get-enum-values-as-array
export const allCaseRights = Object.values(CaseRight).filter(
  (v) => !isNaN(Number(v))
) as CaseRight[];
