import classNames from "classnames";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { ReactComponent as PresentIcon } from "@content/icons/present-icon.svg";
import { useAppDispatch, useAppSelector } from "@hooks";
import presentationApi from "@services/api/presentation/presentationApi";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./tempPresentationPageOverlay.module.scss";
import {
  clearTempPresentationPageState,
  tempPresentationPageStateSelector
} from "./presentationSlice";
import {
  PRESENTATION_BROADCAST_CHANNEL,
  PresentationAction,
  PresentationActionType
} from "./presentationBroadcastActions";

interface TempPresentationPageOverlayProps {
  newSearchClicked: () => void;
}

const TempPresentationPageOverlay = ({ newSearchClicked }: TempPresentationPageOverlayProps) => {
  const localizer = useLocalization();
  const dispatch = useAppDispatch();
  const tempPresentationPageState = useAppSelector(tempPresentationPageStateSelector);

  const { postMessage } = useBroadcastChannel<PresentationAction>({
    name: PRESENTATION_BROADCAST_CHANNEL
  });

  if (!tempPresentationPageState) {
    return null;
  }

  const resumePresentation = () => {
    postMessage({ type: PresentationActionType.StopShowTempPage });
    dispatch(presentationApi.util.invalidateTags(["CasePresentation"]));
    dispatch(clearTempPresentationPageState());
  };

  const showNewPage = () => {
    newSearchClicked();
  };

  return (
    <div
      className={classNames(
        styles.container,
        "rounded-top",
        "padding-m",
        "d-flex",
        "flex-column",
        "align-items-start",
        "gap-m"
      )}
    >
      <h3 className="text-white">{localizer.temporaryDisplay()}</h3>
      <div className="lead text-white">{localizer.chosenPageInsteadOfPresentation()}</div>

      <div className="d-flex w-100 justify-content-between">
        <Button theme="primary" onClick={resumePresentation}>
          <PresentIcon className="embla-icon icon-left" /> {localizer.backToPresentation()}
        </Button>
        <Button className={classNames(styles.newPageBtn)} onClick={showNewPage}>
          <EmblaIcon iconName={"document-view"} />
          {localizer.showNewPage()}
        </Button>
      </div>
    </div>
  );
};

export default TempPresentationPageOverlay;
