import React from "react";
import styles from "./tableBodyCenterContent.module.css";

type TableBodyCenterContentProps = {
  colSpan: number;
  className?: string;
};

export const TableBodyCenterContent: React.FC<TableBodyCenterContentProps> = ({
  colSpan,
  className,
  children
}) => {
  return (
    <tr role="row" className={className}>
      <td className={styles.alignedCell} colSpan={colSpan}>
        {children}
      </td>
    </tr>
  );
};
