import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks";
import { pdfViewerStateSelector, setGoToIndex } from "../../pdfViewerSlice";

export const useNavigateWithArrowKeys = <T extends HTMLElement>(
  columnCount: number,
  enabled = true
) => {
  const dispatch = useDispatch();
  const eventElementRef = useRef<T | null>(null);
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);

  const handleNavigateWithArrowKeys = useCallback(
    (event: KeyboardEvent) => {
      if (!enabled) {
        return;
      }

      switch (event.key) {
        case "ArrowLeft":
          dispatch(setGoToIndex(pdfViewerState.mostVisiblePageIndex - 1));
          break;
        case "ArrowRight":
          dispatch(setGoToIndex(pdfViewerState.mostVisiblePageIndex + 1));
          break;
        case "ArrowUp":
          dispatch(setGoToIndex(pdfViewerState.mostVisiblePageIndex - columnCount));
          break;
        case "ArrowDown":
          dispatch(setGoToIndex(pdfViewerState.mostVisiblePageIndex + columnCount));
          break;
      }
    },
    [dispatch, enabled, pdfViewerState.mostVisiblePageIndex, columnCount]
  );

  useEffect(() => {
    const eventElement = eventElementRef.current;

    if (eventElement) {
      eventElement.addEventListener("keydown", handleNavigateWithArrowKeys);
    }
    return () => {
      if (eventElement) {
        eventElement.removeEventListener("keydown", handleNavigateWithArrowKeys);
      }
    };
  }, [handleNavigateWithArrowKeys, eventElementRef]);

  return eventElementRef;
};
