import { useEffect, useState } from "react";
import BaseTable, { DefaultTableProps, DndOptions, ExpandingRowOptions } from "./baseTable";

type PageOptions = {
  pageSizeOptions?: number[];
  hidePageSizeFilter?: boolean;
  initialPageSize?: number;
};

type CollapsableSubRowOptions<T extends object> = {
  renderCollapsableRowComponent: (rowData: T) => JSX.Element;
};

type DragAndDropOptions<T extends object> = {
  onDragDropRow: (draggedRow: T, newIndex: number) => void;
  disableDrag?: boolean;
};

type TableProps<T extends object> = {
  useSearch?: boolean;
  useSort?: boolean;
  usePages?: boolean;
  pageOptions?: PageOptions;
  isLoading?: boolean;
  dragAndDropOptions?: DragAndDropOptions<T>;
  collapsableSubRowOptions?: CollapsableSubRowOptions<T>;
  // Note: If custom filtering is needed inside synchronous table --> Pass Custom filters in here and use to customize filtering inside baseTable
  // See: https://react-table.tanstack.com/docs/examples/filtering
} & DefaultTableProps<T>;

const Table = <T extends object>({
  columns,
  data,
  rowOnClick,
  noResultsOptions,
  useSearch = false,
  useSort = false,
  usePages = false,
  pageOptions,
  isLoading = false,
  collapsableSubRowOptions,
  dragAndDropOptions
}: TableProps<T>) => {
  const [tableData, setTableData] = useState<T[]>([]);

  useEffect(() => {
    // Because we have to make the "readonly data" writable, we have to wait for changes here:
    if (isLoading === false) {
      setTableData([...data]);
    }
  }, [data, isLoading]);

  const onPreview = (draggedIndex: number, hoverIndex: number) => {
    setTableData((prev) => {
      const dataToSplice = [...prev];
      const draggedItem = dataToSplice[draggedIndex];
      dataToSplice.splice(draggedIndex, 1);
      dataToSplice.splice(hoverIndex, 0, draggedItem);
      return dataToSplice;
    });
  };

  const intialPageSize = pageOptions
    ? (pageOptions.initialPageSize ??
      (pageOptions?.pageSizeOptions ? pageOptions.pageSizeOptions[0] : undefined))
    : undefined;

  const filterOptions =
    usePages || useSearch
      ? {
          usePageSizeFilter: usePages && !pageOptions?.hidePageSizeFilter,
          pageSizeOptions: pageOptions?.pageSizeOptions,
          useSearchQueryFilter: useSearch
        }
      : undefined;

  const dndOptions =
    dragAndDropOptions && !dragAndDropOptions.disableDrag
      ? ({
          onDragDropRowCallback: dragAndDropOptions.onDragDropRow,
          onDragDropPreviewCallback: onPreview
        } as DndOptions<T>)
      : undefined;

  const expandRowOptions: ExpandingRowOptions<T> | undefined = collapsableSubRowOptions
    ? {
        renderExpandedSubRowComponent: collapsableSubRowOptions.renderCollapsableRowComponent,
        expandOnClickRow: true,
        hideRowBorderTop: true,
        defaultExpandIfLessThanCount: 4
      }
    : undefined;

  return (
    <BaseTable
      columns={columns}
      data={tableData}
      noResultsOptions={noResultsOptions}
      rowOnClick={rowOnClick}
      isLoading={isLoading}
      filterOptions={filterOptions}
      isSortable={useSort}
      isPaginated={usePages}
      initialPageSize={intialPageSize}
      dndOptions={dndOptions}
      expandRowOptions={expandRowOptions}
    />
  );
};

export default Table;
